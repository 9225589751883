import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { catchError, map, } from 'rxjs/operators';
import { BehaviorSubject, throwError, Observable, } from 'rxjs';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import {
  ACTION, createCloudHeaders, getIsAnyDay, getNextDayAvailable, getWeekdaySchedule,
  isUndefinedOrNull, isUndefinedOrNullOrEmpty, randomIntFromInterval
} from '../../shared/utils';
import { IChatMessage } from '../../models';
import { Message, MenuItem } from '../../services/message';
import { MESSAGES, MENU_ITEMS } from '../../services/mock-messages';

import { SessionService } from '../session/session.service';

const RECORD_LIMIT = 10;
const TASK_ORIGIN = 'leadcarrot';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  // Source data
  _done = new BehaviorSubject(false);
  _loading = new BehaviorSubject(false);
  _data = new BehaviorSubject([]);
  private query: QueryConfig;
  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();
  messages: IChatMessage[] = [];
  messagesBehaviour = new BehaviorSubject<IChatMessage[]>([]);

  chatObserver: BehaviorSubject<IChatMessage[]>[] = [];

  // Observing section for multiple conversations
  private activeChats: any[] = [];

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private sessionService: SessionService,
  ) { }


  emailSend(email: any, taskId: string = '') {

    console.log('---------------------------------------------------');
    console.log('typeof email.dateTime', typeof email.dateTime);
    console.warn('EmailService => emailSend() | email:', email);
    console.log('---------------------------------------------------');

    try {
      const topicName = ACTION.EMAIL_SEND;

      const data = JSON.stringify(email);
      const customAttributes = {
        origin: TASK_ORIGIN,
        action: ACTION.EMAIL_SEND,
        taskId
      };

      const apiLeadCarrot = environment.cloudUrl + '/pubsubPublish';
      const headers = createCloudHeaders(this.sessionService.userToken);
      const body = {
        data: {
          message: email.message,
          config: email.config,
          isAutomation: email.isAutomation,
          performAt: email.performAt,
          schedule: email.schedule,
        },
        customAttributes,
        topicName,
      };


      return this.http.post(
        apiLeadCarrot,
        body,
        {
          headers,
          // withCredentials: true
        })
        .pipe(
          map((response: Response) => {
            console.log('pubsubPublish():', response);
            return { response, email };
          }),
          catchError((error: Response) => throwError(error))
        );
    } catch (error) {
      // console.error(error);
      // this.ngxLoader.stop();
      // this.openSnackBar('Unable to change password', 'close');
      // resetForm(this.passwordForm);
      console.error(error);
    }


    /*
    const body = JSON.stringify(sms);
    console.log('SMS Body:', body);

    return this.http
      .post(
        `${this.sessionService.END_POINT}/sms/${this.sessionService.companyId}`,
        body,
        { headers: createAuthHeaders(this.sessionService.userToken) }
      )
      .pipe(
        map((res: Response) => res),
        catchError((error: Response) => throwError(error))
      );

    // throwError(Error('TODO: Re-Enable the SMS Sending'));
    */
  }


  getMessages(): Promise<Message[]> {
    return Promise.resolve(MESSAGES);
  }

  getMenuItems(): Promise<MenuItem[]> {
    return Promise.resolve(MENU_ITEMS);
  }

  calculateTaskSchedule(step: any, lastDateTime: any) {

    const triggerTime = {
      immediate: false,
      dateTime: new Date(), // DEFAULT is now.
    };
    if (!isUndefinedOrNullOrEmpty(lastDateTime)) {
      triggerTime.dateTime = new Date(lastDateTime);
    }
    if (!isUndefinedOrNullOrEmpty(step) && !isUndefinedOrNullOrEmpty(step.schedule) && !isUndefinedOrNullOrEmpty(step.schedule.unitTime)) {
      if (step.schedule.unitTime === 'immediate' || step.schedule.unitTime === 'immediately') {
        triggerTime.immediate = true;
      }
    }

    console.log('triggerTime:', triggerTime);

    // Add the to current time
    if (step.schedule.unitTime === 'minutes') {
      // Add minutes to the time.
      const offset = (step.schedule.unitValue * ONE_MINUTE);
      triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);

    } else if (step.schedule.unitTime === 'hours') {
      // Add hours to the time.
      const offset = (step.schedule.unitValue * ONE_HOUR);
      triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);

    } else {
      // Add days to the time.
      const offset = (step.schedule.unitValue * ONE_DAY);
      triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);
    }

    const isAnyDay = getIsAnyDay(step.schedule.weekDaySelect);
    if (isAnyDay) {

      console.log('--- ANY DAY ---');

      if (step.schedule.timeSettingType === 'any_time') {

        console.log('--- TIME ANY ---');

      } else {

        console.log('--- TIME RANGE ---');

        // Get the range START and END time. (0 - 24);
        let fromHour = isUndefinedOrNull(step.schedule.fromTime) ? 0 : step.schedule.fromTime;
        if (fromHour < 0) {
          fromHour = 0;
        } else if (fromHour > 23) {
          fromHour = 23;
        }
        let toHour = step.schedule.toTime;
        if (toHour < fromHour) {
          toHour = fromHour + 1;
        } else if (toHour > 24) {
          toHour = 24;
        }

        // console.log('from:', fromHour);
        // console.log('to:', toHour);
        // console.log('units:', step.schedule.unitTime);

        // Trigger Time is OUTSIDE day range THEN
        const triggerMoment = moment(triggerTime.dateTime);

        // Get Trigger Time HOUR
        const hour = triggerMoment.get('hour');

        // console.log('hour:', hour);

        if (hour >= toHour) {

          console.log('AFTER time range');

          // Set HOUR to range start hour
          triggerMoment.set('hour', fromHour);
          // Set Minute with fuzzy filter
          if (!triggerTime.immediate) {
            triggerMoment.set('minute', randomIntFromInterval(0, 5));
          }

          // Set Day to next available day.
          const days = triggerMoment.get('date');
          triggerMoment.set('date', days + 1);

        } else if (hour < fromHour) {

          console.log('BEFORE time range');

          // Set HOUR to range start hour
          triggerMoment.set('hour', fromHour);
          // Set Minute with fuzzy filter
          if (!triggerTime.immediate) {
            triggerMoment.set('minute', randomIntFromInterval(0, 5));
          }

        } else {

          console.log('INSIDE time range');

          const minute = triggerMoment.get('minute');

          // Set Minute with fuzzy filter
          if (!triggerTime.immediate) {
            triggerMoment.set('minute', minute + 1);
          }
        }

        triggerTime.dateTime = triggerMoment.toDate();
      }

    } else {

      console.warn('--- SPECIFIC DAY/DAYS ---');

      if (step.schedule.timeSettingType === 'any_time') {

        console.warn('--- TIME ANY ---');

        // Get the trigger time
        const triggerMoment = moment(triggerTime.dateTime);
        const weekday = triggerMoment.isoWeekday();

        // Get weekday select
        const weekdaySelector = getWeekdaySchedule(step.schedule.weekDaySelect);
        const dayOffset = getNextDayAvailable(weekday + 1, weekdaySelector);

        if (weekdaySelector[weekday] === false) {
          // Set Day to next available day.
          const days = triggerMoment.get('date');

          if (dayOffset === 7) {
            triggerMoment.set('date', days + 1);
          } else {
            triggerMoment.set('date', days + dayOffset + 1);
          }
        }

        triggerTime.dateTime = triggerMoment.toDate();

      } else {
        console.warn('--- TIME RANGE ---');

        // Get the range START and END time. (0 - 24);
        let fromHour = isUndefinedOrNull(step.schedule.fromTime) ? 0 : step.schedule.fromTime;
        if (fromHour < 0) {
          fromHour = 0;
        } else if (fromHour > 23) {
          fromHour = 23;
        }
        let toHour = step.schedule.toTime;
        if (toHour < fromHour) {
          toHour = fromHour + 1;
        } else if (toHour > 24) {
          toHour = 24;
        }

        // Trigger Time is OUTSIDE day range THEN
        const triggerMoment = moment(triggerTime.dateTime);
        const weekday = triggerMoment.isoWeekday();

        // Get weekday select
        const weekdaySelector = getWeekdaySchedule(step.schedule.weekDaySelect);
        const dayOffset = getNextDayAvailable(weekday + 1, weekdaySelector);

        if (dayOffset < 1) {

          console.warn('BAD OFFSET');

          // Trigger Time is OUTSIDE hour range THEN
          const hour = triggerMoment.get('hour');
          if (hour < toHour || hour >= fromHour) {
            // Increase DAY by +1

            // Set HOUR to range start hour
            triggerMoment.set('hour', fromHour);
            // Set Minute with fuzzy filter
            if (!triggerTime.immediate) {
              triggerMoment.set('minute', randomIntFromInterval(0, 5));
            }
          }
        } else {

          // Get Trigger Time HOUR
          const hour = triggerMoment.get('hour');

          console.log('hour:', hour);

          if (hour >= toHour) {

            console.log('AFTER time range');

            // Set HOUR to range start hour
            triggerMoment.set('hour', fromHour);
            // Set Minute with fuzzy filter
            if (!triggerTime.immediate) {
              triggerMoment.set('minute', randomIntFromInterval(0, 5));
            }

            if (dayOffset > 0) {
              // Set Day to next available day.
              const days = triggerMoment.get('date');

              if (dayOffset === 7) {
                triggerMoment.set('date', days + 1);
              } else {
                triggerMoment.set('date', days + dayOffset + 1);
              }

              // Trigger Time is OUTSIDE hour range THEN
              // const hour = triggerMoment.get('hour');
              if (hour < toHour || hour >= fromHour) {
                // Increase DAY by +1

                // Set HOUR to range start hour
                triggerMoment.set('hour', fromHour);
                // Set Minute with fuzzy filter
                if (!triggerTime.immediate) {
                  triggerMoment.set('minute', randomIntFromInterval(0, 5));
                }
              }
            }

          } else if (hour < fromHour) {

            console.log('BEFORE time range');

            // Set HOUR to range start hour
            triggerMoment.set('hour', fromHour);
            // Set Minute with fuzzy filter
            if (!triggerTime.immediate) {
              triggerMoment.set('minute', randomIntFromInterval(0, 5));
            }

          } else {

            console.log('INSIDE time range');

            const minute = triggerMoment.get('minute');

            // Set Minute with fuzzy filter
            if (!triggerTime.immediate) {
              triggerMoment.set('minute', minute + 1);
            }
          }
        }

        console.log('triggerMoment:', triggerMoment.toDate());

        triggerTime.dateTime = triggerMoment.toDate();
      }
    }

    return triggerTime;
  }

}
