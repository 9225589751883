export class Upload {
    createdBy?: string;
    fileName: string;
    url: string;
    userName?: string;
    createdAt?: Date;

    constructor(
        fileName: string,
    ) {
        this.fileName = fileName;
        this.url = '';
    }
}
