import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatFormField } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab } from '@angular/material/tabs';
import { MatToolbar } from '@angular/material/toolbar';

import { ExportResults } from '../models';
import { MaterialModule } from '../shared/material.module';

@Component({
  selector: 'app-export-results-dialog',
  templateUrl: './export-results-dialog.component.html',
  styleUrls: ['./export-results-dialog.component.scss'],
})
export class ExportResultsDialogComponent implements OnInit {
  form: FormGroup;
  title: string;

  constructor(
    // @Inject('moment') private moment,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExportResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      userId,
      taskId,
      recordCount,
      category,
      location,
      title,
      message,
      filename,
    }: ExportResults
  ) {
    this.title = title;

    this.form = fb.group({
      filename: [filename, Validators.required],
    });
  }

  ngOnInit() {}

  public export() {
    this.dialogRef.close(this.form.value);
  }

  public close() {
    this.dialogRef.close();
  }
}
