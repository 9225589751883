<div *ngIf="snapshot | async as snap">


    <div class="box">
        <div class="full-width filename-upload">{{ file.name }}</div>
        <div class="full-width" style="display: inline-flex; justify-content: flex-end;">
            <div *ngIf="!percentage" class="details">
                <div class="transferSize">0 KiB</div>
                <div class="percentDone text-right">0%</div>
                <div class="full-width">
                    <progress [value]="0" max="100" class="statusbar-upload"></progress>
                </div>
            </div>

            <div *ngIf="percentage | async as pct" class="details">
                <div class="transferSize">
                    <span *ngIf="pct < 100">{{ toKiB(snap?.bytesTransferred)| number: '1.0-2' }} KiB of</span>
                    {{ toKiB(snap?.totalBytes) | number: '1.0-2' }} KiB</div>
                <div class="percentDone text-right">
                    <span *ngIf="pct < 100">{{ pct | number: '1.0-2' }}%</span>
                </div>

                <div class="full-width">
                    <div *ngIf="pct < 100" class="full-width">
                        <progress [value]="pct" max="100" class="statusbar-upload"></progress>
                    </div>
                    <div *ngIf="!isActive(snap) && !fileInfo" class="full-width">
                        {{ waitMessage }}
                    </div>
                </div>

                <div *ngIf="fileInfo">
                    <div *ngIf="pct === 100" class="full-width">
                        <span style="color: green;">{{ fileMessage }}</span>
                    </div>
                </div>
                <div *ngIf="!fileInfo">
                    <div class="full-width">
                        <span>{{ fileMessage }}</span>
                    </div>
                </div>
            </div>

            <div class="actions">
                <div *ngIf="percentage | async as pct">

                    <div *ngIf="pct !== 100" class="justify">
                        <button (click)="task.pause()" [disabled]="!isActive(snap)" mat-mini-fab color="primary"
                            class="btn-actions">
                            <mat-icon svgIcon="pause"></mat-icon>
                        </button>
                        <button (click)="task.cancel()" [disabled]="!isActive(snap)" mat-mini-fab color="primary"
                            class="btn-actions">
                            <mat-icon svgIcon="stop"></mat-icon>
                        </button>
                        <button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')" mat-mini-fab
                            color="primary" class="btn-actions">
                            <mat-icon svgIcon="play"></mat-icon>
                        </button>
                    </div>

                    <div *ngIf="pct === 100" class="justify">
                        <div *ngIf="fileInfo">
                            <button mat-mini-fab color="warn" class="btn-actions" style="visibility: hidden;">
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                            <button mat-mini-fab color="warn" class="btn-actions" style="visibility: hidden;">
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                            <button (click)="deletePhoto()" mat-mini-fab color="warn" class="btn-actions">
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="!fileInfo">

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="fileInfo" class="full-width">
            <div *ngIf="fileInfo" class="full-width" style="border: 2px solid gray;display: flex;">
                <figure style="margin: 0;">
                    <img [src]="fileInfo.downloadURL" style="height: 300px; border: 2px solid gray;">
                </figure>
            </div>
            <div style="padding-top: 10px; display: block;">
                <button
                    style="height: 60px; margin: 0 auto;"
                    mat-raised-button
                    color="primary"
                    (click)="usePhoto()">Use Photo</button>
            </div>
        </div>
    </div>

<!--
<br/>
<a [href]="url" target="_blank" rel="noopener">Download ME!</a>
-->