import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, } from '@angular/fire/firestore';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ICompany, IUser } from '../../models';
import {
    COLLECTION, SERVICE, PROVIDER, isUndefinedOrNull, isUndefinedOrNullOrEmpty,
    createAuthHeaders, hasPermissionIntegration
} from '../../shared/utils';

import { AuthService } from '../auth/auth.service';
// import { SessionService } from '../session/session.service';

const { 'v4': uuidv4 } = require('uuid');

export enum API_KEY_TYPE {
    COMPANY = 'company',
    USER = 'user',
}

const IS_DEBUG = !environment.production;

@Injectable({
    providedIn: 'root'
})
export class IntegrationService {
    integrationCollection: AngularFirestoreCollection<any>;
    apiKeysCollection: AngularFirestoreCollection<any>;
    END_POINT: string;

    /*
    integrationSettings: any[] = [];
    integrationSettingsSubject = new BehaviorSubject<any[]>([]);
    apiKeys: ApiKey[] = [];
    apiKeysSubject = new BehaviorSubject<ApiKey[]>([]);
    */

    private _user: IUser;
    private _company: ICompany;

    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
        private http: HttpClient,
        // private sessionService: SessionService,
    ) {
        if (!IS_DEBUG) {
            this.END_POINT = environment.backendApiUrl;
        } else {
            this.END_POINT = environment.backendApiDebugUrl;
        }

        this.authService.user
            .subscribe(user => {
                this._user = user;
            });

        this.authService.company
            .subscribe(company => {
                this._company = company;
            });
    }

    // *************************************************************************

    async createSetting(data: any = null): Promise<string> {
        if (isUndefinedOrNull(data)) {
            throw Error('Missing required parameter data');
        }

        /*
        const isAllowed = hasPermissionIntegration('create', this.sessionService.permissionGroup.permissions);
        if (!isAllowed) {
            throw Error('Missing required permission to add an integration');
        }
        */

        if (isUndefinedOrNullOrEmpty(data.id)) {
            data.id = uuidv4();
        }

        // Validate the data before saving
        const companyId = this._company.id;
        const userId = this._user.id;
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        data.companyId = companyId;
        data.userId = userId;
        data.createdAt = timestamp;
        data.createdBy = userId;
        data.updatedAt = timestamp;
        data.updatedBy = userId;
        data.isDeleted = false;

        try {
            await this.afs
                .collection(COLLECTION.INTEGRATION)
                .doc(data.id)
                .set(data);
            await this.addCallbackUrl(data.id, this._user.token);
            return Promise.resolve(data.id);
        } catch (error) {
            throw Error('Unable to write to Database');
        }
    }

    getSetting(settingId: string) {
        if (isUndefinedOrNullOrEmpty(settingId)) {
            throw Error('Missing required parameter settingId');
        }

        /*
        const isAllowed = hasPermissionIntegration('read', this.sessionService.permissionGroup.permissions);
        if (!isAllowed) {
            throw Error('Missing required permission to read an integration');
        }
        */

        const companyId = this._company.id;
        const userId = this._user.id;
        if (!isUndefinedOrNullOrEmpty(companyId) && !isUndefinedOrNullOrEmpty(userId)) {
            // Validate the request for permission before return data
        }

        // console.log('INTEGRATION SERVICE | getSetting() | settingId:', settingId);

        try {
            const docId = settingId.toString().trim();
            /*
            this.integrationCollection = this.afs
                .collection(
                `${ COLLECTION.INTEGRATION }/${ docId }`, ref => ref
            );

            return this.integrationCollection.valueChanges();
            */
            return this.afs.collection(COLLECTION.INTEGRATION).doc(docId).valueChanges();
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    getSettings(params: any = null) {
        let companyId = this._company.id;
        let providerId = null; // PROVIDER.TWILIO;
        let serviceId = null; // SERVICE.SMS;
        let userId = null; // this.sessionService.user.value.id;
        // let isCompany = false;
        // let isUser = false;

        if (isUndefinedOrNull(params)) {
            throw Error('Missing companyId OR serviceId OR providerId OR isCompany OR isUser');
        }
        if (!isUndefinedOrNull(params.companyId)) {
            companyId = params.companyId;
        }
        if (!isUndefinedOrNull(params.userId)) {
            userId = params.companyId;
        }
        if (!isUndefinedOrNull(params.serviceId)) {
            serviceId = params.serviceId;
        }
        if (!isUndefinedOrNull(params.providerId)) {
            providerId = params.providerId;
        }
        if (!isUndefinedOrNull(params.userId)) {
            userId = params.userId;
        }
        /*
        if (!isUndefinedOrNull(params.isCompany)) {
          if (params.isCompany) {
            isCompany = false;
            companyId = this.sessionService.company.value.id;
          }
        }
        if (!isUndefinedOrNull(params.isUser)) {
          if (params.isUser) {
            isUser = false;
            userId = this.sessionService.user.value.id;
          }
        }
        */

        try {
            if (companyId && userId && providerId && serviceId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('userId', '==', userId)
                        .where('serviceId', '==', serviceId)
                        .where('providerId', '==', providerId)
                        .orderBy('name')
                );
            } else if (companyId && userId && serviceId && !providerId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('userId', '==', userId)
                        .where('serviceId', '==', serviceId)
                        .orderBy('name')
                );
            } else if (companyId && userId && providerId && !serviceId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('userId', '==', userId)
                        .where('providerId', '==', providerId)
                        .orderBy('name')
                );

            } else if (userId && providerId && serviceId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('userId', '==', userId)
                        .where('serviceId', '==', serviceId)
                        .where('providerId', '==', providerId)
                        .orderBy('name')
                );
            } else if (userId && serviceId && !providerId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('userId', '==', userId)
                        .where('serviceId', '==', serviceId)
                        .orderBy('name')
                );
            } else if (userId && providerId && !serviceId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('userId', '==', userId)
                        .where('providerId', '==', providerId)
                        .orderBy('name')
                );

            } else if (companyId && providerId && serviceId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('serviceId', '==', serviceId)
                        .where('providerId', '==', providerId)
                        .orderBy('name')
                );
            } else if (companyId && serviceId && !providerId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('serviceId', '==', serviceId)
                        .orderBy('name')
                );
            } else if (companyId && providerId && !serviceId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('providerId', '==', providerId)
                        .orderBy('name')
                );

            } else if (companyId) {
                this.integrationCollection = this.afs.collection(
                    `${ COLLECTION.INTEGRATION }`, ref => ref
                        .where('companyId', '==', companyId)
                        .orderBy('name')
                );
            } else {
                throw Error('Missing required paramaters');
            }

            return this.integrationCollection.valueChanges();
        } catch (error) {
            console.error(error);
            throw Error('Unable to read from Database');
        }
    }

    async updateSetting(settingId: string, data: any) {
        if (isUndefinedOrNullOrEmpty(settingId)) {
            throw Error('Missing required parameter settingId');
        }

        if (isUndefinedOrNull(data)) {
            throw Error('Missing required parameter data');
        }

        /*
        const isAllowed = hasPermissionIntegration('update', this.sessionService.permissionGroup.permissions);
        if (!isAllowed) {
            throw Error('Missing required permission to update an integration');
        }
        */

        const companyId = this._company.id;
        const userId = this._user.id;
        if (!isUndefinedOrNullOrEmpty(companyId) && !isUndefinedOrNullOrEmpty(userId)) {
            // Validate the request for permission before return data
        }

        try {
            const docId = settingId.toString().trim();
            await this.afs.collection(COLLECTION.INTEGRATION).doc(docId)
                .update(data);

            if (data.isDefault === true) {

                const serviceId = await firebase.firestore()
                    .collection(COLLECTION.INTEGRATION)
                    .doc(docId)
                    .get()
                    .then(snapshot => {
                        if (snapshot.exists) {
                            // console.log('*** found the integration to update');
                            return snapshot.data().serviceId;
                        }
                    })
                    .catch((error: any) => {
                        console.error(error);
                        return null;
                    });

                // Remove the default setting from all other Intergrations of this type for this company.
                if (serviceId !== null) {
                    await firebase.firestore()
                        .collection(COLLECTION.INTEGRATION)
                        .where('companyId', '==', data.companyId)
                        .where('serviceId', '==', serviceId)
                        .get()
                        .then(snapshots => {
                            if (snapshots.empty) {
                                return;
                            }
                            snapshots.forEach(snapshot => {
                                if (snapshot.exists) {
                                    if (snapshot.id !== docId) {
                                        firebase.firestore()
                                            .collection(COLLECTION.INTEGRATION)
                                            .doc(snapshot.id)
                                            .update({ isDefault: false });
                                    }
                                }
                            });
                        });
                }
            }

            await this.addCallbackUrl(settingId, this._user.token);
            return Promise.resolve(settingId);
        } catch (error) {
            throw Error('Unable to write to Database');
        }
    }

    deleteSetting(settingId: string) {
        if (isUndefinedOrNullOrEmpty(settingId)) {
            throw Error('Missing required parameter settingId');
        }

        /*
        // const isAllowed = hasPermissionIntegration('delete', this.sessionService.permissionGroup.permissions);
        const isAllowed = hasPermissionIntegration('delete', this.sessionService.permissionGroup.permissions);
        if (!isAllowed) {
            throw Error('Missing required permission to delete an integration');
        }
        */

        const companyId = this._company.id;
        const userId = this._user.id;
        if (!isUndefinedOrNullOrEmpty(companyId) && !isUndefinedOrNullOrEmpty(userId)) {
            // Validate the request for permission before return data
        }

        try {
            const docId = settingId.toString().trim();
            return this.afs.collection(COLLECTION.INTEGRATION).doc(docId)
                .delete();
        } catch (error) {
            throw Error('Unable to delete data from Database');
        }
    }


    // *************************************************************************


    /* Lead Carrot API Keys */
    async createApiKey(data: any = null): Promise<string> {
        if (isUndefinedOrNull(data)) {
            throw Error('Missing required parameter data');
        }
        if (isUndefinedOrNullOrEmpty(data.type)) {
            throw Error('Invalid API key type');
        }

        if (isUndefinedOrNullOrEmpty(data.id)) {
            data.id = uuidv4();
        }

        // Validate the data before saving
        const companyId = this._company.id;
        const userId = this._user.id;
        if (data.type === API_KEY_TYPE.COMPANY) {
            data.userId = '';
        } else {
            data.userId = userId;
        }
        data.name = data.id;
        data.companyId = companyId;
        data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        data.createdBy = userId;
        data.updatedAt = data.createdAt;
        data.updatedBy = userId;
        data.deletedAt = null;
        data.deletedBy = '';
        data.isActive = true;
        data.isDeleted = false;

        try {
            await this.afs.collection(COLLECTION.API_KEY).doc(data.id)
                .set(data);
            return Promise.resolve(data.id);
        } catch (error) {
            throw Error('Unable to write to Database');
        }
    }

    getApiKey(apiKeyId: string = null) {
        if (isUndefinedOrNullOrEmpty(apiKeyId)) {
            throw Error('Missing required parameter apiKeyId');
        }

        try {
            const docId = apiKeyId.toString().trim();
            this.apiKeysCollection = this.afs.collection(
                `${ COLLECTION.API_KEY }/${ docId }`, ref => ref
            );
            return this.apiKeysCollection.valueChanges();
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    getApiKeys(params: any = null) {
        if (isUndefinedOrNull(params)) {
            throw Error('Missing companyId OR serviceId OR providerId');
        }

        let apiKeyType: API_KEY_TYPE = null;
        if (!isUndefinedOrNull(params.type)) {
            apiKeyType = params.type;
        }

        let companyId = this._company.id;
        if (!isUndefinedOrNull(params.companyId)) {
            // console.log('#1 companyId =', companyId);
            companyId = params.companyId;
        }

        let userId = this._user.id;
        if (!isUndefinedOrNull(params.userId)) {
            // console.log('#1 userId =', userId);
            userId = params.userId;
        }

        // console.log('getApiKeys() | params =', params);
        // console.log('companyId = [' + companyId + ']');
        // console.log('userId = [' + userId + ']');
        // console.log('apiKeyType = [' + apiKeyType + ']');

        try {
            if (companyId && userId && apiKeyType) {
                // console.log('getApiKeys() | companyId, userId, type');
                this.apiKeysCollection = this.afs.collection(
                    `${ COLLECTION.API_KEY }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('userId', '==', userId)
                        .where('type', '==', apiKeyType)
                        .orderBy('createdAt')
                );
            } else if (companyId && userId && !apiKeyType) {
                // console.log('getApiKeys() | companyId, userId, !type');
                this.apiKeysCollection = this.afs.collection(
                    `${ COLLECTION.API_KEY }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('userId', '==', userId)
                        .orderBy('createdAt')
                );
            } else if (companyId && apiKeyType) {
                // console.log('getApiKeys() | companyId, type');
                this.apiKeysCollection = this.afs.collection(
                    `${ COLLECTION.API_KEY }`, ref => ref
                        .where('companyId', '==', companyId)
                        .where('type', '==', apiKeyType)
                        .orderBy('createdAt')
                );
            } else if (companyId && !apiKeyType) {
                // console.log('getApiKeys() | companyId, !type');
                this.apiKeysCollection = this.afs.collection(
                    `${ COLLECTION.API_KEY }`, ref => ref
                        .where('companyId', '==', companyId)
                        .orderBy('createdAt')
                );
            } else if (userId && apiKeyType) {
                // console.log('getApiKeys() | userId, type');
                this.apiKeysCollection = this.afs.collection(
                    `${ COLLECTION.API_KEY }`, ref => ref
                        .where('userId', '==', userId)
                        .where('type', '==', apiKeyType)
                        .orderBy('createdAt')
                );
            } else if (userId && !apiKeyType) {
                // console.log('getApiKeys() | userId, !type');
                this.apiKeysCollection = this.afs.collection(
                    `${ COLLECTION.API_KEY }`, ref => ref
                        .where('userId', '==', userId)
                        .orderBy('createdAt')
                );
            } else {
                throw Error('Missing required paramaters');
            }

            return this.apiKeysCollection.valueChanges();
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    updateApiKey(apiKeyId: string, data: any) {
        if (isUndefinedOrNullOrEmpty(apiKeyId)) {
            throw Error('Missing required parameter apiKeyId');
        }

        if (typeof data === 'undefined' || data === null) {
            throw Error('Missing required parameter data');
        }

        // Validate the data before saving
        if (!isUndefinedOrNullOrEmpty(data.type)) {
            const userId = this._user.id;
            const companyId = this._company.id;
            if (data.type === API_KEY_TYPE.COMPANY) {
                data.userId = '';
            } else {
                data.type = API_KEY_TYPE.USER;
                data.userId = userId;
            }
            data.companyId = companyId;
            data.updatedAt = data.createdAt;
            data.updatedBy = userId;
        }

        try {
            const docId = apiKeyId.toString().trim();
            return this.afs.collection(COLLECTION.API_KEY).doc(docId)
                .update(data);
        } catch (error) {
            console.error('Unable to write to Database');
            throw Error('Unable to write to Database');
        }
    }

    deleteApiKey(apiKeyId: string) {
        if (isUndefinedOrNullOrEmpty(apiKeyId)) {
            throw Error('Missing required parameter apiKeyId');
        }

        const companyId = this._company.id;
        const userId = this._user.id;
        if (companyId && userId) {
            // Validate the request for permission before return data
        }

        try {
            const docId = apiKeyId.toString().trim();
            return this.afs.collection(COLLECTION.API_KEY).doc(docId).delete();
        } catch (error) {
            throw Error('Unable to delete data from Database');
        }
    }

    // *************************************************************************

    getProviderSettings(providerId: string, customValues: any = false) {
        let helpText = '';
        let logoUrl = '';
        let logoMiniUrl = '';
        let name = '';
        let serviceId = '';
        const customSettings = this.getProviderSettingsCustom(providerId, customValues);

        if (providerId === PROVIDER.AMAZON_SES) {
            helpText = 'Connect with Amazon SES to send Email messages.';
            logoUrl = '/assets/images/integrations/logo-amazon_ses-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-amazon_ses.png';
            name = 'Amazon SES';
            serviceId = SERVICE.EMAIL;

        } else if (providerId === PROVIDER.GMAIL) {
            helpText = 'Connect with Gmail to send Email messages.';
            logoUrl = '/assets/images/integrations/logo-gmail-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-gmail.png';
            name = 'Gmail';
            serviceId = SERVICE.EMAIL;

        } else if (providerId === PROVIDER.LINKEDIN) {
            helpText = 'Connect with LinkedIn to find and chat with leads.';
            logoUrl = '/assets/images/integrations/logo-linkedin-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-linkedin.png';
            name = 'LinkedIn';
            serviceId = SERVICE.SOCIAL;

        } else if (providerId === PROVIDER.MAILGUN) {
            helpText = 'Connect with Mailgun to send Email messages.';
            logoUrl = '/assets/images/integrations/logo-mailgun-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-mailgun.png';
            name = 'Mailgun';
            serviceId = SERVICE.EMAIL;

        } else if (providerId === PROVIDER.PLIVO) {
            helpText = 'Connect with Plivo to send SMS messages.';
            logoUrl = '/assets/images/integrations/logo-plivo-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-plivo.png';
            name = 'Plivo';
            serviceId = SERVICE.SMS;

        } else if (providerId === PROVIDER.SENDGRID) {
            helpText = 'Connect with SendGrid to send Email messages.';
            logoUrl = '/assets/images/integrations/logo-sendgrid-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-sendgrid.png';
            name = 'SendGrid';
            serviceId = SERVICE.EMAIL;

        } else if (providerId === PROVIDER.SLYBROADCAST) {
            helpText = 'Connect with SlyBroadcast to send ringless voicemail messages.';
            logoUrl = '/assets/images/integrations/logo-slybroadcast-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-slybroadcast.png';
            name = 'SlyBroadcast';
            serviceId = SERVICE.RINGLESS;

        } else if (providerId === PROVIDER.SMTP) {
            helpText = 'Connect with SMTP to send Email messages.';
            logoUrl = '/assets/images/integrations/logo-smtp-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-smtp.png';
            name = 'SMTP';
            serviceId = SERVICE.EMAIL;

        } else if (providerId === PROVIDER.STRIPE) {
            helpText = 'Connect with Stripe to enable One-Time-Charge.';
            logoUrl = '/assets/images/integrations/logo-stripe-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-stripe.png';
            name = 'Stripe';
            serviceId = SERVICE.PAYMENT;

        } else if (providerId === PROVIDER.TEXTMAGIC) {
            helpText = 'Connect with TextMagic to send SMS messages.';
            logoUrl = '/assets/images/integrations/logo-textmagic-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-textmagic.png';
            name = 'TextMagic';
            serviceId = SERVICE.SMS;

        } else if (providerId === PROVIDER.TWILIO) {
            helpText = 'Connect with Twilio to send SMS messages.';
            logoUrl = '/assets/images/integrations/logo-twilio-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-twilio.png';
            name = 'Twilio';
            serviceId = SERVICE.SMS;

        } else if (providerId === PROVIDER.YAHOO_MAIL) {
            helpText = 'Connect with Yahoo! to send Email messages.';
            logoUrl = '/assets/images/integrations/logo-yahoo-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-yahoo.png';
            name = 'Yahoo!';
            serviceId = SERVICE.EMAIL;

        } else if (providerId === PROVIDER.YELP) {
            helpText = 'Connect with Yelp! to find and chat with leads.';
            logoUrl = '/assets/images/integrations/logo-yelp-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-yelp.png';
            name = 'Yelp!';
            serviceId = SERVICE.EMAIL;

        } else if (providerId === PROVIDER.ZAPIER) {
            helpText = 'Connect with Zapier to connect with other services.';
            logoUrl = '/assets/images/integrations/logo-zapier-full.png';
            logoMiniUrl = '/assets/images/integrations/logo-zapier.png';
            name = 'Zapier';
            serviceId = SERVICE.AUTOMATION;

        } else {
            throw Error('Uknown provider ID [' + providerId + ']');
        }

        return {
            helpText,
            logoUrl,
            logoMiniUrl,
            name,
            serviceId,
            customSettings,
        };
    }

    getProviderName(providerId: string) {
        let providerName = '';
        if (providerId === PROVIDER.AMAZON_SES) {
            providerName = 'Amazon SES';
        } else if (providerId === PROVIDER.GMAIL) {
            providerName = 'Gmail';
        } else if (providerId === PROVIDER.LINKEDIN) {
            providerName = 'LinkedIn';
        } else if (providerId === PROVIDER.MAILGUN) {
            providerName = 'Mailgun';
        } else if (providerId === PROVIDER.PLIVO) {
            providerName = 'Plivo';
        } else if (providerId === PROVIDER.SENDGRID) {
            providerName = 'SendGrid';
        } else if (providerId === PROVIDER.SLYBROADCAST) {
            providerName = 'SlyBroadcast';
        } else if (providerId === PROVIDER.SMTP) {
            providerName = 'SMTP';
        } else if (providerId === PROVIDER.STRIPE) {
            providerName = 'Stripe';
        } else if (providerId === PROVIDER.TEXTMAGIC) {
            providerName = 'TextMagic';
        } else if (providerId === PROVIDER.TWILIO) {
            providerName = 'Twilio';
        } else if (providerId === PROVIDER.YAHOO) {
            providerName = 'Yahoo!';
        } else if (providerId === PROVIDER.YAHOO_MAIL) {
            providerName = 'Yahoo Mail';
        } else if (providerId === PROVIDER.YELP) {
            providerName = 'Yelp!';
        } else if (providerId === PROVIDER.ZAPIER) {
            providerName = 'Zapier';
        } else {
            throw Error('Uknown provider ID [' + providerId + ']');
        }

        return providerName;
    }

    getProviderSettingsCustom(providerId: string, customValues: any = false) {
        let customSettings = {};
        if (providerId === PROVIDER.AMAZON_SES) {
            customSettings = [
                {
                    field: 'region',
                    name: 'Region',
                    value: customValues ? customValues.region : '',
                },
                {
                    field: 'apiKey',
                    name: 'AWS Access Key ID',
                    value: customValues ? customValues.apiKey : '',
                },
                {
                    field: 'secretKey',
                    name: 'AWS Secret Access Key',
                    value: customValues ? customValues.secretKey : '',
                },
                {
                    field: 'fromName',
                    name: 'From Name',
                    value: customValues ? customValues.fromName : '',
                },
                {
                    field: 'fromEmail',
                    name: 'From Email',
                    value: customValues ? customValues.fromEmail : '',
                },
            ];

        } else if (providerId === PROVIDER.FACEBOOK) {
            customSettings = [
                {
                    field: 'email',
                    name: 'Email',
                    value: customValues ? customValues.email : '',
                },
                {
                    field: 'password',
                    name: 'Password',
                    value: customValues ? customValues.password : '',
                },
            ];
        } else if (providerId === PROVIDER.GMAIL) {
            customSettings = [
                {
                    field: 'fromName',
                    name: 'From Name',
                    value: customValues ? customValues.fromName : '',
                },
                {
                    field: 'fromEmail',
                    name: 'From Email',
                    value: customValues ? customValues.fromEmail : '',
                },
                {
                    field: 'password',
                    name: 'Password',
                    value: customValues ? customValues.password : '',
                },
                {
                    field: 'smtp',
                    name: 'SMTP',
                    value: customValues ? customValues.smtp : 'smtp.gmail.com',
                },
                {
                    field: 'port',
                    name: 'Port',
                    value: customValues ? customValues.port : '465',
                },
            ];
        } else if (providerId === PROVIDER.LINKEDIN) {
            customSettings = [
                {
                    field: 'email',
                    name: 'Email',
                    value: customValues ? customValues.email : '',
                },
                {
                    field: 'password',
                    name: 'Password',
                    value: customValues ? customValues.password : '',
                },
            ];
        } else if (providerId === PROVIDER.MAILGUN) {
            customSettings = [
                {
                    field: 'apiKey',
                    name: 'Private API key',
                    value: customValues ? customValues.apiKey : '',
                },
                {
                    field: 'domain',
                    name: 'Domain',
                    value: customValues ? customValues.domain : '',
                },
                {
                    field: 'proxy',
                    name: 'Proxy - *optional*',
                    value: customValues ? customValues.proxy : '',
                },
                {
                    field: 'fromName',
                    name: 'From Name',
                    value: customValues ? customValues.fromName : '',
                },
                {
                    field: 'fromEmail',
                    name: 'From Email',
                    value: customValues ? customValues.fromEmail : '',
                },
            ];

        } else if (providerId === PROVIDER.PLIVO) {
            customSettings = [
                {
                    field: 'accountId',
                    name: 'Auth ID',
                    value: customValues ? customValues.accountId : '',
                },
                {
                    field: 'authToken',
                    name: 'Auth Token',
                    value: customValues ? customValues.authToken : '',
                },
                {
                    field: 'fromNumber',
                    name: 'From Phone Number',
                    value: customValues ? customValues.fromNumber : '',
                },
            ];

        } else if (providerId === PROVIDER.SENDGRID) {
            customSettings = [
                {
                    field: 'apiKey',
                    name: 'API Key',
                    value: customValues ? customValues.apiKey : '',
                },
                {
                    field: 'fromName',
                    name: 'From Name',
                    value: customValues ? customValues.fromName : '',
                },
                {
                    field: 'fromEmail',
                    name: 'From Email',
                    value: customValues ? customValues.fromEmail : '',
                },
            ];

        } else if (providerId === PROVIDER.SLYBROADCAST) {
            customSettings = [
                {
                    field: 'username',
                    name: 'User ID',
                    value: customValues ? customValues.username : '',
                },
                {
                    field: 'password',
                    name: 'Password',
                    value: customValues ? customValues.password : '',
                },
                {
                    field: 'fromNumber',
                    name: 'From Phone Number',
                    value: customValues ? customValues.fromNumber : '',
                },
            ];

        } else if (providerId === PROVIDER.SMTP) {
            customSettings = [
                {
                    field: 'smtpServer',
                    name: 'SMTP Server',
                    value: customValues ? customValues.smtpServer : '',
                },
                {
                    field: 'smtpPort',
                    name: 'SMTP Port',
                    value: customValues ? customValues.smtpPort : 465,
                },
                {
                    field: 'smtpSsl',
                    name: 'Requires SSL (yes/no)',
                    value: customValues ? customValues.smtpSsl : true,
                },
                {
                    field: 'smtpAuth',
                    name: 'Requires Authentication (yes/no)',
                    value: customValues ? customValues.smtpAuth : true,
                },
                {
                    field: 'username',
                    name: 'Email',
                    value: customValues ? customValues.username : '',
                },
                {
                    field: 'password',
                    name: 'Password',
                    value: customValues ? customValues.password : '',
                },
                {
                    field: 'fromName',
                    name: 'From Name',
                    value: customValues ? customValues.fromName : '',
                },
                {
                    field: 'fromEmail',
                    name: 'From Email',
                    value: customValues ? customValues.fromEmail : '',
                },
                {
                    field: 'imapServer',
                    name: 'IMAP Server',
                    value: customValues ? customValues.imapServer : '',
                },
                {
                    field: 'imapPort',
                    name: 'IMAP Port',
                    value: customValues ? customValues.imapPort : 993,
                },
                {
                    field: 'imapSsl',
                    name: 'Requires SSL (yes/no)',
                    value: customValues ? customValues.imapSsl : true,
                },
                {
                    field: 'imapAuth',
                    name: 'Requires Authentication (yes/no)',
                    value: customValues ? customValues.imapAuth : true,
                },
            ];

        } else if (providerId === PROVIDER.STRIPE) {
            customSettings = [
                {
                    field: 'apiKey',
                    name: 'API Key',
                    value: customValues ? customValues.apiKey : '',
                },
                {
                    field: 'customerId',
                    name: 'Stripe Customer ID',
                    value: customValues ? customValues.customerId : '',
                },
            ];

        } else if (providerId === PROVIDER.TEXTMAGIC) {
            customSettings = [
                {
                    field: 'username',
                    name: 'Account SID',
                    value: customValues ? customValues.accountId : '',
                },
                {
                    field: 'apiKey',
                    name: 'Auth Token',
                    value: customValues ? customValues.authToken : '',
                },
                {
                    field: 'fromNumber',
                    name: 'From Phone Number',
                    value: customValues ? customValues.fromNumber : '',
                },
            ];

        } else if (providerId === PROVIDER.TWILIO) {
            customSettings = [
                {
                    field: 'accountId',
                    name: 'Account SID',
                    value: customValues ? customValues.accountId : '',
                },
                {
                    field: 'authToken',
                    name: 'Auth Token',
                    value: customValues ? customValues.authToken : '',
                },
                {
                    field: 'fromNumber',
                    name: 'From Phone Number',
                    value: customValues ? customValues.fromNumber : '',
                },
            ];

        } else if (providerId === PROVIDER.YAHOO_MAIL) {
            customSettings = [
                {
                    field: 'smtpServer',
                    name: 'SMTP Server',
                    value: customValues ? customValues.smtpServer : 'smtp.mail.yahoo.com',
                },
                {
                    field: 'smtpPort',
                    name: 'SMTP Port',
                    value: customValues ? customValues.smtpPort : 465,
                },
                {
                    field: 'smtpSsl',
                    name: 'Requires SSL (yes/no)',
                    value: customValues ? customValues.smtpSsl : true,
                },
                {
                    field: 'smtpAuth',
                    name: 'Requires Authentication (yes/no)',
                    value: customValues ? customValues.smtpAuth : true,
                },
                {
                    field: 'username',
                    name: 'Email',
                    value: customValues ? customValues.username : '',
                },
                {
                    field: 'password',
                    name: '3rd Party App Password',
                    value: customValues ? customValues.password : '',
                },
                {
                    field: 'fromName',
                    name: 'From Name',
                    value: customValues ? customValues.fromName : '',
                },
                {
                    field: 'fromEmail',
                    name: 'From Email',
                    value: customValues ? customValues.fromEmail : '',
                },
                {
                    field: 'imapServer',
                    name: 'IMAP Server',
                    value: customValues ? customValues.imapServer : 'imap.mail.yahoo.com',
                },
                {
                    field: 'imapPort',
                    name: 'IMAP Port',
                    value: customValues ? customValues.imapPort : 993,
                },
                {
                    field: 'imapSsl',
                    name: 'Requires SSL (yes/no)',
                    value: customValues ? customValues.imapSsl : true,
                },
            ];

        } else if (providerId === PROVIDER.ZAPIER) {
            customSettings = [
                {
                    field: 'apiKey',
                    name: 'API Key',
                    value: customValues ? customValues.apiKey : '',
                },
            ];

        } else {
            throw Error('Uknown provider ID [' + providerId + ']');
        }

        return customSettings;
    }

    async addCallbackUrl(settingId: string, userToken: string) {
        const body = JSON.stringify({ settingId });

        // console.log('this.END_POINT', this.END_POINT);

        return this.http
            .post(
                `${ this.END_POINT }/callbackresponse/addcallbackUrl`,
                body,
                // { headers: createAuthHeaders(this.sessionService.userToken) }
                { headers: createAuthHeaders(userToken) }
            )
            .subscribe((res: any[]) => { console.log(res); });
    }

    // *************************************************************************

    async getActiveDefaultIntegration(companyId: string, integrationType: string = 'sms') {
        try {
            if (typeof companyId === 'undefined' || companyId === null) {
                throw Error('Company ID is UNDEFINED or NULL');
            }

            if (typeof integrationType === 'undefined' || integrationType === null) {
                throw Error('Integration Type ID is UNDEFINED or NULL');
            }

            const queryRef = firebase.firestore()
                .collection(COLLECTION.INTEGRATION)
                .where('companyId', '==', companyId)
                .where('serviceId', '==', integrationType)
                .where('isEnabled', '==', true);
            const data = await queryRef
                .get()
                .then(querySnapshot => {
                    if (querySnapshot.empty) {
                        // Do nothing as we didn't find it
                        throw Error('No document exists in collection [' + COLLECTION.INTEGRATION + '] for company id ['
                            + companyId + '] for service type [' + integrationType + ']');
                    } else {
                        // find the default.
                        let defaultIndex = 0;
                        for (let index = 0; index < querySnapshot.docs.length; index++) {
                            const integration = querySnapshot.docs[index].data();
                            if (typeof integration !== 'undefined') {
                                if (integration.isDefault === true) {
                                    defaultIndex = index;
                                    break;
                                }
                            }
                        }
                        const docData = querySnapshot.docs[defaultIndex].data();
                        if (typeof docData !== 'undefined') {
                            docData.id = querySnapshot.docs[0].id;
                            return docData;
                        }
                    }
                });
            return Promise.resolve(data);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
}
