import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Overlay, CloseScrollStrategy } from '@angular/cdk/overlay';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

import { IStage } from '../models';
import { isUndefinedOrNull, isUndefinedOrNullOrEmpty } from '../shared/utils';
const stripeCurrencies = require('../shared/stripe-currencies.json');

import { SessionService } from '../services/session/session.service';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

const DEFAULT_TITLE = 'Push To Pipeline';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss'],
})
export class ImportDialogComponent implements OnInit, OnDestroy {
  countryCode: string;
  supportedCurrencies: string[] = [];

  allSubscriptions = new Subscription();
  title: string;
  recordCount: number;
  recordCountMessage: string;

  form: FormGroup;
  toPipeline = new FormControl();
  toStage = new FormControl();
  allCurrencyTypes = new FormControl();

  pipelineId: string;
  pipelines: any[] = [];
  stageId: string;
  stages: IStage[] = [];

  ownerId: string;
  ownerName: string;
  dealCurrencyType: string;
  dealValue: number;
  dealExpectedCloseDate: Date;
  dealTitle: string;

  currencyTypes: any[] = [];

  public config: PerfectScrollbarConfigInterface = {};
  cancelText: string;

  constructor(
    public dialogRef: MatDialogRef<ImportDialogComponent>,
    private fb: FormBuilder,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    try {
      this.cancelText = 'Cancel';

      // Load supported currencies for STRIPE
      this.countryCode = 'US';
      let index = -1;
      index = stripeCurrencies.findIndex(
        (countryData) => countryData.id === this.countryCode
      );
      if (index > -1) {
        this.supportedCurrencies =
          stripeCurrencies[index].supported_payment_currencies;
      } else {
        index = stripeCurrencies.findIndex(
          (countryData) => countryData.id === 'US'
        );
        if (index > -1) {
          this.supportedCurrencies =
            stripeCurrencies[index].supported_payment_currencies;
        } else {
          this.supportedCurrencies = ['usd', 'cad', 'eur'];
        }
      }

      if (!isUndefinedOrNullOrEmpty(this.data)) {
        console.log('data:', data);

        this.currencyTypes.push({
          id: 'USD',
          name: 'US Dollar',
          code: 'USD',
          symbol: '$',
        });
        this.currencyTypes.push({
          id: 'AUD',
          name: 'Australian Dollar',
          code: 'AUD',
          symbol: '$',
        });
        this.currencyTypes.push({
          id: 'CAD',
          name: 'Canadian Dollar',
          code: 'CAD',
          symbol: '$',
        });
        this.currencyTypes.push({
          id: 'EUR',
          name: 'Euro',
          code: 'EUR',
          symbol: '€',
        });
        this.currencyTypes.push({
          id: 'GBP',
          name: 'Pound Sterling',
          code: 'GBP',
          symbol: '£',
        });
        this.currencyTypes.push({
          id: 'BTC',
          name: 'Bitcoin',
          code: 'BTC',
          symbol: '₿',
        });
        this.currencyTypes.push({
          id: 'JPY',
          name: 'Japanese Yen',
          code: 'JPY',
          symbol: '¥',
        });

        this.ownerId = this.data.ownerId;
        this.ownerName = this.data.ownerName;
        this.dealCurrencyType = this.data.dealCurrencyType;
        this.dealValue = this.data.dealValue;
        this.dealExpectedCloseDate = this.data.dealExpectedCloseDate;
        this.dealTitle = this.data.dealTitle;

        this.title = isUndefinedOrNullOrEmpty(this.data.title)
          ? DEFAULT_TITLE
          : this.data.title.trim();
        this.recordCount =
          !isUndefinedOrNull(this.data.recordCount) && this.data.recordCount < 1
            ? 0
            : this.data.recordCount;
        if (this.recordCount < 0) {
          this.recordCountMessage = '';
        } else if (this.recordCount === 1) {
          this.recordCountMessage = 'lead to push into pipeline';
        } else {
          this.recordCountMessage = 'leads to push into pipeline';
        }

        if (isUndefinedOrNull(this.data.pipelines)) {
          throw Error('No pipelines to select');
        }

        this.pipelineId = isUndefinedOrNullOrEmpty(this.data.pipelineId)
          ? ''
          : this.data.pipelineId;
        this.stageId = isUndefinedOrNullOrEmpty(this.data.stageId)
          ? ''
          : this.data.stageId;
        this.pipelines = this.data.pipelines;
      }

      this.form = this.fb.group({
        toPipeline: [this.pipelineId, Validators.required],
        toStage: [this.stageId, Validators.required],
        dealTitle: [''],
        dealValue: [this.dealValue.toFixed(2), Validators.required],
        allCurrencyTypes: [this.dealCurrencyType, Validators.required],
        ownerId: [this.ownerId, Validators.required],
        ownerName: [this.ownerName, Validators.required],
      });
    } catch (error) {
      console.log(error);
      this.recordCount = 0;
      this.pipelines = null;
      this.stages = null;
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  changePipeline(pipelineIndex: number) {
    const pipeline = this.pipelines[pipelineIndex];
    this.pipelineId = pipeline.pipelineId;

    console.log('pipeline:', pipeline);

    this.stages = pipeline.stage;
    this.stageId = this.stages[0].id;

    console.log('stages:', this.stages);
    console.log('stageId:', this.stageId);

    this.form.get('toStage').setValue(this.stageId);
    this.toStage.setValue(this.stageId);

    console.log('stageId:', this.stageId);
  }

  changeStage(stageIndex: number) {
    const stage = this.stages[stageIndex];
    this.stageId = stage.id;
    this.form.get('toStage').setValue(this.stageId);
    this.toStage.setValue(this.stageId);

    console.log('stageId:', this.stageId);
  }

  push() {
    let pipelineId = this.toPipeline.value;
    if (!isUndefinedOrNullOrEmpty(pipelineId)) {
      pipelineId = this.pipelineId.trim();
    }
    let stageId = this.toStage.value;
    if (!isUndefinedOrNullOrEmpty(stageId)) {
      stageId = this.stageId.trim();
    }

    const formStageId = this.form.get('toStage').value;
    const ctrlStageId = this.toStage.value;

    console.log('THIS stage ID:', this.stageId);
    console.log('FORM stage ID:', formStageId);
    console.log('CTRL stage ID:', ctrlStageId);

    let dealTitle = this.form.get('dealTitle').value;
    if (!isUndefinedOrNullOrEmpty(dealTitle)) {
      dealTitle = this.dealTitle.trim();
    }
    let dealValue = this.form.get('dealValue').value;
    if (!isUndefinedOrNullOrEmpty(dealValue)) {
      dealValue = parseFloat(this.dealValue.toFixed(2));
    } else {
      if (typeof dealValue === 'string') {
        dealValue = parseFloat(
          parseFloat(dealValue.replace(/[^0-9\,\.]/g, '')).toFixed(2)
        );
      } else {
        dealValue = parseFloat(this.dealValue.toFixed(2));
      }
    }
    let dealCurrencyType = this.form.get('allCurrencyTypes').value;
    if (!isUndefinedOrNullOrEmpty(dealCurrencyType)) {
      dealCurrencyType = this.dealCurrencyType;
    }
    // !TODO: Add some validation and a drop-down date selector to the form
    const dealExpectedCloseDate = this.dealExpectedCloseDate;

    let ownerId = this.form.get('ownerId').value;
    if (!isUndefinedOrNullOrEmpty(ownerId)) {
      ownerId = this.ownerId.trim();
    }
    let ownerName = this.form.get('ownerName').value;
    if (!isUndefinedOrNullOrEmpty(ownerName)) {
      ownerName = this.ownerName.trim();
    }

    const data = {
      pipelineId,
      stageId,
      dealTitle,
      dealValue,
      dealCurrencyType,
      dealExpectedCloseDate,
      ownerId,
      ownerName,
    };

    console.log('IMPORT DATA:', data);

    // this.dialogRef.close();
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }
}
