import { Component, OnInit, Input } from '@angular/core';
import { TimelineItem } from './timeline-item';
import { isUndefinedOrNull } from '../shared/utils';

const navigatorLanguages = require('navigator-languages');

@Component({
    selector: 'app-af-vertical-timeline',
    templateUrl: './af-vertical-timeline.component.html',
    styleUrls: ['./af-vertical-timeline.component.scss']
})
export class AfVerticalTimelineComponent implements OnInit {

    list: TimelineItem[] = [];

    constructor() { }

    @Input()
    set items(list: TimelineItem[]) {
        this.list = list;
    }

    ngOnInit() {
    }

    action(item: TimelineItem) {
        if (item.command) {
            item.command();
        }
    }

    formatDate(unixTime: number): string {
        if (isUndefinedOrNull(unixTime)) {
            return '';
        }

        const lang = this.getLang();
        const newDate = new Date(unixTime);
        // console.log('lang:', lang);
        const dateString = newDate.toDateString() + ', ' + newDate.toLocaleTimeString(lang);

        return dateString;
    }

    getLang() {
        return (navigator.language || navigator.languages[0]);
    }

}
