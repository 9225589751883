import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap, map } from 'rxjs/operators';
// import { SessionService } from '../../services/session/session.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COLLECTION } from '../shared/utils';

const DEFAULT_PHOTO_URL = '/assets/images/favicon.png';
const FILE_UPLOAD_SUCCESS = 'File uploaded successfully';
const FILE_UPLOAD_DELETED = 'File has been removed';
const PLEASE_WAIT = 'Please wait while we process the file.';
const SNACKBAR_DURATION = 4000;

interface FileStorageInfo {
  downloadURL: string;
  path: string;
  userId: string;
  tag: string;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;
  @Input() userId: string;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<firebase.storage.UploadTaskSnapshot>;
  fileInfo: FileStorageInfo;
  showProgress: boolean;
  fileMessage: string;
  isWaiting: boolean;
  waitMessage: string;

  constructor(
    private db: AngularFirestore,
    // private sessionService: SessionService,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage,
  ) {
    this.showProgress = true;
    this.fileMessage = '';
    this.isWaiting = false;
    this.waitMessage = PLEASE_WAIT;
  }

  ngOnInit() {
    this.startUpload();
  }

  createStoragePath(filename: string) {
    return `${ this.userId }/${ Date.now() }_${ this.file.name }`;
  }

  startUpload() {
    // The storage path
    const path = this.createStoragePath(this.file.name);

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    let metadata: firebase.storage.UploadMetadata;
    metadata = {
      customMetadata: {
        userId: this.userId,
        tag: 'profile-photo',
      },
    };

    this.task = this.storage.upload(path, this.file, metadata);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),

      // The file's download URL
      finalize(async () => {
        this.fileInfo = {
          downloadURL: await ref.getDownloadURL().toPromise(),
          path,
          userId: this.userId,
          tag: 'profile-photo',
        };
        this.db.collection('files')
          .add(this.fileInfo);
        this.showProgress = false;
        this.fileMessage = FILE_UPLOAD_SUCCESS;
      })
    );
  }

  isActive(taskItem: any): boolean {
    return taskItem.state === 'running' && (taskItem.bytesTransferred < taskItem.totalBytes);
  }

  toKiB(size: number): number {
    return size / 1024;
  }

  async usePhoto() {
    // Update the user profile to use the new photo that we uploaded
    return this.db.collection(COLLECTION.USER).doc(this.userId)
      .set({ photoURL: this.fileInfo.downloadURL }, { merge: true })
      .then(() => {
        // this.sessionService.setAttribute('userPhotoURL', this.fileInfo.downloadURL);
        // this.sessionService.user.value.photoURL = this.fileInfo.downloadURL;
        // this.sessionService.user.next(this.sessionService.user.value);
        this.openSnackBar('User profile photo updated.', 'close');
        return Promise.resolve(true);
      })
      .catch(error => {
        console.warn('Unable to set user profile photo:', error);
        this.openSnackBar(error.message, 'close');
        return false;
      });
  }

  async deletePhoto() {
    // Reference to storage bucket
    const ref = this.storage.ref(this.fileInfo.path);
    try {
      await ref.delete()
        .subscribe(() => {

          // Set profile photo URL to default
          // if (this.sessionService.user.value.photoURL === this.fileInfo.downloadURL) {
          this.db.collection(COLLECTION.USER).doc(this.userId)
            .update({ photoURL: DEFAULT_PHOTO_URL })
            .then(() => {
              // this.sessionService.setAttribute('userPhotoURL', DEFAULT_PHOTO_URL);
              // this.sessionService.user.value.photoURL = DEFAULT_PHOTO_URL;
              // this.sessionService.user.next(this.sessionService.user.value);
              this.openSnackBar('User profile photo reset.', 'close');
              return Promise.resolve(true);
            })
            .catch(error => {
              console.warn('Unable to reset user profile photo:', error);
              this.openSnackBar(error.message, 'close');
              return false;
            });
          // }

          this.fileMessage = FILE_UPLOAD_DELETED;
          this.fileInfo = null;
          this.openSnackBar(FILE_UPLOAD_DELETED, 'close');
        });
    } catch (error) {

      console.warn(error);
      const message = error.serverResponse.error.message;
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;

        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;

        case 'storage/canceled':
          // User canceled the upload
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
      this.openSnackBar(message, 'close');
    }
  }

  /**
   * Display a message to the user with the Material Snackbar.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: SNACKBAR_DURATION,
    });
  }
}
