import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Md5 } from 'ts-md5/dist/md5';
import { User } from 'firebase';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject, throwError } from 'rxjs';

import {
    IApiResponse, ICompany, ICoordinates, IEntities,
    IEvent, ITargeting, IPost, IUser
} from '../../models';

export const enum VISIBILITY {
    HIDDEN = 'HIDDEN',
    NORMAL = 'NORMAL',
    FORCED_ALLOW = 'FORCED_ALLOW',
}

import { environment } from '../../../environments/environment';
import { COLLECTION, arrayUnique, getDocument, getIsAdmin, isUndefinedOrNull, isUndefinedOrNullOrEmpty, createAuthHeaders } from '../../shared/utils';

import { AuthService } from '../auth/auth.service';

const { 'v4': uuidv4 } = require('uuid');
const IS_DEBUG = !environment.production;
const COMPANY_NOT_FOUND = 'Company not found in Lead Carrot.';
const TEAM_NAME_IS_EMPTY = 'Team name is empty.';
const TEAM_NOT_FOUND = 'Team not found in Lead Carrot.';
const USER_NOT_FOUND = 'User not found in Lead Carrot.';
const DEFAULT_POST_ICON = ''; // 'https://leadcarrot.io/icon/';

@Injectable({
    providedIn: 'root'
})

export class TimelineService {
    private _timeline: any[] = [];
    timeline = new BehaviorSubject<any[]>([]);

    private _currentUser: User;

    private _user: IUser = null;
    private _company: ICompany = null;
    private _endPoint: string;
    private _apiCloud: string;

    private _isAdmin: boolean;
    private _subs = new Subscription();

    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth,
        private authService: AuthService,
    ) {
        try {
            if (!IS_DEBUG) {
                this._endPoint = environment.backendApiUrl;
            } else {
                this._endPoint = environment.backendApiDebugUrl;
            }

            this._apiCloud = environment.cloudUrl; // + '/userGetTeamMembers';

            this.afAuth.authState
                .subscribe((user: User | null) => {
                    this._currentUser = user;
                    this._timeline = [];
                    this.timeline.next(this._timeline);
                });

            this.authService.user
                .subscribe(async (user: IUser) => {
                    if (user) {
                        this._user = user;
                        try {
                            this._isAdmin = false; // await getIsAdmin(this._user.id);
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        this._isAdmin = false;
                    }
                    return Promise.resolve();
                });

            this.authService.company
                .subscribe(async (company: ICompany) => {
                    // this._subs.unsubscribe();
                    this._company = company;

                    if (company) {
                        try {
                            // this.getByCompany(this._company.id);
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        this._timeline = [];
                        this.timeline.next(this._timeline);
                    }
                    return Promise.resolve();
                });

        } catch (error) {
            console.error(error);
        }
    }

    get(timelineId: string) {
        try {
            return getDocument(timelineId, COLLECTION.TIMELINE);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getAll(params: any): Observable<any[]> {
        console.warn('getAll timeline items for contactId:', params);

        try {
            if (isUndefinedOrNullOrEmpty(params)
                || isUndefinedOrNullOrEmpty(params.contactId)
            ) {
                const userId = isUndefinedOrNullOrEmpty(params.from) ? params.userId : params.from;

                return this.afs.collection<any>(COLLECTION.TIMELINE, ref => ref
                    .where('userId', '==', isUndefinedOrNullOrEmpty(userId) ? this._currentUser.uid : userId)
                    .orderBy('createdAt', 'desc'))
                    .snapshotChanges()
                    .pipe(
                        map(actions => {
                            return actions.map(item => {
                                return {
                                    id: item.payload.doc.id,
                                    ...item.payload.doc.data()
                                };
                            });
                        })
                    );

            } else {

                console.warn('get timeline by contactId:', params.contactId);


                return this.afs.collection<any>(COLLECTION.TIMELINE, ref => ref
                    .where('contactId', '==', params.contactId)
                    .orderBy('createdAt', 'desc'))
                    .snapshotChanges()
                    .pipe(
                        map(actions => {
                            return actions.map(item => {
                                return {
                                    id: item.payload.doc.id,
                                    ...item.payload.doc.data()
                                };
                            });
                        })
                    );
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async create(data: any): Promise<any> {
        try {
            if (isUndefinedOrNullOrEmpty(data)) {
                throw Error('No data to log.');
            }
            if (isUndefinedOrNullOrEmpty(data.action)) {
                throw Error('No action to log.');
            }
            if (isUndefinedOrNullOrEmpty(data.subject)) {
                throw Error('No subject to log.');
            }

            const doc: any = Object.assign(data);
            doc.id = uuidv4();
            doc.createdAt = firebase.firestore.FieldValue.serverTimestamp();
            doc.createdBy = isUndefinedOrNullOrEmpty(data.createdBy) ? this._user.id : data.createdBy.trim();
            doc.userName = isUndefinedOrNullOrEmpty(data.userName) ? this._user.name : data.userName.trim();
            doc.userPhotoURL = isUndefinedOrNullOrEmpty(data.userPhotoURL) ? this._user.photoURL : data.userPhotoURL.trim();
            doc.companyId = isUndefinedOrNullOrEmpty(data.companyId) ? this._company.id : data.createdBy.trim();

            doc.action = data.action.trim();
            doc.subject = data.subject.trim();
            doc.title = isUndefinedOrNullOrEmpty(data.title) ? doc.action + ' ' + doc.subject : data.title.trim();
            doc.message = isUndefinedOrNullOrEmpty(data.message) ? doc.title : data.message.trim();
            doc.icon = isUndefinedOrNullOrEmpty(data.icon) ? null : data.icon.trim();
            doc.visibility = isUndefinedOrNullOrEmpty(data.visibility) ? VISIBILITY.NORMAL : data.visibility;

            if (!isUndefinedOrNullOrEmpty(data.organizationId)) {
                doc.organizationId = data.organizationId.trim();
            }
            if (!isUndefinedOrNullOrEmpty(data.contactId)) {
                doc.contactId = data.contactId.trim();
            }

            if (doc.icon === null) {
                if (doc.subject === 'note') {
                    doc.icon = 'fa fa-sticky-note';
                } else if (doc.subject === 'sms') {
                    doc.icon = 'fa fa-commenting';
                } else if (doc.subject === 'email') {
                    doc.icon = 'fa fa-email';
                } else if (doc.subject === 'contact' && doc.action === 'create') {
                    doc.icon = 'fa fa-address-card';
                } else if (doc.action === 'delete') {
                    doc.icon = 'fa fa-trash';
                }
            }

            await firebase.firestore()
                .collection(COLLECTION.TIMELINE)
                .doc(doc.id)
                .set(doc);

            return await getDocument(doc.id, COLLECTION.TIMELINE);

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    delete(timelineId: string) {
        try {
            return firebase.firestore()
                .collection(COLLECTION.TIMELINE)
                .doc(timelineId)
                .delete();

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

}
