export class Pixel {
  id?: string;
  body: string;
  name: string;
  userId: string;
  userName: string;
  type: string;
  isActive: boolean;

  constructor(
    id: string,
    body: string,
    name: string,
    userId: string,
    userName: string,
    type: string,
    isActive: boolean
  ) {
    this.id = id;
    this.body = body;
    this.name = name;
    this.userId = userId;
    this.userName = userName;
    this.type = type;
    this.isActive = isActive;
  }
}
