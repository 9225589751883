// https://www.youtube.com/watch?v=XQA1bbHQJp0
// at 2hr 5min

import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isUndefinedOrNullOrEmpty } from '../../shared/utils';
import localforage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {

  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: 'leadcarrotcrm.auth0.com',
      client_id: 'z1I5sDe2nLMaZrusRymngxypj6EDPnXb',
      redirect_uri: `${ window.location.origin }` + '/auth0',
    })
  ) as Observable<Auth0Client>)
    .pipe(
      shareReplay(1), // Every subscription receives the same shared value
      catchError(err => throwError(err))
    );

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$
    .pipe(
      concatMap((client: Auth0Client) => from(client.isAuthenticated())),
      tap(res => this.loggedIn = res)
    );

  handleRedirectCallback$ = this.auth0Client$
    .pipe(
      concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(private router: Router) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${ window.location.origin }` + '/auth0',
        appState: { target: redirectPath }
      });
    });
  }

  handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;

    let isAuth = false;

    localforage.getItem<string>('auth0').then(res => {
      const snippet = res;

      if (!isUndefinedOrNullOrEmpty(snippet)) {
        isAuth = true;
      }
      localforage.getItem<string>('query-options')
        .then(querydata => {
          let queryOptions = querydata;

          if (!isUndefinedOrNullOrEmpty(isAuth)) {
            queryOptions = '';
          }
          if (
            (!params.includes('code=') && !params.includes('state='))
            && (isAuth && queryOptions.includes('code=') && queryOptions.includes('state='))
          ) {
            localforage.removeItem('auth0');
            localforage.removeItem('query-options');
            this.router.navigate(['#/setting/company-integration' + queryOptions]);

          } else {
            if (params.includes('code=') && params.includes('state=')) {
              this.initAuthCallback();
            } else if (isAuth && queryOptions.includes('code=') && queryOptions.includes('state=')) {
              this.initAuthCallback();
            }
          }
        });
    });
  }

  initAuthCallback() {
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest([
          this.getUser$(),
          this.isAuthenticated$
        ]);
      })
    );

    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {
      // Redirect to target route after callback processing
      this.router.navigate([targetRoute]);
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: 'z1I5sDe2nLMaZrusRymngxypj6EDPnXb',
        returnTo: `${ window.location.origin }` + '/assets/auth0.html',
      });
    });
  }

}
