<div class="app">
  <!-- header -->

  <!--
  <app-header *ngIf="isLoggedIn"></app-header>
  <app-navbar *ngIf="isLoggedIn"></app-navbar>
  -->
  
  <!-- main app container -->
  <div class="app-container" [ngClass]="{ 'bg-light': user, 'bg-login': user === null }">
    <app-alert></app-alert>
    <router-outlet></router-outlet>
  </div>

  <!-- footer -->
  <!--
  <app-footer *ngIf="!isLoggedIn"></app-footer>
  -->
</div>