import { Injectable } from '@angular/core';
import * as Hammer from 'hammerjs';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { HesitateDirective } from './directives/hesitate.directive';

// PIPES
import { BytesPipe } from './pipes/bytes.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { LetterBoldPipe } from './pipes/letter-bold.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SmsOrderByPipe } from './pipes/sms-orderBy.pipe';
import { SmsOrderByDealPipe } from './pipes/sms-orderByDeal.pipe';
import { SortingDealFeaturesPipe } from './pipes/sorting-dealFeatures.pipe';
import { SortingDealsPipe } from './pipes/sorting-deals.pipe';
// import { TimeAgoPipe } from 'time-ago-pipe';

import { AgmCoreModule } from '@agm/core';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AppRoutingModule } from './app-routing.module';
// import { AngularFileUploaderModule } from 'angular-file-uploader';
import { AvatarModule } from 'ngx-avatar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartistModule } from 'ng-chartist';
import { DomSanitizer } from '@angular/platform-browser';
import { DragulaModule } from 'ng2-dragula';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './shared/material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxFlagPickerModule } from 'ngx-flag-picker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
// import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxVerticalTimelineModule } from 'ngx-vertical-timeline';
import { QuillModule } from 'ngx-quill';
import { TimeagoModule } from 'ngx-timeago';

import { AccountService } from './services/account/account.service';
import { AlertService } from './services/alert/alert.service';
import { AuthService } from './services/auth/auth.service';
import { BottomSheetService } from './services/bottom-sheet/bottom-sheet.service';
// import { CategoryService } from './services/category/category.service';
// import { InMemDataService } from './services/in-memory-data/in-memory-data.service';
import { FingerprintService } from './services/fingerprint/fingerprint.service';
import { NotificationService } from './services/notification/notification.service';
import { PostService } from './services/post/post.service';
import { SessionService } from './services/session/session.service';
import { TimelineService } from './services/timeline/timeline.service';
import { UserService } from './services/user/user.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatIconRegistry } from '@angular/material/icon';

import { AddTagComponent } from './add-tag/add-tag.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AfVerticalTimelineComponent } from './af-vertical-timeline/af-vertical-timeline.component';
import { AlertComponent } from './alert/alert.component';
import { AppComponent } from './app.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { ExportDealComponent } from './export-deal/export-deal.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { ExportCustomDialogComponent } from './export-custom-dialog/export-custom-dialog.component';
import { ExportResultsDialogComponent } from './export-results-dialog/export-results-dialog.component';
import { FabStickyComponent } from './fab-sticky/fab-sticky.component';
import { FinderComponent } from './finder/finder.component';
import { FilterOptionsComponent } from './filter-options/filter-options.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { MergeFieldsComponent } from './merge-fields/merge-fields.component';
import { PushLeadsDialogComponent } from './push-leads-dialog/push-leads-dialog.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UpdateConfirmationComponent } from './update-confirmation/update-confirmation.component';
import { UploaderComponent } from './uploader/uploader.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { UserComponent } from './user/user.component';
import { MatChipsModule } from '@angular/material/chips';

// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

@Injectable({
  providedIn: 'root',
})
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  } as any;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    HesitateDirective,

    // ----------------------------
    // Pipes
    // ----------------------------
    BytesPipe,
    FileSizePipe,
    LetterBoldPipe,
    SearchFilterPipe,
    SmsOrderByPipe,
    SmsOrderByDealPipe,
    SortingDealFeaturesPipe,
    SortingDealsPipe,
    // TimeAgoPipe,

    // ----------------------------
    // Components
    // ----------------------------
    AddTagComponent,
    AfVerticalTimelineComponent,
    AlertComponent,
    AppComponent,
    ExportCustomDialogComponent,
    ExportDealComponent,
    ExportDialogComponent,
    ExportResultsDialogComponent,
    FabStickyComponent,
    FilterOptionsComponent,
    FinderComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    ImportDialogComponent,
    ImportFileComponent,
    PushLeadsDialogComponent,
    MergeFieldsComponent,
    NavbarComponent,
    UploaderComponent,
    UploadImageComponent,
    UploadTaskComponent,
    DeleteConfirmationComponent,
    UploadImageComponent,
    UpdateConfirmationComponent,
    UserComponent,
    AddUserComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey,
      libraries: ['places'],
      language: 'en',
    }),
    // AngularFileUploaderModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AppRoutingModule,
    AvatarModule,
    AutocompleteLibModule,
    BrowserModule,
    BrowserAnimationsModule,
    ChartistModule,
    DragulaModule.forRoot(),
    FlexLayoutModule,
    FontAwesomeModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    MatChipsModule,
    MaterialModule,
    MatDialogModule,
    MatGoogleMapsAutocompleteModule,
    MatProgressSpinnerModule,
    MatSelectCountryModule,
    NgSelectModule,
    NgxDatatableModule,
    NgxFlagPickerModule,
    NgxIntlTelInputModule,
    NgxMaterialTimepickerModule,
    // NgxSpinnerModule,
    NgxVerticalTimelineModule,
    PerfectScrollbarModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    TimeagoModule.forRoot(),
    /*
    HttpClientInMemoryWebApiModule.forRoot(
      InMemDataService, { dataEncapsulation: false, delay: 1000 }),
    */
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFireAuthGuard,
    AccountService,
    AlertService,
    AuthService,
    BottomSheetService,
    // CategoryService,
    FingerprintService,
    NotificationService,
    PostService,
    SessionService,
    TimelineService,
    UserService,
  ],
  entryComponents: [
    AddTagComponent,
    AlertComponent,
    ExportCustomDialogComponent,
    ExportDealComponent,
    ExportDialogComponent,
    ExportResultsDialogComponent,
    FilterOptionsComponent,
    ImportDialogComponent,
    ImportFileComponent,
    MergeFieldsComponent,
    PushLeadsDialogComponent,
    UploaderComponent,
    UploadImageComponent,
    UploadTaskComponent,
    DeleteConfirmationComponent,
    UpdateConfirmationComponent,
    UserComponent,
    AddUserComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    domSanitizer.bypassSecurityTrustResourceUrl('./favicon.ico');

    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/mdi.svg')
    ); // Or whatever path you placed mdi.svg at
    matIconRegistry.addSvgIcon(
      `google-adwords`,
      domSanitizer.bypassSecurityTrustResourceUrl(
        `./assets/images/Google-Adwords-Icon-01.svg`
      )
    );
    matIconRegistry.addSvgIcon(
      `google-login`,
      domSanitizer.bypassSecurityTrustResourceUrl(
        `./assets/images/btn_google_light_normal_ios.svg`
      )
    );
    matIconRegistry.addSvgIcon(
      `stripe-logo`,
      domSanitizer.bypassSecurityTrustResourceUrl(
        `./assets/images/logo-stripe.svg`
      )
    );
    matIconRegistry.addSvgIcon(
      `slybroadcast-logo`,
      domSanitizer.bypassSecurityTrustResourceUrl(
        `./assets/images/logo-slybroadcast.svg`
      )
    );
    matIconRegistry.addSvgIcon(
      `yahoo-logo`,
      domSanitizer.bypassSecurityTrustResourceUrl(
        `./assets/images/logo-yahoo.svg`
      )
    );
  }
}
