export class CustomField {
  category?: string;
  createdAt: any;
  createdBy: string;
  fieldCode: string | undefined;
  fieldName: string;
  id: string;
  isRequired: boolean;
  showInDetail: boolean;
  showInForm: boolean;
  type: string;
  updatedAt: any;
  updatedBy?: string;
  formControlName?: string;

  constructor(
    showInForm: boolean,
    showInDetail: boolean,
    fieldName: string,
    isRequired: boolean,
    type: string,
    fieldCode: string,
    id: string,
    category?: string,
  ) {
    this.fieldCode = fieldCode;
    this.fieldName = fieldName;
    this.isRequired = isRequired;
    this.type = type;
    this.id = id;
    this.showInForm = showInForm;
    this.showInDetail = showInDetail;
    this.createdAt = new Date();
    this.createdBy = '';
    this.updatedAt = new Date();
    this.updatedBy = '';
    this.category = typeof category === 'undefined' ? '' : category;
  }
}
