<h2 mat-dialog-title>{{ dlgTitle }}
    <div class="dlg-button-close" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </div>
</h2>
<mat-dialog-content [perfectScrollbar]="config">

    <form [formGroup]="formMoveDeal">
        <mat-form-field *ngIf="isAutomation && !isMoveOnReply" appearance="outline">
            <mat-label>Automation step name</mat-label>
            <input matInput placeholder="Automation step name" [formControl]="autoStepName" name="autoStepName">
        </mat-form-field>

        <mat-form-field *ngIf="isExportDeals || (!isAutomation && !isChat)" appearance="outline">
            <mat-label>From Pipeline</mat-label>
            <mat-select placeholder="From Pipeline" matNativeControl [disabled]="true" [formControl]="fromPipelines"
                name="fromPipelines">
                <mat-option *ngFor="let pipeline of pipelines; let i = index" value="{{ pipeline.id }}">
                    {{ pipeline.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="isExportDeals || isMoveDeals">
            <mat-label>From Stage</mat-label>
            <mat-select placeholder="Choose From Stage" matNativeControl [formControl]="fromDealStages"
                name="fromDealStages">
                <mat-option *ngFor="let stage of fromStages" value="{{ stage.id }}">{{ stage.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="export()" class="dealMove">Export</button>
    <button class="mat-raised-button" style="margin-left: 15px;" (click)="closeDialog()">
        {{ cancelText }}</button>
</mat-dialog-actions>