import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IFilterOptions } from '../models';

@Component({
  selector: 'app-filter-options',
  templateUrl: './filter-options.component.html',
  styleUrls: ['./filter-options.component.scss']
})
export class FilterOptionsComponent implements OnInit {

  filters: IFilterOptions;
  title: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FilterOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.filters = data.filters;
    this.title = data.title;
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close({ filters: this.filters });
  }

  close() {
    this.dialogRef.close();
  }


  /*
    email: { enabled: boolean, show: boolean, exists: boolean, value: string, showExists: boolean, hideExists };
  (change)="toggleFilter($event, 'email', true)">
  */
  toggleFilterEnabled(event: any, field: string) {
    const enabled = event.checked;
    if (enabled) {
      this.filters[field].enabled = true;
    } else {
      this.filters[field].enabled = false;
    }
  }

  toggleFilterExists(event: any, field: string, show: boolean) {

    const exists = event.checked;
    console.log('event.checked:', event.checked);

    // this.filters[field].enabled = true;
    this.filters[field].exists = exists;
    // this.filters[field].showExists = exists;
  }
}
