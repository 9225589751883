import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, empty, of } from 'rxjs';
import { User } from '../../models/User2';
import * as _ from 'lodash';
import * as firebase from 'firebase/app';

/* globally accessible app code (in every feature module) */
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { SessionService } from '../session/session.service';

export interface IStripeMessage {
    success: boolean;
    message: string;
    email: string;
    userId: string;
}

@Injectable({
    providedIn: 'root'
})
export class StripeService {

    public stripeCancelSubject = new BehaviorSubject({ success: false, message: '', email: '', userId: '' } as IStripeMessage);

    private stripeSubs: any[] = [];
    private stripeSubsSubject = new BehaviorSubject([] as any[]);
    public stripeSubs$: Observable<any[]> = this.stripeSubsSubject.asObservable();

    private isWaitingSubject = new BehaviorSubject(true);
    public isWaiting$: Observable<boolean> = this.isWaitingSubject.asObservable();

    private showMessageSubject = new BehaviorSubject('');
    public showMessage$: Observable<string> = this.showMessageSubject.asObservable();

    userEmail: string;
    userId: string;
    user: User;
    stripeId: string;

    customer: any;
    subscriptions: any[] = [];
    canceled: any[] = [];

    constructor(
        private afs: AngularFirestore,
        public authService: AuthService,
        public sessionService: SessionService,
        public userService: UserService,
        private httpClient: HttpClient,
    ) {
        console.log('INIT StripeService.constructor()');
        this.resetService();

        this.authService.user
            .subscribe(user => {
                if (user) {
                    this.userId = user.id;
                    this.userEmail = user.email;

                    // const userData = this.sessionService.userData;
                    // .subscription(userData => {
                    //        if (userData) {
                    //            this.userId = userData.id;
                    //             this.userEmail = userData.response.userEmail;
                    //            console.warn('userData:', userData);
                    this.nextAuth(user);
                    //        }
                    // });

                } else {
                    this.sessionService.logout();
                    // this.authService.signOut();
                    // this.router.navigate(['#/auth/login']);
                }
            });
    }

    private resetService() {
        console.log('*** StripeService.resetService() ***');
        this.user = null; // This comes from UserService
        this.userEmail = '';
        this.showMessageSubject.next('');
        this.isWaitingSubject.next(_.cloneDeep(false));
    }

    // Each Lead Carrot account is created AFTER creating a Stripe Account.
    // Therefore, we should ALWAYS have a StripeID as we save it when creating the user
    public async getStripeId(email: string) {

        console.log('CALL getStripeId( ' + email + ' )');
        if (typeof email !== 'undefined' && email === null) {
            this.userEmail = email;
        }
        console.log('getStripdId( ' + this.userEmail + ' )');

        if (typeof email === 'undefined' || email === null || email.trim() === '') {

            console.warn('NO user email');

            this.authService.user
                .subscribe(auth => this.nextAuth(auth));
        } else {
            if (typeof this.userId === 'undefined' || this.userId === null) {
                await this.userService.getUser(this.userId)
                    .then(user => {
                        this.nextGetUser(user);
                        return user;
                    })
                    .catch((error: any) => {
                        console.warn(error);
                        throw error;
                    });
            } else {
                // this.getStripeDataFromApi(this.userEmail);
                this.getStripeDataFromApi(email);
            }
        }
        return email;
    }

    private async nextAuth(user: any) {
        if (user) {
            console.log('StripeService.authService() - AUTHORIZED');

            this.userEmail = user.email;
            this.userId = user.id;
            return await this.userService
                .getUser(user.id)
                .then(nextUser => {
                    this.nextGetUser(nextUser);
                    return nextUser;
                })
                .catch((error: any) => {
                    console.warn(error);
                    throw error;
                });
        } else {
            console.log('StripeService.authService() - NOT AUTHORIZED');
        }
    }

    private nextGetUser(user: any) {
        console.warn('nextGetUser() | user:', user);

        this.user = user;
        this.userId = user.id;
        this.userEmail = user.userEmail;
        const stripeId = this.getStripeIdFromDb(this.userId);

        console.warn('nextGetUser() | user:', user);
        console.warn('nextGetUser() | stripeId:', stripeId);
        console.warn('nextGetUser() | user.email:', user.userEmail);

        if (!stripeId) {
            this.getStripeDataFromApi(this.userEmail);
        }
    }

    private getStripeIdFromDb(userId: string): Promise<string> {
        console.log('getStripeIdFromDb() | userId:', userId);

        let stripeId = '';
        if (this.userId === userId) {
            if (
                (typeof this.user !== 'undefined' && this.user !== null) &&
                (typeof this.user.stripeId !== 'undefined' && this.user.stripeId !== null && this.user.stripeId.trim() !== '')
            ) {
                this.stripeId = this.user.stripeId;
                stripeId = this.user.stripeId;
            }
        } else {
            console.log('We can only search for the current user');
            console.log('This is some ADMIN feature we can add');
        }

        return Promise.resolve(stripeId);
    }

    private getStripeDataFromApi(email: string) {

        console.warn('getStripeDataFromApi() | email:', email);

        if (typeof email !== 'undefined' && email !== null && email.trim() !== '') {
            const headers = new HttpHeaders();
            headers.set('Content-Type', 'application/json');
            const body = {
                email,
            };
            // this.httpClient.put('https://us-central1-leadca-72a79.cloudfunctions.net/stripeTestGetId', body, { headers })
            this.httpClient.put('https://us-central1-leadca-72a79.cloudfunctions.net/stripeGetId', body, { headers })
                .subscribe(
                    (data: any) => {
                        if (data.success === true) {

                            console.warn('stripe data:', data.data);

                            if (typeof data.data.customers.data !== 'undefined' && data.data.customers.data.length) {
                                this.stripeId = data.data.customers.data[0].id;
                                this.customer = data.data.customers.data[0];
                                this.subscriptions = this.updateSubscriptionStatus(
                                    data.data.customers.data[0].subscriptions.data
                                );
                            } else {
                                this.stripeId = '';
                                this.customer = null;
                                this.subscriptions = [];
                            }

                            console.warn('this.subscriptions === ', this.subscriptions);
                            console.warn('stripeId:', this.stripeId);

                            this.isWaitingSubject.next(false);
                        }
                    },
                    error => {
                        console.log('Error', error);
                        this.isWaitingSubject.next(false);
                    }
                );
        }
    }

    private updateSubscriptionStatus(subscriptions: []) {
        // Because the changes to subscriptions via the stripe api are not instant.
        // mark any recently canceled subscriptions as canceled.
        // ToDo: Try and intercept the Cancel Event from Stripe to remove the listing...
        const tempSubscriptions = subscriptions.slice(0);
        if (this.canceled.length > 0) {
            tempSubscriptions.forEach((item: any, index) => {
                this.canceled.forEach((canceledItem, canceledIndex) => {
                    if (item.id === canceledItem) {
                        item.status = 'canceled';
                    }
                });
            });
        }
        return tempSubscriptions;
    }

    public cancelStripeSubscription(subscriptionId: string): boolean {

        this.isWaitingSubject.next(true);
        this.logCancelRequest(this.userId, this.userEmail, subscriptionId); // Log cancelation to database
        this.canceled.push(subscriptionId); // Add this subscription to the canceled subscriptions list

        if (typeof subscriptionId !== 'undefined' && subscriptionId !== null && subscriptionId.trim() !== '') {
            const headers = new HttpHeaders();
            headers.set('Content-Type', 'application/json');
            const body = {
                subscriptionId,
            };
            // this.httpClient.put('https://us-central1-leadca-72a79.cloudfunctions.net/stripeTestCancelSubscription', body, { headers })
            this.httpClient.put('https://us-central1-leadca-72a79.cloudfunctions.net/stripeCancelSubscription', body, { headers })
                .subscribe((data: any) => {
                    if (data.success === true) {
                        this.getStripeDataFromApi(this.userEmail);
                        this.updateCancelStatus(this.userId, true);
                        const cancelData: IStripeMessage = {
                            success: true,
                            message: 'Subscription has been canceled',
                            email: this.userEmail,
                            userId: this.userId,
                        };
                        this.stripeCancelSubject.next(cancelData);
                        this.showMessageSubject.next('Subscription has been canceled');
                        this.isWaitingSubject.next(false);
                    }
                },
                    error => {
                        console.log('Error', error);
                        const cancelData: IStripeMessage = {
                            success: false,
                            message: 'Error while attempting to cancel subscription',
                            email: this.userEmail,
                            userId: this.userId,
                        };
                        this.stripeCancelSubject.next(cancelData);
                        this.showMessageSubject.next('Error while attempting to cancel subscription');
                        this.isWaitingSubject.next(false);
                    });
        }
        return true;
    }

    private logCancelRequest(userId, userEmail, subscriptionId) {
        const row = {
            uid: userId,
            email: userEmail,
            subscriptionId,
            completed: false,
            date_created: firebase.firestore.FieldValue.serverTimestamp(),
        };
        this.afs.collection('cancel_subscription')
            .add(row)
            .then((res) => {
                const json = {
                    success: true,
                    message: 'Cancel user subscription',
                    data: { row }
                };
                return json;
            })
            .catch((error) => error);
    }

    private updateCancelStatus(userId: string, status: boolean): Promise<any> {

        const data = {
            completed: status
        };

        if (typeof userId === 'undefined') {
            const json = {
                success: true,
                message: 'Cancel subscription status updated',
            };

            return Promise.resolve(json);
        }

        return this.afs.collection('cancel_subscription')
            .doc(userId)
            .set(data, { merge: true })
            .then((res) => {
                const json = {
                    success: true,
                    message: 'Cancel subscription status updated',
                };
                console.log(json);
                return json;
            })
            .catch((error) => {
                const json = {
                    success: false,
                    message: 'Unable to update cancel subscription status',
                    error,
                };
                console.error(json);
                return json;
            });
    }

}
