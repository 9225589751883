<!-- nav -->
<nav *ngIf="isLoggedIn" class="navbar navbar-expand navbar-dark">
    <div class="navbar-nav">
        <!--<a class="nav-item nav-link" routerLink="/" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">Home</a>-->
        <a class="nav-item nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dashboard</a>

        <!--<a class="nav-item nav-link" routerLink="/home">Home</a>-->

        <a class="nav-item nav-link" routerLink="/deals">Deals</a>
        <a class="nav-item nav-link" routerLink="/chat">Chats</a>
        <a class="nav-item nav-link" routerLink="/contacts">Contacts</a>
        <a class="nav-item nav-link" routerLink="/organizations">Organizations</a>
        <a class="nav-item nav-link" routerLink="/teams">Teams</a>
        <a class="nav-item nav-link" routerLink="/activity">Activities</a>
        <a class="nav-item nav-link" routerLink="/import">Import</a>
        <a class="nav-item nav-link" routerLink="/finder">Lead Finder</a>
        <a class="nav-item nav-link" routerLink="/toolbox">Toolbox</a>
        <!-- <a class="nav-item nav-link" (click)="logout()">Logout</a> -->
    </div>
</nav>