<div *ngIf="showDropZone" class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)"
    [class.hovering]="isHovering">

    <h3>Profile Image Drop Zone</h3>
    <p>Drag and Drop a File</p>

    <div class="file">
        <label class="file-label">
            <input class="file-input" type="file" (change)="onDrop($event.target.files)">
        </label>
    </div>

    <div class="file-cta">
        <span class="file-icon">
            <i class="fa fa-upload"></i>
        </span>
        <span class="file-label">
            or choose a file…
        </span>
    </div>

</div>

<!--
<h3>Uploads</h3>
-->

<div *ngFor="let file of files">
    <upload-task [file]="file" [userId]="userId"></upload-task>
</div>