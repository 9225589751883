import localforage from 'localforage';
import axios from 'axios';
import {
  AfterViewInit,
  Component,
  HostListener,
  ElementRef,
  NgZone,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location as NgLocation } from '@angular/common';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

// Init Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
// firebase.initializeApp(environment.firebase); // firebase.initializeApp(yourConfig);

// Init GeoFireX
import * as geofirex from 'geofirex-af';

import { AngularCsv } from 'angular-csv-ext';
import { AngularFirestore } from '@angular/fire/firestore';
import { cloneDeep } from 'lodash';
import { ɵBROWSER_SANITIZATION_PROVIDERS } from '@angular/platform-browser';
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  FormArray,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { MaterialModule } from '../shared/material.module';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Md5 } from 'ts-md5';
import { Routes, Router, RouterModule } from '@angular/router';
import * as xml2js from 'xml2js';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
} from 'ngx-perfect-scrollbar';
import { SelectionModel } from '@angular/cdk/collections';
// import { NgxSpinnerService } from 'ngx-spinner';

import {
  Observable,
  of,
  BehaviorSubject,
  pipe,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, first, map, subscribeOn } from 'rxjs/operators';

import firebaseApp from 'firebase/app';

// firebaseApp.initializeApp(environment);
// Init Firebase
// firebase.initializeApp(environment.firebase); // firebase.initializeApp(yourConfig);

import * as moment from 'moment';
const { v4: uuidv4 } = require('uuid');

import { environment } from '../../environments/environment';
import {
  downloadFilteredExport,
  getSessionSetting,
  isUndefinedOrNull,
  isUndefinedOrNullOrEmpty,
  setSessionSetting,
} from '../shared/utils';

// Import Models
import { IApiResponse, ICompany, IUser } from '../models';

// Import Services
import {
  AuthService,
  FingerprintService,
  LeadService,
  MessageService,
  NearbyLocationsService,
} from '../services';

// Import Components
import { ExportCustomDialogComponent } from '../export-custom-dialog/export-custom-dialog.component';
import { ExportDialogComponent } from '../export-dialog/export-dialog.component';
import { ExportResults } from '../models/ExportResults';
import { ExportResultsDialogComponent } from '../export-results-dialog/export-results-dialog.component';
import { FilterOptionsComponent } from '../filter-options/filter-options.component';
import { ImportDialogComponent } from '../import-dialog/import-dialog.component';
import { PushLeadsDialogComponent } from '../push-leads-dialog/push-leads-dialog.component';

// -----------------------------------------------------------
declare var require: any;
import { Address } from 'google-address-autocomplete';
import { MapsAPILoader } from '@agm/core';
// import * as AgmCore from '@agm/core';
import {
  Location,
  Appearance,
  GermanAddress,
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
// import {} from 'googlemaps';
// -----------------------------------------------------------

const COL_PAUSED = 'paused';
const DEFAULT_COUNTRY_CODE = 'US';
const IS_DEBUG = false;

const MIN_RESULTS = 50;
const MIN_RESULT_CHECK_INTERVAL = 50000; // 10 seconds

import {
  DataExport,
  DataFilter,
  DataImport,
  Area,
  IFilters,
  IGeoCoord,
} from './models';

export interface IWlOptions {
  apikey: string;
  locationId: string;
  token: string;
  integrationId: string;
}

export interface IBranding {
  css: string;
  icon: string;
  js: string;
  logo: string;
  name: string;
  spinner: string;
  url: string;
}

export interface IBulkSearch {
  name: string;
}

export interface ISearchLimits {
  userId: string;
  daily: number;
  weekly: number;
  monthly: number;
}

export interface ISearchStats {
  level: string;
  userId: string;
  clientId: string;
  used: number;
  available: number;
  limit: number;
  period: string;
}

const MAX_BULK_SEARCH = 5;

// Default Search Radius is 40 km;
const DEFAULT_SEARCH_RADIUS = 40;
const DEFAULT_SEARCH_RADIUS_MILES = 25;
const MIN_RADIUS = 1;
const MAX_RADIUS = 40;
const MAX_RADIUS_MILES = 25;

const LOADING_PREVIOUS_SEARCH = 'Loading previous search results...';
const SEARCH_LIVE_DATA = 'Searching for live data...';
const EXPORTING_RESULTS = 'Exporting search results...';
const ERROR_CONNECTION_TO_LEAD_FINDER = 'Error connection to Lead Finder.';
const ERROR_CONNECTION_TO_LEAD_CARROT_PLUGIN =
  'Error connecting to Lead Carrot Plugin.';

const COLLECTION_TRIGGER_GMB = 'trigger_gmb';
const COLLECTION_SEARCH_LIMITS_AGENCY = 'search_limits_agency';

const DEFAULT_SEARCH_LIMITS: ISearchLimits = {
  userId: '',
  daily: 315,
  weekly: 315,
  monthly: 1230,
};

const DEFAULT_SEARCH_LIMITS_TRIAL: ISearchLimits = {
  userId: '',
  daily: 5,
  weekly: 5,
  monthly: 5,
};

const GLOCAL = require('./glocal.json');

@Component({
  selector: 'app-finder',
  templateUrl: './finder.component.html',
  styleUrls: ['./finder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinderComponent implements OnInit, OnDestroy, AfterViewInit {
  message: string = 'Connecting...';
  searchRadius = DEFAULT_SEARCH_RADIUS;
  searchRadiusMiles = DEFAULT_SEARCH_RADIUS_MILES;
  searchStats: ISearchStats = {
    level: 'app',
    userId: '',
    clientId: '',
    available: 0,
    limit: 0,
    used: 0,
    period: 'weekly',
  };

  searchLimitsFinder: ISearchLimits = null;
  searchLimitsAgency: ISearchLimits = null;
  searchCount: number = 0;

  onlyCategories = true;
  bulkSearch = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  noSearches: boolean = true;

  bulkList: IBulkSearch[] = [];
  searchList: any[] = [];

  // Initialize geofirex by passing a reference to the Firebase App.
  geo = geofirex.init(firebase);

  // addLeadCategory = new FormControl();
  // options: string[] = glocal.map(item => item.name);
  // filteredCategories: Category[] = [];
  filteredCategories: any[] = [];
  // categoryForm: FormGroup;
  isLoading = false;
  keyword = 'name';
  glocal = GLOCAL;

  branding: IBranding = {
    css: '',
    icon: '',
    js: '',
    logo: '',
    name: 'Lead Carrot',
    spinner: '',
    url: '',
  };

  categories$ = new BehaviorSubject<any[]>([]);
  categoriesLoading = false;
  categoriesInput$ = new Subject<string>();
  // selectedMovie: any;
  minLengthTerm = 3;

  public filters: IFilters = this._getDefaultFilters();

  public geoCoord: IGeoCoord = {
    locality: '',
    state: '',
    country: '',
    lat: 25.9875,
    long: -97.186389,
    radius: DEFAULT_SEARCH_RADIUS * 1000,
    place_id: '',
  };

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement,
    },
  };

  initGeoLocation = false;
  timerGeoLocation: any = null;
  googleMapType = '';
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;

  company: ICompany;
  user: IUser;

  allSubscriptions = new Subscription();
  searchSubscriptions = new Subscription();

  tokenResponse = new Observable();
  integrationInfo: any = null;

  waitingMessage: string;
  processingFilters = false;
  firstTime = true;
  lastCount = 0;
  hasNewMessage: boolean; // Use this to send system messages to users

  form: FormGroup;
  categoryCount: number;
  getLocSubs: any;
  getTaskService: any;

  area: Area;

  lastState: boolean;
  showSpinner: boolean;
  showSpinnerAjax: boolean;

  public gravatarUrl = '';
  userId = '';
  email = '';
  isLoggedIn = false;
  showPluginHelp = false;

  uid: string;
  public allCategoriesLookup: string[];
  public allCategoriesList: any[];

  selectedTab: number;
  searches = [{ city: '' }];
  cities: string[];

  leadCategory: string;
  leadExcludeCategory: string;

  leadLocation: string;
  addLeadsStep: number;
  exportFileName: string;

  showDropDown: boolean; // Toggles display of dropdown containing the search results

  badLocation = true;
  data: any[] = [];
  temp: any[] = []; // Filtering uses 'temp', and 'rows'
  rows: any[] = [];
  filtered: any[] = [];
  columns: any = null;

  allSelected: any[] = [];

  reorderable = true;

  dataExport: DataExport = {
    format: 'csv',
    step: 1,
    count: 0,
    percent: 0,
    data: null,
  };

  dataImport: DataImport = {
    format: 'csv',
    step: 1,
    count: 0,
    percent: 0,
    data: null,
  };

  dataFilter: DataFilter = {
    step: 1,
    count: 0,
    selected: [],
  };
  csv2go: AngularCsv;

  timeout: any;
  timeoutNextResults: any;

  loadingIndicator = true;
  stillSearching: boolean;
  showNoResultsMessage = false;
  showNoMoreResultsMessage = false;

  // Used when we find more locations in the radius of the initial search
  addedExtraLocations = false;
  extraLocations = [];
  isMapVisible = true;
  hasResults = false;
  didSearch = false;

  allPipelines = new FormControl();
  pipelines: any[] = [];
  stages: any[] = [];
  pipelineId: string;
  stageId: string;

  apiLeadCarrot = environment.cloudUrl + '/';
  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild('leadsPS', { static: false }) leadsPS: PerfectScrollbarComponent;
  @ViewChild('search', { static: false }) public searchElement: ElementRef;
  @ViewChild('mydatatable', { static: false }) public table: any;

  // Initialize geofirex by passing a reference to the Firebase App.
  points: any[] = [];
  pointsSubject = new BehaviorSubject<any[]>([]);
  geocoder: any;

  routeLinks: any[];
  activeLinkIndex = -1;
  lastTicket: any = {};

  wlOptions: IWlOptions = {
    apikey: null,
    locationId: null,
    token: null,
    integrationId: null,
  };

  leadIds: string[] = [];

  @HostListener('window:message', ['$event'])
  handleMessage(event: any) {
    /*
    if (event.origin.trim() === 'http://localhost:4200') {
      return;
    }
    */

    // console.warn('*** GOT THE MESSAGE ***');
    // console.warn('*** postMessage:', event);

    if (typeof event.data.action !== 'undefined') {
      // console.warn('FINDER COMPONENT - ' + event.data.key);

      if (event.data.action === 'save') {
        // Save values
        if (event.data.key !== 'undefined') {
          if (event.data.key === 'finderData') {
            localforage.setItem(event.data.key, event.data.value);
            if (
              typeof event.data.value.apikey !== 'undefined' &&
              event.data.value.apikey.trim() !== ''
            ) {
              localforage.setItem('finderApikey', event.data.value.apikey);
            }
            localforage.setItem('finderCompanyId', event.data.value.companyId);
            localforage.setItem('finderUserId', event.data.value.userId);
          } else {
            localforage.setItem(event.data.key, event.data.value);
          }
        }
        // this.ngOnInit();
      }
    }

    // }

    /*
    console.warn('*** MESSAGE ORIGIN *** :', event.origin);
    console.warn(event);
    console.warn(event.data);
    localforage.setItem('finderData', JSON.stringify(event.data));
    alert(event.origin);
    */
  }

  uuid: string = '';
  visitorId: string = null;
  fingerprint$: Observable<string>;
  searchParams: any = null;
  searchLoaded: boolean = false;
  doSearch: boolean = true;
  showPushToCrm: boolean = false;

  // geocoder = new GeoCoder;
  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public authService: AuthService,
    // public categoryService: CategoryService,
    private dialog: MatDialog,
    private fingerprintService: FingerprintService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public leadService: LeadService,
    private ngLocation: NgLocation,
    private mapsApiLoader: MapsAPILoader,
    public msgService: MessageService,
    public nearbyLocationsService: NearbyLocationsService,
    private ngZone: NgZone,
    private router: Router,
    public snackBar: MatSnackBar // private spinner: NgxSpinnerService
  ) {
    this.fingerprint$ = this.fingerprintService.subscribeToFingerprint$();
    this.allSubscriptions.add(
      this.fingerprint$.subscribe((visitorId) => (this.visitorId = visitorId))
    );

    if (IS_DEBUG) {
      this.apiLeadCarrot = environment.cloudUrl + '/';
    }
    this.stillSearching = false;
    this.pipelineId = '';
    this.pipelines = [];
    this.stageId = '';
    this.stages = [];
    this.points = [];
    this.pointsSubject.subscribe((value) => {
      this.points = value;
    });

    this.waitingMessage = SEARCH_LIVE_DATA;

    this.leadCategory = '';
    this.leadLocation = '';

    this.hasNewMessage = false;
    this.categoryCount = -1;
    this.allCategoriesLookup = [];
    this.allCategoriesList = [];

    console.clear();
    this.form = this.formBuilder.group({
      theCategories: this.buildCategories(),
    });

    // console.log(this.form.get('theCategories'));

    this.lastState = false;

    this.area = {
      center: {
        latitude: 0,
        longitude: 0,
      },
      radius: DEFAULT_SEARCH_RADIUS,
    };
    this.searchRadius = DEFAULT_SEARCH_RADIUS;
    this.searchRadiusMiles = DEFAULT_SEARCH_RADIUS_MILES;

    this.dataExport = {
      format: 'csv',
      step: 1,
      count: 0,
      percent: 0,
      data: null,
    };

    this.dataImport = {
      format: 'csv',
      step: 1,
      count: 0,
      percent: 0,
      data: null,
    };

    this.dataFilter = {
      step: 1,
      count: 0,
      selected: [],
    };

    this.showDropDown = false;

    const debug = false;

    this.cities = [];
    this.selectedTab = 0;
    this.searches = [{ city: '' }];

    this.resetGeoCoords();

    this.leadCategory = '';
    this.leadExcludeCategory = '';
    this.leadLocation = '';

    this.addLeadsStep = 0;

    this._loadCategories();

    // Moved form ngOnInit()
    this.zoom = 10;
    this.latitude = 52.520008;
    this.longitude = 13.404954;
    this._setCurrentPosition();
    this.updateRadius(this.searchRadiusMiles * 1609.344);
    this.rows = [];
    this.rows.push([]);
    this.temp = [];
    this.temp.push([]);
    this.filtered = [];
    this.filtered.push([]);
    let index = 0;
    this.selectedTab = index;
    this.cities = [];
    // this.cities[index] = this.geoCoord.locality;
    this.searches = [];
    // this.searches.push({ city: this.geoCoord.locality });
    this.points = [];
    this.lastCount = 0;

    this.searchParams = null;

    this.route.queryParams.subscribe((value) => {
      console.warn('*** Finder - sub queryParams:', value);
      if (value) {
        this.searchParams = value;
        console.log('value:', value);
        if (typeof this.searchParams.searchId !== 'undefined') {
          if (this.searchParams.searchId === 'null/') {
            console.log('searchId is null');
            this.searchParams = { searchId: '' };
          } else {
            const TOKEN = '%2F';
            const nPos = this.searchParams.searchId.indexOf(TOKEN);
            if (nPos > -1) {
              if (nPos === this.searchParams.searchId.length - TOKEN.length) {
                this.searchParams.searchId = this.searchParams.searchId.slice(
                  0,
                  this.searchParams.searchId.length - TOKEN.length
                );
                if (this.searchParams.searchId === 'null') {
                  console.log('searchId is null');
                  this.searchParams.searchId = '';
                }
              }
            }
          }
        }
      }
    });

    console.warn('*** Finder - queryParams:', this.searchParams);
  }

  ngOnInit() {
    // token === integrationId
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      // Load the css file from storage.
      // this._loadCustomCss(this.wlOptions.integrationId);

      console.warn('*** Finder - params:', params);
      if (params.get('token') === null) {
        this.isLoggedIn = false;
        this.message = ERROR_CONNECTION_TO_LEAD_CARROT_PLUGIN;
        this.showPluginHelp = true;
        return;
      }

      const options = await this.parseRouteOptions(params);
      this.wlOptions.apikey = options.apikey;
      this.wlOptions.integrationId = options.integrationId;
      this.wlOptions.locationId = options.locationId;
      this.wlOptions.token = options.token;

      // Lookup the apikey in the CRM and get the UID.
      this.visitorId = await this.fingerprintService.getVisitor();
      console.log('*** visitorId:', this.visitorId);

      this.tokenResponse = await this.getCustomLoginToken(
        this.wlOptions.integrationId,
        this.visitorId
      );

      this.tokenResponse.subscribe((customToken: any) => {
        try {
          // Login to FIRESTORE with this CUSTOM TOKEN
          console.log('customToken:', customToken);

          if (typeof customToken.data !== null) {
            firebase
              .auth()
              .signInWithCustomToken(customToken.data)
              .then(async (userCredential) => {
                // Signed in
                localforage.setItem('token', customToken.data);
                // Parse JWT and custom branding
                this.integrationInfo = this.parseJwt(customToken.data);
                this.branding = this.parseBranding(this.integrationInfo.claims);

                console.warn(
                  'customToken: userCredential.user:',
                  userCredential.user
                );
                console.warn(
                  'customToken: this.integrationInfo:',
                  this.integrationInfo
                );
                console.warn(
                  'customToken: claims:',
                  this.integrationInfo.claims
                );

                // const user = userCredential.user;

                this.allSubscriptions.add(
                  this.authService.user.subscribe(async (user) => {
                    this.user = user;
                    if (user) {
                      this.userId = user.id;
                      this.email = user.email;

                      // Validate the login --->
                      if (this.integrationInfo.claims.agency) {
                        const validationStatus = await this.testConnection(
                          this.integrationInfo.claims.custom.apikey,
                          this.wlOptions.locationId
                        );

                        if (validationStatus) {
                          this.isLoggedIn = true;
                        } else {
                          this.isLoggedIn = false;
                          this.message = ERROR_CONNECTION_TO_LEAD_FINDER;
                          this.showPluginHelp = true;
                          // return Promise.resolve(false);
                        }
                      } else if (this.integrationInfo.claims.plugin) {
                        const validationStatus = await this.testConnection(
                          this.integrationInfo.claims.custom.apikey,
                          this.wlOptions.locationId
                        );

                        if (validationStatus) {
                          this.isLoggedIn = true;
                        } else {
                          this.isLoggedIn = false;
                          this.message = ERROR_CONNECTION_TO_LEAD_CARROT_PLUGIN;
                          this.showPluginHelp = true;
                          // return Promise.resolve(false);
                        }
                      } else {
                        this.isLoggedIn = true;
                      }

                      // Load previous search data.
                      if (this.isLoggedIn && this.searchLoaded === false) {
                        this.searchLoaded = true;
                        if (
                          this.searchParams !== null &&
                          typeof this.searchParams.searchId !== 'undefined' &&
                          this.searchParams.searchId !== null
                        ) {
                          this.loadSearchRecord(
                            this.searchParams.searchId
                          ).then((searchRecord) => {
                            // Load the values into the form.
                            if (searchRecord) {
                              // Trigger the search.
                              this.area.center.latitude = searchRecord.lat;
                              this.area.center.longitude = searchRecord.long;
                              this.leadCategory = searchRecord.category;
                              this.leadLocation = searchRecord.location;

                              this.geoCoord.lat = searchRecord.lat;
                              this.geoCoord.long = searchRecord.long;
                              this.geoCoord.locality = searchRecord.locality;
                              this.geoCoord.state = searchRecord.region;
                              this.geoCoord.country = searchRecord.country_name;
                              this.geoCoord.place_id = searchRecord.place_id;

                              const seachLocation: Location = {
                                latitude: searchRecord.lat,
                                longitude: searchRecord.long,
                              };
                              this.onLocationSelected(seachLocation);

                              this.doSearch = false;
                              this.badLocation = false;

                              console.warn('lead category:', this.leadCategory);
                              this.waitingMessage = LOADING_PREVIOUS_SEARCH;
                              this.findLeads('history');
                            }
                          });
                        }
                      }
                    } else {
                      this.userId = '';
                      this.email = '';
                      this.isLoggedIn = false;
                    }

                    const searchLimitsResults = await Promise.all([
                      this.getLimitsAgency('leadfinder'),
                      this.getLimitsAgency(this.user.id),
                      // this.getLimitsLocation(this.user.id),
                    ]);
                    this.searchLimitsFinder = searchLimitsResults[0];
                    this.searchLimitsAgency = searchLimitsResults[1];
                    // this.searchLimitsLocation = searchLimitsResults[2];

                    this.searchCount = await this.getSearchCount(
                      this.user.id,
                      'monthly',
                      this.searchLimitsAgency.monthly
                    );
                    this.searchCount = this.searchCount + 1;

                    console.log('this.searchCount:', this.searchCount);
                    console.log(
                      'this.searchLimitsAgency.monthly:',
                      this.searchLimitsAgency.monthly
                    );

                    if (this.searchCount >= this.searchLimitsAgency.monthly) {
                      this.noSearches = true;
                    } else {
                      this.noSearches = false;
                    }
                    console.log('this.noSearches:', this.noSearches);

                    if (this.wlOptions.locationId !== '') {
                      this.searchStats = Object.assign(
                        {},
                        await this.getSearchStats(
                          this.userId,
                          this.wlOptions.locationId
                        )
                      );
                      console.log('🥕🥕');
                      console.log('searchStats:', this.searchStats);
                    }

                    // Create Gravatar hash from user email.
                    const md5 = new Md5();
                    this.gravatarUrl = md5
                      .appendStr(this.email.toLocaleLowerCase().trim())
                      .end()
                      .toString();
                    // <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=40&d=mp" >

                    // console.log('Gravatar URL from email:', this.gravatarUrl);

                    this.gravatarUrl =
                      'https://www.gravatar.com/avatar/' +
                      this.gravatarUrl +
                      '?s=38&d=mp';

                    return await this.getFilters(
                      this.wlOptions.locationId
                    ).subscribe((value) => {
                      // console.log('this.getFilters():', value);

                      if (value && value.success) {
                        if (isUndefinedOrNull(value.data.searchFilters.email)) {
                          this.filters = this._getDefaultFilters();
                        } else {
                          this.filters = value.data.searchFilters;
                        }
                      } else {
                        this.filters = this._getDefaultFilters();
                      }

                      // console.log('new value for filters:', this.filters);

                      for (
                        let searchIndex = 0;
                        searchIndex < this.rows.length;
                        searchIndex++
                      ) {
                        this.filtered[searchIndex] = this.rows[
                          searchIndex
                        ].filter((lead: any) => {
                          // Only display the filtered results
                          // if (lead.categories[category] === true) {
                          return this._showLead(this.filters, lead);
                        });
                        this.temp[searchIndex] = this.filtered[searchIndex];
                      }
                    });
                  })
                );
              });
          }
        } catch (err) {
          localforage.removeItem('token');
          const errorCode = err.code;
          const errorMessage = err.message;
          console.warn('errorCode:', errorCode);
          /*
            {
                "apikey": null,
                "locationId": null,
                "token": "finder",
                "integrationId": "finder"
            }
            */
          if (
            this.wlOptions.locationId === null ||
            this.wlOptions.locationId === 'standalone'
          ) {
            this.userId = '';
            this.email = '';
            this.isLoggedIn = false;
            this.message = ERROR_CONNECTION_TO_LEAD_CARROT_PLUGIN;
            this.showPluginHelp = true;
          }
        }
      });
    });

    this.allSubscriptions.add(
      this.router.events.subscribe((res) => {
        const routeLink = this.routeLinks.find(
          (tab) => tab.link === '.' + this.router.url
        );
        this.activeLinkIndex = this.routeLinks.indexOf(routeLink);
      })
    );

    this.lastState = this.showSpinner;

    if (this.lastState !== false) {
      if (
        typeof this.area !== 'undefined' &&
        this.area.center.longitude !== 0
      ) {
        // ToDo: Added by Andre
        this.showSpinner = true;

        /*
        this.getLocSubs = this.leadService.queryHashes2(
          this.leadCategory,
          this.area.center.latitude,
          this.area.center.longitude,
          this.searchRadius
        );
        */

        // CREATE a susbscription to the results FROM the Lead Service.
        /*
        this.allSubscriptions.add(
          this.leadService.points.subscribe((leads) => {
            if (leads) {
              if (leads.length !== this.lastCount) {
                this.showSpinner = true;
                this.lastCount = leads.length;

                console.log('LEADS.length === ', leads.length);

                // TODO: IF we have no image AND we have a URL for: YouTube, Twitter, Instagram THEN check for avatar

                // Only display the filtered results
                this.temp = [];
                for (const row of leads) {
                  if (row.categories[this.leadCategory] === true) {
                    const showIt = this._showLead(this.filters, row);
                    if (showIt) {
                      this.temp.push(row);
                      // Build the Exclude Filter list
                      this.createFilterCategoryList(row);
                    }
                  }
                }
                this.rows[0] = this.temp[0];
                if (this.rows[0].length > 0) {
                  this.showSpinner = false;
                }
              }
            } else {
              console.log('------------ NO LEADS ----------------');
            }
            return true;
          })
        );
        */
        // this.showSpinner = false;
      } else {
        /*
        console.warn('*** nothing here ***');
        this.allSelected = [];
        this.rows = [];
        this.rows.push([]);
        this.temp = [];
        this.temp.push([]);
        */
      }
    }

    /*
    this.addLeadCategory
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.categoryService.search({ name: value }, 1)
          .pipe(
            finalize(() => this.isLoading = false),
          )
        )
      )
      .subscribe(categories => this.filteredCategories = categories.results);
    */
  }

  ngOnDestroy() {
    if (typeof this.getLocSubs !== 'undefined') {
      this.getLocSubs.unsubscribe();
    }
    this.allSubscriptions.unsubscribe();
    this.searchSubscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.getUserLocation();
    // console.log('afterinit');

    if (!this.initGeoLocation) {
      this.timerGeoLocation = setInterval(() => {
        if (
          typeof this.searchElement !== 'undefined' &&
          this.searchElement !== null
        ) {
          this.initGeoLocation = true;

          /*
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult =
              autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            } else {
              this.onChange(place);
            }
          });
          */
        } else {
          clearInterval(this.timerGeoLocation);
        }
      }, 500);

      try {
        setTimeout(() => {
          clearInterval(this.timerGeoLocation);
        }, 5000);
      } catch (error) {
        console.warn(error.message);
      }
    }
  }

  async getLimitsAgency(product: string): Promise<ISearchLimits> {
    try {
      return this.afs
        .collection<ISearchLimits>(COLLECTION_SEARCH_LIMITS_AGENCY)
        .doc(product)
        .ref.get()
        .then(async (snapshot) => {
          if (snapshot.exists) {
            const docs = snapshot.data();
            if (typeof docs === 'undefined') {
              return DEFAULT_SEARCH_LIMITS;
            }
            return docs;
          } else {
            return DEFAULT_SEARCH_LIMITS;
          }
        });
    } catch (error) {
      console.error(error);
      return Promise.resolve(DEFAULT_SEARCH_LIMITS_TRIAL);
    }
  }

  async loadSearchRecord(searchId: string): Promise<any> {
    try {
      if (!searchId) {
        return Promise.resolve(null);
      }

      // Get the seach history from the database.
      return firebase
        .firestore()
        .collection(COLLECTION_TRIGGER_GMB)
        .doc(searchId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            return null;
          }
          return snapshot.data();
        });

      /*
      category: "clothing store"
      client_id: "standalone"
      country_code: "US"
      country_name: "United States of America"
      date_created: t {seconds: 1633709614, nanoseconds: 938000000}
      keyword: "clothing store"
      language: "en"
      lat: 43.0389025
      locality: "Milwaukee"
      location: "Milwaukee, WI, US"
      long: -87.9064736
      place_id: "ChIJ50eLV9cCBYgRhHtBtSIZX0Q"
      primary: true
      region: "WI"
      start: 0
      type: "gmb"
      user_email: "blueshirtguy@leadcarrot.io"
      user_id: "g4qRbhiqjoXM0mtTaMFNt299hUv2"
      user_name: "blueshirtguy@leadcarrot.io"
      uuid: "d16e33de-7540-4d6c-9454-b0898d8b91a0"
      */

      return Promise.resolve(null);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getSearchCount(
    userId: string,
    timeframe: string = 'monthly',
    limit: number = 0
  ) {
    try {
      if (!userId) {
        throw Error('Missing required user ID to get search count');
      }

      if (!timeframe) {
        throw Error('Missing required timeframe to get search count');
      }

      // Lookup Thrivecart Product in database
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(endDate.getDate());

      const startDate = new Date(endDate.toDateString());
      startDate.setHours(0, 0, 0, 0);
      console.log('** begin:', startDate.toDateString());

      startDate.setMonth(startDate.getMonth() - 1);

      console.log('begin:', startDate.toDateString());
      console.log('end:', endDate.toDateString());

      const fsBegin = firebase.firestore.Timestamp.fromDate(startDate);
      const fsEnd = firebase.firestore.Timestamp.fromDate(endDate);

      console.log('fsBegin:', fsBegin);
      console.log('fsEnd:', fsEnd);

      if (limit <= 0) {
        limit = 10000;
      }
      console.log('limit:', limit);
      console.log('userId:', userId);

      const searches = await firebase
        .firestore()
        .collection(COLLECTION_TRIGGER_GMB)
        .orderBy('date_created', 'desc')
        .where('date_created', '>=', fsBegin)
        .where('date_created', '<', fsEnd)
        .where('user_id', '==', userId)
        .where('primary', '==', true)
        .limit(limit)
        .get()
        .then((snapshots) => {
          if (snapshots.empty) {
            console.log('snapshots.empty');
            return 0;
          }

          return snapshots.docs.length;
        });

      console.log('trigger_gmb:', searches);
      return searches;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  parseBranding(params: any): IBranding {
    try {
      const branding: IBranding = {
        css: params.css ? params.brandCss : '',
        icon: params.brandIcon ? params.brandIcon : '',
        js: params.js ? params.brandJs : '',
        logo: params.brandLogo ? params.brandLogo : '',
        name: params.brandName ? params.brandName : '',
        spinner: params.brandSpinner ? params.brandSpinner : '',
        url: params.brandUrl ? params.brandUrl : '',
      };
      return branding;
    } catch (error) {
      console.error(error);
      const branding: IBranding = {
        css: '',
        icon: '',
        js: '',
        logo: '',
        name: 'Lead Carrot',
        spinner: '',
        url: 'https://leadcarrot.io',
      };
      return branding;
    }
  }

  async parseRouteOptions(params: ParamMap): Promise<IWlOptions> {
    try {
      let apikey = params.get('apikey');
      let locationId = params.get('locationId');
      let token = params.get('token');

      if (typeof apikey === 'undefined' || apikey === null) {
        const tempValue = await localforage.getItem<string>('finderApikey');
        console.warn('GET finderApikey:', tempValue);
        if (tempValue !== null) {
          apikey = tempValue;
        }
      }

      if (typeof locationId === 'undefined' || locationId === null) {
        const tempValue = await localforage.getItem<string>('finderLocationId');
        console.warn('GET finderLocationId:', tempValue);
        if (tempValue !== null) {
          this.wlOptions.locationId = tempValue;
        }
      }

      if (typeof token === 'undefined') {
        const tempValue = await localforage.getItem<string>('finderToken');
        console.warn('GET finderToken:', tempValue);
        if (tempValue !== null) {
          token === tempValue;
        }
      }

      const options: IWlOptions = {
        apikey: apikey,
        integrationId: token,
        locationId: locationId,
        token: token,
      };
      return Promise.resolve(options);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private _setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 10;
      });
    }
  }

  _getAddressParts(address: any[]) {
    const parts = {
      locality: '',
      township: '',
      county: '',
      state: '',
      country: '',
    };
    let location = '';
    let isBadLocation = true;

    if (
      typeof address === 'undefined' ||
      address === null ||
      address.length < 1
    ) {
      return {
        address: parts,
        location: location,
        isBadLocation: isBadLocation,
      };
    }

    let neighbourhood = "";
    let subLocality = "";

    for (const line of address) {
      if (line.types[0] === 'neighborhood') {
        neighbourhood = line.short_name;
      } else if (line.types[0] === 'sublocality_level_1') {
        subLocality = line.short_name;
      } else if (line.types[0] === 'locality') {
        parts.locality = line.short_name;
      } else if (line.types[0] === 'administrative_area_level_3') {
        parts.township = line.short_name;
      } else if (line.types[0] === 'administrative_area_level_2') {
        parts.county = line.short_name;
      } else if (line.types[0] === 'administrative_area_level_1') {
        parts.state = line.short_name;
      } else if (line.types[0] === 'country') {
        parts.country = line.short_name;
      }
    }

    if (parts.locality === "") {
      if (neighbourhood !== "" && subLocality !== "") {
        parts.locality = neighbourhood + " - " + subLocality;
      } else if (neighbourhood !== "" && subLocality === "") {
        parts.locality = neighbourhood;
      } else if (neighbourhood === "" && subLocality !== "") {
        parts.locality = subLocality;
      }
    }

    if (parts.country !== '' && parts.state !== '' && parts.locality !== '') {
      isBadLocation = false;
      location = parts.locality + ', ' + parts.state + ', ' + parts.country;
    } else if (
      parts.country !== '' &&
      parts.state === '' &&
      parts.locality !== ''
    ) {
      isBadLocation = false;
      location = parts.locality + ', ' + parts.country;
    }

    return {
      address: parts,
      location: location,
      isBadLocation: isBadLocation,
    };
  }

  onAutocompleteSelected(result: PlaceResult) {
    // console.log('onAutocompleteSelected: ', result.address_components);
    this.resetGeoCoords();
    const radius = this.validateRadius(this.searchRadiusMiles * 1.609344);
    this.searchRadius = radius;
    this.updateRadius(radius);

    
    console.log("result.address_components:", result.address_components);

    const parts = this._getAddressParts(result.address_components);

    console.log("parts:", parts);


    this.zoom = 10;
    this.googleMapType = '';

    this.geoCoord.place_id = result.place_id;
    this.latitude = result.geometry.location.lat();
    this.longitude = result.geometry.location.lng();

    this.area.center.latitude = this.latitude;
    this.area.center.longitude = this.longitude;
    // this.area.radius = this.geoCoord.radius;

    this.geoCoord.lat = this.latitude;
    this.geoCoord.long = this.longitude;
    this.geoCoord.locality = parts.address.locality;
    this.geoCoord.state = parts.address.state;
    this.geoCoord.country = parts.address.country;

    this.leadLocation = parts.location;
    this.badLocation = parts.isBadLocation;
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);

    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.zoom = 9;

    this.geoCoord.lat = this.latitude;
    this.geoCoord.long = this.longitude;

    this.area.center.latitude = location.latitude;
    this.area.center.longitude = location.longitude;

    console.warn('this.leadLocation:', this.leadLocation);
    const searchItem = this.searchList.find(
      (item: any) => item.location === this.leadLocation
    );

    if (this.searchList.length < 1 || typeof searchItem === 'undefined') {
      const data: any = {
        category: this.leadCategory,
        geoCoord: Object.assign({}, this.geoCoord),
        location: this.leadLocation,
        radius: this.searchRadius, // this.area.radius = this.searchRadius * 1000
      };
      this.searchList.push(data);

      if (this.searchList.length >= MAX_BULK_SEARCH) {
        const message = `You've reached the maximum of ${MAX_BULK_SEARCH} locations to search at one time.`;
        this.snackBar.open(message, 'close', { duration: 5000 });
      }
      console.log('searchList onLocationSelected():', this.searchList);
      this.createMarker(data.location, data.geoCoord.lat, data.geoCoord.long);
    }

    if (this.bulkSearch) {
      this.bulkList.push({ name: this.leadLocation });
      this.leadLocation = '';
      (<HTMLInputElement>document.querySelector('#search')).value = '';
    }
  }

  addBulk(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.bulkList.push({ name: value });

      console.log('ADD to BULK');

      if (
        this.searchList.length < 1 ||
        typeof this.searchList.find((item: any) => item.location === value) ===
        'undefined'
      ) {
        const data: any = {
          category: this.leadCategory,
          geoCoord: Object.assign({}, this.geoCoord),
          location: value,
          radius: this.searchRadius, // this.area.radius = this.searchRadius * 1000
        };
        this.searchList.push(data);
        console.log('searchList - addBulk():', this.searchList);

        this.createMarker(data.location, data.geoCoord.lat, data.geoCoord.long);
      }
    }
  }

  removeBulk(searchLocation: IBulkSearch): void {
    console.log('remove bulk:', this.bulkList);
    this.bulkList = this.bulkList.filter(
      (item: IBulkSearch) => item.name !== searchLocation.name
    );
    console.log('this.bulkList:', this.bulkList);

    this.searchList = this.searchList.filter(
      (item: any) => item.location !== searchLocation.name
    );
    console.log('this.searchList:', this.searchList);

    this.points = this.points.filter(
      (item: any) => item.content !== searchLocation.name
    );
  }

  parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  private _loadCustomCss(integrationId: string) {
    const cssId = 'custom-css';
    const elCustomCss = document.getElementById(cssId);
    if (elCustomCss === null) {
      if (integrationId !== '') {
        // load css file url from database.
        this.getCssUrl(this.userId).subscribe((value) => {
          if (value) {
            try {
              const head = document.getElementsByTagName('head')[0];
              const link = document.createElement('link');
              link.id = cssId;
              link.rel = 'stylesheet';
              link.type = 'text/css';
              link.href = value;
              link.media = 'all';
              head.appendChild(link);
            } catch (err) {
              console.error(err);
            }
          }
        });
      }
    }
  }

  private _loadCategories() {
    /*
    this.categories$ = concat(
      of([]), // default items
      this.categoriesInput$.pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.categoriesLoading = true),
        switchMap((category: string) => {

          return this.getCategories(category).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.categoriesLoading = false)
          )
        })
      )
    );
    */
    // const a = of([]);

    this.categoriesInput$.subscribe((typeAhead: string) => {
      if (isUndefinedOrNullOrEmpty(typeAhead)) {
        this.categoriesLoading = false;
        this.categories$.next([]);
        return;
      }

      this.categoriesLoading = true;

      // Not enough characters to auto-complete
      if (typeAhead.length < this.minLengthTerm) {
        this.categories$.next([]);
      } else {
        const values = this.getCategories(typeAhead);
        this.categories$.next(values);
        this.categoriesLoading = false;
      }
      this.categoriesLoading = false;

      return;
    });
  }

  private _filtersEnabled(filters: any = null) {
    try {
      if (
        !filters.email.enabled &&
        !filters.url.enabled &&
        !filters.phone.enabled &&
        !filters.postal.enabled &&
        !filters.ssl.enabled &&
        !filters.fbPixel.enabled
      ) {
        // console.log('SHOW IT - ALL FILTERS are disabled');
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.warn('Unable to read search filters');
      throw error;
    }
  }

  private _showLead(filters: IFilters, lead: any): boolean {
    if (isUndefinedOrNullOrEmpty(lead)) {
      // console.log('HIDE IT - LEAD is Undefined OR Null OR Empty');
      return false;
    }
    if (isUndefinedOrNullOrEmpty(filters)) {
      // console.log('HIDE IT - FILTER is Undefined OR Null OR Empty');
      return false;
    }
    if (
      isUndefinedOrNullOrEmpty(filters.email) ||
      isUndefinedOrNullOrEmpty(filters.url) ||
      isUndefinedOrNullOrEmpty(filters.phone) ||
      isUndefinedOrNullOrEmpty(filters.postal) ||
      isUndefinedOrNullOrEmpty(filters.ssl) ||
      isUndefinedOrNullOrEmpty(filters.fbPixel)
    ) {
      // console.log('HIDE IT - FILTER option is Undefined OR Null OR Empty');
      // console.log('FILTERS:', filters);
      return false;
    }
    if (
      !filters.email.enabled &&
      !filters.url.enabled &&
      !filters.phone.enabled &&
      !filters.postal.enabled &&
      !filters.ssl.enabled &&
      !filters.fbPixel.enabled
    ) {
      // console.log('SHOW IT - ALL FILTERS are disabled');
      return true;
    }

    if (filters.email.enabled) {
      if (filters.email.exists && isUndefinedOrNullOrEmpty(lead.email)) {
        // console.log('HIDE IT - missing email');
        return false;
      } else if (
        !filters.email.exists &&
        !isUndefinedOrNullOrEmpty(lead.email)
      ) {
        // console.log('HIDE IT - has email');
        return false;
      }
    }
    if (filters.url.enabled) {
      if (filters.url.exists && isUndefinedOrNullOrEmpty(lead.website)) {
        // console.log('HIDE IT - missing website');
        return false;
      } else if (
        !filters.url.exists &&
        !isUndefinedOrNullOrEmpty(lead.website)
      ) {
        // console.log('HIDE IT - has website');
        return false;
      }
    }
    if (filters.phone.enabled) {
      if (
        filters.phone.exists &&
        isUndefinedOrNullOrEmpty(lead.telephone_e164)
      ) {
        // console.log('HIDE IT - missing telephone_e164');
        return false;
      } else if (
        !filters.phone.exists &&
        !isUndefinedOrNullOrEmpty(lead.telephone_e164)
      ) {
        // console.log('HIDE IT - has telephone_e164');
        return false;
      }
    }
    if (filters.postal.enabled) {
      if (
        filters.postal.exists &&
        isUndefinedOrNullOrEmpty(lead.address.postalCode)
      ) {
        // console.log('HIDE IT - missing postalCode');
        return false;
      } else if (
        !filters.postal.exists &&
        !isUndefinedOrNullOrEmpty(lead.address.postalCode)
      ) {
        // console.log('HIDE IT - has postalCode');
        return false;
      }
    }
    if (filters.ssl.enabled) {
      if (!isUndefinedOrNullOrEmpty(lead.site_data)) {
        if (
          filters.ssl.exists &&
          (isUndefinedOrNull(lead.site_data.has_ssl) ||
            lead.site_data.has_ssl === false)
        ) {
          // console.log('HIDE IT - missing ssl');
          return false;
        } else if (
          !filters.ssl.exists &&
          !isUndefinedOrNull(lead.site_data.has_ssl) &&
          lead.site_data.has_ssl === true
        ) {
          // console.log('HIDE IT - has ssl');
          return false;
        }
      } else {
        return false;
      }
    }
    if (filters.fbPixel.enabled) {
      if (!isUndefinedOrNullOrEmpty(lead.site_data)) {
        if (
          filters.fbPixel.exists &&
          (isUndefinedOrNull(lead.site_data.has_fb_pixel) ||
            lead.site_data.has_fb_pixel === false)
        ) {
          // console.log('HIDE IT - missing fb_pixel');
          return false;
        } else if (
          !filters.fbPixel.exists &&
          !isUndefinedOrNull(lead.site_data.has_fb_pixel) &&
          lead.site_data.has_fb_pixel === true
        ) {
          // console.log('HIDE IT - has fb_pixel');
          return false;
        }
      } else {
        return false;
      }
    }

    // console.log('SHOW IT');
    return true;
  }

  private _getDefaultFilters() {
    return {
      email: {
        enabled: false,
        exists: true,
      },
      phone: {
        enabled: false,
        exists: true,
      },
      url: {
        enabled: false,
        exists: true,
      },
      postal: {
        enabled: false,
        exists: true,
      },
      ssl: {
        enabled: false,
        exists: true,
      },
      fbPixel: {
        enabled: false,
        exists: true,
      },
    };
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  getCategories(term: string = null): any[] {
    try {
      return this._filter(term).map((item) => item.name);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.glocal.filter((item: any) => item.lcase.includes(filterValue));
  }

  trackByFn(item: any) {
    return item.id;
  }

  selectEvent(item: any) {
    // do something with selected item
    // console.log('selectEvent()');
    this.leadCategory = item.name;
    // this.isLoadingResult = false;
  }

  onChangeSearch(val: string) {
    // console.log('onChangeSearch()');
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.leadCategory = val;
  }

  onFocused(event: any) {
    // do something when input is focused
    // console.log('onFocused()');
    // this.isLoadingResult = true;
  }

  // displayFn(category: Category) {
  displayFn(category: any) {
    if (category) {
      return category.name;
    }
  }

  async addLocations(
    uuid: string = '',
    doSearch: boolean = false,
    primary: boolean = true,
    category: string
  ) {
    try {
      if (!uuid) {
        uuid = this.uuid;
      }

      // TODO: update the search limit check
      const promises: any[] = [];
      promises.push(
        this.afs
          .doc(`${COL_PAUSED}/${this.user.id}`)
          .ref.get()
          .then(async (snapshot) => {
            if (snapshot.exists) {
              throw Error(
                'Access to the Business Finder has been paused for your account. Please contact support.'
              );
            }
          })
      );
      promises.push(
        this.afs
          .doc(`${COL_PAUSED}/${this.user.id}_${this.wlOptions.locationId}`)
          .ref.get()
          .then(async (snapshot) => {
            if (snapshot.exists) {
              throw Error(
                'Access to the Business Finder has been paused for your account. Please contact support.'
              );
            }
          })
      );
      await Promise.all(promises);

      for (const location of this.extraLocations) {
        const locationCoords = {
          lat: location.lat,
          long: location.long,
          country: this.geoCoord.country,
          state: this.geoCoord.state,
          locality: location.name,
        };

        if (location.name) {
          // console.log('**** locationCoords:', locationCoords);
          const locationName =
            location.name +
            ', ' +
            locationCoords.state +
            ', ' +
            locationCoords.country;

          this.leadService.triggerSearch(
            category,
            locationName,
            locationCoords,
            uuid,
            this.wlOptions.locationId,
            doSearch,
            false
          );
        }
      }

      /*
        // console.log(location);
        // console.log('LeadService -> getLocations()');

        this.getLocSubs = this.leadService
          .queryHashes2(
            this.leadCategory,
            location.lat,
            location.long,
            this.searchRadius
          )
          .subscribe(async (leads) => {
            if (leads) {
              for (let index = 0; index < leads.length; index++) {
                await this.leadService.validateLeadSsl(leads[index]);
              }

              for (let index = 0; index < leads.length; index++) {
                await this.leadService.validateLeadPixel(leads[index]);
              }

              for (let index = 0; index < leads.length; index++) {
                await this.leadService.validateLead(leads[index]);
              }

              if (this.lastCount !== leads.length || this.firstTime) {
                this.lastCount = leads.length;
                this.firstTime = false;

                const data = leads.filter((item) => {
                  if (
                    typeof item !== 'undefined' &&
                    typeof item['id'] !== 'undefined'
                  ) {
                    // Only display the filtered results
                    if (item.categories[this.leadCategory] === true) {
                      // this.showSpinner = false;
                      if (this.filters.noEmail && item.email === '') {
                        //
                      } else if (this.filters.noUrl && item.website === '') {
                        //
                      } else if (this.filters.noPhone && item.telephone === '') {
                        //
                      } else if (this.filters.noPostal && item.address.postalCode === '') {
                        //
                      } else {
                        return true;
                      }
                    }
                  }
                });

                let index = 0;
                let isFound = false;
                for (const city of this.cities) {
                  if (typeof data[0] !== 'undefined') {
                    if (city === data[0].address.addressLocality) {
                      isFound = true;
                      break;
                    }
                  }
                  index++;
                }

                if (isFound) {
                  this.rows[index] = [...data];
                  this.temp[index] = [...data];

                  // console.log('found leads [' + index + ']:', this.rows[index].length);
                  // console.log(data);

                  if (this.rows[index].length > 0) {
                    this.showSpinner = false;
                  }
                }
              }
            } else {
              this.showSpinner = true;
            }
          });

        const geoCoord = cloneDeep(this.geoCoord);
        geoCoord.locality = location.name;
        const searchLocation = location.name;
        // console.warn('searchLocation:', searchLocation);

        this.createMarker(
          location.name,
          parseFloat(location.lat),
          parseFloat(location.long)
        );

        geoCoord.state = '';
        geoCoord.country = '';
        this.leadService.triggerSearch(
          this.leadCategory,
          searchLocation,
          geoCoord,
          false
        );
        // await this.geocodeLatLng(this.geoCoord.lat, this.geoCoord.long);
      }
      */

      return Promise.resolve();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  resetGeoCoords() {
    // this.geoCoord.lat = 0;
    // this.geoCoord.long = 0;
    this.geoCoord.lat = 25.9875;
    this.geoCoord.long = -97.186389;
    this.googleMapType = '';

    this.geoCoord.locality = '';
    this.geoCoord.state = '';
    this.geoCoord.country = '';
    this.updateRadius(this.searchRadius);
  }

  get theCategories() {
    return this.form.get('theCategories');
  }

  buildCategories() {
    const arr = this.allCategoriesList.map((category) => {
      return this.formBuilder.control(category.selected);
    });
    return this.formBuilder.array(arr);
  }

  async deleteLeads(): Promise<any> {
    this.leadService.resetFoundLeads();
    const deleteResult = await this.deleteRows(this.allSelected);
  }

  async deleteRows(rows: any[] = []) {
    try {
      if (isUndefinedOrNullOrEmpty(rows)) {
        throw Error('no rows to delete.');
      }

      this.waitingMessage = 'Deleting Leads';
      this.showSpinner = true;
      this.stillSearching = true;

      // console.log(this.waitingMessage);

      let totalRows = 0;
      for (const items of rows) {
        for (const row of items) {
          totalRows = totalRows + 1;
        }
      }

      let rowCount = 0;
      for (const items of rows) {
        for (const row of items) {
          rowCount = rowCount + 1;

          console.log(
            'DELETE: [ ' +
            rowCount +
            ' ] of ' +
            totalRows +
            ' | ' +
            ((rowCount / totalRows) * 100).toFixed(2) +
            '%'
          );

          const docId = row.id;

          // console.log('ID:', docId);

          await firebase.firestore().collection('leads_v2').doc(docId).delete();

          console.log('deleted row:', docId);
        }
      }

      this.stillSearching = false;
      this.showSpinner = false;
      this.waitingMessage = SEARCH_LIVE_DATA;

      return Promise.resolve();
    } catch (error) {
      console.error(error);
      this.stillSearching = false;
      this.showSpinner = false;
      this.waitingMessage = SEARCH_LIVE_DATA;
    }
  }

  getTableRowsCount(city: string, index: number): string {
    if (index > this.rows.length) {
      return city + ' - ' + '0';
    } else {
      return (
        city +
        ' - (' +
        this.filtered[index].length.toString() +
        ' of ' +
        this.rows[index].length.toString() +
        ')'
      );
    }
  }

  async resetSearch(clearTemp: boolean = true): Promise<any> {
    this.leadService.resetFoundLeads();
    let docId = this.leadCategory.toLowerCase() + '_';
    if (this.leadLocation.toLowerCase() !== '') {
      docId = docId + this.leadLocation.toLowerCase();
    } else {
      // Get id from last location added.
      docId = docId + this.bulkList[0].name.toLowerCase();
    }

    try {
      await firebase
        .firestore()
        .collection('scaleserp_history')
        .doc(docId)
        .delete();
      await firebase
        .firestore()
        .collection('zenserp_history')
        .doc(docId)
        .delete();
      await firebase.firestore().collection('yelp_history').doc(docId).delete();
      const message = 'Search category [' + docId + '] is reset.';
      console.log('reset message:', message);
      // this.snackBar.open(message, 'close', { duration: 5000 });
    } catch (error) {
      console.error(error);
    }

    const country = this.geoCoord.country;
    const state = this.geoCoord.state;

    // Clear all the cities in radius.
    for (const location of this.extraLocations) {
      let docId = this.leadCategory.toLowerCase() + '_';

      if (location.name.toLowerCase() !== '') {
        docId = docId + `${location.name.toLowerCase()}, ${state.toLowerCase()}, ${country.toLowerCase()}`;
        // } else {
        //   // Get id from last location added.
        //   docId = docId + this.bulkList[0].name;
      }

      try {
        await firebase
          .firestore()
          .collection('scaleserp_history')
          .doc(docId)
          .delete();
        await firebase
          .firestore()
          .collection('zenserp_history')
          .doc(docId)
          .delete();
        await firebase
          .firestore()
          .collection('yelp_history')
          .doc(docId)
          .delete();
        const message = 'Search category [' + docId + '] is reset.';
        console.log('reset message:', message);
        // this.snackBar.open(message, 'close', { duration: 5000 });
      } catch (error) {
        console.error(error);
      }
    }

    if (clearTemp) {
      const categoryLcase = this.leadCategory.toLowerCase();

      for (const bulk of this.bulkList) {
        const parts = this.bulkList[0].name.split(',');
        const location = parts[0];

        const queryRef = firebase
        .firestore()
        .collection('scaleserp_leads')
        .where('address.addressLocality', '==', location)
        .where('categoryList', "array-contains-any", [categoryLcase]);

        const querySnapshot = await queryRef.get();
        const docs = querySnapshot.docs;

        console.log('categoryLcase:', categoryLcase);
        console.log('locationLcase:', location);
        console.log('docs.length:', docs.length);

        const promises: any = [];
        for (const doc of docs) {
          promises.push(doc.ref.delete());
        }
        await Promise.all(promises).catch(error => {console.error(error);});
        console.log('done delete #1');
      }

      // Clear all the cities in radius.
      for (const extraLocation of this.extraLocations) {
        const location = extraLocation.name;

        const queryRef = firebase
        .firestore()
        .collection('scaleserp_leads')
        .where('address.addressLocality', '==', location)
        .where('categoryList', "array-contains-any", [categoryLcase]);

        const querySnapshot = await queryRef.get();
        const docs = querySnapshot.docs;

        console.log('categoryLcase:', categoryLcase);
        console.log('locationLcase:', location);
        console.log('docs.length:', docs.length);

        const promises: any = [];
        for (const doc of docs) {
          promises.push(doc.ref.delete());
        }
        await Promise.all(promises).catch(error => {console.error(error);});
        console.log('done delete #2');
      }
    }
    return Promise.resolve(null);
  }

  /*
  submit(value: any) {
    const f = Object.assign({}, value, {
      categories: value.theCategories.map((s, i) => {
        return {
          id: this.allCategoriesList[i].id,
          selected: s,
        };
      }),
    });

    this.temp[0] = this.rows[0];

    let resetRows = false;

    // tslint:disable-next-line: prefer-for-of
    for (let cat = 0; cat < f.categories.length; cat++) {
      const item = f.categories[cat];
      if (item.selected) {
        for (let index = this.temp[0].length - 1; index >= 0; index--) {
          if (typeof this.temp[0][index] !== 'undefined') {
            const name = this.allCategoriesLookup[item.id];

            if (this.temp[0][index].categories[name] === true) {
              console.log('DELETE THIS ROW === ', this.temp[0][index]);
              delete this.temp[0][index];
              resetRows = true;
            }
          }
        }
      }
    }

    if (resetRows) {
      const newRows = [];
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.temp[0].length; index++) {
        if (typeof this.temp[0][index] !== 'undefined') {
          newRows.push(this.temp[0][index]);
        }
      }
      this.allSelected[0] = [];
      this.rows[0] = newRows;
      this.temp[0] = [];
    }

    // RESET the list of categories after removing some categories
    console.log('RESET category filter');
    // console.log('rows');
    console.log(this.rows[0]);

    this.allCategoriesLookup = [];
    for (const row of this.rows[0]) {
      if (row.categories[this.leadCategory] === true) {
        // Build the Exclude Filter list
        this.createFilterCategoryList(row);
      }
    }

    this.temp[0] = [...this.rows[0]];
  }
  */

  public onTimeoutNextResults(lastCount: number, searchType: string = null) {
    let nextTimeout = 1000 * 120;
    if (searchType === 'history') {
      this.waitingMessage = LOADING_PREVIOUS_SEARCH;
      nextTimeout = 1000 * 7;
    } else {
      this.waitingMessage = SEARCH_LIVE_DATA;
      nextTimeout = 1000 * 120;
    }

    this.showSpinner = true;
    this.stillSearching = true;

    clearTimeout(this.timeoutNextResults);

    this.timeoutNextResults = setTimeout(
      () => {
        if (lastCount === this.lastCount) {
          this.showSpinner = false;
          this.stillSearching = false;

          const MESSAGE = 'Finshed finding leads';
          this.snackBar.open(MESSAGE, 'close', { duration: 6000 });
        } else {
          clearTimeout(this.timeout);
          this.onTimeoutNextResults(this.lastCount, searchType);
        }
      },
      nextTimeout,
      lastCount
    );
  }

  public onTimeout() {
    clearTimeout(this.timeout);

    /*
    this.timeout = setTimeout(() => {
      if (this.didSearch && !this.hasResults) {
        this.showSpinner = false;
        this.showNoResultsMessage = true;
        const data = {
          userId: this.user.id,
          userName: this.user.name,
          userEmail: this.user.email,
          category: this.leadCategory,
          geoCoord: this.geoCoord,
        };

        // createTicket(data, this.lastTicket);
      }
    }, 1000 * 60 * 1);
    */
  }

  public toggleCatgorySelect(event: any) {
    this.leadCategory = '';
    this.onlyCategories = !this.onlyCategories;
  }

  public toggleBulkSearch(event: any) {
    // this.location = '';
    this.bulkSearch = !this.bulkSearch;
  }

  public toggleMap() {
    this.isMapVisible = !this.isMapVisible;
  }

  public toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    // console.log(this.table);
    this.table.rowDetail.toggleExpandRow(row);
  }

  public onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  /*
  onAutocompleteSelected(result: PlaceResult) {

    console.log('@@@@@@ onAutocompleteSelected: ', result);

    const radius = this.validateRadius(this.area.radius);
    const parsedAddress = this.parseAddressLocation(result);

    this.resetGeoCoords();
    this.updateRadius(radius);

    this.geoCoord.lat = result.geometry.location.lat();
    this.geoCoord.long = result.geometry.location.lng();
    this.geoCoord.locality = parsedAddress.locality;
    this.geoCoord.state = parsedAddress.state;
    this.geoCoord.country = parsedAddress.country;

    this.leadLocation = parsedAddress.location;
    this.badLocation = parsedAddress.isBadLocation;

    this.area.center.latitude = result.geometry.location.lat();
    this.area.center.longitude = result.geometry.location.lng();
  }

  onLocationSelected(location: Location) {

    console.log('@@@ onLocationSelected: ', location);

    const radius = this.validateRadius(this.area.radius);

    this.resetGeoCoords();
    this.updateRadius(radius);
    this.geoCoord.lat = location.latitude;
    this.geoCoord.long = location.longitude;

    // ToDo: AF - Check on how we use these values and document it

    this.area.center.latitude = location.latitude;
    this.area.center.longitude = location.longitude;
    this.area.radius = radius;
    console.log('RADIUS: ', this.area.radius);
  }
  */

  public onChange(address: Address) {
    console.warn('*** onChange(address) *** | address:', address);

    this.searchRadius = this.searchRadiusMiles * 1.60934;
    const radius = this.validateRadius(this.searchRadius);
    this.searchRadius = radius;
    const parsedAddress = this._getAddressParts(address);

    this.resetGeoCoords();
    this.updateRadius(radius);
    this.geoCoord.lat = address.geometry.location.lat();
    this.geoCoord.long = address.geometry.location.lng();
    this.geoCoord.locality = parsedAddress.address.locality;
    this.geoCoord.state = parsedAddress.address.state;
    this.geoCoord.country = parsedAddress.address.country;
    this.geoCoord.place_id = address.place_id;

    // ToDo: AF - Check on how we use these values and document it
    this.leadLocation = parsedAddress.location;
    this.badLocation = parsedAddress.isBadLocation;

    this.area.center.latitude = address.geometry.location.lat();
    this.area.center.longitude = address.geometry.location.lng();
    // this.area.radius = this.geoCoord.radius;
    // console.log('RADIUS: ', this.area.radius);
  }

  validateRadius(radius: number): number {
    let newRadius = radius;
    if (typeof radius === 'undefined' || radius === null) {
      newRadius = DEFAULT_SEARCH_RADIUS;
    }
    if (radius < MIN_RADIUS || radius > MAX_RADIUS) {
      newRadius = DEFAULT_SEARCH_RADIUS;
    }

    return newRadius;
  }

  parseAddressLocation(address: Address) {
    // console.log('area: ', this.area);
    let locality = '';
    let state = '';
    let country = '';
    let location = '';
    let isBadLocation = true;

    // tslint:disable-next-line: prefer-for-of
    for (
      let indexItem = 0;
      indexItem < address.address_components.length;
      indexItem++
    ) {
      const component = address.address_components[indexItem];
      // console.log('component:', component);

      // tslint:disable-next-line: prefer-for-of
      for (let indexType = 0; indexType < component.types.length; indexType++) {
        const cType = component.types[indexType];
        // console.log('cType:', cType);

        if (cType === 'locality') {
          locality = component.short_name;
          break;
        } else if (cType === 'administrative_area_level_1') {
          state = component.short_name;
          break;
        } else if (cType === 'country') {
          country = component.short_name;
          break;
        }
      }
    }

    isBadLocation = true;
    if (country !== '' && state !== '' && locality !== '') {
      isBadLocation = false;
      location = locality + ', ' + state + ', ' + country;
    } else if (country !== '' && state === '' && locality !== '') {
      isBadLocation = false;
      location = locality + ', ' + country;
    }

    return {
      locality,
      state,
      country,
      location,
      isBadLocation,
    };
  }

  getCssUrl(whitelabelId: string) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
    const url = this.apiLeadCarrot + 'apiGetCssUrl/' + whitelabelId;

    console.log('getCssUrl() | url:', url);

    try {
      return this.http
        .get<IApiResponse>(url, { headers })
        .pipe(map((res: any) => res));
    } catch (error) {
      console.error(error);
      throwError(error);
    }
  }

  getFilters(locationId: string) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    const body = {
      token: 'leadcarrot',
      locationId,
    };

    // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
    const url = this.apiLeadCarrot + 'userGetFilterSettings';

    // console.log('getFilters() | url:', url);

    try {
      return this.http
        .post<IApiResponse>(url, body, { headers })
        .pipe(map((res: any) => res));
    } catch (error) {
      console.error(error);
      throwError(error);
    }
  }

  setFilters(locationId: string, searchFilters: any) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    const body = {
      token: 'leadcarrot',
      locationId,
      searchFilters,
    };

    // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
    const url = environment.cloudUrl + '/' + 'userSetFilterSettings';
    // console.log('url:', url);
    return this.http.post<IApiResponse>(url, body, { headers }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error))
    );
  }

  updateRadius(radius: number) {
    this.geoCoord.radius = (radius * 1000);
    this.area.radius = (radius * 1000);
  }

  onRadiusChanged(value: MatSliderChange) {
    this.searchRadius = value.value * 1.609344;
    this.updateRadius(this.searchRadius);
  }

  onLogout() {
    this.authService.signOut();
  }

  public isAdmin() {
    // return true;
    /*
    if (this.wlOptions.locationId === 'CytBb6uq53XBSJtTSmpD') {
      return true;
    } else {
      return false;
    }
    */

    if (
      this.email.indexOf('@leadcarrot.io') !== -1 ||
      this.email.indexOf('@leadtracker.io') !== -1 ||
      this.email.indexOf('@fortinmedia.ca') !== -1 ||
      // this.email.indexOf('@socialmainst.com') !== -1 ||
      this.email === 'andre.v.fortin@gmail.com'
    ) {
      return true;
    } else {
      return false;
    }
  }

  public findLeads(searchType: string = null) {
    this.leadCategory = this.leadCategory.trim();

    // console.log('findLeads()');
    this.showNoResultsMessage = false;

    // this.addedExtraLocations = false;

    this.didSearch = false;
    this.hasResults = false;
    // console.log('hasResults:', this.hasResults);

    if (this.searchList.length < 1) {
      if (
        typeof this.leadCategory === 'undefined' ||
        this.leadCategory === ''
      ) {
        // Error you need to enter a name for the niche before we can add it.
        this.leadLocation = '';
        const MESSAGE = 'You need to choose a business location';
        const snackBarRef = this.snackBar.open(MESSAGE, 'close', {
          duration: 4000,
        });
        return;
      } else {
        const data: any = {
          category: this.leadCategory,
          geoCoord: this.geoCoord,
          location: this.leadLocation,
          radius: this.searchRadius,
        };
        this.searchList.push(data);
      }
    }

    const uuid = uuidv4();
    this.uuid = uuid;

    const searchOptions: any = {
      uuid: uuid,
      userId: this.userId,
      clientId: this.wlOptions.locationId,
      searchType: searchType,
      searchList: [...this.searchList],
    };

    console.log('searchOptions - findLeads():', searchOptions);

    this.getData(searchOptions);
    this.searchCount = this.searchCount + 1;

    console.log('this.searchCount:', this.searchCount);
    console.log(
      'this.searcLimitsAgency.monthly:',
      this.searchLimitsAgency.monthly
    );

    if (this.searchCount >= this.searchLimitsAgency.monthly) {
      this.noSearches = true;
    } else {
      this.noSearches = false;
    }
    console.log('this.noSearches:', this.noSearches);
  }

  getNearbyCities(
    geoCoord: any,
    uuid: string,
    category: string,
    doSearch: boolean = true
  ) {
    if (!uuid) {
      return;
    }

    const primary = false;

    this.extraLocations = [];

    const coords = { lat: geoCoord.lat, long: geoCoord.long };
    const nearby$ = this.nearbyLocationsService.getNearbyLocations(coords);
    this.allSubscriptions.add(
      nearby$.subscribe((response) => {
        xml2js.parseString(response.toString(), (err, result) => {
          for (const node of result.osm.node) {
            let placeName = '';
            for (const tag of node.tag) {
              if (
                tag.$.k === 'place' &&
                (tag.$.v === 'town' || tag.$.v === 'city')
              ) {
                const location = {
                  name: placeName,
                  lat: node.$.lat,
                  long: node.$.lon,
                };
                this.extraLocations.push(location);
                break;
              }
              if (tag.$.k === 'name') {
                placeName = tag.$.v;
              }
            }
          }
          this.addLocations(uuid, doSearch, primary, category);
          return result;
        });
      })
    );
  }

  public selectAllFilters() {
    this.form.controls.theCategories.setValue(
      this.form.controls.theCategories.value.map((value) => true)
    );
  }

  /*
  public filterLeads() {
    // console.log('filterLeads()');
    this.submit(this.form.value);
  }
  */

  public setExportFileName() {
    this.dataExport.step++;
  }

  public async openDialog(exportType: string = 'default') {
    if (exportType === 'filterLeads') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '450px';
      dialogConfig.data = {
        id: 1,
        title: 'Filter Categories',
        categories: this.allCategoriesList,
      };

      // const dialogRef = this.dialog.open(FilterDialogComponent, dialogConfig);
      this.buildCategories();
      console.log(this.allCategoriesList);
    } else if (exportType === 'importResults') {
      let data = this.leadLocation.toLocaleLowerCase();
      data = data.replace(/\,/g, '');
      data = data.replace(/\./g, '');
      data = data.replace(/\s/g, '_');

      let recordCount = 0;
      const keys = Object.keys(this.allSelected);
      for (const key of keys) {
        if (typeof this.allSelected[key] !== 'undefined') {
          if (this.allSelected[key].length > 0) {
            recordCount += this.allSelected[key].length;
          }
        }
      }

      const pipelineId = '';
      const stageId = '';

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '450px';
      dialogConfig.data = {
        title: 'Push To CRM',
        recordCount,
        pipelineId,
        stageId,
        pipelines: this.pipelines,
        ownerId: this.user.id,
        ownerName: this.user.name,
        dealCurrencyType: 'USD',
        dealValue: 0.0,
        dealExpectedCloseDate: new Date(),
        dealTitle: '',
        wlOptions: this.wlOptions,
      };

      const dialogRef = this.dialog.open(
        PushLeadsDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe(async (value) => {
        if (typeof value === 'undefined') {
          this.showSpinner = false;
          this.waitingMessage = SEARCH_LIVE_DATA;
          const MESSAGE = 'Push to CRM canceled';
          this.snackBar.open(MESSAGE, 'close', { duration: 4000 });
          return Promise.resolve(false);
        }

        try {
          // console.log('Push to CRM Dialog value:', value);

          // return Promise.resolve(value);

          // Stop the spinner after 60 secs.
          // this.waitingMessage = EXPORTING_RESULTS;
          // this.showSpinner = true;

          console.log('*** [' + recordCount + '] leads selected');

          /*
          const importOptions = {
            userId: this.userId,
            companyId: this.company.id,
            companyName: this.company.name,
            pipelineId: value.pipelineId,
            stageId: value.stageId,
            ownerId: value.ownerId,
            ownerName: value.ownerName,
            dealCurrencyType: value.dealCurrencyType,
            dealValue: value.dealValue === '' ? 0.0 : value.dealValue,
            dealExpectedCloseDate: value.dealExpectedCloseDate,
            dealTitle: value.dealTitle,
          };
          */

          const importOptions = {
            tags: value.tags,
            apikey: this.wlOptions.apikey,
            userId: this.userId,
          };

          // console.log('IMPORT OPTIONS:', importOptions);

          this.waitingMessage = 'Verifying custom fields';
          this.showSpinner = true;
          this.stillSearching = true;
          for (const lead of this.allSelected) {
            // 'lead:', lead);
          }
          const importResult = await this.pushToPipeline(
            this.allSelected,
            importOptions
          );
          // console.log(importResult);

          // this.showSpinner = false;
          // this.waitingMessage = SEARCH_LIVE_DATA;
          // const MESSAGE = 'Push to pipeline complete';
          // this.snackBar.open(MESSAGE, 'close', { duration: 2000 });
          return Promise.resolve(true);
        } catch (error) {
          console.error(error);
          const MESSAGE = error.message;
          this.snackBar.open(MESSAGE, 'close', { duration: 6000 });

          this.showSpinner = false;
          this.waitingMessage = SEARCH_LIVE_DATA;
          return Promise.resolve(false);
        }
      });
    } else {
      let data = this.leadLocation.toLocaleLowerCase();
      data = data.replace(/\,/g, '');
      data = data.replace(/\./g, '');
      data = data.replace(/\s/g, '_');

      const filename = (this.exportFileName =
        this.leadCategory.toLocaleLowerCase() +
        '_in_' +
        data +
        '_' +
        moment().format('YYYY-MM-DD'));

      let recordCount = 0;
      const keys = Object.keys(this.allSelected);
      for (const key of keys) {
        if (typeof this.allSelected[key] !== 'undefined') {
          if (this.allSelected[key].length > 0) {
            recordCount += this.allSelected[key].length;
          }
        }
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '450px';
      dialogConfig.data = {
        id: 1,
        title: 'Export To CSV',
        category: this.leadCategory,
        location: this.leadLocation,
        filename,
        recordCount,
      };

      const dialogRef = this.dialog.open(ExportDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(async (value) => {
        if (typeof value === 'undefined') {
          this.showSpinner = false;
          this.waitingMessage = SEARCH_LIVE_DATA;
          const MESSAGE = 'Export canceled';
          this.snackBar.open(MESSAGE, 'close', { duration: 3000 });
          return Promise.resolve(false);
        }

        // console.log('Export Dialog value:', value);

        if (recordCount > 0) {
          // Step 3: Convert to CSV
          this.dataExport.step = 3;

          this.dataExport.data = await this.createCsvExport(
            value.exportType,
            this.allSelected
          );

          // Step 4: Download file
          this.dataExport.step = 4;

          // Stop the spinner after 60 secs.
          this.waitingMessage = EXPORTING_RESULTS;
          this.showSpinner = true;

          setTimeout(() => {
            this.showSpinner = false;
            if (
              typeof value.filename !== 'undefined' &&
              value.filename !== null &&
              value.filename.trim() !== ''
            ) {
              // Update module level filename for data export
              this.exportFileName = value.filename.trim();
            }
            this.csv2go = downloadFilteredExport(
              this.dataExport.data,
              this.exportFileName
            );

            this.waitingMessage = SEARCH_LIVE_DATA;
            const MESSAGE = 'Export complete';
            this.snackBar.open(MESSAGE, 'close', { duration: 3000 });
          }, 1000);
          return Promise.resolve(true);
        }
      });
    }
  }

  public openCustomDialog(exportType: string = 'googleCustomAudience') {
    let data = this.leadLocation.toLocaleLowerCase();
    data = data.replace(/\,/g, '');
    data = data.replace(/\./g, '');
    data = data.replace(/\s/g, '_');

    const filename = (this.exportFileName =
      'audience_' +
      this.leadCategory.toLocaleLowerCase() +
      '_in_' +
      data +
      '_' +
      moment().format('YYYY-MM-DD'));

    let recordCount = 0;
    const keys = Object.keys(this.allSelected);
    for (const key of keys) {
      if (typeof this.allSelected[key] !== 'undefined') {
        if (this.allSelected[key].length > 0) {
          recordCount += this.allSelected[key].length;
        }
      }
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.data = {
      id: 1,
      title: 'Export As Custom Audience',
      category: this.leadCategory,
      location: this.leadLocation,
      filename,
      recordCount,
    };

    const dialogRef = this.dialog.open(
      ExportCustomDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(async (value) => {
      if (typeof value === 'undefined') {
        this.showSpinner = false;
        this.waitingMessage = SEARCH_LIVE_DATA;
        const MESSAGE = 'Export canceled';
        this.snackBar.open(MESSAGE, 'close', { duration: 3000 });
        return Promise.resolve(false);
      }

      // console.log('Export Dialog value:', value);

      if (recordCount > 0) {
        // Step 3: Convert to CSV
        this.dataExport.step = 3;

        this.dataExport.data = await this.createCsvExport(
          value.exportType,
          this.allSelected
        );

        // Step 4: Download file
        this.dataExport.step = 4;

        // Stop the spinner after 60 secs.
        this.waitingMessage = EXPORTING_RESULTS;
        this.showSpinner = true;

        setTimeout(() => {
          this.showSpinner = false;
          if (
            typeof value.filename !== 'undefined' &&
            value.filename !== null &&
            value.filename.trim() !== ''
          ) {
            // Update module level filename for data export
            this.exportFileName = value.filename.trim();
          }
          // this.csv2go = this.downloadFilteredExport2(this.dataExport.data, this.exportFileName);
          this.downloadAudience(this.dataExport.data, this.exportFileName);

          this.waitingMessage = SEARCH_LIVE_DATA;
          const MESSAGE = 'Export complete';
          this.snackBar.open(MESSAGE, 'close', { duration: 4000 });
        }, 1000);
        return Promise.resolve(true);
      }
    });
  }

  private viewDialog(exportResults: ExportResults) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = exportResults;

    const dialogRef = this.dialog.open(
      ExportResultsDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((val) => {
      console.log('Dialog output: ', val);
    });
  }

  public showFilters(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '350px';
    dialogConfig.data = {
      title: 'Global Filter Options',
      filters: this.filters,
    };

    const dialogRef = this.dialog.open(FilterOptionsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value) {
        console.log('showFilters() | return:', value.filters);

        this.filters = value.filters;
        const MESSAGE = 'Global search result filters updated.';
        this.snackBar.open(MESSAGE, 'close', { duration: 4000 });

        for (
          let searchIndex = 0;
          searchIndex < this.rows.length;
          searchIndex++
        ) {
          this.filtered[searchIndex] = this.rows[searchIndex].filter(
            (lead: any) => {
              // Only display the filtered results
              // if (lead.categories[category] === true) {
              const showIt = this._showLead(this.filters, lead);
              if (showIt) {
                console.log('🥕 showIt:', showIt);
              } else {
                console.log('🥕 hideIt:', showIt);
              }
              return showIt;
            }
          );
          this.temp[searchIndex] = this.filtered[searchIndex];
        }

        return await this.setFilters(
          this.wlOptions.locationId,
          this.filters
        ).subscribe((myFilters) => {
          console.warn(myFilters);
        });
      }
    });
  }

  /*
  exportData = [
    {
      bizName: 'Business Name',
      bizPhone: 'Phone',
      bizEmail: 'Email',
      emails: 'Emails',
      display_address: 'Full Address',

      has_ssl: 'Has SSL',
      is_mobile_responsive: 'Mobile Responsive',
      biz_url: 'Website URL',

      category: 'Buiness Category',
      google_search: 'Google Search URL',

      facebook_star_rating: 'Facebook Star Rating',
      facebook_review_count: 'Facebook Reviews',

      gmb_url: 'Google My Business URL',
      gmb_star_rating: 'GMB Star Rating',
      gmb_review_count: 'GMB Review Count',
      gmb_is_verified: 'GMB Verified',
      gmb_verify_url: 'GMB Verify URL',

      yelp_url: 'Yelp! URL',
      yelp_star_rating: 'Yelp! Star Rating',
      yelp_review_count: 'Yelp! Reviews',
      yelp_has_video: 'Yelp! Uses Video',
      yelp_is_claimed: 'Yelp! Claimed Status',
      yelp_biz_claim_url: 'Yelp! Claim URL',

      facebook_urls: 'Facebook URLS',
      // google_plus_urls: 'Google+ URLS',
      instagram_urls: 'Instagram URLS',
      linkedin_urls: 'LinkedIn URLS',
      pinterest_urls: 'Pinterest URLS',
      twitter_urls: 'Twitter URLS',
      youtube_urls: 'YouTube URLS',

      street: 'Street',
      city: 'City',
      state: 'State',
      postal: 'Postal',
      country: 'Country',
    }
  ];
  */

  fetchData(url: string, body: any, headers: any) {
    return axios
      .post(url, body, headers)
      .then((response: any) => {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch((error: any) => {
        return { success: false, error: error };
      });
  }

  async pushToPipeline(
    selectedCities: any[] = [],
    importOptions: any,
    crmName: string = 'highlevel'
  ): Promise<any> {
    try {
      const API_PUSH_TO_CRM_URL =
        'https://us-central1-aesthetic-root-414319.cloudfunctions.net/apiAddLeadsToGhlV2';
      const LEAD_PUSH_DONE = 'Done pushing contacts to the CRM.';
      const LEAD_PUSH_ERROR = 'Unable to push the contacts to the CRM.';
      const LEAD_PUSH_CONTACTS_APPEARING_SOON =
        'Contacts will start appearing in the CRM in the next few minutes...';
      this.stillSearching = true;
      this.showSpinner = true;
      this.addLeadsStep = 2;
      this.waitingMessage = 'Preparing Leads';

      const bizIds: string[] = [];
      const leads: any[] = [];
      if (
        typeof selectedCities !== 'undefined' &&
        selectedCities !== null &&
        selectedCities.length
      ) {
        for (const cityResults of selectedCities) {
          if (
            typeof cityResults !== 'undefined' &&
            cityResults !== null &&
            cityResults.length
          ) {
            for (const item of cityResults) {
              bizIds.push(item.biz_id);
              leads.push(item);
            }
          }
        }
      }

      const postBodies: any[] = [];
      const batchSize: number = 100;
      const uuid: string = uuidv4();
      let leadIndex = 0;
      let maxIndex = 1;
      if (leads.length <= batchSize) {
        maxIndex = 1;
      } else {
        maxIndex = Math.floor(leads.length / batchSize);
      }
      for (let outerIndex = 0; outerIndex < maxIndex; outerIndex++) {
        let postBody: any = {
          uuid: uuid,
          tags: importOptions.tags,
          leads: [],
          ids: [],
        };

        for (let innerIndex = 0; innerIndex < leads.length; innerIndex++) {
          postBody.ids.push(bizIds[leadIndex]);
          postBody.leads.push(Object.assign({}, leads[leadIndex]));
          leadIndex = leadIndex + 1;
        }

        postBodies.push(Object.assign({}, postBody));
      }

      console.log('postBodies:', postBodies);

      const body = {
        // token: 'leadcarrot',
        // userId: importOptions.userId,
        // companyId: importOptions.companyId,
        // options: importOptions,
        ids: bizIds,
        uuid: uuid,
        tags: importOptions.tags,
        leads: leads,
      };

      const token = localforage.getItem('token');
      const authToken = this.wlOptions.apikey;
      const integrationId = this.wlOptions.integrationId;
      const locationId = this.wlOptions.locationId;
      const axiosConfig: any = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: token,
        },
      };
      const url = `${API_PUSH_TO_CRM_URL}?integrationId=${integrationId}&locationId=${locationId}`;

      const sentMessage = LEAD_PUSH_CONTACTS_APPEARING_SOON;
      this.snackBar.open(sentMessage, 'close', { duration: 5000 });

      this.stillSearching = false;
      this.showSpinner = false;

      // Step 3: All done
      this.addLeadsStep = 4;

      const promises: any[] = [];
      for (const item of postBodies) {
        promises.push(this.fetchData(url, item, axiosConfig));
      }

      return await Promise.all(promises)
        .then((responses) => {
          console.log('push to crm responses:', responses);
          this.stillSearching = false;
          this.showSpinner = false;
          this.waitingMessage = SEARCH_LIVE_DATA;
          let pushMessage = LEAD_PUSH_DONE;
          this.snackBar.open(pushMessage, 'close', { duration: 5000 });

          // Step 3: All done
          this.addLeadsStep = 4;
          return pushMessage;
        })
        .catch((error) => {
          this.stillSearching = false;
          this.showSpinner = false;
          this.waitingMessage = SEARCH_LIVE_DATA;
          const errMessage = LEAD_PUSH_ERROR;
          this.snackBar.open(errMessage, 'close', { duration: 5000 });
          throw error;
        });
    } catch (error) {
      console.error(error);
      throw Error(error.message);
    }
  }

  cityHasResults(index: number): boolean {
    if (typeof index !== 'number' || index < 0) {
      return false;
    }
    if (typeof this.rows[index] !== 'undefined' && this.rows[index].length) {
      return true;
    }
    return false;
  }

  async clearSearcHistory(days: number = 0) {
    try {
      let tempDate = new Date(moment().subtract(2, 'days').toDate());
      // let tempDate = new Date();
      const daysAgo = firebase.firestore.Timestamp.fromDate(tempDate);
      console.log('daysAgo:', daysAgo);

      // Get the primary searches for the last 7 days
      const searchHistory = await firebase
        .firestore()
        .collection(`scaleserp_history`)
        .orderBy('created_at')
        .startAfter(daysAgo)
        .get()
        .then((snapshots: any) => {
          if (snapshots.empty) {
            console.log('--- snapshots is empty');
            return { count: 0, ids: [] };
          }
          const ids: string[] = [];
          for (const doc of snapshots.docs) {
            ids.push(doc.id);
          }
          return { count: snapshots.docs.length, ids: ids };
        });
      this.openSnackBar(
        `${searchHistory.count} results to delete`,
        'close',
        6000
      );

      /*
      if (searchHistory.ids.length) {
        for (const item of searchHistory.ids) {
          firebase
            .firestore()
            .collection(`scaleserp_history`)
            .doc(item)
            .delete();
        }
        this.openSnackBar(`Done clearing history`, 'close', 6000);
      }
      */
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  async getSearchStats(
    userId: string,
    clientId: string
  ): Promise<ISearchStats> {
    const searchStats: ISearchStats = {
      level: 'app',
      userId: userId,
      clientId: clientId,
      available: 0,
      limit: 0,
      used: 0,
      period: 'weekly',
    };

    console.log('🥕 getSearchStats() - searchStats:', searchStats);

    try {
      let tempDate = new Date(moment().subtract(7, 'days').toDate());
      const weekAgo = firebase.firestore.Timestamp.fromDate(tempDate);

      if (clientId === 'standalone') {
        searchStats.used = 0;
      } else {
        // Get the primary searches for the last 7 days
        const weeklySearches = await firebase
          .firestore()
          .collection(COLLECTION_TRIGGER_GMB)
          .orderBy('date_created', 'asc')
          .where('primary', '==', true)
          .where('user_id', '==', userId)
          .where('client_id', '==', clientId)
          .startAfter(weekAgo)
          .limit(this.searchLimitsFinder.weekly)
          .get()
          .then((snapshots: any) => {
            if (snapshots.empty) {
              console.log('--- snapshots is empty');
              return 0;
            }
            return snapshots.docs.length;
          });
        searchStats.used = weeklySearches;

        console.log('*** weekly Searches ***:', weeklySearches);
      }

      console.log(
        '🥕 getSearchStats() - weeklySearches used:',
        searchStats.used
      );

      const limitsLocation: any = await this.afs
        .doc(`search_limits_location/${userId}_${clientId}`)
        .ref.get()
        .then(async (snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();
            if (typeof data === 'undefined') {
              return null;
            }
            return data;
          } else {
            return null;
          }
        });

      if (limitsLocation !== null) {
        if (limitsLocation.weekly !== -1) {
          searchStats.level = 'location';
          searchStats.limit = limitsLocation.weekly;
          if (limitsLocation.weekly > searchStats.used) {
            searchStats.available = limitsLocation.weekly - searchStats.used;
          } else {
            searchStats.available = 0;
          }
          return Promise.resolve(searchStats);
        }
      }

      // Get the agency defaults
      if (typeof userId !== 'undefined' && userId !== null && userId !== '') {
        const limitsAgency: any = await this.afs
          .collection('search_limits_agency')
          .doc(userId)
          .ref.get()
          .then(async (snapshot) => {
            if (snapshot.exists) {
              const docs = snapshot.data();
              if (typeof docs === 'undefined') {
                return null;
              }
              return docs;
            } else {
              return null;
            }
          });

        if (limitsAgency !== null) {
          if (limitsAgency.weekly !== -1) {
            searchStats.level = 'agency';
            searchStats.limit = limitsAgency.weekly;
            if (limitsAgency.weekly > searchStats.used) {
              searchStats.available = limitsAgency.weekly - searchStats.used;
            } else {
              searchStats.available = 0;
            }
            return Promise.resolve(searchStats);
          }
        }
      }

      console.log('searchStats - searchLimitsFinder', this.searchLimitsFinder);

      // Get the Lead Carrot defaults
      if (this.searchLimitsFinder !== null) {
        if (searchStats.used < this.searchLimitsFinder.weekly) {
          searchStats.level = 'app';
          searchStats.limit = this.searchLimitsFinder.weekly;
          if (this.searchLimitsFinder.weekly > searchStats.used) {
            searchStats.available =
              this.searchLimitsFinder.weekly - searchStats.used;
          } else {
            searchStats.available = 0;
          }
          return Promise.resolve(searchStats);
        }
      }

      throw Error('Unable to retrieve search stats.');
    } catch (error) {
      console.error(error);
      searchStats.level = 'app';
      searchStats.limit = 0;
      searchStats.available = 0;
      return Promise.resolve(searchStats);
    }
  }

  getLabel(city: string, index: number) {
    try {
      const filtered = this.filtered[index].length;
      const rows = this.rows[index].length;
      const filteresEnabled = this._filtersEnabled(this.filters);

      if (this.stillSearching && filtered === 0) {
        return `${city} - ...`;
      }

      if (filteresEnabled) {
        return `${city} - (${filtered} of ${rows})`;
      } else {
        return `${city} - ${filtered}`;
      }
    } catch (error) {
      console.error(error);
      return `${city} - ?`;
    }
  }

  async canUserSearch(userId: string, clientId: string) {
    try {
      // console.log('this.user:', this.user);

      const searchStats: ISearchStats = await this.getSearchStats(
        userId,
        clientId
      );

      console.log('🥕 canUserSearch() - searchStats:', searchStats);

      const isActive = await this.afs
        .doc(`${COL_PAUSED}/${userId}`)
        .ref.get()
        .then(async (snapshot) => {
          if (snapshot.exists) {
            const data: any = snapshot.data();
            if (typeof data === 'undefined') {
              return false;
            }
            if (data.agency === true) {
              return false;
            } else if (data.paused.indexOf(clientId) !== -1) {
              return false;
            }
            return true;
          } else {
            return true;
          }
        });

      if (!isActive) {
        console.log('NOT ACTIVE ACCOUNT');
        return Promise.resolve({
          enabled: false,
          searchStats,
        });
      }

      if (searchStats.available > 0) {
        console.log('searches > 0:', searchStats.available);
        return Promise.resolve({
          enabled: true,
          searchStats: searchStats,
        });
      }
    } catch (error) {
      console.error(error);
      return Promise.resolve({
        enabled: false,
        searchStats: {
          userId: userId,
          clientId: clientId,
          level: 'unknow',
          used: 0,
          limit: 0,
          available: 0,
          period: 'weekly',
        },
      });
    }
  }

  // -------------------------------------------------------
  // Need to save the selected state first before reloading.
  // -------------------------------------------------------
  async getData(searchOptions: any) {
    const now = new Date();
    let uuid = searchOptions.uuid;
    if (!uuid) {
      return;
    }
    if (!searchOptions.userId) {
      return;
    }
    if (!searchOptions.searchList) {
      return;
    }

    // --------------------------
    // INPUT parameters
    // --------------------------
    let userId = searchOptions.userId;
    let clientId = searchOptions.clientId;
    let searchType = searchOptions.searchType ? searchOptions.searchType : null;
    let searchList = searchOptions.searchList;

    // --------------------------
    // GLOBAL PARAMETERS
    // --------------------------
    // Clear the screen.
    this.points = [];
    this.lastCount = 0;
    this.firstTime = true;
    this.allCategoriesLookup = [];
    this.allSelected = [];
    this.rows = [];
    this.temp = [];
    this.filtered = [];
    this.extraLocations = [];
    this.cities = [];
    this.searches = [];
    this.selectedTab = 0;

    if (typeof this.getLocSubs !== 'undefined') {
      this.getLocSubs.unsubscribe();
    }
    this.leadService.stop();
    this.remove();

    // Stop the spinner after 3 minutes
    this.stillSearching = true;
    this.showSpinner = true;
    this.onTimeoutNextResults(this.lastCount, searchType);
    this.onTimeout();

    console.log('searchList - getData():', searchList);

    for (let index = 0; index < searchList.length; index++) {
      this.rows.push([]);
      this.temp.push([]);
      this.filtered.push([]);
      this.allSelected.push([]);
    }

    let searchIndex = 0;
    for (const searchItem of searchList) {
      if (searchType !== 'history') {
        const canSearch = await this.canUserSearch(userId, clientId);
        this.searchStats = Object.assign({}, canSearch.searchStats);

        if (!canSearch.enabled) {
          const message =
            'Access to the Business Finder has been paused for your account. Please contact support.';
          this.openSnackBar(message, 'close', 8000);
          return Promise.resolve(false);
        }
      }

      this.getNearbyCities(
        searchItem.geoCoord,
        uuid,
        searchItem.category,
        searchType === 'history' ? false : true
      );

      this.cities.push(searchItem.geoCoord.locality);
      this.searches.push({ city: searchItem.geoCoord.locality });

      // console.log('rows.length:', this.rows[0].length);
      console.log('cities:', this.cities);
      console.log('searches:', this.searches);
      console.log('🥕 searchItem:', searchItem);
      console.log('🥕 searchItem.category:', searchItem.category);

      setTimeout(() => {
        let total = 0;
        for (let index = 0; index < this.rows.length; index++) {
          total = total + this.rows[index].length;
        }

        // alert('found ' + total + ' results');
        if (total < MIN_RESULTS) {
          // alert('reset this search');

          // Reset this particular search
          this.resetSearch();

          // Submit search again (NO Credit Cost)
          setTimeout(() => {
            // alert('searching again');
            this.leadService.triggerSearch(
              searchItem.category,
              searchItem.location,
              searchItem.geoCoord,
              uuid,
              clientId,
              searchType === 'history' ? false : true,
              false
            );
          }, 3000);
        }
      }, MIN_RESULT_CHECK_INTERVAL);

      this.searchSubscriptions.add(
        this.leadService
          .queryHashes2(
            searchItem.category,
            searchItem.geoCoord.lat,
            searchItem.geoCoord.long,
            searchItem.searchRadius,
            uuid
          )
          .subscribe(
            async function (searchIndex: number, leads) {
              if (leads) {
                this.hasResults = true;
                this.firstTime = false;

                //console.log('searchIndex:', searchIndex);
                console.log('🥕 lead count === ', leads.length);

                this.rows[searchIndex] = leads;

                this.filtered[searchIndex] = leads.filter((lead: any) => {
                  // Only display the filtered results
                  return this._showLead(this.filters, lead);
                });

                leads.map((lead: any) => {
                  // console.log('🥕 lead.id:', lead.id);
                  if (this.leadIds.indexOf(lead.id) === -1) {
                    this.leadIds.push(lead.id);

                    // console.log('🥕 lead.url:', lead.url);
                    if (lead.url && lead.url.length > 0) {

                      // console.log('🥕 lead:', lead);

                      let differenceInDays = 1000;
                      const updatedAt = lead.date_updated ? lead.date_updated : lead.date_created;
                      if (typeof updatedAt !== "undefined" && updatedAt !== null) {
                        // To calculate the time difference of two dates
                        let differenceInTime = 0;
                        const seconds = updatedAt.seconds;
                        if (seconds) {
                          const seconds2 = (now.getTime() / 1000);
                          // console.log('seconds2:', seconds2);
                          differenceInTime = seconds2 - seconds;
                          // To calculate the no. of days between two dates
                          differenceInDays = differenceInTime / ( 3600 * 24);
                        } else {
                          differenceInTime = now.getTime() - updatedAt.getTime();
                          // To calculate the no. of days between two dates
                          differenceInDays = differenceInTime / (1000 * 3600 * 24);
                        }
                      }

                      console.log('🥕 differenceInDays:', differenceInDays);

                      if (differenceInDays > 10) {
                        console.log('🥕 scrape site for lead.id:', lead.id,);
                        const axiosConfig: any = {
                          method: "GET",
                          headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                          },
                          params: {
                            leadId: lead.id,
                            url: lead.url,
                            dynamic: false,
                          }
                        };
                        const url = "https://imonfire.net/pub/site-scrape";
                        axios.get(url, axiosConfig)
                      }
                    }
                  }
                });

                this.temp[searchIndex] = this.filtered[searchIndex];

                // this.rows[searchIndex] = leads;
                // this.temp[searchIndex] = leads; // [...leads];

                // console.log('🥕 found leads : ', this.rows[searchIndex].length);
                // this.temp[searchIndex] = [...this.rows[searchIndex]];

                /*
                if (this.lastCount !== leads.length || this.firstTime) {
                  const category = category;
                  this.lastCount = leads.length;
                  this.firstTime = false;

                  console.log('🥕 lead count === ', leads.length);
                  this.rows[searchIndex] = leads.filter(async (lead) => {
                    if (typeof lead !== 'undefined' && lead !== null) {
                      // console.log('🥕🥕🥕🥕🥕');
                      console.log(`🥕 rows[${searchIndex}] - lead:`, lead);

                      // Only display the filtered results
                      if (lead.categories[category] === true) {
                        return this._showLead(this.filters, lead);
                      }
                    }
                  });

                  console.log('🥕 found leads : ', this.rows[searchIndex].length);

                  this.temp[searchIndex] = [...this.rows[searchIndex]];
                }
                */
              }
              this.didSearch = true;
            }.bind(this, searchIndex)
          )
      );
      // Location to put the next group of results.
      searchIndex++;

      if (searchType === 'history') {
        console.log('skip live search');
      } else {
        this.leadService.triggerSearch(
          searchItem.category,
          searchItem.location,
          searchItem.geoCoord,
          uuid,
          clientId,
          searchType === 'history' ? false : true,
          true
        );
      }
    }
    this.doSearch = true;
  }

  getGmbRatingString(aggregateRating: any) {
    if (aggregateRating) {
      let reviews = aggregateRating.reviewCount
        ? aggregateRating.reviewCount
        : 0;
      let rating = aggregateRating.ratingValue
        ? aggregateRating.ratingValue
        : 0;
      return rating + ' rating | ' + reviews + ' reviews';
    }
  }

  createFilterCategoryList(item: any): boolean {
    // Build filter list
    if (
      item.categories[this.leadCategory.trim().toLocaleLowerCase()] === true
    ) {
      const keys = Object.keys(item.categories);
      for (const key of keys) {
        if (!this.allCategoriesLookup.includes(key)) {
          if (key !== '&' && key !== 'the' && key !== 'of' && key !== 'and') {
            // console.log(key);
            this.allCategoriesLookup.push(key);

            // Rebuild the list
            this.allCategoriesLookup.sort();
            this.allCategoriesList = [];
            this.categoryCount = 0;
            for (const cat of this.allCategoriesLookup) {
              // if (!this.processingFilters) { return false; }

              this.allCategoriesList.push({
                id: this.categoryCount,
                name: cat.substr(0, 1).toLocaleUpperCase() + cat.substr(1),
                selected: false,
              });
              this.categoryCount++;
            }
          }
        } else {
          // Increase the count by 1
        }
      }
    }

    return true;
  }

  stopSearch() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutNextResults);

    this.stillSearching = false;
    this.showSpinner = false;
    this.showSpinnerAjax = false;

    const MESSAGE = 'STOP getting new leads for this search.';
    this.snackBar.open(MESSAGE, 'close', { duration: 4000 });

    if (typeof this.getTaskService !== 'undefined') {
      this.getTaskService.unsubscribe();
    }
    if (typeof this.getLocSubs !== 'undefined') {
      this.getLocSubs.unsubscribe();
    }
    if (typeof this.allSubscriptions !== 'undefined') {
      this.allSubscriptions.unsubscribe();
    }
    if (typeof this.searchSubscriptions !== 'undefined') {
      this.searchSubscriptions.unsubscribe();
    }
    this.leadService.stop();
  }

  getRowClass(row) {
    // return { 'age-is-ten': (row.age % 10) === 0 };
    return { 'padding-4px': true };
  }

  getCellClass({ row, column, value }): any {
    // return { 'is-female': value === 'female' };
    return { 'padding-4px': true };
  }

  isDisabled() {
    // console.log('*** ALL SELECTED LENGTH =', this.allSelected.length);
    // console.log(this.allSelected);

    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.allSelected.length; index++) {
      if (
        typeof this.allSelected !== 'undefined' &&
        this.allSelected[index].length > 0
      ) {
        // console.log('*** IS DISABLED =', false);
        return false;
      }
    }
    // console.log('*** IS DISABLED =', true);
    return true;
  }

  moreFilters(event) {
    return false;
  }

  moreActions(event) { }

  selectAllLeads() {
    for (let index = 0; index < this.filtered.length; index++) {
      if (this.allSelected[index] === 'undefined') {
        this.allSelected[index] = [];
      }
      this.allSelected[index] = [];
      // this.selected[index].slice(0, this.rows[index].length);
      this.allSelected[index].push(...this.filtered[index]);
      // Important: Emit the select event
    }

    // this.table.onHeaderSelect(true);
    // if (this.allSelected[0].length === 0 && this.rows[0].length) {
    //   this.table.onHeaderSelect(true);
    // }

    let totalRecords = 0;
    const keys = Object.keys(this.allSelected);
    for (const key of keys) {
      if (typeof this.allSelected[key] !== 'undefined') {
        if (this.allSelected[key].length > 0) {
          totalRecords += this.allSelected[key].length;
        }
      }
    }

    /*
    if (this.selected[0] !== 'undefined') {
      this.table.selected = this.selected[0];
    }
    */

    const MESSAGE = totalRecords + ' selected.';
    this.snackBar.open(MESSAGE, 'close', { duration: 4000 });
  }

  // ------------------------
  // -- checkbox
  onSelect({ selected }, index: number) {
    if (typeof selected[index] === 'undefined') {
      this.allSelected[index] = [];
    }

    // console.log('selected:', selected);
    // console.log('index:', index);

    this.allSelected[index] = selected;
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }

  add(index: number) {
    this.allSelected[index].push(this.filtered[0][1], this.filtered[0][3]);
  }

  update(index: number) {
    this.allSelected[index] = [this.filtered[0][1], this.filtered[0][3]];
  }

  // ToDo: Add code to save the selected items here
  remove() {
    for (let index = 0; index < this.allSelected.length; index++) {
      this.allSelected[index] = [];
    }
  }

  callback() {
    // console.log('ENTER this.callback()');
  }

  closeDropDown() {
    this.showDropDown = false;
  }

  openDropDown() {
    this.showDropDown = true;
  }

  async getUserLocation() {
    try {
      // console.log('---------------');
      // console.log('ENTER getUserLocation()');
      // Locate the user
      if (navigator.geolocation) {
        // console.log('YES Geolocation');
        await navigator.geolocation.getCurrentPosition(async (position) => {
          this.geoCoord.lat = position.coords.latitude;
          this.geoCoord.long = position.coords.longitude;

          this.area.center.latitude = this.geoCoord.lat;
          this.area.center.longitude = this.geoCoord.long;

          // await this.geocodeLatLng(this.geoCoord.lat, this.geoCoord.long, true);

          // console.warn('getUserLocation() - position: ', position);
          return Promise.resolve(position);
        });
      } else {
        const message = 'No Geolocation found.';
        // console.warn(message);

        /*
        // Plantagenet, ON, Canada
        latitude: 45.533823899999994
        longitude: -75.0030239
        */
        this.geoCoord.lat = 25.9875;
        this.geoCoord.long = -97.186389;
        this.googleMapType = 'default';

        this.area.center.latitude = this.geoCoord.lat;
        this.area.center.longitude = this.geoCoord.long;

        return Promise.resolve(message);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // ======= Function to create a marker
  createMarker(content: string, latitude: number, longitude: number) {
    console.log('createMarket()');

    console.log('');
    /*
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: map,
      zIndex: 10000, // Math.round(latlng.lat() * -100000) << 5
    });
    */
    this.points.push({
      content,
      latitude,
      longitude,
      icon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
        origin: {
          x: 0,
          y: 0,
        },
      },
    });

    console.log('this.points:', this.points);

    /*
     google.maps.event.addListener(marker, 'click', () => {
       infowindow.setContent(content);
       infowindow.open(map, marker);
     });
   */
  }

  async geocodeLatLng(lat: number, lng: number, update: boolean = false) {
    const latlng = { lat, lng };

    if (typeof this.geocoder === 'undefined') {
      console.warn('geocodeLatLng() => this.geocoder is UNDEFINED');
      return Promise.resolve();
    }

    try {
      console.warn('geocodeLatLng() => this.geocoder is DEFINEDS');
      return this.geocoder.geocode({ location: latlng }, (results, status) => {
        if (update) {
          if (status.toString() === 'OK') {
            if (results[0]) {
              this.onChange(results[0]);

              /*
              this.leadLocation = results[0].formatted_address;
              this.badLocation = false;
              const MESSAGE =
                'Address found [' + results[0].formatted_address + ']';
              const snackBarRef = this.snackBar.open(MESSAGE, 'close', {
                duration: 4000,
              });
              google.maps[0].setZoom(11);
              // map.setZoom(11);
              const marker = new google.maps.Marker({
                position: latlng,
                map: google.maps[0],
              });
              */

              /*
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(map, marker);


                // Add circle overlay and bind to marker
                const circle = new google.maps.Circle({
                  map: google.maps[0],
                  radius: 16093,    // 10 miles in metres
                  fillColor: '#AA0000'
                });
                circle.bindTo('center', marker, 'position');
              */
            } else {
              const MESSAGE = 'No results found';
              this.snackBar.open(MESSAGE, 'close', { duration: 4000 });
            }
          } else {
            const MESSAGE = 'Geocoder failed due to: ' + status;
            this.snackBar.open(MESSAGE, 'close', { duration: 4000 });
          }
        } else {
          if (status.toString() === 'OK') {
            if (results[0]) {
              // this.leadLocation = results[0].formatted_address;
              // this.badLocation = false;
              const message =
                'Address found [' + results[0].formatted_address + ']';
              console.warn(message);
              // this.snackBar.open(message, 'close', { duration: 4000 });
            } else {
              const message = 'No results found';
              console.warn(message);
              this.snackBar.open(message, 'close', { duration: 4000 });
            }
          } else {
            const message = 'Geocoder failed due to: ' + status;
            console.warn(message);
            this.snackBar.open(message, 'close', { duration: 4000 });
          }
        }
        return { results, status };
      });
    } catch (error) {
      console.warn(error);
      return Promise.reject(error);
    }
  }

  public doesExist(aSource: string[], index: number = 0) {
    if (
      typeof aSource === 'undefined' ||
      aSource === null ||
      typeof index === 'undefined' ||
      index === null ||
      index < 0 ||
      aSource.length < 1 ||
      index > aSource.length - 1 ||
      typeof aSource[index] === 'undefined' ||
      aSource[index] === null ||
      aSource[index].trim() === ''
    ) {
      return false;
    }

    return true;
  }

  public hasFb(source: any) {
    if (typeof source === 'undefined' || source === null) {
      return false;
    } else {
      if (typeof source.fb_url !== 'undefined' && source.fb_url.trim() !== '') {
        return true;
      } else {
        return false;
      }
    }
  }

  public hasFbPixel(source: any) {
    try {
      // console.log(source);

      if (typeof source === 'undefined' || source === null) {
        //       && (typeof source.site_data === 'undefined' || source.site_data === null)
        //    ) {
        return false;
      } else {
        if (typeof source.has_fb_pixel !== 'undefined' && source.has_fb_pixel) {
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  }

  async placeMarker(event: any) {
    try {
      console.warn('xxx event:', event);

      console.warn('placeMarker(event):', event.c);

      console.warn('Place Marker LAT: ' + event.coords.lat);
      console.warn('Place Marker LONG: ' + event.coords.lng);

      this.geoCoord.lat = event.coords.lat;
      this.geoCoord.long = event.coords.lng;

      this.area.center.latitude = this.geoCoord.lat;
      this.area.center.longitude = this.geoCoord.long;

      await this.geocodeLatLng(this.geoCoord.lat, this.geoCoord.long, true);
      return Promise.resolve(true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  onPage(event: any) {
    return false;
  }

  gotoBilling() {
    // console.log('GOTO Options');
    this.router.navigateByUrl('/options');
  }

  gotoLeadFinder() {
    // console.log('GOTO Lead Finder');
    this.router.navigateByUrl('/');
  }

  getActiveClass(indexOfRouteLink) {
    let tabsclass = 'mat-tab-link';
    if (this.activeLinkIndex === indexOfRouteLink) {
      tabsclass = 'mat-tab-link mat-tab-label-active';
    }
    return tabsclass;
  }

  async getCustomLoginToken(integrationId: string, visitorId: string = null) {
    try {
      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );

      console.log('--- visitorId:', visitorId);

      const url = `https://us-central1-aesthetic-root-414319.cloudfunctions.net/authGetToken_v2?integrationId=${integrationId}&visitorId=${visitorId}`;

      return await this.http
        .get<IApiResponse>(url, { headers })
        .pipe(map((res: any) => res));
    } catch (error) {
      console.error(error);
      return Promise.resolve(null);
    }
  }

  // Function Stubs for modal dialogs

  changeStepAddLeads(step: number) {
    return;
  }

  async createCsvExport(
    exportType: string = '',
    selectedCities: any[] = [],
    leadSource: string = 'Lead Finder'
  ): Promise<any> {
    try {
      const type =
        typeof exportType === 'undefined' || exportType === null
          ? 'BASIC'
          : exportType.toLocaleUpperCase();

      // console.log('*********************************************************');
      // console.log('createCsvExport() -- exportType: ', type);

      let totalRecords = 0;
      const keys = Object.keys(selectedCities);
      for (const key of keys) {
        if (typeof selectedCities[key] !== 'undefined') {
          if (selectedCities[key].length > 0) {
            totalRecords += selectedCities[key].length;
          }
        }
      }

      const exportData: any[] = [];
      if (type === 'TERRITORY') {
        const headers = {
          name: 'Business Name',
          phone: 'Phone',
          email: 'Email',
          emails: 'All Emails',
          website: 'Website',
          street: 'Street',
          city: 'City',
          state: 'State',
          fullAddress: 'Full Address',
          category: 'Category',
          called: 'Called? (Y/N)',
          setAppt: 'Booked Appt? (Y/N)',
        };
        exportData.push(headers);

        // Export each of the selected rows
        for (const key of keys) {
          for (const item of selectedCities[key]) {
            let email = '';
            if (
              typeof item.yelp_bizEmail === 'undefined' ||
              item.yelp_bizEmail === null
            ) {
              email = '';
            } else {
              email = item.yelp_bizEmail;
            }

            const row = {
              name: item.name,
              phone:
                typeof item.telephone === 'undefined' ? '' : item.telephone,
              email: typeof item.email === 'undefined' ? email : item.email,
              emails:
                typeof item.emails === 'undefined'
                  ? email
                  : item.emails.join(', '),
              website: typeof item.url === 'undefined' ? '' : item.url,
              street:
                typeof item.address.streetAddress === 'undefined'
                  ? ''
                  : item.address.streetAddress,
              city:
                typeof item.address.addressLocality === 'undefined'
                  ? ''
                  : item.address.addressLocality,
              state:
                typeof item.address.addressRegion === 'undefined'
                  ? ''
                  : item.address.addressRegion,
              fullAddress:
                typeof item.display_address === 'undefined'
                  ? ''
                  : item.display_address,
              category:
                typeof item.category === 'undefined' ? '' : item.category,
              called: '',
              setAppt: '',
            };
            exportData.push(row);

            this.dataExport.count++;
            this.dataExport.percent =
              (this.dataExport.count / totalRecords) * 100;
          }
        }
      } else if (type === 'BASIC') {
        const headers = {
          // Required Fields - Organization and Person
          orgName: 'Organization - Name',
          personName: 'Person - Name',

          // Optional Fields - Organization
          orgAddress: 'Organization - Address',
          orgCategory: 'Organization - Category',
          orgLeadSource: 'Organization - Lead source', // set to Lead Carrot

          // Custom Fields - Organization
          orgWebsite: 'Organization - Website',
          orgEmails: 'Organization - Emails',

          // Optional Fields - Person
          personPhone: 'Person - Phone',
          personEmail: 'Person - Email',
          personLeadSource: 'Person - Lead source', // set to Lead Carrot

          // Required Fields - Deal
          dealOwner: 'Deal - Owner', // Blank to have deals opened in first stage of first pipeline
          // deal_title: 'Deal - Title',  // Needs to be blank to have Pipedrive automatically populate
          dealStage: 'Deal - Stage', // Blank to have deals opened in first stage of first pipeline

          // Required Fields - Activity
          // activity_subject: 'Activity - Subject' // Do Not leave blank
        };
        exportData.push(headers);

        // Export each item
        for (const key of keys) {
          for (const item of selectedCities[key]) {
            let email = '';
            if (
              typeof item.yelp_bizEmail === 'undefined' ||
              item.yelp_bizEmail === null
            ) {
              email = '';
            } else {
              email = item.yelp_bizEmail;
            }

            const row = {
              // Required Fields - Organization and Person
              orgName: item.name,
              personName: item.name,

              // Optional Fields - Organization
              orgAddress:
                typeof item.display_address === 'undefined'
                  ? ''
                  : item.display_address,
              orgCategory:
                typeof item.category === 'undefined' ? '' : item.category,
              orgLeadSource: leadSource,

              // Custom Fields - Organization
              orgWebsite: typeof item.url === 'undefined' ? '' : item.url,
              orgEmails:
                typeof item.emails === 'undefined'
                  ? ''
                  : item.emails.join(', '),

              // Optional Fields - Person
              personPhone:
                typeof item.telephone === 'undefined' ? '' : item.telephone,
              personEmail: typeof item.email === 'undefined' ? '' : item.email,
              personLeadSource: leadSource,

              // Required Fields - Deal
              dealOwner: '', // Blank to have deals opened in first stage of first pipeline
              // deal_title: item.name + ' deal',  // Blank to have Pipedrive automatically populate
              dealStage: '', // Blank to have deals opened in first stage of first pipeline

              // Required Fields - Activity
              // activity_subject: ''  // Do Not leave blank
            };
            exportData.push(row);

            this.dataExport.count++;
            this.dataExport.percent =
              (this.dataExport.count / totalRecords) * 100;
          }
        }
      } else if (type === 'FULL') {
        const headers = {
          bizName: 'Business Name',
          bizPhone: 'Phone',
          bizEmail: 'Email',
          emails: 'Emails',
          displayAddress: 'Full Address',

          hasSsl: 'Has SSL',
          isMobileResponsive: 'Mobile Responsive',
          bizUrl: 'Website URL',

          category: 'Buiness Category',
          googleSearch: 'Google Search URL',

          facebookStarRating: 'Facebook Star Rating',
          facebookReviewCount: 'Facebook Reviews',

          gmbUrl: 'Google My Business URL',
          gmbStarRating: 'GMB Star Rating',
          gmbReviewCount: 'GMB Review Count',
          gmbIsVerified: 'GMB Verified',
          gmbVerifyUrl: 'GMB Verify URL',

          yelpUrl: 'Yelp! URL',
          yelpStarRating: 'Yelp! Star Rating',
          yelpReviewCount: 'Yelp! Reviews',
          yelpHasVideo: 'Yelp! Uses Video',
          yelpIsClaimed: 'Yelp! Claimed Status',
          yelpBizClaimUrl: 'Yelp! Claim URL',

          facebookUrls: 'Facebook URLS',
          // google_plus_urls: 'Google+ URLS',
          instagramUrls: 'Instagram URLS',
          linkedinUrls: 'LinkedIn URLS',
          pinterestUrls: 'Pinterest URLS',
          twitterUrls: 'Twitter URLS',
          youtubeUrls: 'YouTube URLS',

          street: 'Street',
          city: 'City',
          state: 'State',
          postal: 'Postal',
          country: 'Country',
        };
        exportData.push(headers);

        // Export each of the selected rows
        for (const key of keys) {
          for (const item of selectedCities[key]) {
            let email = '';
            if (
              typeof item.yelp_bizEmail === 'undefined' ||
              item.yelp_bizEmail === null
            ) {
              email = '';
            } else {
              email = item.yelp_bizEmail;
            }

            let facebookStarRating = 0;
            let facebookReviewCount = 0;

            let gmbStarRating = 0;
            let gmbReviewCount = 0;
            let yelpStarRating = 0;
            let yelpReviewCount = 0;

            let gmbUrl = '';
            let gmbIsVerified = '';
            let gmbVerifyUrl = '';
            let yelpUrl = '';
            let yelpHasVideo = '';
            let yelpIsClaimed = '';
            let yelpBizClaimUrl = '';
            let facebookUrls = '';
            // let google_plus_urls = '';
            let instagramUrls = '';
            let linkedinUrls = '';
            let pinterestUrls = '';
            let twitterUrls = '';
            let youtubeUrls = '';

            if (
              typeof item.fb_data !== 'undefined' &&
              typeof item.fb_data.fb_aggregateRating !== 'undefined'
            ) {
              if (typeof item.fb_data.fb_aggregateRating !== 'undefined') {
                facebookStarRating =
                  typeof item.fb_data.fb_aggregateRating.ratingValue ===
                    'undefined'
                    ? 0
                    : item.fb_data.fb_aggregateRating.ratingValue;
                facebookReviewCount =
                  typeof item.fb_data.fb_aggregateRating.ratingCount ===
                    'undefined'
                    ? 0
                    : item.fb_data.fb_aggregateRating.ratingCount;
              } else {
                facebookStarRating = 0;
                facebookReviewCount = 0;
              }
            }

            if (typeof item.gmb_data !== 'undefined') {
              if (typeof item.gmb_data.gmb_aggregateRating !== 'undefined') {
                gmbStarRating =
                  typeof item.gmb_data.gmb_aggregateRating.ratingValue ===
                    'undefined'
                    ? 0
                    : item.gmb_data.gmb_aggregateRating.ratingValue;
                gmbReviewCount =
                  typeof item.gmb_data.gmb_aggregateRating.ratingCount ===
                    'undefined'
                    ? 0
                    : item.gmb_data.gmb_aggregateRating.ratingCount;
              } else {
                gmbStarRating = 0;
                gmbReviewCount = 0;
              }

              gmbUrl =
                typeof item.gmb_data.gmb_url === 'undefined'
                  ? 0
                  : item.gmb_data.gmb_url;
              gmbIsVerified =
                typeof item.gmb_data.gmb_is_verified === 'undefined'
                  ? ''
                  : item.gmb_data.gmb_is_verified;
              gmbVerifyUrl =
                typeof item.gmb_data.gmb_url_claim === 'undefined'
                  ? ''
                  : item.gmb_data.gmb_url_claim;
            }

            if (typeof item.yelp_data !== 'undefined') {
              if (typeof item.yelp_data.yelp_aggregateRating !== 'undefined') {
                yelpStarRating =
                  typeof item.yelp_data.yelp_aggregateRating.ratingValue ===
                    'undefined'
                    ? 0
                    : item.yelp_data.yelp_aggregateRating.ratingValue;
                yelpReviewCount =
                  typeof item.yelp_data.yelp_aggregateRating.ratingCount ===
                    'undefined'
                    ? 0
                    : item.yelp_data.yelp_aggregateRating.ratingCount;
              } else {
                yelpStarRating = 0;
                yelpReviewCount = 0;
              }

              yelpUrl =
                typeof item.yelp_data.yelp_url === 'undefined'
                  ? 0
                  : item.yelp_data.yelp_url;
              yelpHasVideo =
                typeof item.yelp_data.yelp_hasVideo === 'undefined'
                  ? ''
                  : item.yelp_data.yelp_hasVideo;
              yelpIsClaimed =
                typeof item.yelp_data.yelp_is_claimed === 'undefined'
                  ? ''
                  : item.yelp_data.yelp_is_claimed;
              yelpBizClaimUrl =
                typeof item.yelp_data.yelp_url_claim === 'undefined'
                  ? ''
                  : item.yelp_data.yelp_url_claim;
            }

            if (typeof item.social !== 'undefined') {
              facebookUrls =
                typeof item.social.facebook === 'undefined'
                  ? ''
                  : item.social.facebook.join(', ');
              // google_plus_urls = typeof item.social.google_plus === 'undefined' ? '' : item.social.google_plus.join(', ');
              instagramUrls =
                typeof item.social.instagram === 'undefined'
                  ? ''
                  : item.social.instagram.join(', ');
              linkedinUrls =
                typeof item.social.linkedin === 'undefined'
                  ? ''
                  : item.social.linkedin.join(', ');
              pinterestUrls =
                typeof item.social.pinterest === 'undefined'
                  ? ''
                  : item.social.pinterest.join(', ');
              twitterUrls =
                typeof item.social.twitter === 'undefined'
                  ? ''
                  : item.social.twitter.join(', ');
              youtubeUrls =
                typeof item.social.youtube === 'undefined'
                  ? ''
                  : item.social.youtube.join(', ');
            }
            try {
              delete item.social.twitter['https://twitter.com/share'];
              delete item.social.facebook['https://www.facebook.com/login.php'];
            } catch (error) {
              // do nothing
            }

            let hasSsl = '';
            if (
              !isUndefinedOrNull(item.site_data) &&
              !isUndefinedOrNull(item.site_data.has_ssl)
            ) {
              hasSsl = item.site_data.has_ssl;
            }
            let hasMobilefriendly = '';
            if (
              !isUndefinedOrNull(item.site_data) &&
              !isUndefinedOrNull(item.site_data.has_mobilefriendly)
            ) {
              hasMobilefriendly = item.site_data.has_mobilefriendly;
            }

            const row = {
              bizName: item.name,
              bizPhone: item.telephone,
              bizEmail: typeof item.email === 'undefined' ? '' : item.email,
              emails:
                typeof item.emails === 'undefined'
                  ? ''
                  : item.emails.join(', '),
              displayAddress:
                typeof item.display_address === 'undefined'
                  ? ''
                  : item.display_address,

              hasSsl,
              hasMobilefriendly,
              bizUrl: typeof item.url === 'undefined' ? '' : item.url,

              category:
                typeof item.category === 'undefined' ? '' : item.category,
              googleSearch:
                'https://www.google.com/search?q=' + item.google_search,

              facebookStarRating,
              facebookReviewCount,

              gmbUrl,
              gmbStarRating,
              gmbReviewCount,
              gmbIsVerified,
              gmbVerifyUrl,

              yelpUrl,
              yelpStarRating,
              yelpReviewCount,
              yelpHasVideo,
              yelpIsClaimed,
              yelpBizClaimUrl,

              facebookUrls,
              // google_plus_urls: google_plus_urls,
              instagramUrls,
              linkedinUrls,
              pinterestUrls,
              twitterUrls,
              youtubeUrls,

              street:
                typeof item.address.streetAddress === 'undefined'
                  ? ''
                  : item.address.streetAddress,
              city:
                typeof item.address.addressLocality === 'undefined'
                  ? ''
                  : item.address.addressLocality,
              state:
                typeof item.address.addressRegion === 'undefined'
                  ? ''
                  : item.address.addressRegion,
              postal:
                typeof item.address.postalCode === 'undefined'
                  ? ''
                  : item.address.postalCode,
              country:
                typeof item.address.addressCountry === 'undefined'
                  ? ''
                  : item.address.addressCountry,
            };
            exportData.push(row);

            this.dataExport.count++;
            this.dataExport.percent =
              (this.dataExport.count / totalRecords) * 100;
          }
        }
      } else if (type === 'GOOGLE_CUSTOM_AUDIENCE') {
        // Export each of the selected rows
        for (const key of keys) {
          for (const item of selectedCities[key]) {
            const url = item.url;
            if (!isUndefinedOrNullOrEmpty(item.url)) {
              exportData.push({ url });
              this.dataExport.count++;
              this.dataExport.percent =
                (this.dataExport.count / totalRecords) * 100;
            }
          }
        }
      } else {
        console.warn('UNKNOWN export type [' + type + ']');
      }

      return Promise.resolve(exportData);
    } catch (error) {
      console.error(error);
      return Promise.resolve([]);
    }
  }

  fakeValidateUserData() {
    return of({
      userDate1: 1,
      userData2: 2,
    });
  }

  downloadFilteredExport2(dataJson: any, filename: string): AngularCsv {
    // console.log('Filename: ', filename);
    // console.log('CSV Export', dataJson);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
    };

    if (isUndefinedOrNullOrEmpty(filename)) {
      return null;
    }

    // return new AngularCsv(dataJson, filename.trim(), options);
  }

  downloadAudience(value: any, filename: string) {
    //    this.fakeValidateUserData()
    //      .subscribe((res) => {
    // console.log('value:', value);
    const urls: string[] = [];
    for (const item of value) {
      urls.push(item.url);
    }

    this.dynamicDownloadByHtmlTag({
      filename,
      value: urls,
    });
    //      });
  }

  private dynamicDownloadByHtmlTag(arg: { filename: string; value: any }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const urls = arg.value.join(',\n');
    const element = this.setting.element.dynamicDownload;
    const fileType =
      arg.filename.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute(
      'href',
      `data:${fileType};charset=utf-8,${encodeURIComponent(urls)}`
    );
    element.setAttribute('download', arg.filename);

    const event = new MouseEvent('click');
    element.dispatchEvent(event);
  }

  openSnackBar(message: string, action: string, duration: number = 4000) {
    this.snackBar.open(message, action, { duration });
  }

  async testConnection(apikey: any, locationId: string) {
    try {
      console.warn('testConnection():', {
        apikey: apikey,
        locationId: locationId,
      });
      this.showPushToCrm = false;
      if (locationId.indexOf('@') !== -1) {
        this.showPushToCrm = false;
        return Promise.resolve(true);
      } else if (locationId === 'standalone') {
        console.warn('*** testConnection(): TRUE');
        this.showPushToCrm = false;
        return Promise.resolve(true);
      } else {
        const url = `https://rest.gohighlevel.com/v1/locations/${locationId}?includeTwilio=false`;
        console.log('testConnection URL:', url);

        const axiosConfig: any = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${apikey}`,
          },
        };
        const locationDetails = await axios
          .get(url, axiosConfig)
          .then((response: any) => {
            return response.data;
          });

        console.warn('locationDetails:', locationDetails);
        console.warn('*** testConnection(): TRUE');
        this.showPushToCrm = true;
        return Promise.resolve(true);
      }
    } catch (error) {
      console.warn('ERROR: testConnection():', error.message);
      console.error(error);
      return Promise.resolve(false);
    }
  }
}
