<!--
<mat-toolbar color="primary" style="background-color: #1d6e9a;">

  <mat-toolbar-row>
    <span>
      <img src="/assets/images/lead-carrot-banner-transparent_x40.png" style="height: 24px;">
    </span>
    <span class="example-fill-remaining-space"></span>
    <span class="align-center"></span>
    <span class="example-spacer" style="text-align: center;">
    </span>


    <div class="d-inline-block" placement="bottom-right" style="margin-left: 20px;">
      <button mat-stroked-button [matMenuTriggerFor]="settings" id="dropDownMoreActions">
        <img [src]="gravatarUrl">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #settings="matMenu">
        <button mat-menu-item>{{ this.email }}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="gotoLeadFinder()"><i class="fa fa-search fa-fw" aria-hidden="true">&nbsp;</i>Lead
          Finder</button>
        <button mat-menu-item (click)="gotoBilling()"><i class="fa fa-shopping-cart fa-fw" aria-hidden="true">&nbsp;</i>Billing</button>
        <mat-divider></mat-divider>
        button class="dropdown-item" (click)="open(exportResultsCustom)">Export Results as Custom CSV</button>
        <button mat-menu-item (click)="onLogout()"><i class="fa fa-sign-out fa-fw" aria-hidden="true">&nbsp;</i>Logout</button>
        <button mat-menu-item [matMenuTriggerFor]="invertebrates">Invertebrates</button>
      </mat-menu>
    </div>

      <div ngbDropdownMenu aria-labelledby="dropDownMoreActions">
        <div style="padding-left: 24px; padding-right: 24px;">{{ this.email }}</div>
        <button class="dropdown-item" (click)="open(showUserSettings)">
          <i class="fa fa-cog fa-fw" aria-hidden="true">&nbsp;</i>Settings</button>

        <mat-divider></mat-divider>
        <button class="dropdown-item" (click)="gotoLeadFinder()">
          <i class="fa fa-search fa-fw" aria-hidden="true">&nbsp;</i>Lead Finder</button>

        <button class="dropdown-item" (click)="gotoBilling()">
          <i class="fa fa-shopping-cart fa-fw" aria-hidden="true">&nbsp;</i>Billing</button>

        <mat-divider></mat-divider>
        <button class="dropdown-item" (click)="open(exportResultsCustom)">Export Results as Custom CSV</button>
        <button class="dropdown-item" (click)="onLogout()">
          <i class="fa fa-sign-out fa-fw" aria-hidden="true">&nbsp;</i>Logout</button>
      </div>
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="hasNewMessage" style="background-color: green;">
    <span style="width: 100%;"></span>
    <button style="float: right; margin-right: 30 px;" name="stopSearch" id="stopSearch" type="submit"
      mat-raised-button color="warn" (click)="stopSearch()">Close</button>
  </mat-toolbar-row>
</mat-toolbar>
-->

<div *ngIf="isLoggedIn" class="page-content">
  <!-- Are you sure you want to revert the displayed columns back to the initial view? [OK] [Cancel] -->
  <style>
    .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
      padding: 4px !important;
    }

  </style>

  <div class="row">
    <div class="col-xs-6 col-md-4 col-lg-4" style="text-align: left; padding-bottom: 0px;">
      <div class="search-stats" style="display: none;">
        <span>{{ searchStats.available }}</span> of <span>{{ searchStats.limit }} searches left</span>
      </div>
      <div *ngIf="showSpinner || stillSearching" style="padding-left: 20px; padding-bottom: 0px; margin-bottom: 0px;">
        <mat-progress-spinner mode="indeterminate" [diameter]="30"
          style="float: left; margin-top: 20px; margin-right: 10px;"></mat-progress-spinner>
        <div
          style="padding-top: 5px; padding-bottom: 0px; margin-bottom: 0px; font-size: 12pt; font-weight: 700; color: #1d6e9a">{{
          waitingMessage }}</div>
      </div>

      <!--
      <div class="row">
        <div class="col" style="padding-left: 20px;">
          <button class="btn btn-outline-primary" style="background-color: orange;" id="dropDownMoreActions" (click)="useBetaSearch()">Try Beta USA Search</button>
        </div>
      </div>
      -->


      <!--
      <button [disabled]="!selected.length" class="btn btn-success btn-block" (click)="open(addToNiche)">
        <i class="fa fa-plus fa-fw" aria-hidden="true"></i> Add Leads</button>
      -->

    </div>
    <!-- <div class="col-lg-8 col-md-6 col-sm-4"></div> -->
    <div class="col-xs-6 col-md-8 col-lg-8" style="text-align: right; min-height: 56px;">

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12" style="padding-top: 5px;">
          <form style="padding-right:15px;">


            <!--
            <div *ngIf="isAdmin()">
            -->
            <div>
            <!--
              <button id="btnDelete" class="btnDelete-enabled"
                style="margin-bottom: 10px; margin-right: 20px; color: white; background-color: orange;"
                mat-raised-button (click)="clearSearcHistory(0)" matTooltip="Clear Recent Search History">
                <mat-icon style="margin-bottom: 15px;" svgIcon="refresh"></mat-icon>
              </button>
            -->

              <button [disabled]="leadCategory === ''" id="btnDelete" class="btnDelete-enabled"
                style="margin-bottom: 10px; margin-right: 20px;" mat-raised-button (click)="resetSearch()"
                matTooltip="Reset Search">
                <mat-icon style="margin-bottom: 15px;" svgIcon="refresh"></mat-icon>
              </button>

              <!--
              <button [disabled]="isDisabled() || stillSearching" id="btnDelete" class="btnDelete-enabled"
                style="margin-bottom: 10px; margin-right: 20px;" mat-raised-button (click)="deleteLeads()"
                matTooltip="Delete Leads">
                <mat-icon style="margin-bottom: 15px;" svgIcon="delete"></mat-icon>
              </button>
              -->
            </div>



            <button *ngIf="showPushToCrm" [disabled]="isDisabled() || stillSearching" id="btnImport"
              class="btnImport-enabled" style="margin-bottom: 10px;" mat-raised-button
              (click)="openDialog('importResults')">
              <mat-icon style="margin-bottom: 15px;" svgIcon="account-arrow-left"></mat-icon>Push to CRM
            </button>

            <button [disabled]="isDisabled() || stillSearching" id="btnExport"
              style="margin-left: 20px; margin-bottom: 10px;" mat-raised-button color="default"
              (click)="openDialog('exportResults')">
              <mat-icon style="margin-bottom: 15px;" svgIcon="file-download"></mat-icon>Export to CSV
            </button>

            <button [disabled]="isDisabled() || stillSearching" id="btnCustomAudience"
              style="margin-left: 20px; margin-bottom: 10px;" mat-raised-button color="default"
              (click)="openCustomDialog('googleCustomAudience')">
              <mat-icon style="margin-bottom: 15px;" svgIcon="google-adwords"></mat-icon>Custom Audience
            </button>


            <!--
            <div class="d-inline-block" placement="bottom-right" style="margin-left: 20px;">
              <button mat-raised-button id="btnHistory"><mat-icon>history</mat-icon>History</button>
            </div>
            -->


            <div class="d-inline-block" placement="bottom-right">
              <button mat-raised-button id="btnFilter" style="margin-left: 20px; margin-bottom: 10px;"
                (click)="showFilters()">
                <mat-icon style="margin-bottom: 15px;" svgIcon="filter-variant"></mat-icon>Filter
              </button>
            </div>
          </form>

          <!--
          <div class="d-inline-block" placement="bottom-right" style="margin-left: 20px;">
            <button mat-stroked-button [matMenuTriggerFor]="moreActions" id="dropDownMoreActions">More<mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #moreActions="matMenu" aria-labelledby="dropDownMoreActions">
              <button mat-menu-item (click)="openDialog('exportResults')">Export Results</button>
              <button class="dropdown-item" (click)="openDialog('exportResultsCustom')">Export Results for Pipedrive</button>
              <button class="dropdown-item" (click)="openDialog('exportResultsCustomCallSheet')">Export as Territory Call Sheet</button>
            </mat-menu>
          </div>
          -->
        </div>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="perfect-scrollbar-container results-display">

        <perfect-scrollbar [config]="config" #leadsPS>

          <div class="card">

            <div class="card-body">

              <form>

                <div class="row">
                  <div class="col-sm-12 col-md-2" style="text-align: left;">
                    <h4 class="card-title">
                      <i *ngIf="branding.icon" class="fa fa-fw" [ngClass]="branding.icon" aria-hidden="true"></i>
                      <img *ngIf="branding.logo" class="brandLogo" [src]="branding.logo">
                      <span class="branding.name">{{ branding.name }}</span>
                    </h4>
                    <h5 *ngIf="geoCoord.state" class="card-subtitle">&nbsp;{{ leadCategory }} in {{ leadLocation }}</h5>
                    <h5 *ngIf="!geoCoord.state" class="card-subtitle">&nbsp;</h5>
                    <!-- Filter Options -->
                  </div>

                  <div class="col-sm-12 col-md-10" style="text-align: right;">
                    <div class="row">
                      <!-- <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;" /> -->

                      <div class="col-sm-12 col-md-4 form-group" style="margin-bottom: 0; text-align: left;">
                        <mat-label class="category-label" for="category-select"><i aria-hidden="true"></i>Category
                        </mat-label>
                        <mat-slide-toggle class="toggle-filter-option toggle-category" style="font-size: 20pt;"
                          color="primary" [checked]="onlyCategories" (change)="toggleCatgorySelect($event)"
                          matTooltip="Only allow categories from list.">
                        </mat-slide-toggle>

                        <div *ngIf="onlyCategories">
                          <ng-select id="category-select" appearance="outline" [items]="categories$ | async"
                            bindLabel="Category" [trackByFn]="trackByFn" [minTermLength]="minLengthTerm"
                            [loading]="categoriesLoading" typeToSearchText="Enter category here..."
                            [typeahead]="categoriesInput$" [(ngModel)]="leadCategory"
                            [ngModelOptions]="{ standalone: true }">
                          </ng-select>
                        </div>
                        <div *ngIf="!onlyCategories">
                          <input class="form-control any-category" type="text" data-ng-minlength="3"
                            placeholder="Enter category here..." id="addLeadLocation" name="addLeadLocation"
                            [(ngModel)]="leadCategory" [ngModelOptions]="{ standalone: true }">
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-5">
                        <div class="form-group" style="margin-bottom: 0; text-align: left;">

                          <mat-label for="search" class="category-label"><i aria-hidden="true"></i>Location</mat-label>

                          <input
                            [disabled]="leadCategory === null || leadCategory.trim().length < 3 || bulkList.length >= 5"
                            matInput matGoogleMapsAutocomplete autocorrect="off" autocapitalize="off" spellcheck="off"
                            type="text" id="search" name="search" #search class="form-control"
                            style="border: 1px solid #ced4da;" placeholder="Seattle, WA, USA" [(ngModel)]="leadLocation"
                            [ngModelOptions]="{ standalone: true }" (onAddressChange)="onChange($event)"
                            (onAutocompleteSelected)="onAutocompleteSelected($event)"
                            (onLocationSelected)="onLocationSelected($event)">

                          <div *ngIf="bulkSearch" style="padding-top:10px;">
                            <mat-chip-list #chipList aria-label="Bulk location search selection">
                              <mat-chip *ngFor="let bulkItem of bulkList;" [selectable]="selectable"
                                [removable]="removable" (removed)="removeBulk(bulkItem)">
                                {{ bulkItem.name }}
                                <button matChipRemove *ngIf="removable">
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip>
                            </mat-chip-list>
                          </div>

                        </div>
                      </div>

                      <div class="col-sm-12 col-md-3">

                        <div class="row"
                          style="padding: 0px; padding-left: 15px; padding-right: 15px; margin-top: -10px; margin-bottom: 3px; height: 26px;">
                          <!--
                          <button *ngIf="extraLocations.length > 0" name="addLocations" id="addLocations" type="submit"
                            mat-raised-button color="accent" style="width: 100%;" (click)="addLocations()"
                            [disabled]="addedExtraLocations">
                            Add {{ extraLocations.length }} Nearby Locations
                          </button>
                          -->
                        </div>

                        <div class="row"
                          style="padding-top: 0px; padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">

                          <button *ngIf="this.showSpinner || stillSearching" name="stopSearch" id="stopSearch"
                            type="submit" class="btn btn-danger" style="width: 100%;" (click)="stopSearch()">Stop
                          </button>

                          <button *ngIf="!this.showSpinner && !stillSearching" name="findLeads" id="findLeads"
                            type="submit" mat-raised-button color="primary" style="width: 100%;" (click)="findLeads()"
                            [disabled]="badLocation || !leadCategory || showSpinner || noSearches">Search
                          </button>


                          <!--
                            <div class="btn-group" style="text-align: left;width: 100%;margin-left: 15px;margin-right: 15px;">
                              <button name="findLeads" id="findLeads" type="submit" class="btn btn-primary" style="width: 100%;"
                                (click)="findLeads()" [disabled]="badLocation || !leadCategory || showSpinner">Search</button>
                              <button name="filterLeads" id="filterLeads" type="" class="btn btn-default" style="width: 50px;"
                                (click)="openDialog('filterLeads')" [disabled]="this.rows === [] || showSpinner"><i class="fa fa-filter"
                                  aria-hidden="true"></i></button>
                            </div>
                            -->

                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <!--
                  <div *ngIf="showSpinner" class="app-loading" style="padding-top: 40px; padding-bottom: 40px;">
                    <div class="logo"></div>
                    <svg class="spinner" viewBox="25 25 50 50">
                    <svg class="spinner" width="50" height="50" viewBox="0 0 100 100">
                      <circle class="path" cx="50" cy="50" r="40" fill="none" stroke-width="5" stroke-miterlimit="3" stroke="black" />
                    </svg>
                  </div>
                  -->

                <div style="display: block">
                  <button color="default" mat-mini-fab="" class="mat-mini-fab mat-primary">
                    <mat-icon *ngIf="!isMapVisible" class="mat-icon material-icons" role="img" aria-hidden="true"
                      (click)="toggleMap()">visibility_on</mat-icon>
                    <mat-icon *ngIf="isMapVisible" class="mat-icon material-icons" role="img" aria-hidden="true"
                      (click)="toggleMap()">visibility_off</mat-icon>
                  </button>

                  <!--
                  <div *ngIf="isAdmin()"
                    style="display: block; float: right; padding-top: 0px; padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
                    <button *ngIf="isAdmin()" name="findLeadsState" id="findLeadsState" type="submit" mat-raised-button
                      (click)="findLeads('state')"
                      [disabled]="badLocation || !leadCategory || showSpinner || stillSearching" color="primary"
                      style="width: 100%;">
                      <mat-icon style="margin-bottom: 12px; color: #3FB64B;" svgIcon="shield-account"></mat-icon> Search
                      Entire State
                    </button>
                  </div>
                  -->

                  <mat-slider aria-label="radius in miles" [ngModelOptions]="{standalone: true}"
                    (change)="onRadiusChanged" [displayWith]="formatLabel" thumbLabel tickInterval="2" min="1" max="25"
                    step="1" value="25" [(ngModel)]="searchRadiusMiles"></mat-slider> {{ searchRadiusMiles }}mi radius

                  <div *ngIf="hasResults"
                    style="display: block; float: right; padding-top: 0px; padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
                    <button name="selectAllLeads" id="selectAllLeads" type="submit" mat-raised-button color="default"
                      style="width: 100%;" (click)="selectAllLeads()" [disabled]="!hasResults || stillSearching">
                      <mat-icon style="margin-top: -15px;" svgIcon="check-all"></mat-icon>Select All
                    </button>
                  </div>

                </div>

                <div [ngStyle]="{'display': (geoCoord.lat && geoCoord.long) ? 'hidden' : 'block'}">



                  <agm-map [hidden]="!isMapVisible" [latitude]="geoCoord.lat" [longitude]="geoCoord.long"
                    (mapClick)="placeMarker($event)">

                    <agm-fullscreen-control></agm-fullscreen-control>

                    <agm-marker [latitude]="geoCoord.lat" [longitude]="geoCoord.long">

                      <agm-info-window>
                        <p>{{ leadLocation }}</p>
                      </agm-info-window>

                    </agm-marker>

                    <agm-marker *ngFor="let point of points" [latitude]="point.latitude" [longitude]="point.longitude"
                      [iconUrl]="point.icon">
                      <agm-info-window>
                        <p>{{ point.content }}</p>
                        <span>{{ point.count }}</span>
                      </agm-info-window>
                    </agm-marker>

                    <agm-circle [latitude]="geoCoord.lat" [longitude]="geoCoord.long" [radius]="searchRadius * 1000"
                      [fillOpacity]="0.50" [fillColor]="'#00A19A'"></agm-circle>

                    <agm-circle *ngFor="let searchItem of searchList" [latitude]="searchItem.geoCoord.lat"
                      [longitude]="searchItem.geoCoord.long" [radius]="searchItem.radius * 1000" [fillOpacity]="0.50"
                      [fillColor]="'#3f51b5'"></agm-circle>

                  </agm-map>

                </div>

                <div #gmap></div>
              </form>

              <!--
                <nav mat-tab-nav-bar>
                  <a mat-tab-link *ngFor="let routeLink of routeLinks; let i = index;" [routerLink]="routeLink.link" routerLinkActive
                    #rla="routerLinkActive" (click)="activeLinkIndex = i" [routerLinkActiveOptions]="{exact: true}"
                    [class]=getActiveClass(i)>
                    {{routeLink.label}}
                  </a>
                </nav>
      -->

              <div *ngIf="showNoResultsMessage" style="width: 100%">

                <div class="oopsMessage">
                  <h2>Ooohh...</h2>
                  <h3>We need just a little more time to fetch your data!</h3>
                  <p>
                    We'll email you the list when it's ready to:
                  </p>
                  <p style="font-weight: 600;">
                    {{ email }}
                  </p>
                  <p>
                    (Please note this may take an hour or two.)
                  </p>
                  <p>
                    Thanks for using Lead Carrot.
                  </p>
                </div>
              </div>

              <mat-tab-group style="width: 100%" #leadsTabGroup mat-align-tabs="start" [(selectedIndex)]="selectedTab">
                <div *ngFor="let search of searches; let last = last; let index = index;">
                  <mat-tab [label]="getLabel(search.city, index)">
                    <!-- Display the leads -->
                    <ngx-datatable [ngClass]="rows[index].length === 0 ? 'hide' : 'unhide'"
                      [sorts]="[{prop: 'email', dir: 'desc'}]" #mydatatable id="mydatatable" name="mydatatable"
                      style="padding: 3px; padding-right:0px;" class="material" [columnMode]="'force'"
                      [footerHeight]="60" [headerHeight]="70" [limit]="50" [rowHeight]="50" [rowClass]="getRowClass"
                      [rows]='filtered[index]' [scrollbarH]="false" [selected]="allSelected[index]"
                      [selectionType]="'checkbox'" [selectAllRowsOnPage]="false" (activate)="onActivate($event)"
                      (select)="onSelect($event, index)" (page)="onPage($event)">

                      <!-- Row Detail Template -->
                      <!--
                              <ngx-datatable-row-detail [rowHeight]="100" #myDetailRow (toggle)="onDetailToggle($event)">
                                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                                  <div style="padding-left:35px;">
                                    <div>
                                      <strong>Facebook Info</strong>
                                    </div>
                                      <div>url: [{{ row.facebook_url }}], rating: [{{ row.facebook_star_rating }}], reviews: [{{ row.facebook_review_count }}]</div>
                                  </div>
                                </ng-template>
                              </ngx-datatable-row-detail>
                            -->

                      <!-- Add the checkbox column -->
                      <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
                      </ngx-datatable-column>

                      <!--
                  <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                      <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row"
                        (click)="toggleExpandRow(row)">
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  -->

                      <!-- Add the profile image column -->
                      <ngx-datatable-column prop="image" name="P" [width]="35" [minWidth]="35" [sortable]="false"
                        [canAutoResize]="false" [draggable]="false" [resizeable]="false" [headerCheckboxable]="false"
                        [checkboxable]="false">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <img src="{{ value }}" style="height: 30px;">
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Business Name -->
                      <ngx-datatable-column prop="name" name="Business Name" cellClass="padding-left: 0px;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div style="margin-top: 5px;">
                            {{ value }}
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Business Email-->
                      <ngx-datatable-column prop="email" name="Email" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="value" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="{{ value }}">
                            <a href="mailto:{{ value }}" target="_blank">
                              <span style="color:	#3b5998;">
                                <mat-icon *ngIf="value" svgIcon="email-outline"></mat-icon>
                              </span>
                              <!--
                              <span style="color:	#3b5998;">
                                <i *ngIf="value" class="fa fa-envelope-o" aria-hidden="true"></i>
                              </span>
                              -->
                            </a>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Business Phone -->
                      <ngx-datatable-column prop="telephone_e164" name="Phone" [width]="1" [minWidth]="30">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div style="text-align: center; font-size: 20px;" placement="left" matTooltip="{{ value }}">
                            <a href="tel:{{ value }}" target="_blank">
                              <span style="color: green;">
                                <mat-icon *ngIf="value" svgIcon="phone"></mat-icon>
                              </span>
                              <!--
                              <span style="color: green;">
                                <i *ngIf="value" class="fa fa-phone" aria-hidden="true"></i>
                              </span>
                              -->
                            </a>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Business URL-->
                      <ngx-datatable-column prop="url" name="Biz URL" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div style="text-align: center; font-size: 20px;" placement="left" matTooltip="{{ value }}">
                            <a href="{{ value }}" target="_blank">
                              <span>
                                <mat-icon *ngIf="value" svgIcon="earth"></mat-icon>
                                <!--<i *ngIf="value" class="fa fa-globe" aria-hidden="true"></i>-->
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Website SSL -->
                      <ngx-datatable-column prop="site_data.has_ssl" name="SSL" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="value; else notShowSsl" style="text-align: center; font-size: 20px;"
                            placement="left">
                            <span style="color: forestgreen;">
                              <mat-icon svgIcon="lock"></mat-icon>
                              <!--<i class="fa fa-lock" aria-hidden="true" style="color: green;"></i>-->
                            </span>
                          </div>
                          <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>

                        <ng-template #notShowSsl>
                          <div style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Moible Friendly -->
                      <ngx-datatable-column prop="site_data.has_mobilefriendly" name="Mobile First" [width]="1"
                        [minWidth]="30" style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="Is Mobile Responsive">
                            <span *ngIf="value === true" style="color: blueviolet;">
                              <mat-icon svgIcon="cellphone"></mat-icon>
                              <!--<i class="fa fa-mobile" aria-hidden="true" style="color: blueviolet;"></i>-->
                            </span>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Google Search URL-->
                      <div style="display: none;">
                        <ngx-datatable-column prop="google_search" name="Search" [width]="1" [minWidth]="30"
                          style="text-align: center;">

                          <ng-template ngx-datatable-cell-template let-value="value">
                            <div style="text-align: center; font-size: 20px;" placement="left"
                              matTooltip="Search for business on Google">
                              <a href="{{ value }}" target="_blank">
                                <span class="color-google-plus">
                                  <mat-icon *ngIf="value" svgIcon="google"></mat-icon>
                                  <!--<i class="fa fa-google" aria-hidden="true"></i>--->
                                </span>
                              </a>
                            </div>
                          </ng-template>
                        </ngx-datatable-column>
                      </div>

                      <!-- Google Maps URL-->
                      <ngx-datatable-column prop="data_cid" name="GMB Maps URL" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template let-value="value" let-row="rowData" let-rowIndex="rowIndex"
                          ngx-datatable-cell-template>

                          <div *ngIf="value" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="GMB URL">
                            <a href="https://google.com/maps?cid={{ value }}" target="_blank">
                              <span style="color:	#c41200;">
                                <img src="/assets/images/google-maps-icon.png" style="height: 20px;">
                              </span>
                            </a>
                          </div>

                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Google Rating Value-->
                      <ngx-datatable-column prop="gmb_data.gmb_aggregateRating.ratingValue" name="GMB Rating"
                        [width]="1" [minWidth]="30" style="text-align: center;">
                        <ng-template let-value="value" let-row="rowData" let-rowIndex="rowIndex"
                          ngx-datatable-cell-template>

                          <div *ngIf="value" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="GMB Rating Value">{{ value }}<i class="fa fa-star" aria-hidden="true"></i>
                          </div>

                        </ng-template>
                      </ngx-datatable-column>


                      <!-- Google Review Count -->
                      <ngx-datatable-column prop="gmb_data.gmb_aggregateRating.ratingCount" name="GMB Reviews"
                        [width]="1" [minWidth]="30" style="text-align: center;">
                        <ng-template let-value="value" let-row="rowData" let-rowIndex="rowIndex"
                          ngx-datatable-cell-template>

                          <div *ngIf="value" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="GMB Reviews">
                            {{ value }}
                          </div>

                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Facebook URL-->
                      <!--
                            <ngx-datatable-column prop="facebook_url" name="FB URL" [width]="1" [minWidth]="30" style="text-align: center;">
                              <ng-template ngx-datatable-cell-template let-value="value">
                                <div *ngIf="value" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Has Facebook Page">
                                  <a href="{{ value }}" target="_blank">
                                    <span style="color:	#3b5998;">
                                      <i class="fa fa-facebook" aria-hidden="true"></i>
                                    </span>
                                  </a>
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                            -->

                      <!-- FB Stars -->
                      <!--
                              <ngx-datatable-column prop="facebook_star_rating" name="FB Rating" [width]="1" [minWidth]="40">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                  <div style="text-align: center;">
                                    {{ value }}
                                  </div>
                                </ng-template>
                              </ngx-datatable-column>
                              -->

                      <!-- FB Reviews -->
                      <!--
                              <ngx-datatable-column prop="facebook_review_count" name="FB Reviews" [width]="1" [minWidth]="40">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                  <div style="text-align: center;">
                                    {{ value }}
                                  </div>
                                </ng-template>
                              </ngx-datatable-column>
                              -->

                      <!-- Yelp! URL-->
                      <ngx-datatable-column prop="yelp_data.yelp_url" name="Yelp! URL" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="value" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="{{ value }}">
                            <a href="{{ value }}" target="_blank">
                              <span style="color:	#c41200;">
                                <mat-icon *ngIf="value" svgIcon="yelp"></mat-icon>
                                <!--<i class="fa fa-yelp" aria-hidden="true"></i>-->
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Yelp! Stars -->
                      <!--
                            <ngx-datatable-column prop="yelp_data.yelp_aggregateRating.ratingValue" name="Y! Rating" [width]="1" [minWidth]="30">
                              <ng-template ngx-datatable-cell-template let-value="value">
                                <div style="text-align: center;">
                                  {{ value }}
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                            -->

                      <!-- Yelp! Reviews -->
                      <!--
                            <ngx-datatable-column prop="yelp_data.yelp_aggregateRating.reviewCount" name="Y! Reviews" [width]="1" [minWidth]="35">
                              <ng-template ngx-datatable-cell-template let-value="value">
                                <div style="text-align: center;">
                                  {{ value }}
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                            -->

                      <!-- Business Is Claimed-->
                      <!--
                            <ngx-datatable-column prop="yelp_biz_claim_status" name="Y! Claimed" [width]="1" [minWidth]="30" style="text-align: center;">
                              <ng-template ngx-datatable-cell-template let-value="value">
                                <div *ngIf="value === true" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Business Claimed on Yelp!">
                                  <a href="https://www.google.com/search?q={{ value }}" target="_blank">
                                    <span style="color:	green;">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                  </a>
                                </div>
                                <div *ngIf="value === ''" style="text-align: center; font-size: 20px;" placement="left">
                                </div>
                                <div *ngIf="value !== true && value !== ''" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Link to 'Claim Your Biz'">
                                  <a href="{{ value }}" target="_blank">
                                    <span style="color:	orange;">
                                      <i class="fa fa-ticket" aria-hidden="true"></i>
                                    </span>
                                  </a>
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                            -->

                      <!-- Business Has Video -->
                      <ngx-datatable-column prop="yelp_data.yelp_hasVideo" name="Y! Video" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="value === true" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="Uses Yelp! Video">
                            <span>
                              <mat-icon svgIcon="video"></mat-icon>
                              <!--<i class="fa fa-film" aria-hidden="true"></i>-->
                            </span>
                          </div>
                          <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- ////////////////////////////////////// -->

                      <!-- Facebook -->
                      <ngx-datatable-column prop="fb_data" name="FB" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="hasFb(value); else notShowFb" style="text-align: center; font-size: 20px;"
                            placement="left" matTooltip="Has Facebook">
                            <a *ngIf="value.fb_hasAds" href="{{ value.fb_url }}/ads/?ref=page_internal" target="_blank">
                              <span class="color-facebook">
                                <!-- <span style="color: goldenrod"> -->
                                <i class="fa fa-facebook" aria-hidden="true"></i>
                              </span>
                            </a>
                            <a *ngIf="!value.fb_hasAds" href="{{ value.fb_url }}" target="_blank">
                              <span class="color-facebook">
                                <mat-icon svgIcon="facebook"></mat-icon>
                                <!--<i class="fa fa-facebook" aria-hidden="true"></i>-->
                              </span>
                            </a>
                          </div>
                        </ng-template>

                        <ng-template #notShowFb>
                          <div style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>

                      </ngx-datatable-column>


                      <!-- Facebook Pixel -->
                      <ngx-datatable-column prop="site_data.has_fb_pixel" name="FB Pixel" [width]="1" [minWidth]="45"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="value; else notShowFbPixel" style="text-align: center; font-size: 20px;"
                            placement="left" matTooltip="Has Facebook Pixel">
                            <span class="color-facebook">
                              <!-- <span style="color: goldenrod"> -->
                              <mat-icon svgIcon="xml"></mat-icon>
                              <!--<i class="fa fa-code" aria-hidden="true"></i>-->
                            </span>
                          </div>
                        </ng-template>

                        <ng-template #notShowFbPixel>
                          <div style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>
                      </ngx-datatable-column>


                      <!--
                        <ngx-datatable-column prop="site_data.has_fb_pixel" name="FB Pixel" [width]="1" [minWidth]="30"
                          style="text-align: center;">
                          <ng-template ngx-datatable-cell-template let-value="value">
                            <div *ngIf="value === true" style="text-align: center; font-size: 20px;" placement="left"
                              matTooltip="Has Facebook Pixel">
                              <span>
                                <i class="fa fa-film" aria-hidden="true"></i>
                              </span>
                            </div>
                            <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                            </div>
                          </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column prop="has_fb_pixel" name="FB" [width]="1" [minWidth]="30" style="text-align: center;">
                          <ng-template ngx-datatable-cell-template let-value="value">
                            <div *ngIf="doesExist(value, 0)" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Has Facebook">
                              <a href="{{ value[0] }}" target="_blank">
                                <span class="color-facebook">
                                  <i class="fa fa-facebook" aria-hidden="true"></i>
                                </span>
                              </a>
                            </div>
                          </ng-template>
                        </ngx-datatable-column>
                        -->

                      <!-- Flickr -->
                      <!--
                              <ngx-datatable-column prop="flickr_url" name="Flickr" [width]="1" [minWidth]="30" style="text-align: center;">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                  <div *ngIf="value !== ''" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Has Flickr">
                                    <a href="{{ value }}" target="_blank">
                                      <span class="color-flickr">
                                        <i class="fa fa-flickr" aria-hidden="true"></i>
                                      </span>
                                    </a>
                                  </div>
                                  <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                                  </div>
                                </ng-template>
                              </ngx-datatable-column>
                              -->

                      <!-- Google + -->
                      <!--
                              <ngx-datatable-column prop="social.google_plus" name="G+" [width]="1" [minWidth]="30" style="text-align: center;">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                  <div *ngIf="doesExist(value, 0)" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Has Google+">
                                    <a href="{{ value[0] }}" target="_blank">
                                      <span class="color-google-plus">
                                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                                      </span>
                                    </a>
                                  </div>
                                  <div *ngIf="value === false" style="text-align: center; font-size: 20px;" placement="left">
                                  </div>
                                </ng-template>
                              </ngx-datatable-column>
                              -->

                      <!-- Instagram -->
                      <ngx-datatable-column prop="social.instagram" name="Instagram" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="doesExist(value, 0)" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="Has Instagram">
                            <a href="{{ value[0] }}" target="_blank">
                              <span class="color-instagram">
                                <mat-icon svgIcon="instagram"></mat-icon>
                                <!--<i class="fa fa-instagram" aria-hidden="true"></i>-->
                              </span>
                            </a>
                          </div>
                          <div *ngIf="value[0] === false" style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- LinkedIn -->
                      <ngx-datatable-column prop="social.linkedin" name="LI" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="doesExist(value, 0)" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="Has LinkedIn">
                            <a href="{{ value[0] }}" target="_blank">
                              <span class="color-linkedin">
                                <mat-icon svgIcon="linkedin"></mat-icon>
                                <!--<i class="fa fa-linkedin" aria-hidden="true"></i>-->
                              </span>
                            </a>
                          </div>
                          <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Pinterest -->
                      <!--
                              <ngx-datatable-column prop="social.pinterest" name="Pinterest" [width]="1" [minWidth]="30" style="text-align: center;">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                  <div *ngIf="doesExist(value, 0)" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Has Pinterest">
                                    <a href="{{ value[0] }}" target="_blank">
                                      <span class="color-pinterest">
                                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                                      </span>
                                    </a>
                                  </div>
                                  <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                                  </div>
                                </ng-template>
                              </ngx-datatable-column>
                              -->

                      <!-- Twitter -->
                      <ngx-datatable-column prop="social.twitter" name="Twitter" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="doesExist(value, 0)" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="Has Twitter">
                            <a href="{{ value[0] }}" target="_blank">
                              <span class="color=twitter">
                                <mat-icon svgIcon="twitter"></mat-icon>
                                <!--<i class="fa fa-twitter" aria-hidden="true"></i>-->
                              </span>
                            </a>
                          </div>
                          <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Youtube -->
                      <ngx-datatable-column prop="social.youtube" name="YT" [width]="1" [minWidth]="30"
                        style="text-align: center;">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div *ngIf="doesExist(value, 0)" style="text-align: center; font-size: 20px;" placement="left"
                            matTooltip="Has Youtube">
                            <a href="{{ value[0] }}" target="_blank">
                              <span class="color-youtube">
                                <mat-icon svgIcon="youtube"></mat-icon>
                                <!--<i class="fa fa-youtube" aria-hidden="true"></i>-->
                              </span>
                            </a>
                          </div>
                          <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Contact Us -->
                      <!--
                              <ngx-datatable-column prop="contact_us_url" name="Contact Us" [width]="1" [minWidth]="30" style="text-align: center;">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                  <div *ngIf="value !== ''" style="text-align: center; font-size: 20px;" placement="left" matTooltip="Has Contact Us">
                                    <a href="{{ value }}" target="_blank">
                                      <span>
                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                      </span>
                                    </a>
                                  </div>
                                  <div *ngIf="value !== true" style="text-align: center; font-size: 20px;" placement="left">
                                  </div>
                                </ng-template>
                              </ngx-datatable-column>
                              -->

                      <!-- Business Address-->
                      <ngx-datatable-column prop="display_address" name="Address" [width]="1" [minWidth]="30">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          <div style="text-align: center; font-size: 20px;" placement="left" matTooltip="{{ value }}">
                            <span style="color: red;">
                              <mat-icon svgIcon="map-marker"></mat-icon>
                              <!--<i *ngIf="value" class="fa fa-map-marker" aria-hidden="true"></i>-->
                            </span>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Business City-->
                      <ngx-datatable-column prop="address.addressLocality" name="City">
                        <ng-template ngx-datatable-cell-template let-value="value">
                          {{ value }}
                        </ng-template>
                      </ngx-datatable-column>

                      <!-- Business Postal-->
                      <!--
                              <ngx-datatable-column prop="yelp_postal" name="Postal" [width]="1">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                  {{ value }}
                                </ng-template>
                              </ngx-datatable-column>
                            -->

                    </ngx-datatable>

                  </mat-tab>
                </div>
                <!--
                  <mat-tab label="Second">Content 2</mat-tab>
                  <mat-tab label="Third">Content 3</mat-tab>
                  -->
              </mat-tab-group>

            </div>
            <!-- END card-body -->
          </div>
        </perfect-scrollbar>

      </div>
      <!-- END card -->

    </div>
    <!-- END column -->
  </div>
  <br />
  <br />
  <!-- END row -->


  <!--
<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12">
    <ul *ngFor="let category of all_categories_lookup">
      <li>{{ category }}</li>
    </ul>
  </div>
</div>
-->
</div>

<div *ngIf="!isLoggedIn">
  <div class="row">
    <div class="col-12">

      <div style="margin: 0 auto; width: 400px; text-align: center;">
        <div class="card">

          <div class="card-body">
            <div>
              {{ message }}
            </div>
            <div *ngIf="showPluginHelp"><a href="https://plugin.leadcarrot.io">Update your Lead Carrot Plugin Connection
                Here</a></div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
