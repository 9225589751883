import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormControl, FormBuilder, FormGroup, Validator, Validators } from '@angular/forms';
import { IAddTag, IGetTag } from '../models';
// import { ContactService } from '../../services/contact/contact.service';
// import { SmsService } from '../../broadcast/sms/sms.service';
import { SessionService } from '../services/session/session.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// --------- BEGIN Material Auto compelete -----------------
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
// import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subscription } from 'rxjs';
// import { map, startWith } from 'rxjs/operators';
// --------- END Material Auto compelete -------------------

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.css'],
})
export class AddTagComponent implements OnInit, OnDestroy {
  label: string;
  public config: PerfectScrollbarConfigInterface = {};
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  allSubscription = new Subscription();
  filteredTags: Observable<IGetTag[]>;
  selectedTag: IGetTag[] = [];
  allTags: IGetTag[] = [];
  tags: FormGroup;
  cancelText: string;
  tagsReady = false;
  tagsMatched = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    // private ps: PeopleService,
    public dialogueRef: MatDialogRef<AddTagComponent>,
    // private sms: SmsService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
  ) {
    this.cancelText = 'Cancel';
  }

  ngOnInit() {
    console.log(this.data.persons);
    this.tags = this.fb.group({
      tagTitle: new FormControl(''),
    });

    // this.allSubscription.add(
    //   this.sms.getAllTags().subscribe(tag => {
    //     console.log(tag);
    //     tag.response.map(data => {
    //       if(!data.id.startsWith('p_')){
    //         this.allTags.push(data);
    //         console.log(data.label);
    //       }
    //     });
    //    // this.allTags = tag.response;
    //     this.tags.addControl('tagList', this.buildTags());
    //     this.tagsReady = true;
    //   })
    // );
    // this.filteredTags  = this.tags.get('tagTitle').valueChanges.pipe(
    //     startWith(''),
    //     map(tags => this._validate(tags)));

    this.allTags = this.data.allTags;
    this.tagsReady = true;
    this.tags.addControl('tagList', this.buildTags());
  }

  _validate(tags: string) {
    console.log(tags, 'sas');
    // this.tags.get('tagList').
  }

  ngOnDestroy() {
    this.allSubscription.unsubscribe();
  }

  buildTags() {
    const tagsArray = this.allTags.map(tag => {
      return this.fb.control(false);
    });
    return this.fb.array(tagsArray);
  }

  buildPersons() {
    const personArray = this.data.persons.map(per => {
      return this.fb.control(false);
    });
    return this.fb.array(personArray);
  }

  get person() {
    return this.tags.get('contacts');
  }

  get TotalTags() {
    return this.tags.get('tagList');
  }

  saveTag(tags) {
    const contactIds = [];
    const existingTags = [];

    console.log('saveTag() | tags =', tags);

    const formValue = Object.assign({}, tags, {
      tagList: tags.tagList.map((selected, i) => {
        return {
          id: this.allTags[i].id,
          selected
        };
      })
    });

    console.log(formValue);

    this.data.persons.map(id => {
      contactIds.push(id.id);
    });
    formValue.tagList.map(ids => {
      if (ids.selected) {
        existingTags.push(ids.id);
      }
    });
    const tagName = formValue.tagTitle;

    const tag: IAddTag = {
      existingTags,
      contactIds,
      name: tagName,
    };

    console.warn('tag:', tag);
    console.warn('TODO: update AddTag()');

    /*
    this.allSubscription.add(
    this.ps.addTag(tag)
      .subscribe(res => {
        this.openSnackBar('Tag created successfully', 'close');
        this.dialogueRef.close();
      })
    );
    */
    this.dialogueRef.close();
  }

  // isAllowed(tags): boolean {
  //   console.log('isAllowed() | tags =', tags);

  //   const formValue = Object.assign({}, tags, {
  //     tagList: tags.persons.map((selected, i) => {
  //       return {
  //         id: this.data.persons[i].id,
  //         selected
  //       };
  //     })
  //   });

  //   formValue.tagList.map(ids => {
  //     if (ids.selected) {
  //       return true;
  //     }
  //   });
  //   return false;
  // }

  public _filter($event) {
    const VALUE = $event.target.value;
    const INDEX = this.allTags.findIndex(tag => tag.label === VALUE);
    this.tagsMatched = INDEX === -1 ? false : true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log(event.option.value);
    this.label = event.option.value;
    this.tags.get('tagTitle').setValue(event.option.value);
  }

  hey() {
    this.tags.get('tagTitle').setValue('event.option.value');
  }

  remove(tag: string): void {
    this.label = '';
    this.tags.get('tagTitle').setValue('');
  }

  closeDialog() {
    this.dialogueRef.close();
  }

  allowedTocreate(): boolean {
    if (this.data.persons) {
      if (this.data.persons.length > 0) {
        if ((this.tags.get('tagTitle').value === '')) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000
    });
  }

}
