import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { Md5 } from 'ts-md5';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';

import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

// Phone input with flags
import { Country } from '@angular-material-extensions/select-country';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

import { IApiResponse, IGoogleAccount, IUserUpload, IUser } from '../models';
import { environment } from '../../environments/environment';
import { createCloudHeaders, isUndefinedOrNullOrEmpty, resetForm } from '../shared/utils';

// Services
import { AuthService } from '../services/auth/auth.service';
import { SessionService } from '../services/session/session.service';
import { UserService } from '../services/user/user.service';

const matchPasswords: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('newPassword');
  const passwordRepeat = control.get('newPasswordRepeat');
  return password && passwordRepeat && password.value === passwordRepeat.value ? null : { noMatch: true };
};

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  // BEGIN - Perfect Scrollbar
  public type = 'directive';
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollY: true,
  };

  @ViewChild('userPS', { static: false }) userPS: PerfectScrollbarComponent;
  @ViewChild('ref', { static: true }) ref;

  apiLeadCarrot = environment.cloudUrl + '/userGetTeamMembers';
  allSubscription = new Subscription();

  // =[=====================]
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Canada, CountryISO.UnitedKingdom];
  // =[=====================]


  passwordForm: FormGroup;
  userGroup: FormGroup;
  gravatarUrl: any;
  // Main task
  task: AngularFireUploadTask;
  // Download URL
  downloadURL: Observable<string>;
  currentUrl: string = null;
  isUploading = false;
  isUploadPanelVisible: boolean;
  isFileSelected: boolean;
  selectedFileToUpload: File;
  imageUrl: string;
  user: IUser;
  userId: string;
  googleAccounts$: Observable<any>;
  googleAccounts: IGoogleAccount[] = [];
  googleAccountsCount: number;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private userService: UserService,
    private http: HttpClient,
    private ngxLoader: NgxUiLoaderService,
    private sessionService: SessionService,
    public snackBar: MatSnackBar,
    private storage: AngularFireStorage,
  ) {
    this.isFileSelected = false;
    this.isUploadPanelVisible = false;
    this.selectedFileToUpload = null;
    this.userId = '';
  }

  ngOnInit() {
    this.passwordForm = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      newPasswordRepeat: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ])
    }, {
      validators: matchPasswords
    });

    this.userGroup = new FormGroup({
      photoURL: new FormControl(''),
      displayName: new FormControl('', [Validators.required]),
      countryCode: new FormControl(''),
      phoneNumber: new FormControl('', [Validators.required]),
      promoCode: new FormControl(''),
      optinMarketing: new FormControl(''),
      userEmail: new FormControl(''),
    });

    this.allSubscription.add(
      this.authService.user
        .subscribe(user => {
          this.user = user;

          if (isUndefinedOrNullOrEmpty(user)) {
            this.userId = '';
            this.imageUrl = '';
            this.userGroup.get('displayName').setValue('');
            this.userGroup.get('userEmail').setValue('');
            this.userGroup.get('countryCode').setValue('');
            this.userGroup.get('phoneNumber').setValue('');
          } else {
            this.userId = user.id;
            this.imageUrl = user.photoURL;
            this.userGroup.get('displayName').setValue(user.name);
            this.userGroup.get('userEmail').setValue(user.email);
            this.userGroup.get('countryCode').setValue(user.countryCode);
            this.userGroup.get('phoneNumber').setValue(user.phone);
          }
        })
    );
  }

  ngOnDestroy() {
    this.allSubscription.unsubscribe();
  }

  get newPassword() { return this.passwordForm.get('newPassword'); }

  get newPasswordRepeat() { return this.passwordForm.get('newPasswordRepeat'); }

  updateProfile() {
    this.ngxLoader.start();

    const userId = this.user.id;
    const displayName = this.userGroup.get('displayName').value.trim();
    const phoneNumber = this.userGroup.get('phoneNumber').value.e164Number;
    const countryCode = this.userGroup.get('phoneNumber').value.countryCode.toLowerCase();
    const photoURL = this.imageUrl;
    const updateData = {
      displayName,
      phoneNumber,
      countryCode,
      photoURL,
    };

    this.userService.update(userId, updateData)
      .then(() => {
        this.ngxLoader.stop();
        this.openSnackBar('Profile updated.', 'close');
      })
      .catch((error: any) => {
        this.ngxLoader.stop();
        this.openSnackBar('Unable to update profile.', 'close');
        console.error(error);
      });
  }

  changePassword() {
    this.ngxLoader.start();

    const userId = this.user.id;
    const password = this.passwordForm.get('newPassword').value;

    this.allSubscription.add(
      this.userService.updatePassword(userId, password)
        .subscribe(() => {
          this.ngxLoader.stop();
          this.openSnackBar('Password changed successfully', 'close');
          resetForm(this.passwordForm);
        }, err => {
          this.ngxLoader.stop();
          this.openSnackBar('Unable to change password', 'close');
          resetForm(this.passwordForm);
        })
    );
  }

  emailContainsGmail() {
    if (typeof this.user !== undefined) {
      if (this.user.email.toLowerCase().includes('gmail')) {
        return true;
      }
    }
    return false;
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.UnitedStates, CountryISO.Canada, CountryISO.UnitedKingdom];
  }

  onCountrySelected($event: Country) {
    console.log($event);
  }

  onFileSelected(event: any) {
    console.log(event);
    this.selectedFileToUpload = (event.target.files[0] as File);
    this.isFileSelected = true;
  }

  onFileUpload() {
    this.ngxLoader.start();

    const formData = new FormData();
    formData.append('image', this.selectedFileToUpload, this.selectedFileToUpload.name);

    try {
      const apiLeadCarrot = environment.cloudUrl + '/apiUploadFile';
      const headers = createCloudHeaders(this.sessionService.userToken, null);

      return this.http.post<IApiResponse>(
        apiLeadCarrot,
        formData,
        {
          headers,
          withCredentials: true
        }
      )
        .subscribe(value => {
          console.log(value);
          this.ngxLoader.stop();
          this.openSnackBar('File uploaded successfully', 'close');
        }, err => {
          console.error(err);
          this.ngxLoader.stop();
          this.openSnackBar('Unable to upload file', 'close');
        });
    } catch (error) {
      console.error(error);
      this.ngxLoader.stop();
      this.openSnackBar('Unable to upload file', 'close');
    }

  }

  onScrollMessagesEvent(event: any): void {
    // console.log(event);
    if (event.type === 'ps-y-reach-end') {
      // this.scrollToBottomGroups();
      // this.eventReachEndSubject.next(event.type);
    }
  }

  toggleUploadPanel() {
    this.isUploadPanelVisible = !this.isUploadPanelVisible;
  }

  // Profile Picture Update
  onProfilePicEdit(event: FileList) {
    this.isUploading = true;

    const file = event.item(0); // The File object

    // Client-side validation example
    if (file.name.split('.')[1] === 'jpg' || file.name.split('.')[1] === 'jpeg' || file.name.split('.')[1] === 'png') {

      // The storage path
      const path = 'ProfilePicture' + '/' + this.user.id + '/' + new Date().getTime() + '_' + file.name;

      // Totally optional metadata
      const customMetadata = { app: 'Upload File !' };

      // The main task
      this.task = this.storage.upload(path, file, { customMetadata });
      this.task.then(res => {
        console.log('done', res);
        const fileRef = this.storage.ref(path);
        // The file's download URL
        this.downloadURL = fileRef.getDownloadURL();

        this.allSubscription.add(
          this.downloadURL.subscribe(url => {
            this.isUploading = false;
            console.log('Url Image', url);
            this.imageUrl = url;
          })
        );
      });

    } else {
      this.openSnackBar('Error: unsupported file type', 'close');
      return;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  isDisabled(): boolean {
    const hasErrors = !this.passwordForm.valid
      && !this.passwordForm.touched
      && !this.passwordForm.dirty
      && !(this.passwordForm.get('newPassword').touched && this.passwordForm.get('newPassword').dirty)
      && !(this.passwordForm.get('newPasswordRepeat').touched && this.passwordForm.get('newPasswordRepeat').dirty);

    if (this.passwordForm.errors) {
      return true;
    } else {
      return hasErrors;
    }
  }


  async toggleEnabled(id: string) {
    try {
      const index = this.googleAccounts.findIndex(item => item.id === id);
      this.googleAccounts[index].isEnabled = !this.googleAccounts[index].isEnabled;

      console.log('id:', id);
      console.log('index:', index);

      return await this.afs.firestore
        .collection('User')
        .doc(this.userId)
        .collection('google')
        .doc('id')
        .set({ isEnabled: this.googleAccounts[index].isEnabled }, { merge: true });

    } catch (error) {
      console.log(error);
      return Promise.resolve();
    }
  }
}

/*
  signInAuth(providerName: string = 'google') {
    if ('google' === providerName) {
      return this.authService.googleLogin()
        .then(response => {
          if (isUndefinedOrNullOrEmpty(response) || isUndefinedOrNullOrEmpty(response.user)) {
            const message = 'No Lead Carrot account exists for this email.';
            this.openSnackBar(message, 'close');
          }
          response.user.getIdToken()
            .then((token) => {
              console.log('old token:', this.sessionService.userToken);
              console.log('new token:', token);
              const message = 'Linked Google Account to Lead Carrot.';
              this.openSnackBar(message, 'close');
            });
        })
        .catch((error: any) => {
          console.error('error', error);
          console.warn('error.code:', error.code);
          let message = error.message; // 'Invalid email or password';
          if (error.code === 'auth/too-many-requests') {
            message = error.message;
          }
          this.openSnackBar(message, 'close');
        });
    }
  }
  */


/**
 * Create an OAuth2 client with the given credentials, and then execute the
 * given callback function.
 * // @param {Object} credentials The authorization client credentials.
 * // @param {function} callback The callback to call with the authorized client.
 */
/*
authorize(credentials, callback) {
  const { client_secret, client_id, redirect_uris } = credentials.installed;
  const oAuth2Client = new google.auth.OAuth2(
    client_id, client_secret, redirect_uris[0]);
  // Check if we have previously stored a token.
  fs.readFile(TOKEN_PATH, (err, token) => {
    if (err) {
      return this.getNewToken(oAuth2Client, callback);
    }
    oAuth2Client.setCredentials(JSON.parse(token));
    callback(oAuth2Client);
  });
}
*/

/**
 * Get and store new token after prompting for user authorization, and then
 * execute the given callback with the authorized OAuth2 client.
 * // @param {google.auth.OAuth2} oAuth2Client The OAuth2 client to get token for.
 * // @param {getEventsCallback} callback The callback for the authorized client.
 */
/*
getNewToken(oAuth2Client, callback) {
  const authUrl = oAuth2Client.generateAuthUrl({
    access_type: 'offline',
    scope: SCOPES,
  });
  console.log('Authorize this app by visiting this url:', authUrl);
  const rl = readline.createInterface({
    input: process.stdin,
    output: process.stdout,
  });
  rl.question('Enter the code from that page here: ', (code) => {
    rl.close();
    oAuth2Client.getToken(code, (err, token) => {
      if (err) {
        return console.error('Error retrieving access token', err);
      }
      oAuth2Client.setCredentials(token);
      // Store the token to disk for later program executions
      fs.writeFile(TOKEN_PATH, JSON.stringify(token), (err) => {
        if (err) return console.error(err);
        console.log('Token stored to', TOKEN_PATH);
      });
      callback(oAuth2Client);
    });
  });
}
*/

/**
 * Lists the labels in the user's account.
 *
 * // @param {google.auth.OAuth2} auth An authorized OAuth2 client.
 */
/*
listLabels(auth: any) {
  const gmail = google.gmail({ version: 'v1', auth });
  gmail.users.labels.list({
    userId: 'me',
  }, (err, res) => {
    if (err) {
      return console.log('The API returned an error: ' + err);
    }
    const labels = res.data.labels;
    if (labels.length) {
      console.log('Labels:');
      labels.forEach((label) => {
        console.log(`- ${ label.name }`);
      });
    } else {
      console.log('No labels found.');
    }
  });
}
*/

/*
  this.userGroup.get('country').valueChanges
    .subscribe(country => console
      .log('this.countryFormGroup.get("country").valueChanges', country));

  this.countryFormControl.valueChanges
    .subscribe(country => console
      .log('this.countryFormControl.valueChanges', country));

  countries: any[] = [
    { viewValue: '', value: '' },
    { viewValue: 'Canada', value: 'ca' },
    { viewValue: 'United State Of America', value: 'us' },
    { viewValue: 'United Kingdom', value: 'uk' },
    { viewValue: 'Pakistan', value: 'pk' },
    { viewValue: 'SouthAfrica', value: 'sa' },
    { viewValue: 'India', value: 'ind' },
    { viewValue: 'Saudia Arabia', value: 'sa' },
    { viewValue: 'Banglades', value: 'ban' },
    { viewValue: 'Srilanka', value: 'sl' },
    { viewValue: 'Turkey', value: 'tur' },
    { viewValue: 'Australia', value: 'aus' },
    { viewValue: 'China', value: 'ch' },
    { viewValue: 'Korea ', value: 'kr' },
  ];
  countryFormControl = new FormControl();
  countryFormGroup: FormGroup;
*/

/*
verifyNumber() {
// this will show the validation at console  (pending)
const number = this.userGroup.get('phoneNumber');
try {
  const phoneNumber = parsePhoneNumber(number.value, 'US');
  // tslint:disable-next-line:no-unused-expression
  phoneNumber.country === 'US';
  // tslint:disable-next-line:no-unused-expression
  phoneNumber.isValid() === true;
  const format = phoneNumber.format('E.164');
  number.setValue(format);
} catch (error) {
  number.setValue('');
}
}
*/

