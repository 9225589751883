import { Component, Input, OnInit, OnDestroy } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
// import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { AuthService } from '../services/auth/auth.service';
import { ICompany, IUser } from '../models';
// import { isUndefinedOrNull } from '../shared/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  isLoggedIn: boolean;
  user: IUser;
  company: ICompany;

  constructor(
    private authService: AuthService,
    // private route: ActivatedRoute,
    // private router: Router,
  ) {
    this.isLoggedIn = false;

    this.subs.add(
      this.authService.user
        .subscribe(user => {
          this.user = user;
          if (user) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = false;
          }
        })
    );

    this.subs.add(
      this.authService.company
        .subscribe(company => {
          this.company = company;
        })
    );

  }

  ngOnInit(): void {
    this.subs.add(
      this.authService.afAuth.authState
        .subscribe(user => {
          if (user) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = false;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  sidenavToggle() {

  }

  logout() {
    this.authService.signOut();
  }
}
