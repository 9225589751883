import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';

import {
  BehaviorSubject,
  combineLatest,
  of,
  Observable,
  Subscription,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { IArea, ILead, User } from '../../models';
import {
  ACTION,
  COLLECTION,
  createCloudHeaders,
  daysBetween,
  isSearchOptionEnabled,
  isUndefinedOrNull,
  isUndefinedOrNullOrEmpty,
} from '../../shared/utils';
import * as geoUtils from '../../shared/utils-geopoint';

import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { SessionService } from '../session/session.service';

const countries = require('country-list');
const { v4: uuidv4 } = require('uuid');

// Init Firebase
import * as firebase from 'firebase/app';
firebase.initializeApp(environment.firebase); // firebase.initializeApp(yourConfig);

// Init GeoFireX
import * as geofirex from 'geofirex-af';
import { timeStamp } from 'console';

const geofire = require('geofire-common');
// const app = firebase.initializeApp(environment.firebase);
const db = firebase.firestore();

/* globally accessible app code (in every feature module) */
// import { TaskService } from '../task/task.service.old';

const COL_LEADS = 'leads_v2';
const COL_PAUSED = 'paused';
const IS_DEBUG = !environment.production;
const TASK_ORIGIN = 'leadcarrot';
const MAX_DAYS_SSL_CHECK = 30;
const MAX_DAYS_FB_PIXEL_CHECK = 30;
// const MAX_DAYS_EMAIL_CHECK = 3;

export interface IApiResponse {
  success: boolean;
  message?: string;
  error?: any;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  // Initialize geofirex by passing a reference to the Firebase App.
  geo = geofirex.init(firebase);

  END_POINT: string;
  userToken: string;

  anyCategory: Observable<ILead[]>;
  leadsCollection: AngularFirestoreCollection<ILead>;
  leadDoc: AngularFirestoreDocument<ILead>;
  leads: Observable<ILead[]>;
  lead: Observable<ILead>;

  public isWaiting: boolean;

  debug = false;

  private COLLECTION_LEADS = 'leads';
  private enableSoftDelete = false;
  private nicheId: string;
  userEmail: string;
  user: User;

  // TODO: Load these from the database not hard-coded here.
  //       This will let us create and use custom category lists for user in the future.

  categoryFilters = [];

  points: Observable<any>;

  leadsToUpdate: string[] = [];
  leadsToUpdateEmail: string[] = [];
  leadsToUpdatePixel: string[] = [];
  leadsToUpdateSsl: string[] = [];

  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    private http: HttpClient,
    private sessionService: SessionService,
    public userService: UserService // public taskService: TaskService,
  ) {
    if (!IS_DEBUG) {
      this.END_POINT = environment.backendApiUrl;
    } else {
      this.END_POINT = environment.backendApiDebugUrl;
    }

    this.geo = geofirex.init(firebase);

    // console.log('INIT LeadService.constructor()');
    this.resetService();

    this.authService.user.subscribe((user) => {
      // console.log( 'LeadService.constructor() -> this.authService.getAuth().subscribe()' );

      if (user) {
        this.userToken = user.token;

        // console.log('LeadService.constructor() - AUTHORIZED'); // | + auth.uid + '  | (auth) == ', auth);
        this.userEmail = user.email;
        this.leadsCollection = this.afs.collection(
          this.COLLECTION_LEADS,
          (ref) => {
            return ref.where('biz_name', '==', '');
          }
        );
      } else {
        this.resetService();
        // console.log('LeadService.constructor() - NOT AUTHORIZED');
      }
    });
  }

  async resetService(options: any = null) {
    // console.log('*** LeadService.resetService() ***');

    this.leadsToUpdate = [];
    this.leadsToUpdatePixel = [];
    this.leadsToUpdateSsl = [];
    this.user = null; // This comes from UserService
    this.nicheId = '';
    this.userEmail = '';
    this.leadsCollection = null;
    // this.taskService.resetService();

    // this.taskService.isWaiting.subscribe(value => {
    //   this.isWaiting = value;
    // });

    if (!isUndefinedOrNullOrEmpty(options)) {
      return await this.removeTempLeads(options);
    } else {
      return Promise.resolve(true);
    }
  }

  // Trigger ScaleSerp Search
  async removeTempLeads(options: any = null): Promise<any> {
    if (isUndefinedOrNullOrEmpty(options)) {
      return Promise.resolve(true);
    }

    // console.log('options:', options);

    const COLLECTION_NAME = 'scaleserp_leads';
    return await firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .where('category', '==', options.category)
      .where('address.addressLocality', '==', options.locality)
      .where('address.addressRegion', '==', options.region)
      // .where('country_alpha_2', '==', options.country)
      .get()
      .then(async (snapshots) => {
        if (snapshots.empty) {
          // console.log('no TEMP leads found');
          return false;
        }
        await snapshots.forEach((snapshot) => {
          if (snapshot.exists) {
            // console.log('snapshot:', snapshot);
            firebase
              .firestore()
              .collection(COLLECTION_NAME)
              .doc(snapshot.id)
              .delete();
          }
        });
        // console.log('ALL DONE!');
      })
      .catch((error) => error);
  }

  get(id: string): Observable<ILead> {
    // ToDo: Add restriction for Contacts you own or have Visibility to.

    this.leadDoc = this.afs.doc<ILead>(`yelp_leads/${id}`);
    this.lead = this.leadDoc.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as ILead;
          data.id = action.payload.id;
          return data;
        }
      })
    );

    return this.lead;
  }

  stop() {
    // this.taskService.setWaitStatus(false);
    this.leadsCollection = null;
    this.points = null;
    this.geo = geofirex.init(firebase);
  }

  resetFoundLeads() {
    this.leadsToUpdate = [];
    this.leadsToUpdateEmail = [];
    this.leadsToUpdateSsl = [];
    this.leadsToUpdatePixel = [];
  }

  // getAll(uid: string, category: string, city: string, state: string) {
  getAll(uid: string, category: string, location: string) {
    throw Error('skip yelp trigger search getAll()');

    // this.taskService.setWaitStatus(true);

    // Add a row to trigger bot
    const row = {
      category: category.trim().toLowerCase(),
      location: location.trim().toLowerCase(),
      is_done: false,
    };
    this.afs
      .collection('yelp_listings_trigger')
      .add(row)
      .then((res) => {
        const json = {
          success: true,
          message: 'Trigger Extra Yelp! Search',
          data: { row },
        };
        return json;
      })
      .catch((error) => error);

    this.leadsCollection = null;

    const params = {
      user_id: uid,
      biz_type: category.trim().toLowerCase(),
      biz_category: category.trim().toLowerCase(),
      biz_location: location.trim().toLowerCase(),
      business_type: category.trim().toLowerCase(),
      business_location: location.trim().toLowerCase(),
      lat: 0,
      long: 0,
    };

    // const API_URL = 'https://scraper.fortinmedia.ca/api/yelp';
    const API_URL =
      'https://us-central1-leadca-72a79.cloudfunctions.net/api/search/yelp_v1/list';
    const headers = new HttpHeaders()
      // .set('Content-Type', 'application/json; charset=utf-8')
      .set('Content-Type', 'x-www-form-urlencoded')
      .set('User-Agent', 'carrot-bot');

    const body = new HttpParams()
      .set('user_id', uid)
      .set('business_type', category.trim().toLowerCase())
      .set('business_location', location.trim().toLowerCase());

    const options = { headers, reportProgress: true };

    // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
    return this.http
      .post<IApiResponse>(API_URL, body, { responseType: 'json' })
      .subscribe(
        (response) => {
          // this.taskService.setWaitStatus(response.success);
          if (response.success) {
            // this.taskService.startLeadFinder(response.data.uuid, uid, category, location);
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  // Trigger Google Search
  async triggerSearchGmb(searchTrigger: any): Promise<any> {
    const GMB_COLLECTION = 'trigger_gmb';
    searchTrigger.type = 'gmb';

    // console.log('searchTrigger:', searchTrigger);

    return await this.afs
      .collection(GMB_COLLECTION)
      .add(searchTrigger)
      .then((res) => {
        const json = {
          success: true,
          message: 'Trigger Google Search',
          data: { row: searchTrigger },
        };
        return json;
      })
      .catch((error) => error);
  }

  // Trigger ScaleSerp Search
  async triggerSearchSerp(searchTrigger: any): Promise<any> {
    const GMB_COLLECTION = 'trigger_scaleserp';
    searchTrigger.type = 'gmb';
    searchTrigger.start = searchTrigger.start + 1;
    return await this.afs
      .collection(GMB_COLLECTION)
      .add(searchTrigger)
      .then((res) => {
        const json = {
          success: true,
          message: 'Trigger ScaleSERP Search',
          data: { row: searchTrigger },
        };
        // console.log('json:', json);
        return json;
      })
      .catch((error) => error);
  }

  // Trigger Yelp! Search
  async triggerSearchYelp(searchTrigger: any): Promise<any> {
    const isApiEnabled = await isSearchOptionEnabled('yelp');
    if (!isApiEnabled) {
      return Promise.resolve();
    }

    const YELP_COLLECTION = 'trigger_yelp_api';
    searchTrigger.type = 'yelp';
    return await this.afs
      .collection(YELP_COLLECTION)
      .add(searchTrigger)
      .then((res) => {
        const json = {
          success: true,
          message: 'Trigger Extra Yelp! Search',
          data: { row: searchTrigger },
        };
        return json;
      })
      .catch((error) => error);
  }

  async triggerSearch(
    category: string,
    location: string,
    geoCoord: any,
    uuid: string,
    clientId: string,
    doSearch: boolean = true,
    isPrimary: boolean = true
  ) {
    try {
      // console.log('🥕🥕🥕🥕🥕');
      // console.log('uuid:', uuid);
      if (!uuid) {
        // console.warn('🥕🥕🥕🥕🥕');
        // console.warn('MISSING UUID:', uuid);
        return Promise.resolve();
      }

      // Check if user is paused
      // abdelsawal22@gmail.com

      const userId = this.sessionService.user.value.id;
      if (typeof category === 'undefined' || category === null) {
        throw Error('MISSING category to search');
      }

      category = category.trim().toLowerCase();
      // console.log('triggerSearch() - category:', category);

      let countryName = '';
      if (!isUndefinedOrNullOrEmpty(geoCoord.country)) {
        countryName = countries.getName(geoCoord.country);
        if (isUndefinedOrNullOrEmpty(countryName)) {
          countryName = '';
        }
      }

      const searchTrigger = {
        category: category,
        locality: geoCoord.locality,
        region: geoCoord.state,
        country_code: geoCoord.country,
        country_name: countryName,
        date_created: firebase.firestore.FieldValue.serverTimestamp(),
        keyword: category,
        language: 'en',
        lat:
          typeof geoCoord.lat === 'undefined' || geoCoord.lat === null
            ? 0
            : geoCoord.lat,
        long:
          typeof geoCoord.long === 'undefined' || geoCoord.long === null
            ? 0
            : geoCoord.long,
        location,
        place_id:
          typeof geoCoord.place_id === 'undefined' || geoCoord.place_id === null
            ? ''
            : geoCoord.place_id,
        start: 0,
        user_id: this.sessionService.user.value.id,
        user_email: this.sessionService.user.value.email,
        user_name: this.sessionService.user.value.name,
        client_id: clientId,
        uuid: uuid,
        primary: isPrimary,
      };

      // console.log('searchTrigger:', searchTrigger);

      if (doSearch) {
        // Trigger 3rd Party Data API searches
        await Promise.all([
          this.triggerSearchGmb(searchTrigger),

          // ONLY for TESTING
          this.triggerSearchSerp(searchTrigger),
          // this.triggerSearchYelp(searchTrigger)
        ]).catch((error) => console.error(error));
      }
    } catch (error) {
      console.error(error);
    }

    return Promise.resolve();
  }

  /**
   *  Get the data straight from our database for the city / category
   * @param category Category/Niche of search
   * @param locality Location / City of the search
   * @param region Region / Country of the search
   */
  getLocations(
    category: string,
    locality: string,
    region: string,
    countryCode: string,
    searchType: string = ''
  ): Observable<any[]> {
    // const city = locality.toLowerCase();
    const keyword = category.toLowerCase();

    // console.log('DATABASE QUERY:-----------------------');
    // console.log('category:', category);
    // console.log('locality:', locality);
    // console.log('region:', region);
    // console.log('country_code:', countryCode);

    let leadsRef: AngularFirestoreCollection;
    if (countryCode === 'GB' || countryCode === 'UK') {
      if (searchType === '') {
        // console.log('*** GB or UK Search ***');
        leadsRef = this.afs.collection(COL_LEADS, (ref) => {
          return (
            ref
              .where('address.addressLocality', '==', locality)
              .where('address.addressRegion', '==', region)
              // .where('country_alpha_2', '==', countryCode)
              .where(`categories.${keyword}`, '==', true)
          );
        });
      } else {
        leadsRef = this.afs.collection(COL_LEADS, (ref) => {
          return (
            ref
              .where('address.addressRegion', '==', region)
              // .where('country_alpha_2', '==', countryCode)
              .where(`categories.${keyword}`, '==', true)
          );
        });
      }
    } else if (countryCode === 'US' || countryCode === 'CA') {
      if (searchType === '') {
        leadsRef = this.afs.collection(COL_LEADS, (ref) => {
          return (
            ref
              .where('address.addressLocality', '==', locality)
              .where('address.addressRegion', '==', region.toUpperCase())
              // .where('country_alpha_2', '==', countryCode)
              .where(`categories.${keyword}`, '==', true)
          );
        });
      } else {
        leadsRef = this.afs.collection(COL_LEADS, (ref) => {
          return (
            ref
              .where('address.addressRegion', '==', region.toUpperCase())
              // .where('country_alpha_2', '==', countryCode)
              .where(`categories.${keyword}`, '==', true)
          );
        });
      }
    } else {
      if (searchType === '') {
        leadsRef = this.afs.collection(COL_LEADS, (ref) => {
          return (
            ref
              .where('address.addressLocality', '==', locality)
              // .where('address.addressRegion', '==', region.toUpperCase())
              .where('country_alpha_2', '==', countryCode)
              .where(`categories.${keyword}`, '==', true)
          );
        });
      } else {
        leadsRef = this.afs.collection(COL_LEADS, (ref) => {
          return (
            ref
              .where('address.addressRegion', '==', region.toUpperCase())
              // .where('country_alpha_2', '==', countryCode)
              .where(`categories.${keyword}`, '==', true)
          );
        });
      }
    }

    return leadsRef.snapshotChanges().pipe(
      map((changes) => {
        if (!changes || changes.length < 1) {
          // this.setWaitStatus(false);
        } else {
          // this.setWaitStatus(true);
        }
        return changes.map((action) => {
          const data = action.payload.doc.data();
          data.id = action.payload.doc.id;
          // console.log({ msg: { data: data } });
          return data;
        });
      })
    );
  }

  public async validateLeadPixel(lead: any): Promise<any> {
    try {
      if (this.leadsToUpdatePixel.indexOf(lead.id) !== -1) {
        return Promise.resolve();
      } else {
        this.leadsToUpdatePixel.push(lead.id);
      }

      if (!isUndefinedOrNull(lead.updatedAt_fb_pixel)) {
        // console.warn('lead.updatedAt_fb_pixel:', lead.updatedAt_fb_pixel);

        const endDate = new Date();
        const startDate = lead.updatedAt_fb_pixel.toDate(); // new Date(lead.updatedAt_fb_pixel._seconds * 1000);
        // console.log('startDate:', startDate);
        // console.log('endDate:', endDate);
        const days = daysBetween(startDate, endDate);

        // console.log('days:', days);

        if (!isUndefinedOrNull(lead.site_data)) {
          const hasValue =
            typeof lead.site_data.has_fb_pixel === 'undefined'
              ? false
              : lead.site_data.has_fb_pixel;
          if (hasValue === true && days < MAX_DAYS_FB_PIXEL_CHECK) {
            // console.warn('SKIP pixel check');
            return Promise.resolve();
          }
        }
      }

      let url = '';
      if (typeof lead.website !== 'undefined' && lead.website !== '') {
        if (url === '') {
          url = lead.website;
        }
      } else if (typeof lead.url !== 'undefined' && lead.url !== '') {
        if (url === '') {
          url = lead.url;
        }
      }
      if (url !== '') {
        if (!isUndefinedOrNull(lead.site_data)) {
          if (
            typeof lead.site_data.has_fb_pixel === 'undefined' ||
            lead.site_data.has_fb_pixel !== true
          ) {
            await this.fbpixelCheck(lead.id, '');
          }
        } else {
          await this.fbpixelCheck(lead.id, '');
        }
      }

      return Promise.resolve(); // lead;
    } catch (error) {
      console.error(error);
      return Promise.resolve(); // lead;
    }
  }

  public async validateLeadSsl(lead: any): Promise<any> {
    try {
      // console.warn('validateLeadSsl()');

      if (this.leadsToUpdateSsl.indexOf(lead.id) !== -1) {
        return Promise.resolve();
      } else {
        this.leadsToUpdateSsl.push(lead.id);
      }

      // console.log('lead:', lead);
      // console.warn('lead.updatedAt_ssl:', lead.updatedAt_ssl);

      if (!isUndefinedOrNull(lead.updatedAt_ssl)) {
        // console.warn('lead.updatedAt_ssl:', lead.updatedAt_ssl);

        const endDate = new Date();
        const startDate = lead.updatedAt_ssl.toDate(); // new Date(lead.updatedAt_ssl._seconds * 1000);
        // console.log('startDate:', startDate);
        // console.log('endDate:', endDate);
        const days = daysBetween(startDate, endDate);
        // console.log('days:', days);

        if (!isUndefinedOrNull(lead.site_data)) {
          const hasValue =
            typeof lead.site_data.has_ssl === 'undefined'
              ? false
              : lead.site_data.has_ssl;
          if (hasValue === true && days < MAX_DAYS_SSL_CHECK) {
            // console.warn('SKIP ssl check: ' + lead.website);
            return Promise.resolve();
          }
        }
      }

      let url = '';
      if (typeof lead.website !== 'undefined' && lead.website !== '') {
        if (url === '') {
          url = lead.website;
        }
      } else if (typeof lead.url !== 'undefined' && lead.url !== '') {
        if (url === '') {
          url = lead.url;
        }
      }
      if (url !== '') {
        if (!isUndefinedOrNull(lead.site_data)) {
          if (
            typeof lead.site_data.has_ssl === 'undefined' ||
            lead.site_data.has_ssl !== true
          ) {
            await this.sslCheck(lead.id, '');
          }
        } else {
          await this.sslCheck(lead.id, '');
        }
      }

      return Promise.resolve(); // lead;
    } catch (error) {
      console.error(error);
      return Promise.resolve(); // lead;
    }
  }

  public async validateLeadEmail(lead: any): Promise<any> {
    try {
      // console.warn('validateLeadSsl()');

      if (this.leadsToUpdateEmail.indexOf(lead.id) !== -1) {
        return Promise.resolve();
      } else {
        this.leadsToUpdateEmail.push(lead.id);
      }

      // console.log('lead:', lead);
      // console.warn('lead.updatedAt_ssl:', lead.updatedAt_ssl);

      if (!isUndefinedOrNull(lead.updatedAt_email)) {
        // console.warn('lead.updatedAt_ssl:', lead.updatedAt_ssl);

        const endDate = new Date();
        const startDate = lead.updatedAt_email.toDate(); // new Date(lead.updatedAt_ssl._seconds * 1000);
        // console.log('startDate:', startDate);
        // console.log('endDate:', endDate);
        const days = daysBetween(startDate, endDate);
        // console.log('days:', days);

        /*
        const has_value = isUndefinedOrNullOrEmpty(lead.email) ? false : true;
        if (has_value === false && days < MAX_DAYS_EMAIL_CHECK) {
          // console.warn('SKIP email check: ' + lead.website);
          return Promise.resolve();
        }
        */
      }

      let url = '';
      if (typeof lead.website !== 'undefined' && lead.website !== '') {
        if (url === '') {
          url = lead.website;
        }
      } else if (typeof lead.url !== 'undefined' && lead.url !== '') {
        if (url === '') {
          url = lead.url;
        }
      }
      if (url !== '' && isUndefinedOrNullOrEmpty(lead.email)) {
        await this.websiteScrape(lead.id, '');
      }

      return Promise.resolve(); // lead;
    } catch (error) {
      console.error(error);
      return Promise.resolve(); // lead;
    }
  }

  public async validateLead(lead: any): Promise<any> {
    try {
      if (this.leadsToUpdate.indexOf(lead.id) !== -1) {
        return Promise.resolve();
      } else {
        this.leadsToUpdate.push(lead.id);
      }

      let url = '';
      if (typeof lead.website !== 'undefined' && lead.website !== '') {
        if (url === '') {
          url = lead.website;
        }
      } else if (typeof lead.url !== 'undefined' && lead.url !== '') {
        if (url === '') {
          url = lead.url;
        }
      }

      let doUpdate = false;
      const data: any = {};
      data.social = lead.social;

      // IS phones missing data
      if (
        typeof lead.phones !== 'undefined' &&
        lead.phones.length < 1 &&
        !isUndefinedOrNullOrEmpty(lead.telephone)
      ) {
        doUpdate = true;
        data.phones = [lead.telephone];
        // lead.phones.push(lead.telephone);
      }

      if (isUndefinedOrNullOrEmpty(lead.instagram_url)) {
        doUpdate = true;
        lead.instagram_url = '';
        data.instagram_url = '';
      }

      if (isUndefinedOrNullOrEmpty(lead.twitter_url)) {
        doUpdate = true;
        lead.twitter_url = '';
        data.twitter_url = '';
      }

      // is Instagram missing field
      if (
        typeof lead.social !== 'undefined' &&
        typeof lead.social.instagram !== 'undefined' &&
        lead.social.instagram.length &&
        isUndefinedOrNullOrEmpty(lead.instagram_url)
      ) {
        doUpdate = true;
        // console.log('new url for instagram:', lead.social.instagram[0]);
        data.instagram_url = lead.social.instagram[0];
        lead.instagram_url = '';
      } else {
        if (isUndefinedOrNull(lead.instagram_url)) {
          doUpdate = true;
          lead.instagram_url = '';
        }
      }

      // is Twitter missing field
      if (
        typeof lead.social !== 'undefined' &&
        typeof lead.social.twitter !== 'undefined' &&
        lead.social.twitter.length
      ) {
        if (lead.social.twitter[0] === 'https://twitter.com/share') {
          doUpdate = true;
          data.twitter_url = '';
          lead.twitter_url = '';
          if (lead.social.twitter.length === 1) {
            // lead.social.twitter = [];
            data.social.twitter = [];
          } else {
            data.social.twitter[0] = '';
            // lead.social.twitter[0] = '';
          }
        } else {
          if (
            typeof lead.twitter_url === 'undefined' ||
            lead.twitter_url === ''
          ) {
            doUpdate = true;
            // console.log('new url for twitter:', lead.social.twitter[0]);
            data.twitter_url = lead.social.twitter[0];
            lead.twitter_url = lead.social.twitter[0];
          }
        }
      }

      if (
        typeof lead.social !== 'undefined' &&
        typeof lead.social.facebook !== 'undefined' &&
        lead.social.facebook.length
      ) {
        if (lead.social.facebook[0] === 'https://www.facebook.com/login.php') {
          // console.warn( 'BAD FACEBOOK URL : [https://www.facebook.com/login.php]' );

          doUpdate = true;
          if (lead.social.facebook.length === 1) {
            // lead.social.facebook = [];
            data.social.facebook = [];
          } else {
            data.social.facebook[0] = '';
            // lead.social.twitter[0] = '';
          }
        }
      }

      if (!isUndefinedOrNullOrEmpty(lead.twitter_url)) {
        if (lead.image.indexOf('https://abs.twimg.com/errors/')) {
          /*
          doUpdate = true;
          const newUrl = '';
          if (typeof lead.social.twitter === 'undefined') {
            lead.social.twitter = [];
          } else {
            lead.social.twitter[0] = '';
          }
          lead.twitter_url = '';
          console.log('new url for twitter:', newUrl);
          data['twitter_url'] = newUrl;
          const twitter = lead.social.twitter;
          twitter[0] = newUrl;
          data['social'] = [];
          data['social']['twitter'] = twitter;
          data['image'] = '';
          */
        } else if (lead.twitter_url === 'https://twitter.com/wix') {
          doUpdate = true;
          const newUrl = '';
          if (typeof lead.social.twitter === 'undefined') {
            lead.social.twitter = [];
          } else {
            lead.social.twitter[0] = '';
          }
          lead.twitter_url = '';
          // console.log('new url for twitter:', newUrl);
          data.twitter_url = newUrl;
          const twitter = lead.social.twitter;
          twitter[0] = newUrl;
          data.social.twitter = twitter;
          data.image = '';
        } else {
          if (typeof lead.social.twitter !== 'undefined') {
            const newUrl = lead.social.twitter[0].replace(/\/#!\//g, '/');
            if (newUrl !== lead.social.twitter[0]) {
              doUpdate = true;
              lead.social.twitter[0] = newUrl;
              // console.log('new url for twitter:', newUrl);
              data.twitter_url = newUrl;
              const twitter = lead.social.twitter;
              twitter[0] = newUrl;
              data.social = [];
              data.social.twitter = twitter;
              lead.twitter_url = newUrl;
              if (lead.image.indexOf('https://pbs.twimg.com/profile_images/')) {
                lead.image = '';
                data.image = '';
              }
            }
          }
        }
      }

      /*
      if (lead.image.indexOf('https://pbs.twimg.com/profile_images/')) {
        doUpdate = true;
        lead.image = '';
        data['image'] = '';
      }
      */

      if (doUpdate) {
        // console.log('data:', data);
        // console.log('doUpdate:', lead.id);
        await this.afs.collection(COL_LEADS).doc(lead.id).update(data);
      }

      if (isUndefinedOrNullOrEmpty(lead.image)) {
        // console.warn('*** MISSING avatar:', lead.id);
        if (lead.instagram_url !== '') {
          await this.getSocialAvatar(lead.id, 'instagram', lead.instagram_url);
        } else if (lead.twitter_url !== '') {
          await this.getSocialAvatar(lead.id, 'twitter', lead.twitter_url);
        } else if (lead.youtube_url !== '') {
          const username = this.getUsernameFromUrl(lead.youtube_url, 'youtube');
          if (username) {
            await this.getSocialAvatar(lead.id, 'youtube', lead.youtube_url);
          }
        }
      } else {
        // console.log('*** has avatar:', lead.image);
      }

      return Promise.resolve(); // lead;
    } catch (error) {
      console.error(error);
      return Promise.resolve(); // lead;
    }
  }

  removeTrailingSlash(url: string): string {
    const lastChar = url.slice(url.length - 1);
    if (lastChar === '/') {
      return url.substr(0, url.length - 1);
    }
    return url;
  }

  getUsernameFromUrl(socialUrl: string, network: string): string {
    const TOKEN_USER = '/user/';
    const FRONT_SLASH = '/';

    try {
      if ('instagram' === network || 'twitter' === network) {
        const url = this.removeTrailingSlash(socialUrl);
        const nPos = url.lastIndexOf(FRONT_SLASH);
        if (nPos === -1) {
          return '';
        }
        return url.slice(nPos + FRONT_SLASH.length);
      } else if ('youtube' === network) {
        const url = this.removeTrailingSlash(socialUrl);
        const nPos = url.indexOf(TOKEN_USER);
        if (nPos !== -1) {
          return url.substr(nPos + TOKEN_USER.length);
        }
        return '';
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  }

  async getSocialAvatar(leadId: string, network: string, socialUrl: string) {
    try {
      console.log('getSocialAvatar()', leadId);

      const username = this.getUsernameFromUrl(socialUrl, network);
      const taskId = '';
      const topicName = ACTION.SOCIAL_AVATAR;
      const data = JSON.stringify({
        leadId,
        taskId,
        network,
        username,
      });
      const customAttributes = {
        origin: TASK_ORIGIN,
        action: ACTION.SOCIAL_AVATAR,
        taskId,
      };

      const apiLeadCarrot = environment.cloudUrl + '/pubsubPublish';
      const headers = createCloudHeaders(this.sessionService.userToken);
      const body = {
        data,
        customAttributes,
        topicName,
      };

      return this.http
        .post<IApiResponse>(apiLeadCarrot, body, {
          headers,
          // withCredentials: true
        })
        .subscribe(
          (value) => {
            // console.log(value);
            // this.ngxLoader.stop();
            // this.openSnackBar('Password changed successfully', 'close');
            // resetForm(this.passwordForm);
            // console.log(value);
          },
          (error) => {
            // console.error(err);
            // this.ngxLoader.stop();
            // this.openSnackBar('Unable to change password', 'close');
            // resetForm(this.passwordForm);
            console.error(error);
          }
        );
    } catch (error) {
      // console.error(error);
      // this.ngxLoader.stop();
      // this.openSnackBar('Unable to change password', 'close');
      // resetForm(this.passwordForm);
      console.error(error);
    }
  }

  async fbpixelCheck(leadId: string, taskId: string = '') {
    try {
      const topicName = ACTION.FB_PIXEL_CHECK;
      const data = JSON.stringify({
        leadId,
        taskId,
      });
      const customAttributes = {
        origin: TASK_ORIGIN,
        action: ACTION.FB_PIXEL_CHECK,
        taskId,
      };

      const apiLeadCarrot = environment.cloudUrl + '/pubsubPublish';
      const headers = createCloudHeaders(this.sessionService.userToken);
      const body = {
        data,
        customAttributes,
        topicName,
      };

      console.log('fbpixelCheck', leadId);

      return this.http
        .post<IApiResponse>(apiLeadCarrot, body, {
          headers,
        })
        .subscribe(
          (value) => {
            // console.log(value);
            // this.ngxLoader.stop();
            // this.openSnackBar('Password changed successfully', 'close');
            // resetForm(this.passwordForm);
            // console.log(value);
          },
          (error) => {
            console.error(error);
          }
        );
    } catch (error) {
      console.error(error);
    }
  }

  async websiteScrape(leadId: string, taskId: string = '') {
    console.log('websiteScrape: ', leadId);

    try {
      const topicName = ACTION.SCRAPE_WEBSITE;
      const data = JSON.stringify({
        leadId,
        taskId,
      });
      const customAttributes = {
        origin: TASK_ORIGIN,
        action: ACTION.SCRAPE_WEBSITE,
        taskId,
      };

      const apiLeadCarrot = environment.cloudUrl + '/pubsubPublish';
      const headers = createCloudHeaders(this.sessionService.userToken);
      const body = {
        data,
        customAttributes,
        topicName,
      };

      return this.http
        .post<IApiResponse>(apiLeadCarrot, body, {
          headers,
          // withCredentials: true
        })
        .subscribe(
          (value) => {
            // console.log(value);
            // this.ngxLoader.stop();
            // this.openSnackBar('Password changed successfully', 'close');
            // resetForm(this.passwordForm);
            // console.log(value);
          },
          (error) => {
            // console.error(err);
            // this.ngxLoader.stop();
            // this.openSnackBar('Unable to change password', 'close');
            // resetForm(this.passwordForm);
            console.error(error);
          }
        );
    } catch (error) {
      // console.error(error);
      // this.ngxLoader.stop();
      // this.openSnackBar('Unable to change password', 'close');
      // resetForm(this.passwordForm);
      console.error(error);
    }
  }

  async sslCheck(leadId: string, taskId: string = '') {
    console.log('sslCheck: ', leadId);

    try {
      const topicName = ACTION.SSL_CHECK;
      const data = JSON.stringify({
        leadId,
        taskId,
      });
      const customAttributes = {
        origin: TASK_ORIGIN,
        action: ACTION.SSL_CHECK,
        taskId,
      };

      const apiLeadCarrot = environment.cloudUrl + '/pubsubPublish';
      const headers = createCloudHeaders(this.sessionService.userToken);
      const body = {
        data,
        customAttributes,
        topicName,
      };

      return this.http
        .post<IApiResponse>(apiLeadCarrot, body, {
          headers,
          // withCredentials: true
        })
        .subscribe(
          (value) => {
            // console.log(value);
            // this.ngxLoader.stop();
            // this.openSnackBar('Password changed successfully', 'close');
            // resetForm(this.passwordForm);
            // console.log(value);
          },
          (error) => {
            // console.error(err);
            // this.ngxLoader.stop();
            // this.openSnackBar('Unable to change password', 'close');
            // resetForm(this.passwordForm);
            console.error(error);
          }
        );
    } catch (error) {
      // console.error(error);
      // this.ngxLoader.stop();
      // this.openSnackBar('Unable to change password', 'close');
      // resetForm(this.passwordForm);
      console.error(error);
    }
  }

  /**
   * Get locations within a bounding box defined by a center point and distance from from the center point to the side of the box;
   *
   * @param area an object that represents the bounding box
   *    around a point in which locations should be retrieved
   * @param area.center an object containing the latitude and
   *    longitude of the center point of the bounding box
   * @param area.center.latitude the latitude of the center point
   * @param area.center.longitude the longitude of the center point
   * @param area.radius (in kilometers) the radius of a circle
   *    that is inscribed in the bounding box;
   *    This could also be described as half of the bounding box's side length.
   * @return a Promise that fulfills with an array of all the
   *    retrieved locations
   */
  getLocations_old(area: IArea, searchCategory: string, geoCoord: any) {
    // Andre - 2018-10-27
    /*
    // Trigger Yelp! Search
    const col_name = 'trigger_yelp';
    const json_trigger = {
      'date_created': firebase.firestore.FieldValue.serverTimestamp(),
      'category': searchCategory,
      'location': search_location,
      'user_id': this.authService.uid,
      'uuid': uuidv4()
    };

    this.afs.collection(col_name)
      .add(json_trigger)
      .then((res) => {
        const json = {
          'success': true,
          'message': 'Trigger Extra Yelp! Search',
          'data': { 'row': json_trigger }
        };
        return json;
      })
      .catch((error) => error);

    const collection = this.geo.query('leads_v2');
    const center = this.geo.point(area.center.latitude, area.center.longitude);
    const radius = 40;
    const field = 'geo_position';
    return this.points = collection.within(center, radius, field);
    */

    /*
        this.points.subscribe((response) => {
          // console.log(response);
          return response;
        });
    */

    // calculate the SW and NE corners of the bounding box to query for
    const box = geoUtils.boundingBoxCoordinates(area.center, area.radius);
    const geoLong = parseFloat(area.center.longitude.toFixed(2));
    //    console.log('geoLong: [' + geoLong + ']');

    // construct the GeoPoints
    const lesserGeopoint = new firebase.firestore.GeoPoint(
      box.swCorner.latitude,
      box.swCorner.longitude
    );
    const greaterGeopoint = new firebase.firestore.GeoPoint(
      box.neCorner.latitude,
      box.neCorner.longitude
    );

    console.log('🥕🥕🥕🥕🥕');
    console.log('searchCategory:', searchCategory);
    console.log('🥕🥕🥕🥕🥕');

    const categorySearch = {};
    categorySearch[searchCategory] = true;
    const jsonCategory = JSON.stringify(categorySearch);
    const leadsRef = this.afs.collection(COL_LEADS, (ref) => {
      return (
        ref
          .where('address.addressLocality', '==', geoCoord.locality)
          .where('country_alpha_2', '==', geoCoord.country)
          .where('categoryList', 'array-contains', searchCategory)
          .where('category', '==', searchCategory)
          .where('geo_position.geopoint', '>', lesserGeopoint)
          .where('geo_position.geopoint', '<', greaterGeopoint)
          // .where('geo.latitude', '>', box.swCorner.latitude)
          // .where('geo_position.geopoint.latitude', '<', box.neCorner.latitude)
          .limit(500)
      );
    });

    return leadsRef.snapshotChanges().pipe(
      map((changes) => {
        if (!changes || changes.length < 1) {
          // this.setWaitStatus(false);
        } else {
          // this.setWaitStatus(true);
        }
        return changes.map((action: any) => {
          const data = action.payload.doc.data();
          data.id = action.payload.doc.id;
          // console.log({ msg: { data: data } });
          return data;
        });
      })
    );
  }

  /*
    private updatePosition(id: string, lat: number, long: number) {
      const leads = this.afs.collection(this.COLLECTION_LEADS);

      // Use the convenience method
      position =  this.geo.point(id, 'geo_position', lat, long);

      // Or be a little more explicit
      // const point = this.geo.point(lat, long);
      // collection.setDoc(id, { position: point.data });
      console.log('lead id:', id);

      const cities = firestore().collection('cities');

      const position = geo.point(40, -119);

      cities.add({ name: 'Phoenix', position });
    }
    */

  queryHashes(
    category: string,
    lat: number,
    long: number,
    radius: number = 40000
  ) {
    console.warn('🥕🥕🥕🥕🥕');
    console.warn('queryHashes');
    console.warn('🥕🥕🥕🥕🥕');

    const keyword = category.toLowerCase();

    // [START fs_geo_query_hashes]
    // Find cities within 50km of London
    const center = [lat, long];
    // const radiusInM = radius * 1000;

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geofire.geohashQueryBounds(center, radius);
    console.log('bounds:', bounds);

    const promises = [];
    for (const b of bounds) {
      const q = this.afs.collection(COL_LEADS, (ref) => {
        return ref
          .where(`categories`, 'array-contains', keyword)
          .where('category', '==', keyword)
          .orderBy('geo_position.geohash')
          .startAt(b[0])
          .endAt(b[1]);
      });

      promises.push(
        q.snapshotChanges().pipe(
          map((changes) => {
            if (!changes || changes.length < 1) {
              // this.setWaitStatus(false);
            } else {
              // this.setWaitStatus(true);
            }
            return changes.map((action) => {
              const data: any = action.payload.doc.data();
              data.id = action.payload.doc.id;
              // console.log({ msg: { data: data } });
              return data;
            });
          })
        )
      );
    }

    return combineLatest(...promises).pipe(
      switchMap((leads) => {
        // const [californiaCities, coloradoCities] = leads;
        // const combined = californiaCities.concat(coloradoCities);

        const merged = [].concat.apply([], leads);
        return of(merged);
      })
    );

    // Collect all the query results together into a single list
    /*
    return Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs = [];

        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const lat = doc.get('geo.latitude');
            const lng = doc.get('geo.longitude');

            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = geofire.distanceBetween([lat, lng], center);
            const distanceInM = distanceInKm * 1000;
            if (distanceInM <= radiusInM) {
              matchingDocs.push(doc);
            }
          }
        }

        return matchingDocs;
      })
      .then((matchingDocs) => {
        // Process the matching documents
        // [START_EXCLUDE]
        return matchingDocs;
        // [END_EXCLUDE]
      });
    */

    // [END fs_geo_query_hashes]
  }

  queryHashes2(
    category: string,
    lat: number,
    long: number,
    radius: number = 40,
    uuid: string = ''
  ) {
    // console.warn('🥕🥕🥕🥕🥕');
    // console.warn('queryHashes2');
    // console.warn('🥕🥕🥕🥕🥕');

    if (!uuid) {
      console.warn('🥕🥕🥕🥕🥕');
      console.warn('MISSING UUID:', uuid);
      return;
    }

    // TODO: Use Search UUID to perform any logging.

    const keyword = category.toLowerCase();
    const kw = { dentist: true };

    const collection = this.geo.query('leads_v2');

    const center = this.geo.point(lat, long);
    const field = 'geo_position';

    // console.warn('keyword:', keyword);
    // console.warn('🥕🥕🥕🥕🥕');

    return collection.withinCategory(center, radius, field, {
      log: false,
      category: keyword,
    });
  }
}
