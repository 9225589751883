import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Md5 } from 'ts-md5/dist/md5';
import { User } from 'firebase';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject, throwError } from 'rxjs';

import {
    IApiResponse, ICompany, ICoordinates, IEntities,
    IEvent, ITargeting, IPost, IUser
} from '../../models';

export const enum REACTION_TYPE {
    LIKE = 'like',
    LOVE = 'love',
    CARE = 'care',
    HAHA = 'haha',
    WOW = 'wow',
    SAD = 'sad',
    ANGRY = 'angry',
}

export const DEFAULT_PUBLISHING_STATS = {
    reactions: {
        count: 0,
        like: 0,
        love: 0,
        care: 0,
        haha: 0,
        wow: 0,
        sad: 0,
        angry: 0,
    }, comments: {
        count: 0,
    },
    shares: {
        count: 0,
    }
};

export const enum TIMELINE_VISIBILITY {
    HIDDEN = 'HIDDEN',
    NORMAL = 'NORMAL',
    FORCED_ALLOW = 'FORCED_ALLOW',
}
import { environment } from '../../../environments/environment';
import { COLLECTION, arrayUnique, getDocument, getIsAdmin, isUndefinedOrNull, isUndefinedOrNullOrEmpty, createAuthHeaders } from '../../shared/utils';

import { AuthService } from '../auth/auth.service';

const { 'v4': uuidv4 } = require('uuid');
const IS_DEBUG = !environment.production;
const COMPANY_NOT_FOUND = 'Company not found in Lead Carrot.';
const TEAM_NAME_IS_EMPTY = 'Team name is empty.';
const TEAM_NOT_FOUND = 'Team not found in Lead Carrot.';
const USER_NOT_FOUND = 'User not found in Lead Carrot.';
const DEFAULT_POST_ICON = ''; // 'https://leadcarrot.io/icon/';

@Injectable({
    providedIn: 'root'
})

export class PostService {
    private _currentUser: User;

    private _posts: IPost[] = [];
    posts = new BehaviorSubject<IPost[]>([]);

    private _user: IUser = null;
    user = new BehaviorSubject<IUser>(null);

    private _company: ICompany = null;
    company = new BehaviorSubject<ICompany>(null);

    endPoint: string;
    apiCloud: string;

    isAdmin: boolean;
    subs = new Subscription();

    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth,
        private authService: AuthService,
        private http: HttpClient,
    ) {
        try {
            if (!IS_DEBUG) {
                this.endPoint = environment.backendApiUrl;
            } else {
                this.endPoint = environment.backendApiDebugUrl;
            }

            this.apiCloud = environment.cloudUrl; // + '/userGetTeamMembers';

            this.afAuth.authState
                .subscribe((user: User | null) => this._currentUser = user);

            this.authService.user
                .subscribe(async (user: IUser) => {
                    this._user = user;
                    if (user) {
                        try {
                            this.isAdmin = false; // await getIsAdmin(this._user.id);
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        this.isAdmin = false;
                    }
                    return Promise.resolve();
                });

            this.authService.company
                .subscribe(async (company: ICompany) => {
                    this.subs.unsubscribe();
                    this._company = company;

                    if (company) {
                        try {
                            // this.getByCompany(this._company.id);
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        this._posts = [];
                        this.posts.next(this._posts);
                    }
                    return Promise.resolve();
                });

        } catch (error) {
            console.error(error);
        }
    }

    private async _addReaction(postId: string, userId: string, reactionType: REACTION_TYPE) {
        try {
            const data: any = {
                reaction: reactionType,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            };
            return await firebase.firestore()
                .collection(COLLECTION.POSTS)
                .doc(postId)
                .collection('reactions')
                .doc(userId)
                .set(data);
        } catch (error) {
            console.error(error);
            return Promise.resolve(null);
        }
    }

    private async _deleteReaction(postId: string, userId: string) {
        try {
            return await firebase.firestore()
                .collection(COLLECTION.POSTS)
                .doc(postId)
                .collection('reactions')
                .doc(userId)
                .delete();
        } catch (error) {
            console.error(error);
            return Promise.resolve(null);
        }
    }

    private async _getUser(userId: string) {
        if (isUndefinedOrNullOrEmpty(userId)) {
            throw Error('missing user id');
        }

        if (userId === this._user.id) {
            return Promise.resolve(this._user);
        }

        return await firebase.firestore()
            .collection(COLLECTION.CONTACT)
            .doc(userId)
            .get()
            .then(snapshot => {
                if (snapshot.exists) {
                    // Get document data
                    const docData: any = snapshot.data();
                    const docId = snapshot.id;

                    // Use spread operator to add the id to the document data

                    const authEmail = isUndefinedOrNullOrEmpty(docData.email) ? '' : docData.email.trim();
                    const authPhone = isUndefinedOrNullOrEmpty(docData.phoneNumber) ? '' : docData.phoneNumber.trim();
                    const authName = isUndefinedOrNullOrEmpty(docData.displayName) ?
                        (authEmail === '' ? '' : authEmail) : docData.displayName.trim();
                    const authPhoto = isUndefinedOrNullOrEmpty(docData.photoURL) ? '' : docData.photoURL.trim();

                    const email = isUndefinedOrNullOrEmpty(docData.userEmail) ? authEmail : docData.userEmail.trim();
                    const phone = isUndefinedOrNullOrEmpty(docData.phoneNumber) ? authPhone : docData.phoneNumber.trim();
                    const name = isUndefinedOrNullOrEmpty(docData.displayName) ?
                        (authName === '' ? email : authName) : docData.displayName.trim();
                    const nameLcase = name.toLowerCase();
                    let photoURL = isUndefinedOrNullOrEmpty(docData.photoURL) ? authPhoto : docData.photoURL.trim();
                    if (photoURL === '' && email !== '') {
                        photoURL = 'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?d=identicon';
                    }
                    const data = { id: docId, email, phone, name, photoURL, ...docData };
                    return data;
                }
            })
            .catch((error: any) => {
                console.error(error);
                return null;
            });

    }

    private async _updateReaction(postId: string, userId: string, reactionType: REACTION_TYPE) {
        try {
            const data: any = {
                reaction: reactionType,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            };
            return await firebase.firestore()
                .collection(COLLECTION.POSTS)
                .doc(postId)
                .collection('reactions')
                .doc(userId)
                .set(data, { merge: true });
        } catch (error) {
            console.error(error);
            return Promise.resolve(null);
        }
    }

    private async _updatePostStats(postId: string, publishingStats: any) {
        try {
            const data: any = {
                publishingStats,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            };
            return await firebase.firestore()
                .collection(COLLECTION.POSTS)
                .doc(postId)
                .update(data);
        } catch (error) {
            console.error(error);
            return Promise.resolve(null);
        }
    }

    async create(postData: IPost | any): Promise<any> {
        try {
            if (isUndefinedOrNullOrEmpty(postData.message)) {
                throw Error('Missing message body');
            }

            const post: any = {};
            post.id = uuidv4();
            post.createdAt = isUndefinedOrNull(postData.createdAt) ? firebase.firestore.FieldValue.serverTimestamp() : postData.createdAt;
            post.createdBy = this._user.id;
            post.updatedAt = isUndefinedOrNull(postData.updatedAt) ? firebase.firestore.FieldValue.serverTimestamp() : postData.updatedAt;
            post.updatedBy = this._user.id;
            post.isDeleted = false;
            post.deletedAt = null;
            post.deletedBy = '';
            post.index = 0;
            post.actions = isUndefinedOrNullOrEmpty(postData.actions) ? [] : postData.actions;
            post.applicationId = isUndefinedOrNullOrEmpty(postData.applicationId) ? '' : postData.applicationId;
            post.backdatedTime = null;
            post.childAttachments = [];
            post.companyId = isUndefinedOrNullOrEmpty(postData.companyId) ? null : postData.companyId;
            post.contactId = isUndefinedOrNullOrEmpty(postData.contactId) ? null : postData.contactId;
            post.entities = isUndefinedOrNullOrEmpty(postData.entities) ? null : postData.entities;
            post.height = isUndefinedOrNullOrEmpty(postData.height) ? 0 : postData.height;
            post.expandedHeight = isUndefinedOrNullOrEmpty(postData.expandedHeight) ? post.height : postData.expandedHeight;
            post.width = isUndefinedOrNullOrEmpty(postData.width) ? 0 : postData.width;
            post.expandedWidth = isUndefinedOrNullOrEmpty(postData.expandedWidth) ? post.width : postData.expandedWidth;
            post.from = isUndefinedOrNullOrEmpty(postData.from) ? this._user.id : postData.from;
            post.fromName = isUndefinedOrNullOrEmpty(postData.name) ? null : postData.name;
            post.icon = isUndefinedOrNullOrEmpty(postData.icon) ? null : postData.icon;
            post.isExpired = isUndefinedOrNullOrEmpty(postData.isExpired) ? false : postData.isExpired;
            post.isHidden = isUndefinedOrNullOrEmpty(postData.isHidden) ? false : postData.isHidden;
            post.isPopular = isUndefinedOrNullOrEmpty(postData.isPopular) ? false : postData.isPopular;
            post.isPublished = isUndefinedOrNullOrEmpty(postData.isPublished) ? false : postData.isPublished;
            post.isSpherical = isUndefinedOrNullOrEmpty(postData.isSpherical) ? false : postData.isSpherical;
            post.message = postData.message;
            post.messageTags = isUndefinedOrNullOrEmpty(postData.messageTags) ? [] : postData.messageTags;
            post.organizationId = isUndefinedOrNullOrEmpty(postData.organizationId) ? null : postData.organizationId;
            post.parentId = isUndefinedOrNullOrEmpty(postData.parentId) ? '' : postData.parentId;
            post.permalinkUrl = isUndefinedOrNullOrEmpty(postData.permalinkUrl) ? false : postData.permalinkUrl;
            post.privacy = isUndefinedOrNullOrEmpty(postData.privacy) ? null : postData.privacy;
            post.properties = isUndefinedOrNullOrEmpty(postData.properties) ? [] : postData.properties;
            post.publishingStats = DEFAULT_PUBLISHING_STATS;
            post.scheduledPublishTime = isUndefinedOrNullOrEmpty(postData.scheduledPublishTime)
                ? firebase.firestore.FieldValue.serverTimestamp()
                : postData.scheduledPublishTime;
            post.shares = 0;
            post.statusType = isUndefinedOrNullOrEmpty(postData.statusType) ? '' : postData.statusType;
            post.story = isUndefinedOrNullOrEmpty(postData.story) ? '' : postData.story;
            post.storyTags = isUndefinedOrNullOrEmpty(postData.storyTags) ? [] : postData.storyTags;
            post.timelineVisibility = isUndefinedOrNullOrEmpty(postData.timelineVisibility)
                ? TIMELINE_VISIBILITY.NORMAL
                : postData.timelineVisibility;
            post.via = isUndefinedOrNullOrEmpty(postData.via) ? this._user.id : postData.via;

            if (post.icon === null) {
                const userData = await this._getUser(post.from);
                post.icon = userData.photoURL;
                if (post.fromName === null) {
                    post.fromName = userData.name;
                }
            }
            if (post.fromName === null) {
                const userData = await this._getUser(post.from);
                post.fromName = userData.name;
                if (post.icon === null) {
                    post.icon = userData.photoURL;
                }
            }

            await firebase.firestore()
                .collection(COLLECTION.POSTS)
                .doc(post.id)
                .set(post);

            return Promise.resolve(post);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    delete(postId: string) {
        try {
            const data = {
                isDeleted: true,
                deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
                deletedBy: this._user.id,
            };

            return this.afs.collection<IPost>(COLLECTION.POSTS)
                .doc(postId)
                .update(data);

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    get(postId: string) {
        try {
            return this.afs.collection(COLLECTION.POSTS)
                .doc<IPost>(postId)
                .snapshotChanges();
            /*
            .pipe(
                map(actions => {
                    return actions.map(item => {
                        return {
                            id: item.payload.doc.id,
                            ...item.payload.doc.data()
                        };
                    });
                })
            );
            */

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getAll(params: any): Observable<IPost[]> {
        try {
            if (isUndefinedOrNullOrEmpty(params)) {
                throw Error('Missing params');
            }

            if (!isUndefinedOrNull(params.contactId)) {

                if (!isUndefinedOrNull(params.parentId)) {

                    if (!isUndefinedOrNull(params.from)) {

                        return this.afs.collection<IPost>(COLLECTION.POSTS, ref => ref
                            .where('isDeleted', '==', false)
                            .where('contactId', '==', params.contactId)
                            .where('parentId', '==', params.parentId)
                            .where('from', '==', params.from)
                            .orderBy('createdAt', 'desc'))
                            .snapshotChanges()
                            .pipe(
                                map(actions => {
                                    return actions.map(item => {
                                        return {
                                            id: item.payload.doc.id,
                                            ...item.payload.doc.data()
                                        };
                                    });
                                })
                            );
                    } else {
                        return this.afs.collection<IPost>(COLLECTION.POSTS, ref => ref
                            .where('isDeleted', '==', false)
                            .where('contactId', '==', params.contactId)
                            .where('parentId', '==', params.parentId)
                            .orderBy('createdAt', 'desc'))
                            .snapshotChanges()
                            .pipe(
                                map(actions => {
                                    return actions.map(item => {
                                        return {
                                            id: item.payload.doc.id,
                                            ...item.payload.doc.data()
                                        };
                                    });
                                })
                            );
                    }

                } else {

                    if (!isUndefinedOrNull(params.from)) {
                        return this.afs.collection<IPost>(COLLECTION.POSTS, ref => ref
                            .where('isDeleted', '==', false)
                            .where('contactId', '==', params.contactId)
                            .where('from', '==', params.from)
                            .orderBy('createdAt', 'desc'))
                            .snapshotChanges()
                            .pipe(
                                map(actions => {
                                    return actions.map(item => {
                                        return {
                                            id: item.payload.doc.id,
                                            ...item.payload.doc.data()
                                        };
                                    });
                                })
                            );

                    } else {
                        return this.afs.collection<IPost>(COLLECTION.POSTS, ref => ref
                            .where('isDeleted', '==', false)
                            .where('contactId', '==', params.contactId)
                            .orderBy('createdAt', 'desc'))
                            .snapshotChanges()
                            .pipe(
                                map(actions => {
                                    return actions.map(item => {
                                        return {
                                            id: item.payload.doc.id,
                                            ...item.payload.doc.data()
                                        };
                                    });
                                })
                            );
                    }

                }

            } else {

                if (!isUndefinedOrNull(params.parentId)) {

                    if (!isUndefinedOrNull(params.from)) {

                        console.log('reply posts only with a fromId and a parent:', params.parentId);

                        return this.afs.collection<IPost>(COLLECTION.POSTS, ref => ref
                            .where('isDeleted', '==', false)
                            .where('parentId', '==', params.parentId)
                            .where('from', '==', params.from)
                            .orderBy('createdAt', 'desc'))
                            .snapshotChanges()
                            .pipe(
                                map(actions => {
                                    return actions.map(item => {
                                        return {
                                            id: item.payload.doc.id,
                                            ...item.payload.doc.data()
                                        };
                                    });
                                })
                            );

                    } else {

                        console.log('reply posts only with a parent:', params.parentId);

                        return this.afs.collection<IPost>(COLLECTION.POSTS, ref => ref
                            .where('isDeleted', '==', false)
                            .where('parentId', '==', params.parentId)
                            .orderBy('createdAt', 'desc'))
                            .snapshotChanges()
                            .pipe(
                                map(actions => {
                                    return actions.map(item => {
                                        return {
                                            id: item.payload.doc.id,
                                            ...item.payload.doc.data()
                                        };
                                    });
                                })
                            );
                    }

                } else {

                    if (!isUndefinedOrNull(params.from)) {

                        console.log('reply posts only with a fromId:', params.fromId);

                        return this.afs.collection<IPost>(COLLECTION.POSTS, ref => ref
                            .where('isDeleted', '==', false)
                            .where('from', '==', params.from)
                            .orderBy('createdAt', 'desc'))
                            .snapshotChanges()
                            .pipe(
                                map(actions => {
                                    return actions.map(item => {
                                        return {
                                            id: item.payload.doc.id,
                                            ...item.payload.doc.data()
                                        };
                                    });
                                })
                            );

                    } else {
                        throw Error('missing params for getAll()');
                    }

                }
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getOne(postId: string) {
        try {
            return await firebase.firestore()
                .collection(COLLECTION.POSTS)
                .doc(postId)
                .get()
                .then(snapshot => {
                    if (snapshot.exists) {
                        return { id: snapshot.id, ...snapshot.data() };
                    } else {
                        return null;
                    }
                });
        } catch (error) {
            console.error(error);
            return Promise.resolve(null);
        }
    }

    async getReaction(postId: string, userId: string) {
        try {
            return await firebase.firestore()
                .collection(COLLECTION.POSTS)
                .doc(postId)
                .collection('reactions')
                .doc(userId)
                .get()
                .then(snapshot => {
                    if (snapshot.exists) {
                        return snapshot.data();
                    } else {
                        return null;
                    }
                });
        } catch (error) {
            console.error(error);
            return Promise.resolve(null);
        }
    }

    update(postId: string, post: any) {
        try {

            const data = post;
            data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
            data.updatedBy = isUndefinedOrNullOrEmpty(post.updatedBy) ? this._user.id : post.updatedBy;
            delete data.contactId;
            delete data.createdAt;
            delete data.createdBy;

            return this.afs.collection(COLLECTION.POSTS)
                .doc<IPost>(postId)
                .update(data);

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async reaction(postId: string, userId: string, reactionType: REACTION_TYPE) {
        if (isUndefinedOrNullOrEmpty(postId)
            || isUndefinedOrNullOrEmpty(userId)
            || isUndefinedOrNullOrEmpty(reactionType)
        ) {
            return Promise.resolve(null);
        }

        const reactionData = await this.getReaction(postId, userId);

        console.warn('user reaction:', reactionData);

        if (reactionData === null) {

            console.log('ADD reaction:', reactionType);

            // Add your reaction.
            const addResult = await this._addReaction(postId, userId, reactionType);
            if (addResult !== null) {
                // Update post totals
                const post = await this.getOne(postId);
                if (post !== null) {
                    const publishingStats = isUndefinedOrNullOrEmpty(post.publishingStats)
                        ? { reactions: { count: 0 }, comments: { count: 0 }, shares: { count: 0 } }
                        : post.publishingStats;
                    publishingStats.reactions.count = publishingStats.reactions.count + 1;
                    publishingStats.reactions[reactionType] = publishingStats.reactions[reactionType] + 1;
                    if (publishingStats.reactions[reactionType] < 1) {
                        publishingStats.reactions[reactionType] = 1;
                    }

                    await this._updatePostStats(postId, publishingStats);
                }
            }

        } else {
            // Update your reaction.
            if (reactionData.reaction === reactionType) {

                console.log('DELETE reaction:', reactionType);

                // Delete reaction.
                const deleteResult = await this._deleteReaction(postId, userId);
                if (deleteResult !== null) {
                    // Update post totals
                    const post = await this.getOne(postId);
                    if (post !== null) {
                        const publishingStats = isUndefinedOrNullOrEmpty(post.publishingStats)
                            ? DEFAULT_PUBLISHING_STATS
                            : post.publishingStats;

                        publishingStats.reactions.count =
                            (isUndefinedOrNull(publishingStats.reactions.count) || isNaN(publishingStats.reactions.count))
                                ? 0 : publishingStats.reactions.count - 1;
                        publishingStats.reactions[reactionType] =
                            (isUndefinedOrNull(publishingStats.reactions[reactionType]) || isNaN(publishingStats.reactions[reactionType]))
                                ? 0 : publishingStats.reactions[reactionType] - 1;

                        if (publishingStats.reactions[reactionType] < 0) {
                            publishingStats.reactions[reactionType] = 0;
                        }
                        await this._updatePostStats(postId, publishingStats);
                    }
                }
            } else {

                console.log('CHANGE reaction:', reactionType);

                // Change reaction.
                const updateResult = await this._updateReaction(postId, userId, reactionType);
                if (updateResult !== null) {
                    // Update post totals
                    const post = await this.getOne(postId);
                    if (post !== null) {
                        const publishingStats = isUndefinedOrNullOrEmpty(post.publishingStats)
                            ? DEFAULT_PUBLISHING_STATS
                            : post.publishingStats;

                        publishingStats.reactions[reactionData.reaction] =
                            (isUndefinedOrNull(publishingStats.reactions[reactionData.reaction])
                                || isNaN(publishingStats.reactions[reactionData.reaction])
                            ) ? 0 : publishingStats.reactions[reactionData.reaction] - 1;
                        if (publishingStats.reactions[reactionData.reaction] < 0) {
                            publishingStats.reactions[reactionData.reaction] = 0;
                        }

                        publishingStats.reactions[reactionType] =
                            (isUndefinedOrNull(publishingStats.reactions[reactionType])
                                || isNaN(publishingStats.reactions[reactionType])
                            ) ? 0 : publishingStats.reactions[reactionType] + 1;
                        if (publishingStats.reactions[reactionType] < 1) {
                            publishingStats.reactions[reactionType] = 1;
                        }

                        await this._updatePostStats(postId, publishingStats);
                    }
                }

            }
        }
        return;
    }
}
