import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

// Import all the data models
import {
  IPermissionGroup,
  IPipeline,
  ICompany,
  ITeam,
  IUser,
  IUserLite,
} from '../models';

import { isUndefinedOrNullOrEmpty } from '../shared/utils';

// Import all the services
import { AuthService } from '../services/auth/auth.service';
import { DataService } from '../services/data/data.service';
import { IntegrationService } from '../services/integration/integration.service';
import { SessionService } from '../services/session/session.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  company: ICompany;
  heading: string;
  index: number;
  limitExceed: boolean;
  mode: string;
  subs = new Subscription();
  team: ITeam;
  teams: ITeam[] = [];
  teamId: string;

  userId: string;
  user: IUser;
  userNew: IUser;
  users: IUser[] = [];
  usersAvailable: IUser[] = [];

  companyForm: FormArray;
  userForm: FormGroup;

  emailLogoURL: string;
  smsLogoURL: string;
  emailAccounts: any[] = [];
  smsAccounts: any[] = [];

  filteredPermissionGroups: IPermissionGroup[] = [];
  filteredPipelines: IPipeline[] = [];
  permissionGroups: IPermissionGroup[] = [];
  pipelines: IPipeline[] = [];

  isCompanyAdmin: boolean;
  isTeamAdmin: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<AddUserComponent>,
    private integrationService: IntegrationService,
    private ngxLoader: NgxUiLoaderService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar
  ) {
    this.limitExceed = false;
    this.emailLogoURL = null;
    this.smsLogoURL = null;
    this.pipelines = [];
    this.filteredPipelines = [];
    this.mode = '1';
    this.teamId = isUndefinedOrNullOrEmpty(this.data.teamId)
      ? null
      : this.data.teamId;
    this.userId = isUndefinedOrNullOrEmpty(this.data.userId)
      ? null
      : this.data.userId;
    this.isCompanyAdmin = false;
    this.isTeamAdmin = false;
  }

  ngOnInit() {
    this.subs.add(
      this.authService.user.subscribe((user) => {
        this.user = user;
      })
    );

    this.subs.add(
      this.authService.company.subscribe((company) => {
        this.company = company;

        if (company) {
          const index = this.company.adminUsers.indexOf(this.user.id);
          if (index === -1) {
            this.isCompanyAdmin = false;
          } else {
            this.isCompanyAdmin = true;
          }
          if (isUndefinedOrNullOrEmpty(this.data.isTeamAdmin)) {
            this.isTeamAdmin = false;
          } else {
            this.isTeamAdmin = this.data.isTeamAdmin;
          }

          this.pipelines = company.pipelines;
          this.permissionGroups = company.permissionGroups;

          if (this.isCompanyAdmin || this.isTeamAdmin) {
            this.filteredPipelines = this.pipelines.filter(
              (pipeline) => pipeline.teamId === this.teamId
            );
            console.log('pipelines filtered', this.filteredPipelines);
          } else {
            // Only show the pipelines user has access to in the company.
            console.warn(
              'TODO: Filter the pipelines to only those the user has access to in the company.'
            );
            this.filteredPipelines = this.pipelines.filter(
              (pipeline) => pipeline.teamId === this.teamId
            );

            /*
              if (this.userId) {
                this.index = this.dataService.SubUsersNew.findIndex(item => item.id === this.userId);

                console.log(this.index);

                if (this.index !== -1) {
                  this.SingleUser = this.dataService.SubUsersNew[this.index];

                  this.filteredPipelines = this.pipelines
                    .filter(pipeline => pipeline.teamId === this.SingleUser.response.teamDetail[0].teamId);
                }
              } else {
                const message = 'userId is invalid: ' + this.userId;
                this.openSnackBar(message, 'close');
              }
              */
          }
        } else {
          this.pipelines = [];
          this.filteredPipelines = [];
          this.isCompanyAdmin = false;
          this.isTeamAdmin = false;
        }
      })
    );

    // Create the Reactive Form
    this.userForm = new FormGroup({
      name: new FormControl('', Validators.required),
      user: new FormControl(
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ),
      teamId: new FormControl('', Validators.required),
      permissionGroupId: new FormControl('', Validators.required),
      pipelineId: new FormControl('', Validators.required),
      emailIds: new FormControl(''),
      smsIds: new FormControl(''),
    });

    this.integrationService
      .getSettings({ serviceId: 'email' })
      .subscribe((accounts) => {
        this.emailAccounts = accounts;
      });

    this.integrationService
      .getSettings({ serviceId: 'sms' })
      .subscribe((accounts) => {
        this.smsAccounts = accounts;
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  addFromExisting() {
    this.mode = '2';
    this.usersAvailable = this.users.filter(
      (user) => this.team.users.indexOf(this.teamId) === -1
    );
  }

  addUser() {
    try {
      this.ngxLoader.start();

      const emailIds: string[] = [];
      const emailId = this.userForm.get('emailIds').value;
      if (emailId !== '') {
        emailIds.push(emailId);
      }

      const smsIds: string[] = [];
      const smsId = this.userForm.get('smsIds').value;
      if (smsId !== '') {
        smsIds.push(smsId);
      }

      let teamId = this.userForm.get('teamId').value;
      if (this.data.isTeamAdmin) {
        teamId = this.teamId;
      }

      const user: any = {
        name: this.userForm.get('name').value,
        email: this.userForm.get('email').value,
        teams: [
          {
            teamId,
            permissionGroupId: this.userForm.get('permissionGroupId').value,
          },
        ],
        pipelineId: this.userForm.get('pipelineIds').value,
        emailIds,
        smsIds,
      };

      console.log('ADD new user:', user);

      this.subs.add(
        this.dataService.addUserByAdminNew(user).subscribe(
          (result: any) => {
            if (result.data) {
              this.userForm.reset();
              // this.openSnackBar(result.message, 'close');
              this.openSnackBar('User created.', 'close');
              this.dialogRef.close();
            }
          },
          (error) => {
            console.error(error);
            this.ngxLoader.stop();
            if (error.message === 'Users limit exceeded!') {
              this.limitExceed = true;
            }
            this.openSnackBar(error.message, 'close');
            this.dialogRef.close();
          }
        )
      );
    } catch (error) {
      console.error(error);
      this.ngxLoader.stop();
      this.dialogRef.close();
    }
  }

  addUserToForm(user: any) {
    let emailId = '';
    if (
      typeof user.emailIds !== 'undefined' &&
      typeof user.emailIds[this.company.id] !== 'undefined'
    ) {
      emailId = user.emailIds[this.company.id];
    } else {
      emailId = '';
    }

    let smsId = '';
    if (
      typeof user.smsIds !== 'undefined' &&
      typeof user.smsIds[this.company.id] !== 'undefined'
    ) {
      smsId = user.smsIds[this.company.id];
    } else {
      smsId = '';
    }

    this.resetForm();
    this.userForm.get('name').setValue(user.response.displayName);
    this.userForm.get('email').setValue(user.response.userEmail);
    this.userForm.get('teamId').setValue(this.teamId);
    this.userForm.get('emailIds').setValue(emailId);
    this.userForm.get('smsIds').setValue(smsId);
    this.mode = '1';
  }

  backButton() {
    this.mode = '1';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFilteredPermissionGroups() {
    const teamId = this.userForm.get('teamId').value;
    return this.permissionGroups.filter(
      (group: any) => group.teamId === teamId
    );
  }

  getFilterPipelines() {
    return this.pipelines.filter((pipeline) => pipeline.teamId === this.teamId);
  }

  getTeamIndex(teamId: string, teamDetail: any[]) {
    return teamDetail.findIndex((team) => team.teamId === teamId);
  }

  onEmailChange() {
    const emailId = this.userForm.get('emailIds').value;
    console.log('onEmailChange() | emailIds', emailId);
  }

  onSmsChange() {
    const smsId = this.userForm.get('smsIds').value;
    console.log('onSmsChange() | smsIds', smsId);
  }

  onTeamChange() {
    this.filteredPipelines = this.pipelines.filter(
      (pipeline) => pipeline.teamId === this.userForm.get('teamId').value
    );
    console.log('onTeamChange() | pipelines filtered', this.filteredPipelines);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 2000 });
  }

  resetForm() {
    this.userForm.get('name').setValue('');
    this.userForm.get('email').setValue('');
    this.userForm.get('teamId').setValue('');
    this.userForm.get('permissionGroupId').setValue('');
    this.userForm.get('pipelineIds').setValue([]);
    this.userForm.get('smsIds').setValue([]);
    this.userForm.get('emailIds').setValue([]);
  }

  changeEmailLogoURL(url: string = null) {
    if (isUndefinedOrNullOrEmpty(url)) {
      this.emailLogoURL = null;
    } else {
      this.emailLogoURL = url;
    }
  }

  changeSmsLogoURL(url: string = null) {
    if (isUndefinedOrNullOrEmpty(url)) {
      this.smsLogoURL = null;
    } else {
      this.smsLogoURL = url;
    }
  }
}
