import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Setting, User } from '../../models';
import { asObservable } from './asObservable';

/* globally accessible app code (in every feature module) */
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';

const { 'v4': uuidv4 } = require('uuid');


@Injectable({
  providedIn: 'root'
})
export class SettingService {
  settingsCollection: AngularFirestoreCollection<Setting>;
  settingDoc: AngularFirestoreDocument<Setting>;
  settings: Observable<Setting[]>;
  setting: Observable<Setting>;

  debug = false;

  private COLLECTION_SETTINGS = 'settings';
  private enableSoftDelete = false;
  userEmail: string;
  user: User;
  uuid: string;

  // private isWaitingStatus = false;
  public _isWaiting = new BehaviorSubject<boolean>(false);

  private taskRef: AngularFirestoreCollection<any>;
  docId: Observable<any[]>;


  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    public userService: UserService,
    private http: HttpClient,
  ) {
    console.log('INIT SettingService.constructor()');
    this.resetService();

    this.authService.user
      .subscribe(user => {
        console.log('SettingService.constructor() -> this.authService.getAuth().subscribe()');

        if (user) {
          console.log('SettingService.constructor() - AUTHORIZED'); // | (uid) == ' + auth.uid + '  | (auth) == ', auth);
          this.userEmail = user.email;

          /*
          this.tasksCollection = this.afs.collection(this.COLLECTION_TASKS, ref =>
              ref
                  .where('uuid', '==', '')
          );
          this.tasksCollection.snapshotChanges().pipe(map(changes => {
              if (!changes.length) {
                  console.log('is-empty');
                  this.setWaitStatus(false);
              } else {
                  this.setWaitStatus(true);
              }
              return changes.map(a => {
                  const data = a.payload.doc.data() as any;
                  const id = a.payload.doc.id;
                  // console.log('TaskService.contructor() - task: ', data);
                  return { id, ...data };
              });
          })).subscribe(res => console.log(res));
          */

        } else {
          this.resetService();
          console.log('TaskService.constructor() - NOT AUTHORIZED');
        }
      });
  }


  get isWaiting() {
    return asObservable(this._isWaiting);
  }


  public setWaitStatus(value: boolean): void {
    this._isWaiting.next(value);
  }


  private resetService() {
    console.log('*** SettingService.resetService() ***');

    this.user = null; // This comes from UserService
    this.uuid = '';
    this.userEmail = '';

    this.settingsCollection = this.afs.collection(this.COLLECTION_SETTINGS, ref =>
      ref
        .where('uuid', '==', '')
    );
    this.settingsCollection.snapshotChanges()
      .pipe(
        map(changes => {
          if (!changes.length) {
            console.log('is-empty');
            this.setWaitStatus(false);
          } else {
            this.setWaitStatus(true);
          }
        }))
      .subscribe();
  }


  get(id: string): Observable<Setting> {

    // ToDo: Add restriction for Contacts you own or have Visibility to.

    this.settingDoc = this.afs.doc<Setting>(this.COLLECTION_SETTINGS + '/' + id);
    this.setting = this.settingDoc
      .snapshotChanges()
      .pipe(
        map(action => {
          if (action.payload.exists === false) {
            return null;
          } else {
            const data = action.payload.data() as Setting;
            data.id = action.payload.id;
            return data;
          }
        }));

    return this.setting;
  }

  // getAll(uid: string, category: string, city: string, state: string) {
  getAll(uuid: string) {

    // Get leads with ID.
    this.settingsCollection = this.afs
      .collection(this.COLLECTION_SETTINGS, ref => {
        return ref
          .where('uuid', '==', uuid);
      });

    return this.settingsCollection
      .snapshotChanges()
      .pipe(
        map(changes => {
          if (changes === []) {
            this.setWaitStatus(false);
          } else {
            this.setWaitStatus(true);
          }
          return changes.map(action => {
            const data = action.payload.doc.data();
            data.id = action.payload.doc.id;
            console.log({ setting: { data: data } });
            return data;
          });
        })
      );
  }

}
