import * as firebase from 'firebase/app';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { IPermissions } from '../models/IPermissions';
import { AngularCsv } from 'angular-csv-ext';

import { throwError, timer } from 'rxjs';
import { catchError, map, scan, take, tap } from 'rxjs/operators';
import localforage from "localforage";

export function stream(name: string, delay: number, count: number, logLevel?: string) {
    let s = timer(0, delay)
        .pipe(map((v) => v + `-${ name }`));
    if (count !== -1) {
        s = s.pipe(take(count));
    }

    if (logLevel === 'full') {
        s = s.pipe(tap(fullObserver(name)));
    } else if (logLevel === 'partial') {
        s = s.pipe(tap(partialObserver(name)));
    }

    return s;
}

export function fullObserver(streamName: string) {
    return {
        next(value: any) {
            const message = streamName.length < 5 ? `[${ streamName }]:\t\t${ value }` : `[${ streamName }]:\t${ value }`;
            log(message);
        },
        error() {
            const message = streamName.length < 5 ? `[${ streamName }]:\t\tERROR` : `[${ streamName }]:\tERROR`;
            log(message);
        },
        complete() {
            const message = streamName.length < 5 ? `[${ streamName }]:\t\tCOMPLETE` : `[${ streamName }]:\tCOMPLETE`;
            log(message);
        }
    };
}

export function partialObserver(streamName: string) {
    return {
        error() {
            const message = streamName.length < 5 ? `[${ streamName }]:\t\tERROR` : `[${ streamName }]:\tERROR`;
            log(message);
        },
        complete() {
            const message = streamName.length < 5 ? `[${ streamName }]:\t\tCOMPLETE` : `[${ streamName }]:\tCOMPLETE`;
            log(message);
        }
    };
}

function log(value: string) {
    document.body.querySelector('pre').appendChild(document.createElement('div')).textContent = value;
}

export function throwOnItem(count: number, streamName: string) {
    return (source) => source.pipe(
        scan((acc: any, value: any) => [value, ...acc], []),
        map((values: number[]) => {
            if (values.length === count) {
                throw new Error(`Error on the stream '${ stream }'!`);
            }
            return values[0];
        }),
        tap(partialObserver(streamName))
    );
}

const moment = require('moment');
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export enum API_KEY_TYPE {
    COMPANY = 'company',
    USER = 'user',
}

export enum CUSTOM_FIELD_TYPE {
    COMPANY = 'company',
    CONTACT = 'contact',
    DEAL = 'deal',
    ORGANIZATION = 'organization',
}

export const DEFAULT_COUNTRY_CODE = 'US';

export enum SERVICE {
    AUTOMATION = 'automation',
    CRM = 'crm',
    EMAIL = 'email',
    PAYMENT = 'payment',
    RINGLESS = 'rvm',
    RVM = 'rvm',
    SMS = 'sms',
    SOCIAL = 'social',
    VOICE = 'voice',
    VOICEMAIL = 'voicemail',
}

export enum PROVIDER {
    AMAZON_SES = 'amazon_ses',
    FACEBOOK = 'facebook',
    GMAIL = 'gmail',
    GSUITE = 'gsuite',
    LEADCARROT = 'leadcarrot',
    LINKEDIN = 'linkedin',
    MAILCHIMP = 'mailchimp',
    MAILGUN = 'mailgun',
    OFFICE365 = 'office365',
    PIPEDRIVE = 'pipedrive',
    PLIVO = 'plivo',
    SENDGRID = 'sendgrid',
    SLYBROADCAST = 'slybroadcast',
    SMTP = 'smtp',
    STRIPE = 'stripe',
    TEXTMAGIC = 'textmagic',
    TWILIO = 'twilio',
    YAHOO = 'yahoo',
    YAHOO_MAIL = 'yahoo_mail',
    YELP = 'yelp',
    ZAPIER = 'zapier',
}

export const enum ACTION {
    AMAZON_EMAIL_SEND = 'amazon-email-send',
    DEAL_DRIP = 'deal-drip',
    DEAL_MOVE = 'deal-move',
    DEAL_MOVE_BATCH = 'deal-move-batch',
    EMAIL_SEND = 'email-send',
    EMAIL_CAMPAIGN_SEND = 'email-campaign-send',
    FB_PIXEL_CHECK = 'fb-pixel-check',
    GMAIL_EMAIL_SEND = 'gmail-email-send',
    GMB_PLACE_LOOKUP = 'gmb-place-lookup',
    GSUITE_EMAIL_SEND = 'gsuite-email-send',
    MAILGUN_EMAIL_SEND = 'mailgun-email-send',
    OFFICE365_EMAIL_SEND = 'office365-email-send',
    PLIVO_SMS_SEND = 'plivo-sms-send',
    RVM_SEND = 'rvm-send',
    SCRAPE_WEBSITE = 'scrape-website',
    SENDGRID_EMAIL_SEND = 'sendgrid-email-send',
    SENDINBLUE_EMAIL_SEND = 'sendinblue-email-send',
    SLYBROADCAST_RVM_SEND = 'slybroadcast-rvm-send',
    SSL_CHECK = 'ssl-check',
    SOCIAL_AVATAR = 'social-avatar',
    SMS_SEND = 'sms-send',
    SMS_CAMPAIGN_SEND = 'sms-campaign-send',
    SMTP_EMAIL_SEND = 'smtp-email-send',
    STRIPE_OTC = 'stripe-otc',
    TEXTMAGIC_SMS_SEND = 'textmagic-sms-send',
    TWILIO_SMS_SEND = 'twilio-sms-send',
    YAHOO_EMAIL_SEND = 'yahoo-email-send',
    YELP_BIZ_SEARCH = 'yelp-biz-search',
    YELP_BIZ_DETAILS = 'yelp-biz-details',
}

export interface DataExport {
    format: string;
    step: number;
    count: number;
    percent: number;
    data: [any];
}

export enum ACTIVITY_TYPE {
    EMAIL = 'EMAIL',
    CALL = 'CALL',
    MEETING = 'MEETING',
    TASK = 'TASK',
    DEADLINE = 'DEADLINE',
    LUNCH = 'LUNCH',
}

export enum ALERT {
    Success,
    Error,
    Info,
    Warning
}

export const enum COLLECTION {
    API_KEY = 'ApiKey',
    ACTIVITY = 'Activity',
    AUTOMATION_SEQUENCE = 'sequence',
    AUTOMATION_STEP = 'autoStep',
    AUTOMATION_CALLFLOW = 'callflow',
    AUTOMATION_CALLFLOW_STEP = 'callflowStep',
    BBB = 'bbb',
    CAMPAIGN = 'campaign',
    CATEGORY_KEYWORDS = 'category_keywords',
    CHAT_GROUPS = 'Groups',
    CHAT_GROUP_MESSAGES = 'Chat',
    COMPANY = 'Company',
    COMPANY_CUSTOM = 'CompanyCustomField',
    COMPANY_SUBSCRIPTION = 'CompanySubscriptions',
    CONTACT = 'ContactPerson',
    CONTACT_CUSTOM = 'ContactCustomField',
    D7 = 'd7',
    D7_API_KEYS = 'd7_apikeys',
    D7_HISTORY = 'd7_history',
    DEAL = 'Deal',
    DEAL_CUSTOM = 'DealCustomField',
    EMAIL_JOB = 'EmailJobs',
    EMAIL_CAMPAIGN = 'EmailCampaignJobs',
    FACEBOOK = 'fb',
    GOOGLE = 'gmb',
    GMB = 'gmb',
    GMB_API_KEYS = 'gmb_api_keys',
    GMB_TEST = 'gmb_test',
    INTEGRATION = 'integration',
    LEAD = 'leads_v2',
    LOG = 'logs',
    NEW_KEYWORDS = 'new_keywords',
    NOTIFICATION = 'notifications',
    NOTES = 'notes',
    OPTION = 'Options',
    ORGANIZATION = 'Organization',
    ORGANIZATION_CUSTOM = 'OrganizationCustomField',
    PERMISSION_GROUP = 'PermissionGroup',
    POSTS = 'posts',
    PROXY_STATUS_LOGS = 'COL_PROXY_STATUS_LOGS',
    REPORT = 'reports',
    SCALESERP_API_KEYS = 'scaleserp_apikeys',
    SCALESERP_HISTORY = 'scaleserp_history',
    SEARCH_OPTION = 'search_options',
    SMS_JOB = 'SmsJobs',
    SMS_CAMPAIGN = 'SmsCampaignJobs',
    STAGE = 'stage',
    TASK = 'task',
    TASK_PIPELINE = 'task_pipeline',
    TEAM = 'Team',
    TIMELINE = 'timeline',
    USER = 'User',
    WEBHOOK = 'webhook',
    YELP = 'yelp',
    YELP_API_KEYS = 'yelp_apikeys',
    ZENSERP_TRIGGER = 'trigger_zenserp',
    ZENSERP_API_KEYS = 'zenserp_apikeys',
    ZENSERP_HISTORY = 'zenserp_history',
}

export const enum JOB_STATUS {
    NEW = 'new',
    QUEUED = 'queued',
    PROCESSING = 'processing',
    DONE = 'done',
    ERROR = 'error',
}

export const enum NETWORK {
    INSTAGRAM = 'instagram',
    LINKEDIN = 'linkedin',
    TWITTER = 'twitter',
    YOUTUBE = 'youtube',
}

export const enum PRIORITY {
    SYSTEM = 0,
    HIGH = 1,
    NORMAL = 2,
    LOW = 3,
}

export const enum TASK_STATUS {
    ERROR = 'error',
    DONE = 'done',
    PAUSED = 'paused',
    PROCESSING = 'processing',
    QUEUED = 'queued',
    SCHEDULED = 'scheduled',
}

export class Utils {
    http: HttpClient;

    convertTimestampToString(timestampObj): string {
        return new Date(timestampObj._seconds * 1000).toLocaleString();
    }

    convertToInt(floatNumber): number {
        return Math.floor(floatNumber);
    }

    createCloudHeaders(authToken = '', type = 'json'): any {
        let headers: HttpHeaders;
        if (typeof authToken !== 'undefined' && authToken !== null && authToken.trim() !== '') {
            // console.log('!!! found auth token !!!');
            headers = new HttpHeaders()
                .set('x-firebase-id-token', authToken);
            // .set('authorization', 'Bearer ' + authToken);
            if (type === 'json') {
                headers = headers.append('Content-Type', 'application/json');
            }

        } else {
            if (type === 'json') {
                headers = new HttpHeaders()
                    .set('Content-Type', 'application/json');
            }
        }

        // console.log('headers:', headers);
        return headers;
    }

    createAuthHeaders(authToken = '', type = 'json'): any {
        let headers: HttpHeaders;

        if (typeof authToken !== 'undefined' && authToken !== null && authToken.trim() !== '') {
            headers = new HttpHeaders()
                .set('Authorization', authToken);
            // .set('x-firebase-id-token', authToken);
            if (type === 'json') {
                headers = headers.append('Content-Type', 'application/json');
            }
        } else {
            if (type === 'json') {
                headers = new HttpHeaders()
                    .set('Content-Type', 'application/json');
            }
        }

        // console.log('authToken:', authToken);

        return headers;
    }

    async createTicket(options: any, lastTicket: any) {
        const body = {
            ticket: {
                subject: `No search results for [${ options.category }] in [${ options.geoCoord.locality }, ${ options.geoCoord.state }]`,
                comment: {
                    body: `No search results found for:\n\n`
                        + `category: [${ options.category }]\n`
                        + `location: [${ options.geoCoord.locality }, ${ options.geoCoord.state }]\n`
                        + `country: [${ options.geoCoord.country }]\n\n`
                        + `user:\n`
                        + `id: [${ options.userId }]\n`
                        + `name: [${ options.userName }]\n`
                        + `email: [${ options.userEmail }]`
                },
                priority: 'urgent',
                external_id: 'no_search_results',
                type: 'problem'
            }
        };

        if (lastTicket === body) {
            return Promise.resolve();
        }

        lastTicket = body;
        const url = 'https://leadcarrot.zendesk.com/api/v2/tickets.json';
        const agentName = 'andre.v.fortin@gmail.com';
        const agentToken = 'BpWNaSuxDJKsIDphH6rPCqNgsCdzqBVr5seOjlpM';

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Basic ' + btoa(agentName + '/token:' + agentToken));

        return await this.http.post<any>(url, body, { headers })
            .pipe(
                map((res: any) => res),
                catchError((error: any) => throwError(error))
            )
            .subscribe(value => {
                if (value) {
                    console.log('***** create support ticket:', value);
                }
            });

        /*
        const data = {
            category: options.category,
            city: options.geoCoord.locality,
            state: options.geoCoord.state,
            country: options.geoCoord.country,
            userId: options.userId,
            userName: options.userName,
            userEmail: options.userEmail,
            priority: 'urgent',
            external_id: 'no_search_results',
        };
        await this.afs.collection('log_no_results').doc()
            .set(data).catch((error: any) => console.warn(error));

        return Promise.resolve(true);
        */
    }

    daysBetween(startDate: any, endDate: any): number {
        // The number of milliseconds in all UTC days (no DST)
        const oneDay = 1000 * 60 * 60 * 24;

        // A day in UTC always lasts 24 hours (unlike in other time formats)
        const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

        // so it's safe to divide by 24 hours
        return (start - end) / oneDay;
    }

    downloadFilteredExport(dataJson: any, filename: string): AngularCsv {

        console.log('Filename: ', filename);
        console.log('CSV Export', dataJson);

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: true,
            noDownload: false
        };

        if (typeof filename === 'undefined' || filename === null || filename.trim() === '') {
            return null;
        }

        return new AngularCsv(dataJson, filename.trim(), options);
    }

    getDefaultItem(allItems: any[]): any {
        let defaultValue = null;
        if (allItems.length) {
            let isFound = false;
            for (const item of allItems) {
                if (item.isCompany && item.isDefault) {
                    defaultValue = item;
                    isFound = true;
                }
            }
            if (!isFound) {
                for (const item of allItems) {
                    if (!item.isCompany && item.isDefault) {
                        defaultValue = item;
                        isFound = true;
                    }
                }
            }
            if (!isFound) {
                defaultValue = allItems[0];
            }
        }

        return defaultValue;
    }

    hasPermission(objectName: string, permissionName: string, permissions: any = {}) {
        try {
            if (isUndefinedOrNullOrEmpty(objectName)) {
                return false;
            }
            if (isUndefinedOrNullOrEmpty(permissionName)) {
                return false;
            }
            if (isUndefinedOrNullOrEmpty(permissions)) {
                return false;
            }

            let isAllowed = false;
            switch (objectName) {
                case 'activity':
                    isAllowed = hasPermissionActivity(permissionName, permissions);
                    break;
                case 'automation':
                    isAllowed = hasPermissionAutomation(permissionName, permissions);
                    break;
                case 'campaign':
                    isAllowed = hasPermissionCampaign(permissionName, permissions);
                    break;
                case 'dashboard':
                    isAllowed = hasPermissionDashboard(permissionName, permissions);
                    break;
                case 'company':
                    isAllowed = hasPermissionCompany(permissionName, permissions);
                    break;
                case 'contact':
                    isAllowed = hasPermissionContact(permissionName, permissions);
                    break;
                case 'custom_field':
                    isAllowed = hasPermissionCustomField(permissionName, permissions);
                    break;
                case 'deal':
                    isAllowed = hasPermissionDeal(permissionName, permissions);
                    break;
                case 'emailCampaign':
                    isAllowed = hasPermissionEmailCampaign(permissionName, permissions);
                    break;
                case 'import':
                    isAllowed = hasPermissionImport(permissionName, permissions);
                    break;
                case 'integration':
                    isAllowed = hasPermissionIntegration(permissionName, permissions);
                    break;
                case 'marketplace':
                    isAllowed = hasPermissionMarketplace(permissionName, permissions);
                    break;
                case 'note':
                    isAllowed = hasPermissionNote(permissionName, permissions);
                    break;
                case 'organization':
                    isAllowed = hasPermissionOrganization(permissionName, permissions);
                    break;
                case 'permission':
                    isAllowed = hasPermissionPermission(permissionName, permissions);
                    break;
                case 'pipeline':
                    isAllowed = hasPermissionPipeline(permissionName, permissions);
                    break;
                case 'team':
                    isAllowed = hasPermissionTeam(permissionName, permissions);
                    break;
                case 'upload':
                    isAllowed = hasPermissionUpload(permissionName, permissions);
                    break;
                case 'user':
                    isAllowed = hasPermissionUser(permissionName, permissions);
                    break;
                default:
                    isAllowed = false;
            }
            return isAllowed;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    hasPermissionActivity(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.activityAdd;
                break;
            case 'read':
                isAllowed = permissions.activityRead;
                break;
            case 'update':
                isAllowed = permissions.activityUpdate;
                break;
            case 'delete':
                isAllowed = permissions.activityDelete;
                break;
            case 'other':
                isAllowed = permissions.activityOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionAutomation(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.automationAdd;
                break;
            case 'read':
                isAllowed = permissions.automationRead;
                break;
            case 'update':
                isAllowed = permissions.automationUpdate;
                break;
            case 'delete':
                isAllowed = permissions.automationDelete;
                break;
            case 'other':
                isAllowed = permissions.automationOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionCompany(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        return false;
        /*
        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.companyAdd;
                break;
            case 'read':
                isAllowed = permissions.companyRead;
                break;
            case 'update':
                isAllowed = permissions.companyUpdate;
                break;
            case 'delete':
                isAllowed = permissions.companyDelete;
                break;
            case 'other':
                isAllowed = permissions.companyOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
        */
    }

    hasPermissionContact(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.contactAdd;
                break;
            case 'read':
                isAllowed = permissions.contactRead;
                break;
            case 'update':
                isAllowed = permissions.contactUpdate;
                break;
            case 'delete':
                isAllowed = permissions.contactDelete;
                break;
            case 'other':
                isAllowed = permissions.contactOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionCustomField(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.customFieldAdd;
                break;
            case 'read':
                isAllowed = permissions.customFieldRead;
                break;
            case 'update':
                isAllowed = permissions.customFieldUpdate;
                break;
            case 'delete':
                isAllowed = permissions.customFieldDelete;
                break;
            case 'other':
                isAllowed = permissions.customFieldOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionDashboard(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.dashboardAdd;
                break;
            case 'read':
                isAllowed = permissions.dashboardRead;
                break;
            case 'update':
                isAllowed = permissions.dashboardUpdate;
                break;
            case 'delete':
                isAllowed = permissions.dashboardDelete;
                break;
            case 'other':
                isAllowed = permissions.dashboardOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionDeal(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.dealAdd;
                break;
            case 'read':
                isAllowed = permissions.dealRead;
                break;
            case 'update':
                isAllowed = permissions.dealUpdate;
                break;
            case 'delete':
                isAllowed = permissions.dealDelete;
                break;
            case 'other':
                isAllowed = permissions.dealOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionImport(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.dealAdd;
                break;
            case 'read':
                isAllowed = permissions.dealRead;
                break;
            case 'update':
                isAllowed = permissions.dealUpdate;
                break;
            case 'delete':
                isAllowed = permissions.dealDelete;
                break;
            case 'other':
                isAllowed = permissions.dealOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionMarketplace(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        return false;
        /*

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.marketplaceAdd;
                break;
            case 'read':
                isAllowed = permissions.marketplaceRead;
                break;
            case 'update':
                isAllowed = permissions.marketplaceUpdate;
                break;
            case 'delete':
                isAllowed = permissions.marketplaceDelete;
                break;
            case 'other':
                isAllowed = permissions.marketplaceOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
        */
    }

    hasPermissionIntegration(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        /*
            Currently permissions for integration are optional.
            Therefore we return TRUE for the standard options of:
                -create, read, update, delete, other
            if they are missing
        */

        let isAllowed = false;

        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                if (typeof permissions.integrationAdd !== 'undefined') {
                    isAllowed = permissions.integrationAdd;
                }
                break;
            case 'read':
                if (typeof permissions.integrationRead !== 'undefined') {
                    isAllowed = permissions.integrationRead;
                }
                break;
            case 'update':
                if (typeof permissions.integrationUpdate !== 'undefined') {
                    isAllowed = permissions.integrationUpdate;
                }
                break;
            case 'delete':
                if (typeof permissions.integrationDelete !== 'undefined') {
                    isAllowed = permissions.integrationDelete;
                }
                break;
            case 'other':
                if (typeof permissions.integrationOther !== 'undefined') {
                    isAllowed = permissions.integrationOther;
                }
                break;
            default:
                isAllowed = false;
        }

        return isAllowed;
    }

    hasPermissionLeadFinder(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        /*
            Currently permissions for Lead Finder are optional.
            Therefore we return TRUE for the standard options of:
                -create, read, update, delete, other
            if they are missing
        */

        let isAllowed = false;

        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                if (typeof permissions.leadFinderAdd !== 'undefined') {
                    isAllowed = permissions.leadFinderAdd;
                }
                break;
            case 'read':
                if (typeof permissions.leadFinderRead !== 'undefined') {
                    isAllowed = permissions.leadFinderRead;
                }
                break;
            case 'update':
                if (typeof permissions.leadFinderUpdate !== 'undefined') {
                    isAllowed = permissions.leadFinderUpdate;
                }
                break;
            case 'delete':
                if (typeof permissions.leadFinderDelete !== 'undefined') {
                    isAllowed = permissions.leadFinderDelete;
                }
                break;
            case 'other':
                if (typeof permissions.leadFinderOther !== 'undefined') {
                    isAllowed = permissions.leadFinderOther;
                }
                break;
            default:
                isAllowed = false;
        }

        return isAllowed;
    }

    hasPermissionNote(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.noteAdd;
                break;
            case 'read':
                isAllowed = permissions.noteRead;
                break;
            case 'update':
                isAllowed = permissions.noteUpdate;
                break;
            case 'delete':
                isAllowed = permissions.noteDelete;
                break;
            case 'other':
                isAllowed = permissions.noteOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionOrganization(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.organizationAdd;
                break;
            case 'read':
                isAllowed = permissions.organizationRead;
                break;
            case 'update':
                isAllowed = permissions.organizationUpdate;
                break;
            case 'delete':
                isAllowed = permissions.organizationDelete;
                break;
            case 'other':
                isAllowed = permissions.organizationOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionPermission(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.permissionGroupAdd;
                break;
            case 'read':
                isAllowed = permissions.permissionGroupRead;
                break;
            case 'update':
                isAllowed = permissions.permissionGroupUpdate;
                break;
            case 'delete':
                isAllowed = permissions.permissionGroupDelete;
                break;
            case 'other':
                isAllowed = permissions.permissionGroupOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionPipeline(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.pipelineAdd;
                break;
            case 'read':
                isAllowed = permissions.pipelineRead;
                break;
            case 'update':
                isAllowed = permissions.pipelineUpdate;
                break;
            case 'delete':
                isAllowed = permissions.pipelineDelete;
                break;
            case 'other':
                isAllowed = permissions.pipelineOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionTeam(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.teamAdd;
                break;
            case 'read':
                isAllowed = permissions.teamRead;
                break;
            case 'update':
                isAllowed = permissions.teamUpdate;
                break;
            case 'delete':
                isAllowed = permissions.teamDelete;
                break;
            case 'other':
                isAllowed = permissions.teamOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionUpload(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.uploadAdd;
                break;
            case 'read':
                isAllowed = permissions.uploadRead;
                break;
            case 'update':
                isAllowed = permissions.uploadUpdate;
                break;
            case 'delete':
                isAllowed = permissions.uploadDelete;
                break;
            case 'other':
                isAllowed = permissions.uploadOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionUser(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.userAdd;
                break;
            case 'read':
                isAllowed = permissions.userRead;
                break;
            case 'update':
                isAllowed = permissions.userUpdate;
                break;
            case 'delete':
                isAllowed = permissions.userDelete;
                break;
            case 'other':
                isAllowed = permissions.userOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionCampaign(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.smsCampaignAdd;
                break;
            case 'read':
                isAllowed = permissions.smsCampaignRead;
                break;
            case 'update':
                isAllowed = permissions.smsCampaignUpdate;
                break;
            case 'delete':
                isAllowed = permissions.smsCampaignDelete;
                break;
            case 'other':
                isAllowed = permissions.smsCampaignOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    hasPermissionEmailCampaign(name: string, permissions: IPermissions): boolean {
        if (this.isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (this.isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.emailCampaignAdd;
                break;
            case 'read':
                isAllowed = permissions.emailCampaignRead;
                break;
            case 'update':
                isAllowed = permissions.emailCampaignUpdate;
                break;
            case 'delete':
                isAllowed = permissions.emailCampaignDelete;
                break;
            case 'other':
                isAllowed = permissions.emailCampaignOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    }

    isUndefinedOrNull(field: any): boolean {
        if (
            typeof field === 'undefined'
            || field === null
        ) {
            return true;
        } else {
            return false;
        }
    }

    isUndefinedOrNullOrEmpty(field: any): boolean {
        try {
            if (this.isUndefinedOrNull(field)) {
                return true;
            }
            if (typeof field === 'string' && field.trim() === '') {
                return true;
            } else if (Array.isArray(field) && field.length === 0) {
                return true;
            } else if (typeof field === 'object' && isEmpty(field)) {
                return true;
            }
            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    formatPhoneNumber(phoneNumber: string, countryCode: string = 'US'): string {
        if (typeof phoneNumber === 'string') {
            try {
                const phone = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
                return phoneUtil.formatOutOfCountryCallingNumber(phone, countryCode);
            } catch (error) {
                return phoneNumber;
            }
        } else {
            return '';
        }
    }

    convertFromString(timestamp: any): string {
        if (typeof timestamp !== 'string') {
            return this.convertTimestampToString(timestamp);
        }
        if (timestamp !== null && timestamp.trim() !== '') {
            return new Date(timestamp).toLocaleString();
        } else {
            return '';
        }
    }

    resetForm(form: FormGroup): void {
        form.reset();
        Object.keys(form.controls).forEach(key => {
            form.controls[key].setErrors(null);
        });
    }


    toE164(phoneNumber: string, countryCode: string = 'US'): string {
        if (phoneNumber === '') {
            return '';
        }
        const phone = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
        return phoneUtil.format(phone, PNF.E164);
    }

    toNational(phoneNumber: string, countryCode: string = 'US'): string {
        try {
            if (phoneNumber === '') {
                return '';
            }
            const phone = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
            return phoneUtil.format(phone, PNF.NATIONAL);
        } catch (error) {
            console.log(error);
            console.log('phoneNumber:', phoneNumber);
            return '';
        }
    }
}


export function arrayUnique(array1: any): any[] {
    if (typeof array1 === 'undefined') {
        return [];
    }
    if (array1.length < 2) {
        return array1;
    }

    const set = new Set(array1);
    return Array.from(set);
}


export function convertTimestampToString(timestampObj): string {
    return new Date(timestampObj._seconds * 1000).toLocaleString();
}

export function convertToInt(floatNumber): number {
    return Math.floor(floatNumber);
}

export function createCloudHeaders(authToken = '', type = 'json'): any {
    let headers: HttpHeaders;
    if (typeof authToken !== 'undefined' && authToken !== null && authToken.trim() !== '') {
        // console.log('!!! found auth token !!!');
        headers = new HttpHeaders()
            .set('x-firebase-id-token', authToken);
        // .set('authorization', 'Bearer ' + authToken);
        if (type === 'json') {
            headers = headers.append('Content-Type', 'application/json');
        }

    } else {
        if (type === 'json') {
            headers = new HttpHeaders()
                .set('Content-Type', 'application/json');
        }
    }

    // console.log('headers:', headers);
    return headers;
}

export function daysBetween(startDate: any, endDate: any): number {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    // so it's safe to divide by 24 hours
    return (start - end) / oneDay;
}

export function downloadFilteredExport(dataJson: any, filename: string): AngularCsv {

    console.log('Filename: ', filename);
    console.log('CSV Export', dataJson);

    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        noDownload: false
    };

    if (typeof filename === 'undefined' || filename === null || filename.trim() === '') {
        return null;
    }

    return new AngularCsv(dataJson, filename.trim(), options);
}

// Useful function to sanity check an object and required properties

export function existsNotNullNotEmpty(source: any) {
    if (typeof source === 'undefined' || source === null || source.trim() === '') {
        return false;
    } else {
        return true;
    }
}

export function notExistsOrNullOrEmpty(source: any) {
    if (typeof source === 'undefined' || source === null || source.trim() === '') {
        return true;
    } else {
        return false;
    }
}

export function existsNotNullNotEmptyObject(source: any, properties: string[]) {

    // Sanity check required data
    if (typeof source === 'undefined' || source === null) {
        return { success: false, data: 'WARN - utils.existsNotNullNotEmptyObject() - No object specified' };
    }

    // Sanity check required data
    if (typeof properties === 'undefined' || properties === null || !properties.length) {
        return { success: false, data: 'WARN - utils.existsNotNullNotEmptyObject() - No properties specified' };
    }

    const sourceKeys = Object.keys(source);
    let keyName = '';
    let isEmpty = false;
    let isMissing = false;

    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < properties.length; index++) {
        keyName = properties[index];
        if (typeof keyName === 'undefined') {
            console.error('keyName: [' + keyName + '] is undefined');
            isMissing = true;
            break;
        }
        if (sourceKeys.includes(keyName)) {
            if (typeof source[keyName] === 'undefined') {
                console.error('source[keyName]: [' + keyName + '] is undefined');
                isMissing = true;
                break;
            }
            if (source[keyName] === '' || source[keyName].trim() === '') {
                console.log('utils : null or empty ( key: [' + keyName + '], value: [' + source[keyName] + '] )');
                isEmpty = true;
                break;
            }
        } else {
            console.log('utils : missing ( keyname ) ', keyName);
            isMissing = true;
            break;
        }
    }

    // Check if any tests failed
    if (isMissing || isEmpty) {
        return { success: false, data: keyName };
    } else {
        return { success: true, data: '' };
    }
}

export function convert(timestamp: any) {
    try {
        if (timestamp === null) {
            return '';
        }
        const dt = new Date(timestamp._seconds * 1000).toLocaleString();
        // console.log('new dateTime:', dt);
        return dt;
    } catch (error) {
        // console.error(error);
        // console.log('timestamp =', timestamp);
    }
}

export function convertDate(dateString: any) {
    // console.log('new dateTime:', dateString);
    if (typeof dateString === 'string') {
        return new Date(dateString).toLocaleString();
    }

    if (typeof dateString === 'undefined' || dateString === null) {
        return '';
    } else if (dateString !== null && typeof dateString.seconds !== 'undefined') {
        return new Date(dateString.seconds * 1000).toLocaleString();
    } else {
        return dateString.toLocaleString();
    }
}

export function convertFromString(timestampString: string): string {
    if (timestampString !== null && timestampString !== undefined && timestampString.trim() !== '') {
        return new Date(timestampString).toLocaleString();
    } else {
        return '';
    }
}

export function createAuthHeaders(authToken = '', type = 'json'): any {
    let headers: HttpHeaders;

    if (typeof authToken !== 'undefined' && authToken !== null && authToken.trim() !== '') {
        headers = new HttpHeaders()
            .set('Authorization', authToken);
        // .set('x-firebase-id-token', authToken);
        if (type === 'json') {
            headers = headers.append('Content-Type', 'application/json');
        }
    } else {
        if (type === 'json') {
            headers = new HttpHeaders()
                .set('Content-Type', 'application/json');
        }
    }

    // console.log('authToken:', authToken);

    return headers;
}

export function formatPhoneNumber(phoneNumber: string, countryCode: string = 'US'): string {
    if (typeof phoneNumber === 'string') {
        try {
            const phone = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
            return phoneUtil.formatOutOfCountryCallingNumber(phone, countryCode);
        } catch (error) {
            return phoneNumber;
        }
    } else {
        return '';
    }
}

export async function getDocument(uid: string, collectionName: string): Promise<any> {
    try {
        if (typeof uid === 'undefined' || uid === null) {
            throw Error('Document ID is UNDEFINED or NULL');
        }

        if (typeof collectionName === 'undefined' || collectionName === null) {
            throw Error('Collection ID is UNDEFINED or NULL');
        }

        const data = firebase.firestore()
            .doc(`${ collectionName }/${ uid }`)
            .get()
            .then(querySnapshot => {
                if (!querySnapshot.exists) {
                    // Do nothing as we didn't find it
                    throw Error('No document exists in collection [' + collectionName + '] with id [' + uid + ']');
                } else {
                    const docData = querySnapshot.data();
                    if (typeof docData === 'undefined') {
                        throw Error('UNDEFINED document data in collection [' + collectionName + '] with id [' + uid + ']');
                    }
                    docData.id = querySnapshot.id;
                    // console.log(`collection(${collectionName}).doc(${uid}):`, docData);
                    return docData;
                }
            });
        return Promise.resolve(data);
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
}

// const teams = getDocuments('companyId', companyId, COLLECTION.TEAM);

export async function getDocuments(fieldName: string, uid: string, collectionName: string, checkIsDeleted: boolean = false): Promise<any> {
    try {
        if (typeof fieldName === 'undefined' || fieldName === null) {
            throw Error('Field name is UNDEFINED or NULL');
        }
        if (typeof uid === 'undefined' || uid === null) {
            throw Error('Document ID is UNDEFINED or NULL');
        }
        if (typeof collectionName === 'undefined' || collectionName === null) {
            throw Error('Collection ID is UNDEFINED or NULL');
        }

        if (checkIsDeleted) {
            return await firebase.firestore()
                .collection(collectionName)
                .where(fieldName, '==', uid)
                .where('isDeleted', '==', false)
                .get()
                .then(snapshots => {
                    if (snapshots.empty) {
                        // Do nothing as we didn't find it
                        throw Error('No document exists in collection [' + collectionName + '] with id [' + uid + ']');
                    } else {
                        const records: any[] = [];
                        for (const doc of snapshots.docs) {
                            if (!doc.exists) {
                                continue;
                            }
                            const docData = doc.data();
                            if (typeof docData === 'undefined') {
                                throw Error('UNDEFINED document data in collection [' + collectionName + '] with id [' + uid + ']');
                            }
                            docData.id = doc.id;
                            records.push(docData);
                        }
                        return records;
                    }
                });
        } else {
            return await firebase.firestore()
                .collection(collectionName)
                .where(fieldName, '==', uid)
                .get()
                .then(snapshots => {
                    if (snapshots.empty) {
                        // Do nothing as we didn't find it
                        throw Error('No document exists in collection [' + collectionName + '] with id [' + uid + ']');
                    } else {
                        const records: any[] = [];
                        for (const doc of snapshots.docs) {
                            if (!doc.exists) {
                                continue;
                            }
                            const docData = doc.data();
                            if (typeof docData === 'undefined') {
                                throw Error('UNDEFINED document data in collection [' + collectionName + '] with id [' + uid + ']');
                            }
                            docData.id = doc.id;
                            records.push(docData);
                        }
                        return records;
                    }
                });
        }

    } catch (error) {
        console.error(error);
        return Promise.resolve([]);
    }
}



export async function getIsCompanyAdmin(uid: string, companyId: string): Promise<boolean> {
    if (typeof uid === 'undefined' || uid === null || uid.trim() === '') {
        return Promise.resolve(false);
    }
    if (typeof companyId === 'undefined' || companyId === null || companyId.trim() === '') {
        return Promise.resolve(false);
    }
    const queryRef = firebase.firestore()
        .collection(COLLECTION.COMPANY)
        .doc(companyId);

    return await queryRef
        .get()
        .then(async (querySnapshot) => {
            if (!querySnapshot.exists) {
                return false;
            }
            const docData = querySnapshot.data();
            if (typeof docData === 'undefined') {
                return false;
            }

            if (isUndefinedOrNullOrEmpty(docData.adminAssigned)) {
                return false;
            }

            if (docData.adminAssigned.indexOf(uid) === -1) {
                return false;
            }

            return true;
        })
        .catch((error: any) => {
            console.error(error);
            return false;
        });
}

export async function getIsAdmin(uid: string): Promise<boolean> {
    try {
        console.log('--- START -  getIsAdmin() | uid:', uid);

        if (isUndefinedOrNullOrEmpty(uid)) {
            console.log('--- END -  getIsAdmin() | ERROR');
            return Promise.resolve(false);
        }
        return await firebase.firestore()
            .collection('lc_admin')
            .doc(uid)
            .get()
            .then(async (querySnapshot) => {
                if (!querySnapshot.exists) {
                    console.log('--- END -  getIsAdmin() | ERROR inside query then()');
                    return false;
                }
                console.log('--- END -  getIsAdmin() | SUCCESS');
                return true;
            });
    } catch (error) {
        console.error(error);
        console.log('--- END -  getIsAdmin() | ERROR');
        return Promise.resolve(false);
    }
}

export async function getLastSearchDate(userId: string) {
    return await firebase.firestore()
        .collection('user_search_history')
        .doc(userId)
        .collection('searches')
        .orderBy('date_created', 'desc')
        .limit(1)
        .get()
        .then(items => {
            if (items.empty) {
                return null;
            }
            if (items.docs.length > 0) {
                const docData = items.docs[0].data();
                if (typeof docData === 'undefined') {
                    return null;
                }
                return docData.date_created;
            }
            return null;
        })
        .catch((error: any) => {
            console.error(error);
            return null;
        });
}

export function isAllowedTo(objectName: string, permissionName: string, permissions: any = {}) {
    try {
        if (isUndefinedOrNullOrEmpty(objectName)) {
            return false;
        }
        if (isUndefinedOrNullOrEmpty(permissionName)) {
            return false;
        }
        if (isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        switch (objectName) {
            case 'activity':
                isAllowed = hasPermissionActivity(permissionName, permissions);
                break;
            case 'automation':
                isAllowed = hasPermissionAutomation(permissionName, permissions);
                break;
            case 'campaign':
                isAllowed = hasPermissionCampaign(permissionName, permissions);
                break;
            case 'dashboard':
                isAllowed = hasPermissionDashboard(permissionName, permissions);
                break;
            case 'company':
                isAllowed = hasPermissionCompany(permissionName, permissions);
                break;
            case 'contact':
                isAllowed = hasPermissionContact(permissionName, permissions);
                break;
            case 'custom_field':
                isAllowed = hasPermissionCustomField(permissionName, permissions);
                break;
            case 'deal':
                isAllowed = hasPermissionDeal(permissionName, permissions);
                break;
            case 'emailCampaign':
                isAllowed = hasPermissionEmailCampaign(permissionName, permissions);
                break;
            case 'import':
                isAllowed = hasPermissionImport(permissionName, permissions);
                break;
            case 'integration':
                isAllowed = hasPermissionIntegration(permissionName, permissions);
                break;
            case 'marketplace':
                isAllowed = hasPermissionMarketplace(permissionName, permissions);
                break;
            case 'note':
                isAllowed = hasPermissionNote(permissionName, permissions);
                break;
            case 'organization':
                isAllowed = hasPermissionOrganization(permissionName, permissions);
                break;
            case 'permissionGroup':
                isAllowed = hasPermissionPermission(permissionName, permissions);
                break;
            case 'permission':
                isAllowed = hasPermissionPermission(permissionName, permissions);
                break;
            case 'pipeline':
                isAllowed = hasPermissionPipeline(permissionName, permissions);
                break;
            case 'team':
                isAllowed = hasPermissionTeam(permissionName, permissions);
                break;
            case 'upload':
                isAllowed = hasPermissionUpload(permissionName, permissions);
                break;
            case 'user':
                isAllowed = hasPermissionUser(permissionName, permissions);
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function getSearchTotal(option: string, userId: string, now: Date) {

    let start = new moment();
    let end = new moment();

    if (option === 'day') {
        start = moment(now).startOf('day');
        end = moment(start).add(1, 'days');
    } else if (option === 'week') {
        start = moment(now).startOf('week');
        end = moment(start).add(1, 'weeks');
    } else if (option === 'month') {
        start = moment(now).startOf('month');
        end = moment(start).add(1, 'months');
    } else {
        // option === 'forever'
        return await firebase.firestore()
            .collection('trigger_gmb')
            .where('user_id', '==', userId)
            .get()
            .then(items => {
                if (items.empty) {
                    return 0;
                }

                let totalSearches = 0;
                for (const itemSnapshot of items.docs) {
                    if (itemSnapshot.exists) {
                        totalSearches = totalSearches + 1;
                    }
                }
                return totalSearches;
            })
            .catch((error: any) => {
                console.error(error);
                return 0;
            });
    }

    console.log('user_id = ' + userId);
    console.log('date_created >= ' + start.toDate().toString());
    console.log('date_created < ' + end.toDate().toString());

    try {
        if (isUndefinedOrNullOrEmpty(userId)) {
            return Promise.resolve(0);
        }

        return await firebase.firestore()
            .collection('trigger_gmb')
            .where('user_id', '==', userId)
            .where('date_created', '>=', start.toDate())
            .where('date_created', '<', end.toDate())
            .get()
            .then(items => {
                if (items.empty) {
                    return 0;
                }

                let total = 0;
                for (const itemSnapshot of items.docs) {
                    if (itemSnapshot.exists) {
                        total = total + 1;
                    }
                }
                return total;
            })
            .catch((error: any) => {
                console.error(error);
                return 0;
            });
    } catch (error) {
        console.error(error);
        return Promise.resolve(0);
    }
}

export async function getWeeklyLimit(userId: string) {
    const DOC_ID = 'default';
    const WEEKLY_LIMIT = 315;

    if (isUndefinedOrNullOrEmpty(userId)) {
        return await firebase.firestore()
            .collection('search_limits')
            .doc(DOC_ID)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) {
                    return WEEKLY_LIMIT;
                }
                const docData = snapshot.data();
                if (typeof docData === 'undefined') {
                    return WEEKLY_LIMIT;
                }
                return docData.weekly;
            })
            .catch((error: any) => {
                console.error(error);
                return WEEKLY_LIMIT;
            });
    } else {
        let weeklyLimit = WEEKLY_LIMIT;
        weeklyLimit = await firebase.firestore()
            .collection('search_limits')
            .doc(userId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) {
                    return null;
                }
                const docData = snapshot.data();
                if (typeof docData === 'undefined') {
                    return null;
                }
                return docData.weekly;
            })
            .catch((error: any) => {
                console.error(error);
                return null;
            });

        if (weeklyLimit === null) {
            return await firebase.firestore()
                .collection('search_limits')
                .doc(DOC_ID)
                .get()
                .then(snapshot => {
                    if (!snapshot.exists) {
                        return WEEKLY_LIMIT;
                    }
                    const docData = snapshot.data();
                    if (typeof docData === 'undefined') {
                        return WEEKLY_LIMIT;
                    }
                    return docData.weekly;
                })
                .catch((error: any) => {
                    console.error(error);
                    return WEEKLY_LIMIT;
                });
        } else {
            return Promise.resolve(weeklyLimit);
        }
    }
}

export function hasPermissionActivity(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }


    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.activityAdd;
            break;
        case 'read':
            isAllowed = permissions.activityRead;
            break;
        case 'update':
            isAllowed = permissions.activityUpdate;
            break;
        case 'delete':
            isAllowed = permissions.activityDelete;
            break;
        case 'other':
            isAllowed = permissions.activityOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionAutomation(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.automationAdd;
            break;
        case 'read':
            isAllowed = permissions.automationRead;
            break;
        case 'update':
            isAllowed = permissions.automationUpdate;
            break;
        case 'delete':
            isAllowed = permissions.automationDelete;
            break;
        case 'other':
            isAllowed = permissions.automationOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionCompany(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }
    return false;
    /*
    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.companyAdd;
            break;
        case 'read':
            isAllowed = permissions.companyRead;
            break;
        case 'update':
            isAllowed = permissions.companyUpdate;
            break;
        case 'delete':
            isAllowed = permissions.companyDelete;
            break;
        case 'other':
            isAllowed = permissions.companyOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
    */
}

export function hasPermissionContact(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.contactAdd;
            break;
        case 'read':
            isAllowed = permissions.contactRead;
            break;
        case 'update':
            isAllowed = permissions.contactUpdate;
            break;
        case 'delete':
            isAllowed = permissions.contactDelete;
            break;
        case 'other':
            isAllowed = permissions.contactOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionCustomField(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.customFieldAdd;
            break;
        case 'read':
            isAllowed = permissions.customFieldRead;
            break;
        case 'update':
            isAllowed = permissions.customFieldUpdate;
            break;
        case 'delete':
            isAllowed = permissions.customFieldDelete;
            break;
        case 'other':
            isAllowed = permissions.customFieldOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionDashboard(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.dashboardAdd;
            break;
        case 'read':
            isAllowed = permissions.dashboardRead;
            break;
        case 'update':
            isAllowed = permissions.dashboardUpdate;
            break;
        case 'delete':
            isAllowed = permissions.dashboardDelete;
            break;
        case 'other':
            isAllowed = permissions.dashboardOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionDeal(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.dealAdd;
            break;
        case 'read':
            isAllowed = permissions.dealRead;
            break;
        case 'update':
            isAllowed = permissions.dealUpdate;
            break;
        case 'delete':
            isAllowed = permissions.dealDelete;
            break;
        case 'other':
            isAllowed = permissions.dealOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionImport(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.dealAdd;
            break;
        case 'read':
            isAllowed = permissions.dealRead;
            break;
        case 'update':
            isAllowed = permissions.dealUpdate;
            break;
        case 'delete':
            isAllowed = permissions.dealDelete;
            break;
        case 'other':
            isAllowed = permissions.dealOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}


export function hasPermissionIntegration(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    /*
        Currently permissions for integration are optional.
        Therefore we return TRUE for the standard options of:
            -create, read, update, delete, other
        if they are missing
    */

    let isAllowed = false;

    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            if (typeof permissions.integrationAdd !== 'undefined') {
                isAllowed = permissions.integrationAdd;
            }
            break;
        case 'read':
            if (typeof permissions.integrationRead !== 'undefined') {
                isAllowed = permissions.integrationRead;
            }
            break;
        case 'update':
            if (typeof permissions.integrationUpdate !== 'undefined') {
                isAllowed = permissions.integrationUpdate;
            }
            break;
        case 'delete':
            if (typeof permissions.integrationDelete !== 'undefined') {
                isAllowed = permissions.integrationDelete;
            }
            break;
        case 'other':
            if (typeof permissions.integrationOther !== 'undefined') {
                isAllowed = permissions.integrationOther;
            }
            break;
        default:
            isAllowed = false;
    }

    return isAllowed;
}

export function hasPermissionLeadFinder(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    /*
        Currently permissions for Lead Finder are optional.
        Therefore we return TRUE for the standard options of:
            -create, read, update, delete, other
        if they are missing
    */

    let isAllowed = false;

    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            if (typeof permissions.leadFinderAdd !== 'undefined') {
                isAllowed = permissions.leadFinderAdd;
            }
            break;
        case 'read':
            if (typeof permissions.leadFinderRead !== 'undefined') {
                isAllowed = permissions.leadFinderRead;
            }
            break;
        case 'update':
            if (typeof permissions.leadFinderUpdate !== 'undefined') {
                isAllowed = permissions.leadFinderUpdate;
            }
            break;
        case 'delete':
            if (typeof permissions.leadFinderDelete !== 'undefined') {
                isAllowed = permissions.leadFinderDelete;
            }
            break;
        case 'other':
            if (typeof permissions.leadFinderOther !== 'undefined') {
                isAllowed = permissions.leadFinderOther;
            }
            break;
        default:
            isAllowed = false;
    }

    return isAllowed;
}

export function hasPermissionMarketplace(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    return false;
    /*
    if (this.isUndefinedOrNullOrEmpty(name)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.marketplaceAdd;
            break;
        case 'read':
            isAllowed = permissions.marketplaceRead;
            break;
        case 'update':
            isAllowed = permissions.marketplaceUpdate;
            break;
        case 'delete':
            isAllowed = permissions.marketplaceDelete;
            break;
        case 'other':
            isAllowed = permissions.marketplaceOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
    */
}

export function hasPermissionNote(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.noteAdd;
            break;
        case 'read':
            isAllowed = permissions.noteRead;
            break;
        case 'update':
            isAllowed = permissions.noteUpdate;
            break;
        case 'delete':
            isAllowed = permissions.noteDelete;
            break;
        case 'other':
            isAllowed = permissions.noteOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionOrganization(name: string, permissions: IPermissions): boolean {
    try {
        if (isUndefinedOrNullOrEmpty(name)) {
            return false;
        }
        if (isUndefinedOrNullOrEmpty(permissions)) {
            return false;
        }

        let isAllowed = false;
        const permissionName = name.toString().trim();
        switch (permissionName) {
            case 'create':
                isAllowed = permissions.organizationAdd;
                break;
            case 'read':
                isAllowed = permissions.organizationRead;
                break;
            case 'update':
                isAllowed = permissions.organizationUpdate;
                break;
            case 'delete':
                isAllowed = permissions.organizationDelete;
                break;
            case 'other':
                isAllowed = permissions.organizationOther;
                break;
            default:
                isAllowed = false;
        }
        return isAllowed;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export function hasPermissionPermission(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.permissionGroupAdd;
            break;
        case 'read':
            isAllowed = permissions.permissionGroupRead;
            break;
        case 'update':
            isAllowed = permissions.permissionGroupUpdate;
            break;
        case 'delete':
            isAllowed = permissions.permissionGroupDelete;
            break;
        case 'other':
            isAllowed = permissions.permissionGroupOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionPipeline(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.pipelineAdd;
            break;
        case 'read':
            isAllowed = permissions.pipelineRead;
            break;
        case 'update':
            isAllowed = permissions.pipelineUpdate;
            break;
        case 'delete':
            isAllowed = permissions.pipelineDelete;
            break;
        case 'other':
            isAllowed = permissions.pipelineOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionTeam(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.teamAdd;
            break;
        case 'read':
            isAllowed = permissions.teamRead;
            break;
        case 'update':
            isAllowed = permissions.teamUpdate;
            break;
        case 'delete':
            isAllowed = permissions.teamDelete;
            break;
        case 'other':
            isAllowed = permissions.teamOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionUpload(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.uploadAdd;
            break;
        case 'read':
            isAllowed = permissions.uploadRead;
            break;
        case 'update':
            isAllowed = permissions.uploadUpdate;
            break;
        case 'delete':
            isAllowed = permissions.uploadDelete;
            break;
        case 'other':
            isAllowed = permissions.uploadOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionUser(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.userAdd;
            break;
        case 'read':
            isAllowed = permissions.userRead;
            break;
        case 'update':
            isAllowed = permissions.userUpdate;
            break;
        case 'delete':
            isAllowed = permissions.userDelete;
            break;
        case 'other':
            isAllowed = permissions.userOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionCampaign(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.smsCampaignAdd;
            break;
        case 'read':
            isAllowed = permissions.smsCampaignRead;
            break;
        case 'update':
            isAllowed = permissions.smsCampaignUpdate;
            break;
        case 'delete':
            isAllowed = permissions.smsCampaignDelete;
            break;
        case 'other':
            isAllowed = permissions.smsCampaignOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export function hasPermissionEmailCampaign(name: string, permissions: IPermissions): boolean {
    if (isUndefinedOrNullOrEmpty(name)) {
        return false;
    }
    if (isUndefinedOrNullOrEmpty(permissions)) {
        return false;
    }

    let isAllowed = false;
    const permissionName = name.toString().trim();
    switch (permissionName) {
        case 'create':
            isAllowed = permissions.emailCampaignAdd;
            break;
        case 'read':
            isAllowed = permissions.emailCampaignRead;
            break;
        case 'update':
            isAllowed = permissions.emailCampaignUpdate;
            break;
        case 'delete':
            isAllowed = permissions.emailCampaignDelete;
            break;
        case 'other':
            isAllowed = permissions.emailCampaignOther;
            break;
        default:
            isAllowed = false;
    }
    return isAllowed;
}

export async function isRecentSearch(userId: string, options: any) {
    return await firebase.firestore()
        .collection('user_search_history')
        .doc(userId)
        .collection('searches')
        .where('category', '==', options.category)
        .where('location', '==', options.location)
        .orderBy('date_created', 'desc')
        .limit(1)
        .get()
        .then(items => {
            if (items.empty) {
                return false;
            }
            if (items.docs.length > 0) {
                const docData = items.docs[0].data();
                if (typeof docData === 'undefined') {
                    return false;
                }

                const now = moment();
                const nextDate = moment(docData.date_created.toDate()).add(1, 'months');
                if (now.isBefore(nextDate)) {
                    return true;
                }

                return false;
            }
            return false;
        })
        .catch((error: any) => {
            console.error(error);
            return false;
        });
}

export async function isSearchOptionEnabled(option: string = '') {
    const DOC_SEARCH_APIS = 'apis';

    return await firebase.firestore()
        .collection(COLLECTION.SEARCH_OPTION)
        .doc(DOC_SEARCH_APIS)
        .get()
        .then(async (querySnapshot) => {
            if (!querySnapshot.exists) {
                return false;
            } else {
                const docData = querySnapshot.data();
                if (typeof docData === 'undefined' || docData === null) {
                    return false;
                }

                const isEnabled = docData[option];
                if (typeof isEnabled === 'undefined' || isEnabled === null) {
                    return false;
                }
                return isEnabled;
            }
        })
        .catch((error: any) => {
            console.error(error);
            return false;
        });
}

export function isUndefinedOrNull(field: any): boolean {
    if (
        typeof field === 'undefined'
        || field === null
    ) {
        return true;
    } else {
        return false;
    }
}

export function isUndefinedOrNullOrEmpty(field: any): boolean {
    try {
        if (isUndefinedOrNull(field)) {
            return true;
        }
        if (typeof field === 'string' && field.trim() === '') {
            return true;
        } else if (Array.isArray(field) && field.length === 0) {
            return true;
        } else if (typeof field === 'object' && isEmpty(field)) {
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}

function isEmpty(obj: any) {
    for(const prop in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }

export async function loadFromStorage(key: string, defaultValue: string): Promise<string> {
    let setting = await localforage.getItem<string>(key);
    if (isUndefinedOrNullOrEmpty(setting)) {
        setting = isUndefinedOrNullOrEmpty(defaultValue) ? '' : defaultValue;
    }
    return Promise.resolve(setting);
}

// https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
export function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}


export function getCustomIcon(fieldName: string): string {
    const DEFAULT_ICON = 'card-text-outline'; // 'input';
    try {
        if (typeof fieldName === 'undefined' || fieldName === null) {
            return DEFAULT_ICON;
        }
        const nPos = fieldName.indexOf('_');
        if (nPos > 0) {
            const prefix = fieldName.slice(0, nPos);
            const snippet = fieldName.slice(nPos + 1);
            if (prefix === 'org') {
            } else if (prefix === 'cp') {
            } else if (prefix === 'd') {
            } else {
                return DEFAULT_ICON;
            }

            if (snippet === 'category') {
                return 'tag-text-outline';
            } else if (snippet === 'email') {
                return 'email'; // 'at';
            } else if (snippet === 'facebook_url') {
                return 'facebook-box';
            } else if (snippet === 'instagram_url') {
                return 'instagram';
            } else if (snippet === 'linkedin_url') {
                return 'linkedin-box';
            } else if (snippet === 'pinterest_url') {
                return 'pinterest';
            } else if (snippet === 'twitter_url') {
                return 'twitter';
            } else if (snippet === 'yelp_url') {
                return 'yelp';
            } else if (snippet === 'youtube_url') {
                return 'youtube';
            } else if (snippet === 'lead_source') {
                return DEFAULT_ICON;
            } else {
                // console.log('------------------ snippet:', snippet);
                return DEFAULT_ICON;
            }
        }

        return DEFAULT_ICON;
    } catch (error) {
        console.error(error);
        return DEFAULT_ICON;
    }
}

export function getIsAnyDay(weekdays: any): boolean {
    if (weekdays.sunday === true
        && weekdays.monday === true
        && weekdays.tuesday === true
        && weekdays.wednesday === true
        && weekdays.thursday === true
        && weekdays.friday === true
        && weekdays.saturday === true
    ) {
        return true;
    } else {
        return false;
    }
}

export function getNextDayAvailable(weekday: number, weekdaysAvailable: any[] = []) {
    // Find the next day of week available.
    let daysAvailable = false;
    for (let index = 1; index <= 7; index++) {
        if (weekdaysAvailable[index] === true) {
            daysAvailable = true;
            break;
        }
    }
    if (daysAvailable === false) {
        console.log('NO DAYS SCHEDULED');
        return -1;
    }


    let dayOffset = 1;
    if (weekday >= 7) {
        for (let index = 1; index <= 7; index++) {
            if (weekdaysAvailable[index] === true) {
                dayOffset = index; // This is offset for weekday so a match.
                break;
            }
        }
        return (7 - weekday + dayOffset);
    }

    for (let index = 1; index <= 7; index++) {
        if (weekdaysAvailable[index] === true) {
            dayOffset = index;
            break;
        }
    }

    if (dayOffset < weekday) {
        return (7 - weekday + dayOffset);
    } else if (dayOffset === weekday) {
        return 7;
    } else {
        return (dayOffset - weekday);
    }
}

export function getWeekdaySchedule(weekdays: any) {
    const weekdayRange: any[] = [];
    if (isUndefinedOrNullOrEmpty(weekdays)) {
        weekdayRange[1] = true;
        weekdayRange[2] = true;
        weekdayRange[3] = true;
        weekdayRange[4] = true;
        weekdayRange[5] = true;
        weekdayRange[6] = true;
        weekdayRange[7] = true;
    } else {
        weekdayRange[1] = isUndefinedOrNull(weekdays.monday) ? true : weekdays.monday;
        weekdayRange[2] = isUndefinedOrNull(weekdays.tuesday) ? true : weekdays.tuesday;
        weekdayRange[3] = isUndefinedOrNull(weekdays.wednesday) ? true : weekdays.wednesday;
        weekdayRange[4] = isUndefinedOrNull(weekdays.thursday) ? true : weekdays.thursday;
        weekdayRange[5] = isUndefinedOrNull(weekdays.friday) ? true : weekdays.friday;
        weekdayRange[6] = isUndefinedOrNull(weekdays.saturday) ? true : weekdays.saturday;
        weekdayRange[7] = isUndefinedOrNull(weekdays.sunday) ? true : weekdays.sunday;
    }
    return weekdayRange;
}

export function isUrl(url: string): boolean {
    try {
        if (typeof url === 'undefined' || url === null || url.toString().trim() === '') {
            return false;
        }

        const snippet = url.toString().slice(0, 8).toLowerCase();
        if (snippet === 'https://' || snippet === 'http://') {
            return true;
        }

        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export function resetForm(form: FormGroup): void {
    form.reset();
    Object.keys(form.controls).forEach(key => {
        form.controls[key].setErrors(null);
    });
}

// ------------------------------------------------------------------

export async function getSessionSetting(name: string, userId: string) {
    try {
        if (isUndefinedOrNullOrEmpty(name)) {
            return Promise.resolve('');
        }
        if (isUndefinedOrNullOrEmpty(userId)) {
            return Promise.resolve('');
        }

        return await firebase.firestore()
            .collection('session')
            .doc(userId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) {
                    return '';
                }
                const docData = snapshot.data();
                const lastPage = docData[name.trim()];
                if (isUndefinedOrNullOrEmpty(lastPage)) {
                    return '';
                }
                return lastPage;
            });
    } catch (error) {
        console.error(error);
        return Promise.resolve('');
    }
}

export function setSessionSetting(name: string, userId: string, value: any) {
    try {
        if (isUndefinedOrNullOrEmpty(name)) {
            return Promise.resolve('');
        }
        if (isUndefinedOrNullOrEmpty(userId)) {
            return Promise.resolve('');
        }

        localforage.setItem(name.trim(), value);

        const data: any = {};
        data[name.trim()] = value;

        try {
            firebase.firestore()
                .collection('session')
                .doc(userId)
                .set(data, { merge: true });
        } catch (error) {
            console.warn(error.message);
            // No document to update
        }

        return Promise.resolve(data);

    } catch (error) {
        console.warn('setSessionSetting() | name:', name);
        console.error(error);
        return Promise.resolve(null);
    }
}
