import { Injectable } from '@angular/core';

// import uuidv4 from 'uuid/v4';
const { 'v4': uuidv4 } = require('uuid');

import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

// import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { catchError, map } from 'rxjs/operators';

import { IntegrationService } from '../integration/integration.service';
// import { SessionService } from '../session/session.service';
import { environment } from '../../../environments/environment';
import { API_KEY_TYPE, isUndefinedOrNullOrEmpty } from '../../shared/utils';

const IS_DEBUG = !environment.production;

@Injectable({
    providedIn: 'root'
})
export class SlybroadcastService {
    END_POINT: string;
    CLOUD_URL: string;


    private _audioFiles: any[] = [];
    audioFilesSubject = new BehaviorSubject<any[]>([]);

    /*
    integrationSettings: any[] = [];
    integrationSettingsSubject = new BehaviorSubject<any[]>([]);
    apiKeys: ApiKey[] = [];
    apiKeysSubject = new BehaviorSubject<ApiKey[]>([]);
    */

    constructor(
        // private afs: AngularFirestore,
        private http: HttpClient,
        private integrationService: IntegrationService,
    ) {
        if (!IS_DEBUG) {
            this.END_POINT = environment.backendApiUrl;
            this.CLOUD_URL = environment.cloudUrl;
        } else {
            this.END_POINT = environment.backendApiDebugUrl;
            this.CLOUD_URL = environment.cloudUrl;
        }
    }

    loadAudioList(integrationId: string) {
        try {
            if (isUndefinedOrNullOrEmpty(integrationId)) {
                this._audioFiles = [];
                this.audioFilesSubject.next(this._audioFiles);
                return;
            }

            if (isUndefinedOrNullOrEmpty(this.integrationService)) {
                this._audioFiles = [];
                this.audioFilesSubject.next(this._audioFiles);
            } else {
                this.integrationService.getSetting(integrationId)
                    .subscribe(value => {
                        if (isUndefinedOrNullOrEmpty(value)) {
                            this._audioFiles = [];
                            this.audioFilesSubject.next(this._audioFiles);
                            return;
                        }
                        const integration: any = value;

                        const body = {
                            c_uid: integration.custom.username,
                            c_password: integration.custom.password,
                        };
                        const url = this.CLOUD_URL + '/rvmGetAudioList';

                        const headers = new HttpHeaders({
                            'Content-Type': 'application/json'
                        });
                        console.warn('get audio files from SlyBroadcast | body:', body);

                        this.http
                            .post(
                                url,
                                body,
                                {
                                    headers,
                                    observe: 'response', // default is 'body'
                                    responseType: 'json', // default is 'json'
                                },
                            )
                            .subscribe(response => {
                                console.warn('SLYBROADCAST response:', response);

                                if (typeof response !== 'undefined' && !isUndefinedOrNullOrEmpty(response.body)) {

                                    const data: any = response.body;
                                    this._audioFiles = [];

                                    for (const item of data) {
                                        this._audioFiles.push(item);
                                    }
                                    this.audioFilesSubject.next(this._audioFiles);
                                }

                                return;
                            });
                    });
            }
            return;
        } catch (error) {
            console.error(error);
            this._audioFiles = [];
            this.audioFilesSubject.next(this._audioFiles);
            return;
        }
    }
}
