<h2 mat-dialog-title class="dialogTitle">{{ title }}
  <div class="dlg-button-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
</h2>
<mat-dialog-content *ngIf="recordCount > 0" [formGroup]="form" [perfectScrollbar]="config">

  <mat-form-field>
    <mat-label>Pipeline</mat-label>
    <mat-select id="toPipeline" placeholder="Choose Pipeline" matNativeControl [formControl]="toPipeline"
      name="toPipeline">
      <mat-option *ngFor="let pipeline of pipelines; let i = index" (click)="changePipeline(i)"
        value="{{ pipeline.pipelineId }}">{{ pipeline.pipelineName }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Stage</mat-label>
    <mat-select id="toStage" placeholder="Choose Stage" matNativeControl [formControl]="toStage" name="toStage">
      <mat-option *ngFor="let stage of stages; let i = index" (click)="changeStage(i)" value="{{stage.id}}">
        {{ stage.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Deal Title</mat-label>
    <input matInput placeholder="Deal Title" formControlName="dealTitle">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Deal Value</mat-label>
    <input matInput placeholder="Deal Value" formControlName="dealValue">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Deal Currency</mat-label>
    <mat-select placeholder="Deal Currency Type" matNativeControl [formControl]="allCurrencyTypes" name="currencyTypes">
      <mat-option *ngFor="let currencyType of currencyTypes" value="{{currencyType.id}}">{{ currencyType.symbol }} -
        {{ currencyType.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!--
  <mat-form-field>
    <mat-label>Deal Currency</mat-label>
    <mat-select placeholder="Deal Currency Type" matNativeControl [formControl]="allCurrencyTypes" name="currencyTypes">
      <mat-option *ngFor="let currency of supportedCurrencies" value="{{ currency }}">{{ currency }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  -->

  <div>
    <span style="font-weight: bold;">{{ recordCount }}</span> {{ recordCountMessage }}
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="recordCount < 1">

  <div>No leads to push to pipeline</div>

</mat-dialog-content>


<mat-dialog-actions *ngIf="recordCount > 0" align="end">
  <button mat-raised-button color="primary" (click)="push()">Push</button>
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="recordCount < 1" align="end">
  <button mat-raised-button color="primary" (click)="close()">Close</button>
</mat-dialog-actions>