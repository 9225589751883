// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `/angular.json`.

// PRODUCTION

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDdKCT6vKmePeouAbdj590SSixTWF2DSVI',
    authDomain: 'leadcarrot-prod.firebaseapp.com',
    databaseURL: 'https://leadcarrot-prod.firebaseio.com',
    projectId: 'leadcarrot-prod',
    storageBucket: 'leadcarrot-prod.appspot.com',
    messagingSenderId: '823197670564',
  },
  cloudUrl: 'https://us-central1-leadcarrot-prod.cloudfunctions.net',
  apiUrl: 'https://api-crm.leadcarrot.io',
  backendApiUrl: 'https://api-prod.leadcarrot.io',
  // backendApiUrl: 'https://bot.leadcarrot.io',
  backendApiDebugUrl: 'http://localhost:3010',
  googleMapsKey: 'AIzaSyDdKCT6vKmePeouAbdj590SSixTWF2DSVI',
};

// STAGING/TEST
// NO BACKEND-API project setup yet, pointing to DEVELOPMENT INSTEAD
/*
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBTpi3O75SJ8YP2BbY_h7exb8CkJ-XYQr0',
    authDomain: 'leadcarrot-test.firebaseapp.com',
    databaseURL: 'https://leadcarrot-test.firebaseio.com',
    projectId: 'leadcarrot-test',
    storageBucket: 'leadcarrot-test.appspot.com',
    messagingSenderId: '942308969358',
    appId: '1:942308969358:web:3685e2972cc94f69b972df'
  },
  cloudUrl: 'https://us-central1-leadcarrot-test.cloudfunctions.net',
  apiUrl: 'https://api-crm.leadcarrot.io',
  backendApiUrl: 'https://api-test.leadcarrot.io',
  backendApiDebugUrl: 'http://localhost:3005',
  googleMapsKey: 'AIzaSyDdKCT6vKmePeouAbdj590SSixTWF2DSVI',
};
*/

// DEVELOPMENT
/*
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA9ig_nb5Kjk8Y_BuNSaZkTINs1Lb21Ets',
    authDomain: 'leadcarrot-dev.firebaseapp.com',
    databaseURL: 'https://leadcarrot-dev.firebaseio.com',
    projectId: 'leadcarrot-dev',
    storageBucket: 'leadcarrot-dev.appspot.com',
    messagingSenderId: '424137846537',
  },
  cloudUrl: 'https://us-central1-leadcarrot-dev.cloudfunctions.net',
  apiUrl: 'https://api-crm.leadcarrot.io',
  backendApiUrl: 'https://api-dev.leadcarrot.io',
  backendApiDebugUrl: 'http://localhost:3000',
  googleMapsKey: 'AIzaSyDdKCT6vKmePeouAbdj590SSixTWF2DSVI',
};
*/
