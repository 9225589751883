import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-confirmation',
  templateUrl: './update-confirmation.component.html',
  styleUrls: ['./update-confirmation.component.scss']
})
export class UpdateConfirmationComponent implements OnInit, OnDestroy {
  allSubscription = new Subscription();
  @Input() msgs: any;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<UpdateConfirmationComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    // Do nothing
  }

  ngOnInit() {
    //  console.log(this.data.message);
  }

  ngOnDestroy() {
    this.allSubscription.unsubscribe();
  }

  onClick(option: boolean): void {
    this.bottomSheetRef.dismiss(option);
    // tslint:disable-next-line: deprecation
    event.preventDefault();
  }

}
