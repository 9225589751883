<!-- <div class="row">
    <div class="col-md-6">
        <h3>Add Tags</h3>
    </div>
    <div class="col-md-6">
        <mat-icon (click)="closeDialog()" class="float-right text-secondary" style="cursor:pointer">close</mat-icon>
    </div>
</div> -->
<mat-spinner *ngIf="!tagsReady" diameter="40" id="tagspinner"></mat-spinner>
<div *ngIf="tagsReady">
    <h2 mat-dialog-title>Add Tags
        <div class="dlg-button-close" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>
    <mat-dialog-content [perfectScrollbar]="config">
        <form class="example-form" [formGroup]="tags">
            <!-- <div class="perfect-scrollbar-container">
            <perfect-scrollbar [config]="config"> -->
            <mat-form-field class="example-chip-list">
                <input placeholder="New Tag..." matInput formControlName="tagTitle" (input)="_filter($event)">
                <mat-hint class="text-danger support-text" *ngIf="tagsMatched">Tag already exist.</mat-hint>
                <!-- <mat-error>Tag already exist.</mat-error> -->
            </mat-form-field>
            <!-- <h5>Select Persons</h5>
            <div class="container">
                <div class="row">
                    <div class="col-md-4" *ngFor="let p of person['controls']; let i = index">
                        <mat-checkbox [formControl]="p">{{data.persons[i].response.displayName}}</mat-checkbox>
                    </div>
                </div>
            </div> -->
            <!-- *ngIf="allTags.length === 0" -->
            <h5>Select Existing Tag</h5>
            
            <div class="container" *ngIf="allTags.length > 0">
                <div class="row">
                    <div class="col-md-6" *ngFor="let tag of TotalTags['controls']; let i = index">
                        <mat-checkbox [formControl]="tag" [checked]="TotalTags.value[i]" >{{ allTags[i].label }}</mat-checkbox>
                    </div>
                </div>
            </div>
            <h2 *ngIf="allTags.length === 0">No tags found</h2>
            <!-- </perfect-scrollbar>
        </div> -->
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit" (click)="saveTag(tags.value)" [disabled]="tagsMatched && allowedTocreate()">Add
            Tag</button>
        <button class="mat-raised-button" style="margin-left: 15px;" (click)="closeDialog()">{{ cancelText }}</button>
    </mat-dialog-actions>
</div>
