import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import localforage from 'localforage';

@Injectable()
export class FingerprintService {
  private fingerprint$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private fingerprint: string = null;

  constructor() {
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load();
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();

      // This is the visitor identifier:
      this.fingerprint = result.visitorId;
      console.log('visitorId:', this.fingerprint);
      localforage.setItem('visitorId', this.fingerprint);
      this.fingerprint$.next(this.fingerprint);
    })();
    this.fingerprint$.next(this.fingerprint);
  }

  public subscribeToFingerprint$() {
    return this.fingerprint$.asObservable();
  }

  public async getVisitor() {
    try {
      const fpPromise = FingerprintJS.load();
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();

      // This is the visitor identifier:
      this.fingerprint = result.visitorId;
      console.log('visitorId:', this.fingerprint);
      localforage.setItem('visitorId', this.fingerprint);
      this.fingerprint$.next(this.fingerprint);
      return Promise.resolve(this.fingerprint);
    } catch (error) {
      console.error(error);
      return Promise.resolve(null);
    }
  }
}
