import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// import { catchError, map } from 'rxjs/operators';
// import { HttpClient, HttpParams } from '@angular/common/http';
// import { AddTag, ICustomFieldItem, GetPeople, IOrgItem, People } from '../../models';

import { ICompany, ICustomField, IUser } from '../../models';
import { environment } from '../../../environments/environment';
import { COLLECTION, CUSTOM_FIELD_TYPE, isUndefinedOrNull, isUndefinedOrNullOrEmpty, getIsAdmin } from '../../shared/utils';
import { AuthService } from '../auth/auth.service';

// import uuidv4 from 'uuid/v4';
const { 'v4': uuidv4 } = require('uuid');

const IS_DEBUG = !environment.production;

const AUTH_TOKEN_NOT_FOUND = 'Auth token not found.';
const CATEGORY_NOT_FOUND = 'Category not found.';
const COMPANY_ID_NOT_FOUND = 'Company ID not found.';
const COMPANY_NOT_FOUND = 'Company not found.';
const CUSTOM_FIELD_NOT_FOUND = 'Custom Field not found.';
const FIELD_ID_NOT_FOUND = 'Field ID not found.';
const FIELD_NOT_FOUND = 'Field not found.';
const USER_NOT_FOUND = 'User not found.';
const USER_NOT_ASSIGNED_TO_COMPANY = 'User not assigned to company.';

const FIELD_FIELDCODE_NOT_FOUND = '[fieldCode] not found.';
const FIELD_NAME_NOT_FOUND = '[name] not found.';
const FIELD_TYPE_NOT_FOUND = '[type] not found.';
const FIELD_ISREQUIRED_NOT_FOUND = '[isRequired] not found.';
const FIELD_SHOWINFORM_NOT_FOUND = '[showInForm] not found.';
const FIELD_SHOWINDETAIL_NOT_FOUND = '[showInDetail] not found.';

const CUSTOM_FIELD_ADDED = 'Custom Field added.';
const CUSTOM_FIELD_DELETED = 'Custom Field deleted.';
const CUSTOM_FIELD_EXISTS = 'Custom Field exists.';
const CUSTOM_FIELD_UPDATED = 'Custom Field updated.';
const UNABLE_TO_DELETE_CUSTOM_FIELD = 'Unable to delete custom field.';

const SERVICE_NOT_INITIALIZED = 'Service is not initialized.';

export const enum CUSTOM_FIELD_PREFIX {
    COMPANY = 'co_',
    CONTACT = 'cp_',
    DEAL = 'deal_',
    ORGANIZATION = 'org_',
}

@Injectable({
    providedIn: 'root'
})
export class CustomFieldService {

    private _isProcessing: boolean;
    isProcessing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _customCompany: ICustomField[] = [];
    customCompany: BehaviorSubject<ICustomField[]> = new BehaviorSubject<ICustomField[]>([]);

    private _customDeal: ICustomField[] = [];
    customDeal: BehaviorSubject<ICustomField[]> = new BehaviorSubject<ICustomField[]>([]);

    private _customOrg: ICustomField[] = [];
    customOrg: BehaviorSubject<ICustomField[]> = new BehaviorSubject<ICustomField[]>([]);

    private _customContact: ICustomField[] = [];
    customContact: BehaviorSubject<ICustomField[]> = new BehaviorSubject<ICustomField[]>([]);

    private allSubs: Subscription = new Subscription();
    private isAdmin: boolean;
    private isInitialized: boolean;
    private userId: string;
    private companyId: string;

    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
    ) {
        this.isInitialized = false;
        this.init();
        this._isProcessing = false;
        this.isProcessing.next(this._isProcessing);
    }

    _validateCompanyId(companyId: string) {
        if (isUndefinedOrNullOrEmpty(companyId)) {
            companyId = this.companyId;
        }
        if (!this.isAdmin) {
            companyId = this.companyId;
        }
        return companyId;
    }

    init() {
        this.allSubs.add(
            this.authService.user
                .subscribe(async (user: IUser) => {
                    try {
                        if (user) {
                            this.userId = user.id;
                        } else {
                            this.userId = null;
                        }

                    } catch (error) {
                        console.log(error);
                        this.userId = null;
                    }
                })
        );

        this.allSubs.add(
            this.authService.company
                .subscribe(async (company: ICompany) => {
                    try {
                        if (company) {
                            this.companyId = company.id;
                        } else {
                            this.companyId = null;
                        }
                        this.isAdmin = false; // await getIsAdmin(this.userId);
                        this.isInitialized = true;
                        this.loadCustomFields();
                    } catch (error) {
                        console.log(error);
                        this.companyId = null;
                        this._isProcessing = false;
                        this.isProcessing.next(this._isProcessing);
                    }
                })
        );
    }

    destroy() {
        this.isInitialized = false;
        this.allSubs.unsubscribe();
    }

    async add(category: CUSTOM_FIELD_TYPE, params: any, companyId: string = null): Promise<boolean> {
        try {
            if (!this.isInitialized) {
                throw Error(SERVICE_NOT_INITIALIZED);
            }

            const now = new Date();
            const prefix = this.getPrefix(category);
            const fieldCode = prefix + uuidv4();
            const field: ICustomField = {
                category,
                createdAt: now,
                createdBy: isUndefinedOrNullOrEmpty(params.createdBy) ? this.userId : params.createdBy,
                fieldCode,
                fieldName: params.fieldName,
                id: fieldCode,
                isRequired: isUndefinedOrNull(params.isRequired) ? false : !!params.isRequired,
                showInDetail: isUndefinedOrNull(params.showInDetail) ? false : !!params.showInDetail,
                showInForm: isUndefinedOrNull(params.showInForm) ? false : !!params.showInForm,
                type: isUndefinedOrNull(params.type) ? 'string' : params.type,
                updatedAt: now,
                updatedBy: isUndefinedOrNullOrEmpty(params.updatedBy) ? this.userId : params.updatedBy,
            };

            companyId = this._validateCompanyId(companyId);

            const customCollectionName = this.getCustomCollection(category);

            await this.afs
                .collection(COLLECTION.COMPANY)
                .doc(companyId)
                .collection(customCollectionName)
                .doc(fieldCode)
                .set(field);

            return Promise.resolve(true);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async update(category: CUSTOM_FIELD_TYPE, params: any, companyId: string = null): Promise<boolean> {
        try {
            if (!this.isInitialized) {
                throw Error(SERVICE_NOT_INITIALIZED);
            }

            const now = new Date();
            const field: any = {
                id: params.id,
                updatedAt: now,
                updatedBy: isUndefinedOrNullOrEmpty(params.updatedBy) ? this.userId : params.updatedBy,
            };
            if (!isUndefinedOrNullOrEmpty(params.fieldName)) {
                field.fieldName = params.fieldName.trim();
            }
            if (!isUndefinedOrNull(params.showInDetail)) {
                if (typeof params.showInDetail === 'boolean') {
                    field.showInDetail = params.showInDetail;
                }
            }
            if (!isUndefinedOrNull(params.showInForm)) {
                if (typeof params.showInDetail === 'boolean') {
                    field.showInForm = params.showInForm;
                }
            }

            companyId = this._validateCompanyId(companyId);

            const customCollectionName = this.getCustomCollection(category);

            console.log('field:', field);
            console.log('collection:', customCollectionName);

            await this.afs
                .collection(COLLECTION.COMPANY)
                .doc(companyId)
                .collection(customCollectionName)
                .doc(field.id)
                .update(field);

            return Promise.resolve(true);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async delete(category: CUSTOM_FIELD_TYPE, id: string, fieldCode: string = null, companyId: string = null): Promise<boolean> {
        try {
            if (!this.isInitialized) {
                throw Error(SERVICE_NOT_INITIALIZED);
            }

            companyId = this._validateCompanyId(companyId);

            // Get custom field name

            if (isUndefinedOrNullOrEmpty(fieldCode)) {
                fieldCode = id;
            }

            // DELETE this custom field from all the OBJECTS that contain it for this company
            const collectionName = this.getCollection(category);
            await this.afs
                .collection(collectionName, ref => ref
                    .where('companyId', '==', companyId))
                .get()
                .pipe(
                    take(1)
                )
                .subscribe(
                    async (snapshots) => {
                        if (snapshots.size === 0) {
                            console.error('[' + collectionName + '] has no custom fields for [' + fieldCode + '] to delete');
                            return;
                        }

                        this._isProcessing = true;
                        this.isProcessing.next(this._isProcessing);

                        snapshots.forEach(async (doc: any) => {
                            const docData = doc.data();
                            const customField = docData.customField;
                            if (isUndefinedOrNull(customField[fieldCode])) {
                                // console.error('custom field is undefined or null');
                                return;
                            }

                            delete customField[fieldCode];

                            console.log('deleting custom fields from collection [' + collectionName + ']');

                            await this.afs.collection(collectionName).doc(doc.id)
                                .update({ customField })
                                .catch(() => {
                                    console.warn(UNABLE_TO_DELETE_CUSTOM_FIELD
                                        + ' [' + fieldCode + '] in [' + collectionName + '] ID: [' + doc.id + ']');
                                });
                        });
                    }, err => {
                        console.log(err);

                        this._isProcessing = false;
                        this.isProcessing.next(this._isProcessing);

                        throw err;
                    },
                    async () => {
                        // DELETE this custom field from the company
                        const customCollectionName = this.getCustomCollection(category);
                        await this.afs
                            .collection(COLLECTION.COMPANY)
                            .doc(companyId)
                            .collection(customCollectionName)
                            .doc(id)
                            .delete();

                        this._isProcessing = false;
                        this.isProcessing.next(this._isProcessing);
                    });
            return Promise.resolve(true);
        } catch (error) {
            console.error(error);

            this._isProcessing = false;
            this.isProcessing.next(this._isProcessing);

            throw error;
        }
    }

    private getPrefix(category: CUSTOM_FIELD_TYPE): CUSTOM_FIELD_PREFIX {
        let prefix: CUSTOM_FIELD_PREFIX = CUSTOM_FIELD_PREFIX.CONTACT;

        if (CUSTOM_FIELD_TYPE.COMPANY === category) {
            prefix = CUSTOM_FIELD_PREFIX.COMPANY;
        } else if (CUSTOM_FIELD_TYPE.CONTACT === category) {
            prefix = CUSTOM_FIELD_PREFIX.CONTACT;
        } else if (CUSTOM_FIELD_TYPE.DEAL === category) {
            prefix = CUSTOM_FIELD_PREFIX.DEAL;
        } else if (CUSTOM_FIELD_TYPE.ORGANIZATION === category) {
            prefix = CUSTOM_FIELD_PREFIX.ORGANIZATION;
        }

        return prefix;
    }

    private getCustomCollection(category: CUSTOM_FIELD_TYPE): COLLECTION {
        let collectionName: COLLECTION = COLLECTION.COMPANY_CUSTOM;

        if (CUSTOM_FIELD_TYPE.COMPANY === category) {
            collectionName = COLLECTION.COMPANY_CUSTOM;
        } else if (CUSTOM_FIELD_TYPE.CONTACT === category) {
            collectionName = COLLECTION.CONTACT_CUSTOM;
        } else if (CUSTOM_FIELD_TYPE.DEAL === category) {
            collectionName = COLLECTION.DEAL_CUSTOM;
        } else if (CUSTOM_FIELD_TYPE.ORGANIZATION === category) {
            collectionName = COLLECTION.ORGANIZATION_CUSTOM;
        }

        return collectionName;
    }

    private getCollection(category: CUSTOM_FIELD_TYPE): COLLECTION {
        let collectionName: COLLECTION = COLLECTION.COMPANY;

        if (CUSTOM_FIELD_TYPE.COMPANY === category) {
            collectionName = COLLECTION.COMPANY;
        } else if (CUSTOM_FIELD_TYPE.CONTACT === category) {
            collectionName = COLLECTION.CONTACT;
        } else if (CUSTOM_FIELD_TYPE.DEAL === category) {
            collectionName = COLLECTION.DEAL;
        } else if (CUSTOM_FIELD_TYPE.ORGANIZATION === category) {
            collectionName = COLLECTION.ORGANIZATION;
        }

        return collectionName;
    }

    loadCustomFields(companyId: string = null): boolean {
        if (!this.isInitialized) {
            return false;
        }

        companyId = this._validateCompanyId(companyId);
        if (isUndefinedOrNull(companyId)) {
            return false;
        }

        try {
            this.afs
                .collection(COLLECTION.COMPANY)
                .doc(companyId)
                .collection<ICustomField>(COLLECTION.COMPANY_CUSTOM, ref => ref
                    .orderBy('fieldName', 'asc'))
                .valueChanges()
                .subscribe(fields => {
                    if (typeof fields !== 'undefined') {
                        this._customCompany = fields;
                        this.customCompany.next(this._customCompany);
                    }
                });
        } catch (error) {
            console.log(error);
            this._customCompany = [];
            this.customCompany.next(this._customCompany);
            this._isProcessing = false;
            this.isProcessing.next(this._isProcessing);
        }

        try {
            this.afs
                .collection(COLLECTION.COMPANY)
                .doc(companyId)
                .collection<ICustomField>(COLLECTION.DEAL_CUSTOM, ref => ref
                    .orderBy('fieldName', 'asc'))
                .valueChanges()
                .subscribe(fields => {
                    if (typeof fields !== 'undefined') {
                        this._customDeal = fields;
                        this.customDeal.next(this._customDeal);
                    }
                });
        } catch (error) {
            console.log(error);
            this._customDeal = [];
            this.customDeal.next(this._customDeal);
        }

        try {
            this.afs
                .collection(COLLECTION.COMPANY)
                .doc(companyId)
                .collection<ICustomField>(COLLECTION.ORGANIZATION_CUSTOM, ref => ref
                    .orderBy('fieldName', 'asc'))
                .valueChanges()
                .subscribe(fields => {
                    if (typeof fields !== 'undefined') {
                        this._customOrg = fields;
                        this.customOrg.next(this._customOrg);
                    }
                });
        } catch (error) {
            console.log(error);
            this._customOrg = [];
            this.customOrg.next(this._customOrg);
        }

        try {
            this.afs
                .collection(COLLECTION.COMPANY)
                .doc(companyId)
                .collection<ICustomField>(COLLECTION.CONTACT_CUSTOM, ref => ref
                    .orderBy('fieldName', 'asc'))
                .valueChanges()
                .subscribe(fields => {
                    if (typeof fields !== 'undefined') {
                        this._customContact = fields;
                        this.customContact.next(this._customContact);
                    }
                });
        } catch (error) {
            console.log(error);
            this._customContact = [];
            this.customContact.next(this._customDeal);
        }

        return true;
    }
}
