import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { IRole } from '../../models/IRole';
import { User } from '../../models/User2';
import { IUser } from '../../models';

import { environment } from '../../../environments/environment';
import {
  COLLECTION, createCloudHeaders, createAuthHeaders,
  getDocument, getIsAdmin,
  isUndefinedOrNullOrEmpty, isUndefinedOrNull
} from '../../shared/utils';

import { AuthService } from '../auth/auth.service';

const IS_DEBUG = !environment.production;
// const END_POINT_CLOUD = environment.cloudUrl;
const DEFAULT_PASSWORD = 'Leadcarrot123';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  authUser: IUser;
  END_POINT: string;
  apiLeadCarrot: string;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    if (!IS_DEBUG) {
      this.END_POINT = environment.backendApiUrl;
    } else {
      this.END_POINT = environment.backendApiDebugUrl;
    }
    this.apiLeadCarrot = environment.cloudUrl + '/';

    this.authService.user
      .subscribe(user => {
        if (user) {
          this.authUser = user;
        }
      });
  }

  updatePassword(userId: string, password: string): Observable<any> {
    try {
      const apiLeadCarrot = environment.cloudUrl + '/userChangePassword';
      const headers = createCloudHeaders(this.authUser.token);
      const body = {
        userId,
        password,
      };

      // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
      return this.http.post<any>(
        apiLeadCarrot,
        body,
        {
          headers,
          withCredentials: true
        });
    } catch (error) {
      throw error;
    }
  }

  async delete(userId: string) {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }

      const isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (!isAdmin) {
        if (this.authUser.id !== userId) {
          throw Error('You are not authorized to delete the user.');
        }
      }

      return firebase.firestore()
        .collection(COLLECTION.USER)
        .doc(userId)
        .delete();
    } catch (error) {
      throw error;
    }
  }

  async deleteUser(userId: string) {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }

      const isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (!isAdmin) {
        if (this.authUser.id !== userId) {
          throw Error('You are not authorized to delete the user.');
        }
      }

      return this.afs
        .collection(COLLECTION.USER)
        .doc(userId)
        .delete();
    } catch (error) {
      throw error;
    }
  }

  async get(userId: string): Promise<any> {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }

      const isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (!isAdmin) {
        if (this.authUser.id !== userId) {
          throw Error('You are not authorized to retreive the user.');
        }
      }

      return await getDocument(userId, COLLECTION.USER);
    } catch (error) {
      throw error;
    }
  }

  async getAll(): Promise<any[]> {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }

      let isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (this.authUser.email.indexOf('andre@leadcarrot.io') > 0
        || this.authUser.email.indexOf('andre.v.fortin@gmail.com') > 0
        || this.authUser.email.indexOf('@fortinmedia.ca') > 0
      ) {
        isAdmin = true;
      }
      if (!isAdmin) {
        throw Error('You are not authorized to retreive the users.');
      }

      return await firebase.firestore()
        .collection(COLLECTION.USER)
        .where('isDeleted', '==', false)
        .orderBy('displayName', 'asc')
        .get()
        .then(snapshots => {
          const users: any[] = [];
          if (snapshots.empty) {
            return users;
          }

          for (const doc of snapshots.docs) {
            try {
              const docData = doc.data();
              const user = Object.assign({ id: doc.id, ...docData });
              users.push(user);
            } catch (err) {
              console.log(err);
            }
          }
          return users;
        });
    } catch (error) {
      throw error;
    }
  }

  async getUser(userId: string) {
    try {

      console.warn('Function getUser() is deprecated. Please use get() instead.');

      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }

      const isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (!isAdmin) {
        if (this.authUser.id !== userId) {
          throw Error('You are not authorized to retreive the user.');
        }
      }

      return this.afs
        .collection(COLLECTION.USER)
        .doc(userId)
        .snapshotChanges()
        .pipe(map((a: any) => {
          if (a.payload.exists === false) {
            return null;
          } else {
            const data: any = a.payload.data(); // as User;
            data.id = a.payload.id;
            return data;
          }
        }));
    } catch (error) {
      throw error;
    }
  }

  async getUsers() {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }

      const isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (!isAdmin) {
        throw Error('You are not authorized to retreive the users.');
      }

      // Get Users with ID.
      return this.afs
        .collection(COLLECTION.USER, ref => ref
          .where('isDeleted', '==', false)
          .orderBy('displayName', 'asc'))
        .snapshotChanges()
        .pipe(map
          (changes => {
            return changes.map((a: any) => {
              // console.log(action.payload.doc.id);
              const data: any = a.payload.doc.data() as any;
              data.id = a.payload.doc.id;
              return data;
            });
          })
        );
    } catch (error) {
      throw error;
    }
  }

  async update(userId: string, userData: any) {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }
      if (isUndefinedOrNullOrEmpty(userData)) {
        throw Error('Invalid userData: ' + userData);
      }

      const isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (!isAdmin) {
        if (this.authUser.id !== userId) {
          throw Error('You are not authorized to retreive the user.');
        }
      }

      return firebase.firestore()
        .collection(COLLECTION.USER)
        .doc(userId)
        .update(userData);
    } catch (error) {
      throw error;
    }
  }

  async updateUser(userData: any) {
    try {

      console.warn('Function updateUser() is deprecated. Please use update() instead.');

      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      const userId = userData.id;
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }
      if (isUndefinedOrNullOrEmpty(userData)) {
        throw Error('Invalid userData: ' + userData);
      }

      const isAdmin = false; // await getIsAdmin(this.authUser.id);
      if (!isAdmin) {
        if (this.authUser.id !== userId) {
          throw Error('You are not authorized to retreive the user.');
        }
      }

      return this.afs
        .collection(COLLECTION.USER)
        .doc(userId)
        .update(userData);
    } catch (error) {
      throw error;
    }
  }

  // ===========================================================

  initUser(uid: string): User {
    const roles: IRole = {
      admin: false,
      editor: false,
      employee: false,
      owner: true,
      subscriber: false,
    };

    const user: User = {
      id: '',
      email: '',                  // The user's email.
      photoURL: '/assets/images/unknown_user.png',  // The user's avatar photo URL.
      displayName: '',            // the user's display name.
      // owner_id: this.authService.uid,               // The id of the user who controls the user. REQUIRED
      owner_id: uid,               // The id of the user who controls the user. REQUIRED
      owner_name: null,

      name: '',                   // The user's First and Last name.
      first_name: '',             // The user's first name.
      last_name: '',              // The user's last name.
      address: '',                // The user's address.
      address2: '',               // A second address field which is generally used for storing suite or unit numbers.
      city: '',                   // The user's city.
      state: '',                  // The user's state.
      zip: '',                    // The user's postal code.
      country: '',                // The user's country.
      country_code: '',           // The user's country code.
      birthday: '',               // The user's birthday.
      title: '',                  // The user's job title.
      website: '',                // The user's webite.
      bulk_email: 0,              // A flag that indicates whether or not a user is opted in to bulk email.
      // Values are mapped as follows:
      //     ( 0 => Opted-out; 1 => Opted-in; 2 => Double opt-in; -2 => Hard bounce; -5 => Under review)
      // You can only set the value to 0 through the API. You cannot manually opt-in a contact who has opted out.
      bulk_sms: 0,                // A flag that indicates whether or not a user is opted in to bulk sms.
      // Values are mapped as follows:
      //     ( 0 => Opted-out; 1 => Opted-in; 2 => Double opt-in; -2 => Hard bounce; -5 => Under review)
      // You can only set the value to 0 through the API. You cannot manually opt-in a contact who has opted out.

      org_id: '',
      org_name: '',
      org_industry: '',
      company_id: '',             // The id of the company the user is affiliated with.
      company_name: '',            // The company the user is affiliated with.
      company_industry: '',        // The industry of the company the user is affilated with.

      fax: '',                    // The user's fax number.
      phone: '',                  // The user's preferred phone number.
      phone_cell: '',             // The user's mobile phone number.
      phone_home: '',             // The user's home phone number.
      phone_office: '',           // The user's office phone number.
      phone_sms: '',              // The mobile number where the user prefers to receive text messages.
      //
      // SYSTEM FIELD. Can't be updated through the API.
      system_source: '',          // SYSTEM FIELD. Identifies the source from which the user was added to the database.
      source_location: '',        // SYSTEM FIELD. Identifies the specific location from which the user was added to the database.
      import_id: '',              // SYSTEM FIELD. The ID of the import batch the user was imported with, if any.
      //
      ip_addy: '',                // The user's IP address.
      referral_page: '',          // The page the user was referred from.
      referrer_first: '',         // The affiliate ID of the first affiliate to refer the user.
      referrer_last: '',          // The affiliate ID of the last affiliate to refer the user.
      //
      n_lead_source: '',          // The lead source ID for the tracking URL the user arrived from.
      n_content: '',              // The content ID for the tracking URL the user arrived from.
      n_medium: '',               // The medium ID for the tracking URL the user arrived from.
      n_campaign: '',             // The campaign ID for the tracking URL the user arrived from.
      n_term: '',                 // The term ID for the tracking URL the user arrived from.
      //
      aff_sales: 0,               // For affiliates, the total number of affilate sales to date.
      aff_amount: 0,              // For affiliate, the total amount of affilate sales earned to date.
      aff_program_id: '',         // For affiliates, the partner program ID.
      aff_paypal: '',             // For affiliates, the email address for Paypal payments.
      //
      fb_birthday: '',            // The user's birthday, as listed on Facebook.
      fb_gender: '',              // The user's gender, as listed on Facebook.
      fb_likes: '',               // The user's Facebook "likes".
      fb_groups: '',              // The groups the user belongs to on Facebook.
      fb_website: '',             // The user's website, as listed on Facebook.
      fb_hometown: '',            // The user's hometown, as listed on Facebook.
      fb_location: '',            // The user's location, as listed on Facebook.
      //
      mrc_amount: 0,              // The amount of the user's most recent credit card charge.
      mrc_unpaid: 0,              // The amount of the user's remaining unpaid transactions.
      mrc_result: 0,              // The result of the user's most recent credit card charge.
      //
      mri_invoice_num: 0,         // The user's most recent invoice 0,
      mri_invoice_total: 0,       // The user's most recent invoice total;
      //
      sms_inbound_last_date: '',       // The last SMS message the user received from us.
      spent: 0,                   // The total amount the user has spent with us.
      num_purchased: 0,           // The user's total orders.
      grade: 0,                   // The user's score based upon lead scoring rules.
      //
      balance: 0,

      visible_to: [''],
      niche_id: '',

      deals_open_count: 0,
      deals_closed_count: 0,
      deals_lost_count: 0,

      activities_done_count: 0,
      activities_undone_count: 0,
      activities_total_count: 0,

      activity_next_date: null,
      activity_last_date: null,   // The date of the user's last activity. (interacted with a form, website, or email link)

      email_message_count: 0,
      email_last_sent_date: null,
      email_last_received_date: null,

      created_date: null,         // The date the contact was added, measured in seconds from the Unix Epoch.
      updated_date: null,         // The date the contact was updated, measured in seconds from the Unix Epoch.
      deleted_date: null,         // The date the contact was deleted, measured in seconds from the Unix Epoch.

      promo_code: '',
      roles,
    };

    return user;
  }

  newUser(userData: any) {
    try {
      userData.owner_id = this.authUser.id;
      userData.visible_to = [this.authUser.id];

      return this.afs
        .collection(COLLECTION.USER)
        .doc(userData.id) // user.owner_id
        .set(userData);
    } catch (error) {
      throw error;
    }
  }

  // ===========================================================

  changeEmail(userId: string, email: string, newEmail: string) {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }
      if (isUndefinedOrNullOrEmpty(email)) {
        throw Error('Invalid email: ' + email);
      }
      if (isUndefinedOrNullOrEmpty(newEmail)) {
        throw Error('Invalid new email: ' + newEmail);
      }

      const headers = createAuthHeaders(this.authUser.token);

      const params: HttpParams = new HttpParams()
        .set('adminId', this.authUser.id)
        .set('userId', userId.trim())
        .set('email', email.trim())
        .set('newEmail', newEmail.trim());

      // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
      const url = this.apiLeadCarrot + 'accountChangeEmail';

      return this.http.get<any>(url, { params, headers })
        .pipe(
          map((res: any) => {
            console.log(res);
            return res;
          })
        );
    } catch (error) {
      throw error;
    }
  }

  changePassword(userId: string, email: string, password: string = DEFAULT_PASSWORD) {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }
      if (isUndefinedOrNullOrEmpty(email)) {
        throw Error('Invalid email: ' + email);
      }
      if (isUndefinedOrNullOrEmpty(password)) {
        throw Error('Invalid password: ' + password);
      }

      const headers = createAuthHeaders(this.authUser.token);

      const params: HttpParams = new HttpParams()
        .set('adminId', this.authUser.id)
        .set('userId', userId.trim())
        .set('email', email.trim())
        .set('password', password.trim());

      // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
      const url = this.apiLeadCarrot + 'accountChangePassword';

      console.log('url:', url);

      return this.http.get<any>(url, { params, headers })
        .pipe(
          map((res: any) => {
            console.log(res);
            return res;
          })
        );
    } catch (error) {
      throw error;
    }
  }

  deleteAccount(userId: string, email: string) {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }
      if (isUndefinedOrNullOrEmpty(email)) {
        throw Error('Invalid email: ' + email);
      }

      const headers = createAuthHeaders(this.authUser.token);

      const params: HttpParams = new HttpParams()
        .set('adminId', this.authUser.id)
        .set('userId', userId.trim())
        .set('email', email.trim());

      // RxJs - shareReplay - Observable is retryable, the result of the HTTP call is cached.
      const url = this.apiLeadCarrot + 'accountDelete';

      console.log('url:', url);

      return this.http.get<any>(url, { params, headers })
        .pipe(
          map((res: any) => {
            console.log(res);
            return res;
          })
        );
    } catch (error) {
      throw error;
    }
  }

  disableAccount(userId: string, email: string, disable: boolean = true) {
    try {
      if (isUndefinedOrNullOrEmpty(this.authUser.id)) {
        throw Error('Invalid authId: ' + this.authUser.id);
      }
      if (isUndefinedOrNullOrEmpty(userId)) {
        throw Error('Invalid userId: ' + userId);
      }
      if (isUndefinedOrNullOrEmpty(email)) {
        throw Error('Invalid email: ' + email);
      }
      if (isUndefinedOrNull(disable)) {
        throw Error('Invalid status: ' + disable);
      }

      const headers = createAuthHeaders(this.authUser.token);

      const params: HttpParams = new HttpParams()
        .set('adminId', this.authUser.id)
        .set('userId', userId.trim())
        .set('email', email.trim())
        .set('disable', disable.toString());

      const url = this.apiLeadCarrot + 'accountDisable';

      console.log('url:', url);

      return this.http.get<any>(url, { params, headers })
        .pipe(
          map((res: any) => {
            console.log(res);
            return res;
          })
        );
    } catch (error) {
      throw error;
    }
  }

  /*
  delete(id: string) {
    return this.http
      .delete(
        `${ this.END_POINT }/contactPerson/delete/${ this.authUser.company.id }/${ id }`,
        { headers: createAuthHeaders(this.authUser.token) }
      )
      .pipe(
        catchError((error: Response) => throwError(error))
      );
  }
  */

}
