import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { BehaviorSubject, Observable, Observer, from, Subscription } from 'rxjs';
import { map, concatMap, bufferCount, scan, tap, take } from 'rxjs/operators';
import localforage from 'localforage';

import { IQueryConfig } from '../pagination/pagination.service';
import { ICompany, IUser } from '../../models';

import '@firebase/firestore';
import { AngularFirestore, AngularFirestoreCollection, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { COLLECTION, isUndefinedOrNull, isUndefinedOrNullOrEmpty, getIsAdmin } from '../../shared/utils';
import { AuthService } from '../auth/auth.service';

const GROUP_PAGINATION_LIMIT = 15;

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private query: IQueryConfig;
    private queryNoReplies: IQueryConfig;
    private queryWithReplies: IQueryConfig;

    private _chats = new BehaviorSubject([]);
    private _chatsNoReplies = new BehaviorSubject([]);
    private _chatsWithReplies = new BehaviorSubject([]);

    private _done = new BehaviorSubject(false);
    private _doneNoReplies = new BehaviorSubject(false);
    private _doneWithReplies = new BehaviorSubject(false);

    private _loading = new BehaviorSubject(false);
    private _loadingNoReplies = new BehaviorSubject(false);
    private _loadingWithReplies = new BehaviorSubject(false);

    // Observable data
    chats: Observable<any> = this._chats.asObservable();
    chatsNoReplies: Observable<any> = this._chatsNoReplies.asObservable();
    chatsWithReplies: Observable<any> = this._chatsWithReplies.asObservable();

    done: Observable<boolean> = this._done.asObservable();
    doneNoReplies: Observable<boolean> = this._doneNoReplies.asObservable();
    doneWithReplies: Observable<boolean> = this._doneWithReplies.asObservable();

    loading: Observable<boolean> = this._loading.asObservable();
    loadingNoReplies: Observable<boolean> = this._loadingNoReplies.asObservable();
    loadingWithReplies: Observable<boolean> = this._loadingWithReplies.asObservable();

    // private _chats = new BehaviorSubject<any[]>([]);
    // chats: Observable<any[]> = this._chats.asObservable();

    private _messages: any[] = [];
    messages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    private allSubs: Subscription = new Subscription();
    private isAdmin: boolean;
    private isInitialized: boolean;

    private user: IUser;
    private userId: string;
    private lastUserId: string;

    private company: ICompany;
    private companyId: string;
    private lastCompanyId: string;

    private permissions: any = null;

    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
    ) {
        this._chats.next([]);
        this._chatsNoReplies.next([]);
        this._chatsWithReplies.next([]);

        this._done.next(false);
        this._doneNoReplies.next(false);
        this._doneWithReplies.next(false);

        this._loading.next(false);
        this._loadingNoReplies.next(false);
        this._loadingWithReplies.next(false);

        this.user = null;
        this.userId = '';
        this.lastUserId = '';

        this.company = null;
        this.companyId = '';
        this.lastCompanyId = '';

        this.isInitialized = false;

        this.init();
    }

    async init() {

        this.allSubs.add(
            this.authService.user
                .subscribe(async user => {
                    try {
                        if (user) {
                            this.user = user;
                            this.userId = user.id;
                            this.isInitialized = true;

                            if (this.userId !== this.lastUserId) {
                                this.lastUserId = this.userId;
                                this.isAdmin = false; // await getIsAdmin(user.id);
                                if (user.email.indexOf('andre@leadcarrot.io') > 0
                                    || user.email.indexOf('andre.v.fortin@gmail.com') > 0
                                    || user.email.indexOf('@fortinmedia.ca') > 0
                                ) {
                                    this.isAdmin = true;
                                }
                                // this.loadChats();
                            }

                        } else {
                            this.user = null;
                            this.userId = '';
                            this.lastUserId = '';
                            this.isInitialized = false;
                        }
                        return Promise.resolve();
                    } catch (error) {
                        console.log(error);
                        this._loading.next(false);
                        return Promise.resolve();
                    }
                })
        );

        /*
        this.allSubs.add(
            this.sessionService.userPermission
                .subscribe(permissions => {
                    this.permissions = permissions;
                })
        );
        */

        this.allSubs.add(
            this.authService.company
                .subscribe(company => {
                    try {
                        this._chats.next([]);

                        if (!isUndefinedOrNullOrEmpty(company)) {
                            this.company = company;
                            this.companyId = company.id;
                            if (this.companyId !== this.lastCompanyId) {
                                this.lastCompanyId = this.companyId;
                            }

                            this.initializeChatService(company.id);

                        } else {
                            this.company = null;
                            this.companyId = '';
                            this.lastCompanyId = '';
                        }

                        return Promise.resolve();
                    } catch (error) {
                        console.log(error);
                        return Promise.resolve();
                    }
                })
        );

        /*
        this.chats
            .subscribe(data => {
                if (isUndefinedOrNullOrEmpty(data)) {
                    this._chats.next([]);
                } else {
                    this._chats.next(data);
                }
            });

        this.loading
            .subscribe(data => {
                if (isUndefinedOrNullOrEmpty(data)) {
                    this._loading.next(data);
                } else {
                    this._loading.next(false);
                }
            });

        this.done
            .subscribe(data => {
                if (isUndefinedOrNullOrEmpty(data)) {
                    this._done.next(data);
                } else {
                    this._done.next(false);
                }
            });
        */
    }

    initializeChatService(companyId: string) {
        if (!isUndefinedOrNullOrEmpty(companyId)) {

            // if (!this.isAdmin) {
            //     companyId = this.authService.company.value.id;
            // }
            // if (isUndefinedOrNull(companyId)) {
            //     companyId = this.authService.company.value.id;

            // }

            this.initChats(COLLECTION.CHAT_GROUPS, 'updatedAt',
                {
                    reverse: true,
                    prepend: false,
                    companyId,
                    isDeleted: false,
                    limit: GROUP_PAGINATION_LIMIT,
                });

            this.initChatsNoReplies(COLLECTION.CHAT_GROUPS, 'updatedAt',
                {
                    reverse: true,
                    prepend: false,
                    companyId,
                    isDeleted: false,
                    limit: GROUP_PAGINATION_LIMIT,
                });

            this.initChatsWithReplies(COLLECTION.CHAT_GROUPS, 'updatedAt',
                {
                    reverse: true,
                    prepend: false,
                    companyId,
                    isDeleted: false,
                    limit: GROUP_PAGINATION_LIMIT,
                });
        }

        return;
    }

    // Initial query sets options and defines the Observable
    // passing opts will override the defaults
    initChats(path: string, field: string, opts?: any) {
        this.query = {
            path,
            field,
            limit: 2,
            reverse: false,
            prepend: false,
            ...opts
        };

        console.log('*** pagination query:', this.query);

        this._loading.next(false);

        const first = this.afs.collection(this.query.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.query.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                } else {
                    return ref
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                } else {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                }
            }
        });

        this.mapAndUpdate(first);

        // Create the observable array for consumption in components
        this.chats = this._chats
            .asObservable()
            .pipe(
                scan((acc, val) => {
                    return this.query.prepend ? val.concat(acc) : acc.concat(val);
                })
            );
    }

    initChatsNoReplies(path: string, field: string, opts?: any) {
        this.queryNoReplies = {
            path,
            field,
            limit: 2,
            reverse: false,
            prepend: false,
            ...opts
        };

        console.log('*** pagination queryNoReplies:', this.queryNoReplies);

        this._loadingNoReplies.next(false);

        const first = this.afs.collection(this.queryNoReplies.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.queryNoReplies.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.queryNoReplies.companyId)) {
                    return ref
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit);
                } else {
                    return ref
                        .where('companyId', '==', this.queryNoReplies.companyId)
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.queryNoReplies.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.queryNoReplies.isDeleted)
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit);
                } else {
                    return ref
                        .where('isDeleted', '==', this.queryNoReplies.isDeleted)
                        .where('companyId', '==', this.queryNoReplies.companyId)
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit);
                }
            }
        });

        this.mapAndUpdateNoReplies(first);

        // Create the observable array for consumption in components
        this.chatsNoReplies = this._chatsNoReplies
            .asObservable()
            .pipe(
                scan((acc, val) => {
                    return this.queryNoReplies.prepend ? val.concat(acc) : acc.concat(val);
                })
            );
    }

    initChatsWithReplies(path: string, field: string, opts?: any) {
        this.queryWithReplies = {
            path,
            field,
            limit: 2,
            reverse: false,
            prepend: false,
            ...opts
        };

        console.log('*** pagination queryWithReplies:', this.queryWithReplies);

        this._loadingWithReplies.next(false);

        const first = this.afs.collection(this.queryWithReplies.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.queryWithReplies.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.queryWithReplies.companyId)) {
                    return ref
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit);
                } else {
                    return ref
                        .where('companyId', '==', this.queryWithReplies.companyId)
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.queryWithReplies.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.queryWithReplies.isDeleted)
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit);
                } else {

                    console.warn('queryWithReplies:', this.queryWithReplies);

                    return ref
                        .where('isDeleted', '==', this.queryWithReplies.isDeleted)
                        .where('companyId', '==', this.queryWithReplies.companyId)
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit);
                }
            }
        });

        this.mapAndUpdateWithReplies(first);

        // Create the observable array for consumption in components
        this.chatsWithReplies = this._chatsWithReplies
            .asObservable()
            .pipe(
                scan((acc, val) => {
                    return this.queryWithReplies.prepend ? val.concat(acc) : acc.concat(val);
                })
            );
    }

    // Determines the doc snapshot to paginate query
    private getCursor() {
        const current = this._chats.value;
        if (current.length) {
            return this.query.prepend ? current[0].doc : current[current.length - 1].doc;
        }
        return null;
    }

    // Determines the doc snapshot to paginate query
    private getCursorNoReplies() {
        const current = this._chatsNoReplies.value;
        if (current.length) {
            return this.queryNoReplies.prepend ? current[0].doc : current[current.length - 1].doc;
        }
        return null;
    }

    // Determines the doc snapshot to paginate query
    private getCursorWithReplies() {
        const current = this._chatsWithReplies.value;
        if (current.length) {
            return this.queryWithReplies.prepend ? current[0].doc : current[current.length - 1].doc;
        }
        return null;
    }

    // Retrieves additional data from firestore
    more() {
        const cursor = this.getCursor();

        const more = this.afs.collection(this.query.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.query.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                }
            }
        });
        this.mapAndUpdate(more);
    }

    // Retrieves additional data from firestore
    moreNoReplies() {
        const cursor = this.getCursorNoReplies();

        const more = this.afs.collection(this.queryNoReplies.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.queryNoReplies.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.queryNoReplies.companyId)) {
                    return ref
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('companyId', '==', this.queryNoReplies.companyId)
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit)
                        .startAfter(cursor);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.queryNoReplies.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('isDeleted', '==', this.queryNoReplies.isDeleted)
                        .where('companyId', '==', this.queryNoReplies.companyId)
                        .where('hasReply', '==', false)
                        .orderBy(this.queryNoReplies.field, this.queryNoReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryNoReplies.limit)
                        .startAfter(cursor);
                }
            }
        });
        this.mapAndUpdateNoReplies(more);
    }

    // Retrieves additional data from firestore
    moreWithReplies() {
        const cursor = this.getCursorWithReplies();

        console.warn('A - queryWithReplies:', this.queryWithReplies);

        const more = this.afs.collection(this.queryWithReplies.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.queryWithReplies.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.queryWithReplies.companyId)) {
                    return ref
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('companyId', '==', this.queryWithReplies.companyId)
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit)
                        .startAfter(cursor);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.queryWithReplies.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.queryWithReplies.isDeleted)
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('isDeleted', '==', this.queryWithReplies.isDeleted)
                        .where('companyId', '==', this.queryWithReplies.companyId)
                        .where('hasReply', '==', true)
                        .orderBy(this.queryWithReplies.field, this.queryWithReplies.reverse ? 'desc' : 'asc')
                        .limit(this.queryWithReplies.limit)
                        .startAfter(cursor);
                }
            }
        });
        this.mapAndUpdateWithReplies(more);
    }

    // Maps the snapshot to usable format the updates source
    private mapAndUpdate(col: AngularFirestoreCollection<any>) {

        /*
        console.warn('**************************');
        console.log('   mapAndUpdate()');
        console.warn('**************************');
        console.log('*** this._done:', this._done.value);
        console.log('*** this._loading:', this._loading.value);
        console.warn('**************************');
        */

        if (this._done.value || this._loading.value) {
            return;
        }

        // loading
        this._loading.next(true);

        // Map snapshot with doc ref (needed for cursor)
        return col.snapshotChanges()
            .pipe(
                tap(arr => {
                    let values = arr.map(snap => {
                        const data = snap.payload.doc.data();
                        const doc = snap.payload.doc;
                        return { ...data, doc };
                    });

                    // If prepending, reverse the batch order
                    values = this.query.prepend ? values.reverse() : values;

                    // update source with new values, done loading
                    console.log('CHAT GROUPS:', values);

                    this._loading.next(false);

                    // no more values, mark done
                    if (!values.length) {
                        this._done.next(true);
                    }

                    this._chats.next(values);
                })
            )
            .pipe(
                take(1)
            )
            .subscribe();
    }


    // Maps the snapshot to usable format the updates source
    private mapAndUpdateNoReplies(col: AngularFirestoreCollection<any>) {

        /*
        console.warn('**************************');
        console.log('   mapAndUpdateNoReplies()');
        console.warn('**************************');
        console.log('*** this._doneNoReplies:', this._doneNoReplies.value);
        console.log('*** this._loadingNoReplies:', this._loadingNoReplies.value);
        console.warn('**************************');
        */

        if (this._doneNoReplies.value || this._loadingNoReplies.value) {
            return;
        }

        // loading
        this._loadingNoReplies.next(true);

        // Map snapshot with doc ref (needed for cursor)
        return col.snapshotChanges()
            .pipe(
                tap(arr => {
                    let values = arr.map(snap => {
                        const data = snap.payload.doc.data();
                        const doc = snap.payload.doc;
                        return { ...data, doc };
                    });

                    // If prepending, reverse the batch order
                    values = this.queryNoReplies.prepend ? values.reverse() : values;

                    // update source with new values, done loading
                    console.log('CHAT GROUPS NoReplies:', values);

                    this._loadingNoReplies.next(false);

                    // no more values, mark done
                    if (!values.length) {
                        this._doneNoReplies.next(true);
                    }

                    this._chatsNoReplies.next(values);
                })
            )
            .pipe(
                take(1)
            )
            .subscribe();
    }


    // Maps the snapshot to usable format the updates source
    private mapAndUpdateWithReplies(col: AngularFirestoreCollection<any>) {

        /*
        console.warn('**************************');
        console.log('   mapAndUpdateWithReplies()');
        console.warn('**************************');
        console.log('*** this._doneWithReplies:', this._doneWithReplies.value);
        console.log('*** this._loadingWithReplies:', this._loadingWithReplies.value);
        console.warn('**************************');
        */

        if (this._doneWithReplies.value || this._loadingWithReplies.value) {
            return;
        }

        // loading
        this._loadingWithReplies.next(true);

        // Map snapshot with doc ref (needed for cursor)
        return col.snapshotChanges()
            .pipe(
                tap(arr => {
                    let values = arr.map(snap => {
                        const data = snap.payload.doc.data();
                        const doc = snap.payload.doc;
                        return { ...data, doc };
                    });

                    // If prepending, reverse the batch order
                    values = this.queryWithReplies.prepend ? values.reverse() : values;

                    // update source with new values, done loading
                    console.log('CHAT GROUPS WithReplies:', values);

                    this._loadingWithReplies.next(false);

                    // no more values, mark done
                    if (!values.length) {
                        this._doneWithReplies.next(true);
                    }

                    console.log('chats with replies:', values);

                    this._chatsWithReplies.next(values);
                })
            )
            .pipe(
                take(1)
            )
            .subscribe();
    }

    destroy() {
        this.isInitialized = false;
        this._loading.next(false);
        this.allSubs.unsubscribe();
    }

    async deleteChat(chatId: string, companyId: string = null) {
        try {
            const chatRef = this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(chatId)
                .collection(COLLECTION.CHAT_GROUP_MESSAGES);
            await this.deleteFirestoreCollection(chatRef);

            return this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(chatId).delete();
        } catch (error) {
            console.error(error);
            return Promise.resolve(error);
        }
    }

    private async deleteFirestoreCollection(
        collection: AngularFirestoreCollection<any>,
    ): Promise<number> {

        let totalDeleteCount = 0;
        const batchSize = 500;

        return new Promise<number>((resolve, reject) =>
            from(collection.ref.get())
                .pipe(
                    concatMap((q) => from(q.docs)),
                    bufferCount(batchSize),
                    concatMap((docs: Array<QueryDocumentSnapshot<any>>) => new Observable(
                        (o: Observer<number>) => {
                            const batch = this.afs.firestore.batch();
                            docs.forEach((doc) => {
                                batch.delete(doc.ref);
                            });
                            batch.commit()
                                .then(() => {
                                    o.next(docs.length);
                                    o.complete();
                                })
                                .catch((e) => o.error(e));
                        })),
                )
                .subscribe(
                    (batchDeleteCount: number) => totalDeleteCount += batchDeleteCount,
                    (e) => reject(e),
                    () => resolve(totalDeleteCount),
                ),
        );
    }

    async hideGroup(chatId: string) {
        try {
            await this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(chatId)
                .update({ isHidden: true });
            return Promise.resolve(true);
        } catch (error) {
            console.error(error);
            return Promise.resolve(false);
        }
    }

    async showGroup(chatId: string) {
        try {
            await this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(chatId)
                .update({ isHidden: false });
            return Promise.resolve(true);
        } catch (error) {
            console.error(error);
            return Promise.resolve(false);
        }
    }

    async markGroupRead(groupId: string, isRead: boolean = true) {
        try {
            await this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(groupId)
                .update({ isRead });
            return Promise.resolve(true);
        } catch (error) {
            console.error(error);
            return Promise.resolve(false);
        }
    }

    /*
    async loadChats(companyId: string = null, usePagination: boolean = true): Promise<boolean> {

        console.log('LOAD CHATS');

        if (!this.isInitialized) {
            this._chats.next([]);
            return Promise.resolve(false);
        }

        if (!this.isAdmin) {
            companyId = this.authService.company.value.id;
        }
        if (isUndefinedOrNull(companyId)) {
            companyId = this.authService.company.value.id;
            if (isUndefinedOrNull(companyId)) {
                this._chats.next([]);
                return Promise.resolve(false);
            }
        }

        this.lastCompanyId = companyId;

        if (usePagination) {

            console.log('USE PAGINATION');

            this.paginationService.init(COLLECTION.CHAT_GROUPS, 'updatedAt',
                {
                    reverse: true,
                    prepend: false,
                    companyId: companyId,
                    isDeleted: false,
                    limit: 10,
                });
        } else {

            console.log('NO PAGINATION');

            try {
                await this.afs
                    .collection(COLLECTION.CHAT_GROUPS, ref => ref
                        .where('isDeleted', '==', false)
                        .where('companyId', '==', companyId)
                        .orderBy('updatedAt', 'desc'))
                    .valueChanges()
                    .subscribe(chats => {
                        if (typeof chats !== 'undefined') {
                            this._chats.next(chats);
                        }
                    });
            } catch (error) {
                console.log(error);
                this._chats.next([]);
                this._isLoading.next(false);
                return Promise.resolve(false);
            }
        }

        return Promise.resolve(true);
    }
    */

    getChat(chatId: string): Observable<any> {
        try {
            if (isUndefinedOrNullOrEmpty(chatId)) {
                throw Error('Missing chat ID.');
            }

            return this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(chatId)
                .valueChanges();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getMessages(chatId: string) {
        try {
            if (isUndefinedOrNullOrEmpty(chatId)) {
                throw Error('Missing chat ID.');
            }

            console.log('*** set last chat id:', chatId);

            // this.setLastChatId(chatId);

            return this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(chatId)
                .collection(COLLECTION.CHAT_GROUP_MESSAGES, ref => ref
                    .orderBy('createdAt', 'desc'))
                .valueChanges()
                .subscribe(messages => {
                    if (typeof messages !== 'undefined') {
                        this._messages = messages.map(message => {
                            message.chatId = chatId;
                            return message;
                        });
                        // this._messages = messages;

                        console.log('*** messages:', messages);

                        this.messages.next(this._messages);
                    }
                });
        } catch (error) {
            console.error(error);
            this._messages = [];
            this.messages.next(this._messages);
            this._loading.next(false);
            throw error;
        }
    }

    markAsRead(groupId: string, messageId: string) {
        if (typeof groupId === 'undefined' || typeof messageId === 'undefined') {
            return;
        }
        return this.afs
            .collection(COLLECTION.CHAT_GROUPS)
            .doc(groupId)
            .collection(COLLECTION.CHAT_GROUP_MESSAGES)
            .doc(messageId)
            .update({ isRead: true });
    }

    updateChat(chatId: string, params: any, addTimestamp: boolean = true) {
        try {
            if (isUndefinedOrNullOrEmpty(chatId)) {
                throw Error('Missing chat ID.');
            }
            if (isUndefinedOrNullOrEmpty(params)) {
                throw Error('Missing chat params.');
            }

            if (addTimestamp) {
                params.updatedAt = new Date();
                params.updatedBy = this.authService.user.value.id;
            }

            return this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(chatId)
                .update(params);
        } catch (error) {
            console.error(error);
            return;
        }
    }

    async getLastChatId(userId: string = '', companyId: string = ''): Promise<string> {
        if (isUndefinedOrNullOrEmpty(userId)) {
            userId = this.userId;
        }
        if (isUndefinedOrNullOrEmpty(companyId)) {
            companyId = this.companyId;
        }
        const key = 'last-chat-id|' + userId + '|' + companyId;

        const chatId = await localforage.getItem<string>(key);
        if (isUndefinedOrNullOrEmpty(chatId)) {
            return '';
        } else {
            return chatId;
        }
    }

    setLastChatId(chatId: string, userId: string = '', companyId: string = '') {
        if (isUndefinedOrNullOrEmpty(userId)) {
            userId = this.userId;
        }
        if (isUndefinedOrNullOrEmpty(companyId)) {
            companyId = this.companyId;
        }
        const key = 'last-chat-id|' + userId + '|' + companyId;

        localforage.setItem(key, chatId);
    }
}

/*
getChats(companyId: string = null): Observable<any> {
    if (!this.isAdmin) {
        companyId = this.authService.company.value.id;
    }
    if (isUndefinedOrNull(companyId)) {
        companyId = this.authService.company.id;
        if (isUndefinedOrNull(companyId)) {
            throw Error('Missing company ID.');
        }
    }
    return this.afs
        .collection<any>(COLLECTION.CHAT_GROUPS, ref => ref
            .where('companyId', '==', companyId)
            .orderBy('updatedAt', 'desc'))
        .valueChanges();
}
*/
