import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { from, Observable, Observer } from 'rxjs';
import { bufferCount, concatMap, take, map } from 'rxjs/operators';
import { COLLECTION, isUndefinedOrNullOrEmpty } from '../../shared/utils';
import { INotification } from '../../models/INotification';

export const enum TYPE {
  ALL = 'all',
  ACTION = 'action',
  EMAIL = 'email',
  EVENT = 'event',
  MESSAGE = 'message',
  SMS = 'sms',
  SYSTEM = 'system',
  TEAM = 'team',
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  // tslint:disable-next-line: variable-name
  private _allCompanies: boolean;
  userId: string;
  companyId: string;

  constructor(
    // private http: HttpClient,
    private afs: AngularFirestore,
  ) {
    this._allCompanies = false;
    this.userId = '';
    this.companyId = '';
  }

  async add(notification: INotification, userId: string = null) {
    try {
      let validatedUserId = userId;
      if (isUndefinedOrNullOrEmpty(validatedUserId)) {
        if (isUndefinedOrNullOrEmpty(this.userId)) {
          throw Error('Invalid userId: ' + userId);
        }
        validatedUserId = this.userId;
      } else {
        this.userId = validatedUserId;
      }

      // Add the notification to the collection
      await this.afs.collection(COLLECTION.USER)
        .doc(validatedUserId)
        .collection<INotification>(COLLECTION.NOTIFICATION)
        .add(notification);

      return Promise.resolve(notification);

    } catch (error) {
      console.error(error);
      return Promise.resolve(false);
    }
  }

  async clearByContact(contactId: string, companyId: string = null) {
    try {

      console.log('--- START - clearByContact() | contactId: ' + contactId);

      if (isUndefinedOrNullOrEmpty(contactId)) {
        throw Error('--- STOP - clearByContact() - ERROR - Invalid contactId: ' + contactId);
      }

      let validatedCompanyId = companyId;
      if (isUndefinedOrNullOrEmpty(validatedCompanyId)) {

        console.warn('UNABLE to validate companyID');

        if (isUndefinedOrNullOrEmpty(this.companyId)) {
          throw Error('Invalid companyId: ' + companyId);
        }
        validatedCompanyId = this.companyId;
      } else {
        this.companyId = validatedCompanyId;
      }

      console.log('clearByContact() | START - get deals to update');

      const deals: any[] = [];

      this.afs
        .collection(COLLECTION.DEAL, ref => ref
          .where('companyId', '==', validatedCompanyId)
          .where('isDeleted', '==', false)
          .where('contactPersonId', '==', contactId))
        .snapshotChanges()
        .pipe(
          take(1)
        )
        .pipe(
          map(actions => {
            return actions.map((a: any) => {
              const data = a.payload.doc.data() as any;
              const id = a.payload.doc.id;
              return { id, ...data };
            });
          })
        )
        .subscribe(async actions => {
          actions.forEach(snap => {
            deals.push(snap);
          });

          // Update the deals to hasNotification === false
          if (deals.length) {

            for (const deal of deals) {

              console.log('UPDATE DEAL id:', deal);

              await this.afs
                .collection(COLLECTION.DEAL)
                .doc(deal.id)
                .update({ hasNotification: false })
                .then(() => {
                  return true;
                })
                .catch((error: any) => {
                  console.log(error);
                  return false;
                });
            }
          }
        });

      return Promise.resolve(true);

    } catch (error) {
      console.error(error);
      return Promise.resolve(false);
    }
  }

  get allCompanies(): boolean {
    return this._allCompanies;
  }

  set allCompanies(value: boolean) {
    this._allCompanies = !!value;
  }

  get(notificationId: string, userId: string = null) {
    try {
      if (isUndefinedOrNullOrEmpty(notificationId)) {
        throw Error('Invalid notificationId: ' + notificationId);
      }
      let validatedUserId = userId;
      if (isUndefinedOrNullOrEmpty(validatedUserId)) {
        if (isUndefinedOrNullOrEmpty(this.userId)) {
          throw Error('Invalid userId: ' + userId);
        }
        validatedUserId = this.userId;
      } else {
        this.userId = validatedUserId;
      }

      return this.afs
        .collection(COLLECTION.USER)
        .doc(validatedUserId)
        .collection(COLLECTION.NOTIFICATION)
        .doc(notificationId)
        .snapshotChanges();

    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getAll(
    notificationType: TYPE = TYPE.ALL,
    userId: string = null,
    companyId: string = null
  ): Observable<DocumentChangeAction<firebase.firestore.DocumentData>[]> {

    let validatedUserId = userId;
    if (isUndefinedOrNullOrEmpty(validatedUserId)) {
      if (isUndefinedOrNullOrEmpty(this.userId)) {
        throw Error('Invalid userId: ' + userId);
      }
      validatedUserId = this.userId;
    } else {
      this.userId = validatedUserId;
    }

    if (this.allCompanies) {
      return this.afs
        .collection(COLLECTION.USER)
        .doc(validatedUserId)
        .collection(COLLECTION.NOTIFICATION, ref => ref
          .where('isDeleted', '==', false)
          .where('serviceId', '==', notificationType)
          .orderBy('dateTime', 'desc'))
        .snapshotChanges();
    } else {

      let validatedCompanyId = companyId;
      if (isUndefinedOrNullOrEmpty(validatedCompanyId)) {
        if (isUndefinedOrNullOrEmpty(this.companyId)) {
          throw Error('Invalid companyId: ' + companyId);
        }
        validatedCompanyId = this.companyId;
      } else {
        this.companyId = validatedCompanyId;
      }

      return this.afs
        .collection(COLLECTION.USER)
        .doc(validatedUserId)
        .collection(COLLECTION.NOTIFICATION, ref => ref
          .where('isDeleted', '==', false)
          .where('companyId', '==', validatedCompanyId)
          .where('serviceId', '==', notificationType)
          .orderBy('dateTime', 'desc'))
        .snapshotChanges();
    }
  }

  async delete(notificationId: string = null, userId: string = null): Promise<boolean> {
    try {
      if (isUndefinedOrNullOrEmpty(notificationId)) {
        throw Error('Invalid notificationId: ' + notificationId);
      }
      let validatedUserId = userId;
      if (isUndefinedOrNullOrEmpty(validatedUserId)) {
        if (isUndefinedOrNullOrEmpty(this.userId)) {
          throw Error('Invalid userId: ' + userId);
        }
        validatedUserId = this.userId;
      } else {
        this.userId = validatedUserId;
      }

      // Delete the document
      await this.afs
        .collection(COLLECTION.USER)
        .doc(validatedUserId)
        .collection(COLLECTION.NOTIFICATION)
        .doc(notificationId)
        .delete();

      return Promise.resolve(true);
    } catch (error) {
      console.error(error);
      return Promise.resolve(false);
    }
  }

  async deleteAll(serviceId: TYPE = TYPE.ALL, userId: string = null): Promise<number> {
    try {
      let validatedServiceId = serviceId;
      if (isUndefinedOrNullOrEmpty(validatedServiceId)) {
        validatedServiceId = TYPE.ALL;
      }
      let validatedUserId = userId;
      if (isUndefinedOrNullOrEmpty(validatedUserId)) {
        if (isUndefinedOrNullOrEmpty(this.userId)) {
          throw Error('Invalid userId: ' + userId);
        }
        validatedUserId = this.userId;
      } else {
        this.userId = validatedUserId;
      }

      const noftifications = this.afs
        .collection(COLLECTION.USER)
        .doc(validatedUserId)
        .collection(COLLECTION.NOTIFICATION);

      return this._deleteINotifications(validatedServiceId, noftifications);
    } catch (error) {
      console.error(error);
      return Promise.resolve(0);
    }
  }

  private async _deleteINotifications(serviceId: TYPE, collection: AngularFirestoreCollection<any>): Promise<number> {
    try {
      let totalDeleteCount = 0;
      const batchSize = 500;

      return new Promise<number>((resolve, reject) =>
        from(collection.ref.get())
          .pipe(
            concatMap((q: any) => from(q.docs)),
            bufferCount(batchSize),
            concatMap((docs: Array<QueryDocumentSnapshot<any>>) => new Observable(
              (o: Observer<number>) => {
                const batch = this.afs.firestore.batch();
                docs.forEach((doc: any) => {
                  if (serviceId === TYPE.ALL) {
                    batch.delete(doc.ref);
                  } else {
                    if (doc.data().serviceId === serviceId) {
                      batch.delete(doc.ref);
                    }
                  }
                });
                batch.commit()
                  .then(() => {
                    o.next(docs.length);
                    o.complete();
                  })
                  .catch((e) => o.error(e));
              })),
          )
          .subscribe(
            (batchDeleteCount: number) => totalDeleteCount += batchDeleteCount,
            (e) => reject(e),
            () => resolve(totalDeleteCount),
          ),
      );
    } catch (error) {
      console.error(error);
      return Promise.resolve(0);
    }
  }

  async update(notificationId: string, data: any, userId: string = null): Promise<boolean> {
    try {
      if (isUndefinedOrNullOrEmpty(notificationId)) {
        throw Error('Invalid notificationId: ' + notificationId);
      }
      if (isUndefinedOrNullOrEmpty(data)) {
        throw Error('Invalid data: ' + data);
      }

      let validatedUserId = userId;
      if (isUndefinedOrNullOrEmpty(validatedUserId)) {
        if (isUndefinedOrNullOrEmpty(this.userId)) {
          throw Error('Invalid userId: ' + userId);
        }
        validatedUserId = this.userId;
      } else {
        this.userId = validatedUserId;
      }

      await this.afs
        .collection(COLLECTION.USER)
        .doc(validatedUserId)
        .collection(COLLECTION.NOTIFICATION)
        .doc<INotification>(notificationId)
        .update(data);

      return Promise.resolve(true);

    } catch (error) {
      console.error(error);
      return Promise.resolve(false);
    }
  }
}
