import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NearbyLocationsService {

  headers: HttpHeaders; // = new HttpHeaders;

  constructor(
    private http: HttpClient,
  ) {

    /*
    this.headers = new HttpHeaders( {
      'Accept': 'application/xhtml+xml',
      'Accept-Encoding': 'gzip, deflate',
      'Accept-Language': 'en-US,en;q=0.9',
      'Cache-Control': 'max-age=0',
      'Host': 'overpass-api.de',
      'Origin': 'http://overpass-api.de',
      'Upgrade-Insecure-Requests': '1',
      'User-Agent':
        'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Length': '0',
    });
    */
    this.headers = new HttpHeaders();
    this.headers.append('Accept-Encoding', 'gzip, deflate');
    this.headers.append('Accept-Language', 'en-US,en;q=0.9');
    this.headers.append('Cache-Control', 'max-age=0');
    this.headers.append('Host', 'overpass-api.de');
    this.headers.append('Origin', 'http://overpass-api.de');
    this.headers.append('Upgrade-Insecure-Requests', '1');
    this.headers.append('User-Agent',
      'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36');
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.append('Content-Length', '0');
  }

  getNearbyLocations(coords: any) {

    const urlOverpassApiInterpreter = 'https://overpass-api.de/api/interpreter';
    const payload = '<osm-script><query type="node"><around lat="' + coords.lat.toString() + '" lon="' + coords.long.toString() +
      '" radius="40000"/><has-kv k="place" v="town" /></query><print mode="body"/></osm-script>';
    this.headers.set('Content-Length', payload.length.toString());

    return this.http.post(urlOverpassApiInterpreter, payload, {
      headers: this.headers,
      reportProgress: true,
      responseType: 'text',
      withCredentials: false,
    });
    // convert to JSON here
    //  .pipe(map(res => res)).toPromise();
  }
}
