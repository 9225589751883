import * as moment from 'moment';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';
// import { SessionService } from '../session/session.service';
import { environment } from '../../../environments/environment';
import {
    COLLECTION, JOB_STATUS, TASK_STATUS, getDocument,
    getIsAnyDay, getNextDayAvailable, getWeekdaySchedule, isUndefinedOrNull, isUndefinedOrNullOrEmpty, randomIntFromInterval
} from '../../shared/utils';

// import uuidv4 from 'uuid/v4';
const { 'v4': uuidv4 } = require('uuid');

import * as automationData from '../data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const IS_DEBUG = !environment.production;

// const COL_ACTION = 'action';
// const COL_SEQUENCE_TEMPLATE = 'sequenceTemplate';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

import { ISequence, IStep } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class AutomationService {
    private END_POINT: string;

    sequenceCollection: AngularFirestoreCollection<any>;
    sequenceDoc: AngularFirestoreDocument<any>;

    stepCollection: AngularFirestoreCollection<any>;
    stepDoc: AngularFirestoreDocument<any>;

    sequencesObservableArray: Observable<any[]>;
    sequencesArray: any[];

    callflowCollection: AngularFirestoreCollection<any>;
    callflowDoc: AngularFirestoreDocument<any>;

    company: any;

    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
        // private sessionService: SessionService,
    ) {
        if (!IS_DEBUG) {
            this.END_POINT = environment.backendApiUrl;
        } else {
            this.END_POINT = environment.backendApiDebugUrl;
        }

        this.authService.company.subscribe(company => {
            if (company) {
                this.company = company;
            } else {
                this.company = null;
            }
        });
    }

    async addCallflow(params: any, templateId = 'blank', stageId: string) {
        try {

            let callflow: any = {};

            if (!isUndefinedOrNullOrEmpty(params)) {
                callflow = params;
            }

            let newTemplateId = '';
            if (isUndefinedOrNullOrEmpty(templateId)) {
                newTemplateId = 'blank';
            } else {
                newTemplateId = templateId.toString().trim();
            }

            // if (isUndefinedOrNullOrEmpty(callflow.stageId)) {
            // if (isUndefinedOrNullOrEmpty(this.sessionService.stageId)) {
            if (isUndefinedOrNullOrEmpty(stageId)) {
                callflow.id = uuidv4();
                callflow.stageId = callflow.id;
            } else {
                callflow.id = stageId; // this.sessionService.stageId;
                callflow.stageId = callflow.id;
            }
            // }

            callflow.stageName = '';
            /*
            if (isUndefinedOrNullOrEmpty(callflow.stageName)) {
                callflow.stageName = this.sessionService.stageName;
            }
            */

            console.log('callflow.stageId:', callflow.stageId);
            console.log('callflow.stageName:', callflow.stageName);

            callflow.name = typeof callflow.name === 'undefined' ? '' : callflow.name;
            callflow.createdAt = firebase.firestore.Timestamp.now();
            callflow.updatedAt = callflow.createdAt;
            callflow.createdBy = this.authService.user.value.id;
            callflow.updatedBy = callflow.createdBy;
            callflow.isActive = true;
            callflow.isDeleted = false;
            callflow.stepsTotal = 0;

            if (isUndefinedOrNullOrEmpty(callflow.name)) {
                // callflow.name = this.sessionService.stageName;
                callflow.name = ''; // this.sessionService.stageName;
            }
            if (isUndefinedOrNullOrEmpty(callflow.description)) {
                // callflow.description = this.sessionService.stageName;
                callflow.description = ''; // this.sessionService.stageName;
            }
            if (isUndefinedOrNullOrEmpty(callflow.pipelineId)) {
                // callflow.pipelineId = this.sessionService.pipelineId;
                callflow.pipelineId = ''; // this.sessionService.pipelineId;
            }
            if (isUndefinedOrNullOrEmpty(callflow.pipelineName)) {
                callflow.pipelineName = '';
            }
            if (isUndefinedOrNullOrEmpty(callflow.teamId)) {
                callflow.teamId = '';
            }
            if (isUndefinedOrNullOrEmpty(callflow.teamName)) {
                callflow.teamName = '';
            }
            if (isUndefinedOrNullOrEmpty(callflow.photoURL)) {
                callflow.photoURL = '';
            }
            if (isUndefinedOrNullOrEmpty(callflow.isGlobal)) {
                callflow.isGlobal = false;
            }
            if (isUndefinedOrNullOrEmpty(callflow.isShared)) {
                callflow.isShared = false;
            }
            if (isUndefinedOrNullOrEmpty(callflow.isTemplate)) {
                callflow.isTemplate = false;
            }

            if (isUndefinedOrNullOrEmpty(callflow.companyId)) {
                callflow.companyId = this.company.id;
            }
            if (isUndefinedOrNullOrEmpty(callflow.companyName)) {
                callflow.companyName = this.company.id;
            }

            // Get template from Database
            this.getCallflowSteps(newTemplateId)
                .subscribe(steps => {
                    if (steps) {
                        steps.forEach(step => {
                            // Add step to sequence
                            console.log(step.doc.data());
                        });
                    }
                });

            console.log('new callflow =', callflow);

            await this.afs
                .collection(COLLECTION.AUTOMATION_CALLFLOW_STEP)
                .doc(callflow.id)
                .set(callflow);

            console.log('callflowId =', callflow.id);

            return Promise.resolve(callflow);

        } catch (error) {
            throw error;
        }
    }

    async addCallflowStep(callflowId: string, step: any) {
        try {
            if (typeof step === 'undefined') {
                throw Error('Step is UNDEFINED');
            } else if (step === null) {
                throw Error('Step is NULL');
            }

            if (typeof callflowId === 'undefined') {
                throw Error('Step ID is UNDEFINED');
            } else if (callflowId === null) {
                throw Error('Step ID is NULL');
            } else if (callflowId.trim() === '') {
                throw Error('Step ID is EMPTY');
            }

            step.callflowId = callflowId.trim();
            step.sequenceId = callflowId;
            step.stageId = step.sequenceId;

            step.sequenceName = typeof step.sequenceName === 'undefined' ? step.callflowName : step.sequenceName;
            step.createdAt = firebase.firestore.Timestamp.now();
            step.updatedAt = step.createdAt;
            step.createdBy = this.authService.user.value.id;
            step.updatedBy = step.createdBy;
            step.isActive = true;
            step.isDeleted = false;

            if (isUndefinedOrNullOrEmpty(step.id)) {
                step.id = uuidv4();
            }
            if (isUndefinedOrNullOrEmpty(step.companyId)) {
                step.companyId = this.company.id;
            }
            if (isUndefinedOrNullOrEmpty(step.companyName)) {
                step.companyName = this.company.name;
            }
            if (isUndefinedOrNullOrEmpty(step.stageName)) {
                // step.stageName = this.sessionService.stageName;
                step.stageName = ''; // this.sessionService.stageName;
            }
            if (isUndefinedOrNullOrEmpty(step.stats)) {
                step.stats = {
                    clicks: 0,
                    ctr: 0,
                    openRate: 0,
                    sent: 0,
                    delivered: 0,
                    opened: 0,
                };
            }
            if (isUndefinedOrNullOrEmpty(step.schedule)) {
                step.schedule = this.getDefaultSchedule();
            }

            await this.afs
                .collection(COLLECTION.AUTOMATION_CALLFLOW_STEP)
                .doc(step.id)
                .set(step);

            return Promise.resolve(step);

        } catch (error) {
            throw error;
        }
    }

    async addPipelineTask(pipelineStep: any): Promise<any> {

        console.warn('@@@@@@@@@@@@@@@@@@@');
        console.warn('Add Pipeline Task');

        /*
            // Create the schedule info
            const now = new Date();
            const schedule = this.calculateTaskSchedule(step, now);
            console.log('schedule:', schedule);
            try {
              return await this.afs.collection(COLLECTION.TASK_PIPELINE).doc(step.id)
                .set(step);
            } catch (error) {
              console.error(error);
              throw Error('Unable to update the Database');
            }
        */

        /*
        if (step.index !== 0) {
            const previousStep = await getAutoStep(stageId, step.index - 1);
            previousStepDateTime = previousStep.custom.dateTime.toDate();
            console.log('previousStep:', previousStep);
            console.log('previousStep.custom.dateTime =', previousStepDateTime.toString());
            const test = new Date(previousStep.custom.dateTime.seconds * 1000);
            console.log('+++ ', test.toString());
        }
        */

        const dealId = typeof pipelineStep.dealId === 'undefined' ? null : pipelineStep.dealId;
        const pipelineId = pipelineStep.pipelineId;
        const stageId = pipelineStep.stageId;
        const userId = this.authService.user.value.id;

        const resSequenceSteps: any[] = [];
        resSequenceSteps.push(pipelineStep);

        let previousStepDateTime = new Date();
        // const resSequenceSteps = await getSequenceSteps({ stageId: stageId });

        console.log('+++ addNewSteps() dealId = ' + dealId + ' | stageId = ' + stageId);
        console.warn('!!! previousStepDateTime:', previousStepDateTime);
        console.log('autoStep:', resSequenceSteps);
        console.log('autoStep count:', resSequenceSteps.length);

        for (const step of resSequenceSteps) {

            console.log('step:', step);

            // if (step.isDeleted === false && step.isEnabled) {
            if (step.isDeleted === false) {

                let data: any = null;
                if (step.subject === 'sms' && step.type === 'action' && (step.action === 'add' || step.action === 'send')) {

                    /*
                    // Create SMS Message to send
                    console.log('create SMS message to send');
                    data = await createSmsData(
                      dealId,
                      stageId,
                      step,
                      userId,
                      previousStepDateTime,
                    );
                    */

                } else if (step.subject === 'email' && step.type === 'action' && (step.action === 'add' || step.action === 'send')) {

                    /*
                    // Create EMAIL Message to send
                    console.log('create Email message to send');
                    data = await createEmailData(
                      dealId,
                      stageId,
                      step,
                      userId,
                      previousStepDateTime,
                    );
                    */

                } else if (step.subject === 'stripe' && step.type === 'action' && step.action === 'one-time-charge') {

                    /*
                    // Create STRIPE Message to send
                    console.log('create Stripe One-Time-Charge');
                    data = await createStripeData(
                      dealId,
                      stageId,
                      step,
                      userId,
                      previousStepDateTime,
                    );
                    */

                } else if (step.subject === 'deal' && step.type === 'action' && step.action === 'move') {

                    // Create DEAL Message to send
                    console.log('Move deal to new Pipeline/Stage');
                    data = await this.createDealMoveData(
                        dealId,
                        stageId,
                        step,
                        userId,
                        previousStepDateTime,
                        pipelineId,
                    );

                } else if (step.subject === 'deal' && step.type === 'action' && step.action === 'drip') {

                    // Create DEAL Message to send
                    console.warn('Drip deal to new Pipeline/Stage');

                    console.warn('step:', step);

                    data = await this.createDealMoveData(
                        dealId,
                        stageId,
                        step,
                        userId,
                        previousStepDateTime,
                        pipelineId,
                    );
                    data.worker = 'dealDrip';

                    console.warn('data.options.custom:', data.options.custom);

                    data.options.custom.fromPipelineId = step.custom.fromPipelineId;
                    data.options.custom.fromPipelineName = step.custom.fromPipelineName;
                    data.options.custom.fromStageId = step.custom.fromStageId;
                    data.options.custom.fromStageName = step.custom.fromStageName;

                } else {
                    console.log('unhandled automation step subject =', step.subject);
                    console.log('unhandled automation step type =', step.type);
                    console.log('unhandled automation step action =', step.action);
                }

                const newDateTime = data.options.config.dateTime;
                if (typeof newDateTime._seconds !== 'undefined') {
                    previousStepDateTime = new Date(newDateTime._seconds * 1000);
                } else if (typeof newDateTime === 'string') {
                    previousStepDateTime = new Date(newDateTime);
                } else {
                    previousStepDateTime = newDateTime;
                }

                try {
                    // const queryRef = this.afs.collection(COLLECTION.TASK_PIPELINE).doc(step.id);
                    data.id = step.id;
                    data.options.config.id = '';

                    console.log('JOB data =', data);
                    console.log('+++ ADD JOB to TASK collection | id:', data.id);

                    /*
                    await this.afs.collection(COLLECTION.TASK_PIPELINE).doc(data.id)
                      .set(data);
                    */

                    await this.afs
                        .collection(COLLECTION.TASK)
                        .doc(data.id)
                        .set(data, { merge: true });


                } catch (error) {
                    console.error(error);
                }
            }

        }

        return Promise.resolve(true);
    }

    async addSequence(params: any, templateId = 'blank', stageId = '') {
        try {

            let sequence: any = {};

            if (!isUndefinedOrNullOrEmpty(params)) {
                sequence = params;
            }

            let newTemplateId = '';
            if (isUndefinedOrNullOrEmpty(templateId)) {
                newTemplateId = 'blank';
            } else {
                newTemplateId = templateId.toString().trim();
            }

            if (isUndefinedOrNullOrEmpty(sequence.stageId)) {
                // if (isUndefinedOrNullOrEmpty(this.sessionService.stageId)) {
                if (isUndefinedOrNullOrEmpty(stageId)) {
                    sequence.id = uuidv4();
                    sequence.stageId = sequence.id;
                } else {
                    // sequence.id = this.sessionService.stageId;
                    sequence.id = stageId; // this.sessionService.stageId;
                    sequence.stageId = sequence.id;
                }
            }
            if (isUndefinedOrNullOrEmpty(sequence.stageName)) {
                // sequence.stageName = this.sessionService.stageName;
                sequence.stageName = ''; // this.sessionService.stageName;
            }

            console.log('sequence.stageId:', sequence.stageId);
            console.log('sequence.stageName:', sequence.stageName);

            sequence.name = typeof sequence.name === 'undefined' ? '' : sequence.name;
            sequence.createdAt = firebase.firestore.Timestamp.now();
            sequence.updatedAt = sequence.createdAt;
            sequence.createdBy = this.authService.user.value.id;
            sequence.updatedBy = sequence.createdBy;
            sequence.isActive = true;
            sequence.isDeleted = false;
            sequence.stepsTotal = 0;

            if (isUndefinedOrNullOrEmpty(sequence.name)) {
                // sequence.name = this.sessionService.stageName;
                sequence.name = ''; // this.sessionService.stageName;
            }
            if (isUndefinedOrNullOrEmpty(sequence.description)) {
                // sequence.description = this.sessionService.stageName;
                sequence.description = ''; // this.sessionService.stageName;
            }
            if (isUndefinedOrNullOrEmpty(sequence.pipelineId)) {
                // sequence.pipelineId = this.sessionService.pipelineId;
                sequence.pipelineId = ''; // this.sessionService.pipelineId;
            }
            if (isUndefinedOrNullOrEmpty(sequence.pipelineName)) {
                sequence.pipelineName = '';
            }
            if (isUndefinedOrNullOrEmpty(sequence.teamId)) {
                sequence.teamId = '';
            }
            if (isUndefinedOrNullOrEmpty(sequence.teamName)) {
                sequence.teamName = '';
            }
            if (isUndefinedOrNullOrEmpty(sequence.photoURL)) {
                sequence.photoURL = '';
            }
            if (isUndefinedOrNullOrEmpty(sequence.isGlobal)) {
                sequence.isGlobal = false;
            }
            if (isUndefinedOrNullOrEmpty(sequence.isShared)) {
                sequence.isShared = false;
            }
            if (isUndefinedOrNullOrEmpty(sequence.isTemplate)) {
                sequence.isTemplate = false;
            }

            if (isUndefinedOrNullOrEmpty(sequence.companyId)) {
                sequence.companyId = this.company.id;
            }
            if (isUndefinedOrNullOrEmpty(sequence.companyName)) {
                sequence.companyName = this.company.name;
            }

            // Get template from Database
            this.getSteps(newTemplateId)
                .subscribe(steps => {
                    if (steps) {
                        steps.forEach(step => {
                            // Add step to sequence
                            console.log(step.doc.data());
                        });
                    }
                });

            console.log('new sequence =', sequence);

            await this.afs
                .collection(COLLECTION.AUTOMATION_SEQUENCE)
                .doc(sequence.id)
                .set(sequence);

            console.log('sequenceId =', sequence.id);

            return Promise.resolve(sequence);

        } catch (error) {
            throw error;
        }
    }

    async addStep(stageId: string, step: any) {
        try {
            if (typeof step === 'undefined') {
                throw Error('Step is UNDEFINED');
            } else if (step === null) {
                throw Error('Step is NULL');
            }

            if (typeof stageId === 'undefined') {
                throw Error('Step ID is UNDEFINED');
            } else if (stageId === null) {
                throw Error('Step ID is NULL');
            } else if (stageId.trim() === '') {
                throw Error('Step ID is EMPTY');
            }

            step.sequenceId = stageId.trim();
            step.stageId = step.sequenceId;

            step.sequenceName = typeof step.sequenceName === 'undefined' ? '' : step.sequenceName;
            step.createdAt = firebase.firestore.Timestamp.now();
            step.updatedAt = step.createdAt;
            step.createdBy = this.authService.user.value.id;
            step.updatedBy = step.createdBy;
            step.isActive = true;
            step.isDeleted = false;

            if (isUndefinedOrNullOrEmpty(step.id)) {
                step.id = uuidv4();
            }
            if (isUndefinedOrNullOrEmpty(step.companyId)) {
                step.companyId = this.company.id;
            }
            if (isUndefinedOrNullOrEmpty(step.companyName)) {
                step.companyName = this.company.name;
            }
            if (isUndefinedOrNullOrEmpty(step.stageName)) {
                // step.stageName = this.sessionService.stageName;
                step.stageName = ''; // this.sessionService.stageName;
            }
            if (isUndefinedOrNullOrEmpty(step.stats)) {
                step.stats = {
                    clicks: 0,
                    ctr: 0,
                    openRate: 0,
                    sent: 0,
                    delivered: 0,
                    opened: 0,
                };
            }
            if (isUndefinedOrNullOrEmpty(step.schedule)) {
                step.schedule = this.getDefaultSchedule();
            }

            await this.afs
                .collection(COLLECTION.AUTOMATION_STEP)
                .doc(step.id)
                .set(step);

            return Promise.resolve(step);

        } catch (error) {
            throw error;
        }
    }


    calculateTaskSchedule(step: any, lastDateTime: any) {

        const triggerTime = {
            immediate: false,
            dateTime: new Date(), // DEFAULT is now.
        };
        if (!isUndefinedOrNullOrEmpty(lastDateTime)) {
            triggerTime.dateTime = new Date(lastDateTime);
        }
        if (!isUndefinedOrNullOrEmpty(step)
            && !isUndefinedOrNullOrEmpty(step.schedule)
            && !isUndefinedOrNullOrEmpty(step.schedule.unitTime)
        ) {
            if (step.schedule.unitTime === 'immediate' || step.schedule.unitTime === 'immediately') {
                triggerTime.immediate = true;
            }
        }

        console.log('triggerTime:', triggerTime);

        // Add the to current time
        if (step.schedule.unitTime === 'minutes') {
            // Add minutes to the time.
            const offset = (step.schedule.unitValue * ONE_MINUTE);
            triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);

        } else if (step.schedule.unitTime === 'hours') {
            // Add hours to the time.
            const offset = (step.schedule.unitValue * ONE_HOUR);
            triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);

        } else if (step.schedule.unitTime === 'days') {
            // Add days to the time.
            const offset = (step.schedule.unitValue * ONE_DAY);
            triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);
        }

        const isAnyDay = getIsAnyDay(step.schedule.weekDaySelect);
        if (isAnyDay) {

            console.log('--- ANY DAY ---');

            if (step.schedule.timeSettingType === 'any_time') {

                console.log('--- TIME ANY ---');

            } else {

                console.log('--- TIME RANGE ---');

                // Get the range START and END time. (0 - 24);
                let fromHour = isUndefinedOrNull(step.schedule.fromTime) ? 0 : step.schedule.fromTime;
                if (fromHour < 0) {
                    fromHour = 0;
                } else if (fromHour > 23) {
                    fromHour = 23;
                }
                let toHour = step.schedule.toTime;
                if (toHour < fromHour) {
                    toHour = fromHour + 1;
                } else if (toHour > 24) {
                    toHour = 24;
                }

                // console.log('from:', fromHour);
                // console.log('to:', toHour);
                // console.log('units:', step.schedule.unitTime);

                // Trigger Time is OUTSIDE day range THEN
                const triggerMoment = moment(triggerTime.dateTime);

                // Get Trigger Time HOUR
                const hour = triggerMoment.get('hour');

                // console.log('hour:', hour);

                if (hour >= toHour) {

                    console.log('AFTER time range');

                    // Set HOUR to range start hour
                    triggerMoment.set('hour', fromHour);
                    // Set Minute with fuzzy filter
                    if (!triggerTime.immediate) {
                        triggerMoment.set('minute', randomIntFromInterval(0, 5));
                    }

                    // Set Day to next available day.
                    const days = triggerMoment.get('date');
                    triggerMoment.set('date', days + 1);

                } else if (hour < fromHour) {

                    console.log('BEFORE time range');

                    // Set HOUR to range start hour
                    triggerMoment.set('hour', fromHour);
                    // Set Minute with fuzzy filter
                    if (!triggerTime.immediate) {
                        triggerMoment.set('minute', randomIntFromInterval(0, 5));
                    }

                } else {

                    console.log('INSIDE time range');

                    const minute = triggerMoment.get('minute');

                    // Set Minute with fuzzy filter
                    if (!triggerTime.immediate) {
                        triggerMoment.set('minute', minute + 1);
                    }
                }

                triggerTime.dateTime = triggerMoment.toDate();
            }

        } else {

            console.warn('--- SPECIFIC DAY/DAYS ---');

            if (step.schedule.timeSettingType === 'any_time') {

                console.warn('--- TIME ANY ---');

                // Get the trigger time
                const triggerMoment = moment(triggerTime.dateTime);
                const weekday = triggerMoment.isoWeekday();

                // Get weekday select
                const weekdaySelector = getWeekdaySchedule(step.schedule.weekDaySelect);
                const dayOffset = getNextDayAvailable(weekday + 1, weekdaySelector);

                if (weekdaySelector[weekday] === false) {
                    // Set Day to next available day.
                    const days = triggerMoment.get('date');

                    if (dayOffset === 7) {
                        triggerMoment.set('date', days + 1);
                    } else {
                        triggerMoment.set('date', days + dayOffset + 1);
                    }
                }

                triggerTime.dateTime = triggerMoment.toDate();

            } else {
                console.warn('--- TIME RANGE ---');

                // Get the range START and END time. (0 - 24);
                let fromHour = isUndefinedOrNull(step.schedule.fromTime) ? 0 : step.schedule.fromTime;
                if (fromHour < 0) {
                    fromHour = 0;
                } else if (fromHour > 23) {
                    fromHour = 23;
                }
                let toHour = step.schedule.toTime;
                if (toHour < fromHour) {
                    toHour = fromHour + 1;
                } else if (toHour > 24) {
                    toHour = 24;
                }

                // Trigger Time is OUTSIDE day range THEN
                const triggerMoment = moment(triggerTime.dateTime);
                const weekday = triggerMoment.isoWeekday();

                // Get weekday select
                const weekdaySelector = getWeekdaySchedule(step.schedule.weekDaySelect);
                const dayOffset = getNextDayAvailable(weekday + 1, weekdaySelector);

                if (dayOffset < 1) {

                    console.warn('BAD OFFSET');

                    // Trigger Time is OUTSIDE hour range THEN
                    const hour = triggerMoment.get('hour');
                    if (hour < toHour || hour >= fromHour) {
                        // Increase DAY by +1

                        // Set HOUR to range start hour
                        triggerMoment.set('hour', fromHour);
                        // Set Minute with fuzzy filter
                        if (!triggerTime.immediate) {
                            triggerMoment.set('minute', randomIntFromInterval(0, 5));
                        }
                    }
                } else {

                    // Get Trigger Time HOUR
                    const hour = triggerMoment.get('hour');

                    console.log('hour:', hour);

                    if (hour >= toHour) {

                        console.log('AFTER time range');

                        // Set HOUR to range start hour
                        triggerMoment.set('hour', fromHour);
                        // Set Minute with fuzzy filter
                        if (!triggerTime.immediate) {
                            triggerMoment.set('minute', randomIntFromInterval(0, 5));
                        }

                        if (dayOffset > 0) {
                            // Set Day to next available day.
                            const days = triggerMoment.get('date');

                            if (dayOffset === 7) {
                                triggerMoment.set('date', days + 1);
                            } else {
                                triggerMoment.set('date', days + dayOffset + 1);
                            }

                            // Trigger Time is OUTSIDE hour range THEN
                            // const hour = triggerMoment.get('hour');
                            if (hour < toHour || hour >= fromHour) {
                                // Increase DAY by +1

                                // Set HOUR to range start hour
                                triggerMoment.set('hour', fromHour);
                                // Set Minute with fuzzy filter
                                if (!triggerTime.immediate) {
                                    triggerMoment.set('minute', randomIntFromInterval(0, 5));
                                }
                            }
                        }

                    } else if (hour < fromHour) {

                        console.log('BEFORE time range');

                        // Set HOUR to range start hour
                        triggerMoment.set('hour', fromHour);
                        // Set Minute with fuzzy filter
                        if (!triggerTime.immediate) {
                            triggerMoment.set('minute', randomIntFromInterval(0, 5));
                        }

                    } else {

                        console.log('INSIDE time range');

                        const minute = triggerMoment.get('minute');

                        // Set Minute with fuzzy filter
                        if (!triggerTime.immediate) {
                            triggerMoment.set('minute', minute + 1);
                        }
                    }
                }

                console.log('triggerMoment:', triggerMoment.toDate());

                triggerTime.dateTime = triggerMoment.toDate();
            }
        }

        return triggerTime;
    }

    async createDealMoveData(
        dealId: string,
        stageId: string,
        step: any,
        userId: string,
        previousStepDateTime: any,
        pipelineId: string = '',
        // fromPipelineId: string = '',
        // fromStageId: string = '',
    ): Promise<any> {
        try {
            let lastDateTime: any = previousStepDateTime;
            if (typeof previousStepDateTime === 'string') {
                lastDateTime = new Date(previousStepDateTime);
            } else if (typeof previousStepDateTime === 'object') {
                if (typeof previousStepDateTime._seconds !== 'undefined') {
                    lastDateTime = new Date(previousStepDateTime * 1000);
                }
            }

            console.log('----------------------------------------------------------');
            console.warn('typeof lastDateTime:', typeof lastDateTime);
            console.warn('lastDateTime:', lastDateTime);
            console.warn('lastDateTime JSON.stringify():', JSON.stringify(lastDateTime));
            console.log('----------------------------------------------------------');

            let results: any;
            let settingId = step.custom.settingId;
            if (isUndefinedOrNullOrEmpty(settingId) && !isUndefinedOrNullOrEmpty(dealId)) {
                results = await Promise.all([
                    getDocument(dealId, COLLECTION.DEAL),
                ]);
                settingId = '';
            }

            const dealData = typeof results === 'undefined' || typeof results[0] === 'undefined' ? {} : results[0];
            // const activityData = typeof results === 'undefined' || typeof results[2] === 'undefined' ? {} : results[2];

            results = [];

            if (!isUndefinedOrNullOrEmpty(dealData)) {
                console.log('dealData:', dealData);
                console.log('dealData.contactPersonId:', dealData.contactPersonId);
                console.log('dealData.contactId:', dealData.contactId);
            }

            const taskId = '';
            if (pipelineId === '') {
                pipelineId = dealData.pipelineId;
            }
            let contactData = { id: '', displayName: '' };
            try {
                if (!isUndefinedOrNullOrEmpty(dealData) && !isUndefinedOrNullOrEmpty(dealData.contactPersonId)) {
                    contactData = await getDocument(dealData.contactPersonId, COLLECTION.CONTACT);
                }
            } catch (error) {
                console.error(error);
            }

            const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
            // const dateTime = new Date();

            const taskData = {
                options: {
                    custom: step.custom,
                    config: {
                        id: taskId,
                        userId,
                        userName: '',

                        companyId: step.companyId,
                        companyName: step.companyName,

                        organizationId: isUndefinedOrNull(dealData.organizationId) ? '' : dealData.organizationId,
                        organizationName: isUndefinedOrNull(dealData.organizationName) ? '' : dealData.organizationName,

                        contactId: typeof contactData.id === 'undefined' ? '' : contactData.id,
                        contactName: typeof contactData.displayName === 'undefined' ? '' : contactData.displayName,

                        dealId: typeof dealId === 'undefined' ? null : dealId,
                        dealName: isUndefinedOrNull(dealData.dealTitle) ? '' : dealData.dealTitle,
                        dateTime: lastDateTime,

                        deliveryStatus: false,
                        immediate: false,
                        isDeleted: false,
                        isProcessed: false,
                        jobStatus: JOB_STATUS.NEW,

                        sequenceId: step.sequenceId,
                        name: step.name,
                        pipelineId,
                        stageId,

                        integrationId: '', // typeof step.custom.settingId === 'undefined' ? '' : step.custom.settingId,
                        integrationType: '',
                        groupId: '',
                    },
                },
                isAutomation: true,
                id: taskId,
                performAt: lastDateTime,
                schedule: step.schedule,
                status: TASK_STATUS.SCHEDULED,
                createdAt: serverTimestamp,
                createdBy: userId,
                updatedAt: serverTimestamp,
                updatedBy: userId,
                worker: 'dealMove',
            };

            console.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
            console.log('!!!!!!!!         Calculate the time of the next AUTOMATION STEP              !!!!!!!!');
            console.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');

            const triggerTime = this.calculateTaskSchedule(step, lastDateTime);
            taskData.options.config.immediate = triggerTime.immediate;
            taskData.options.config.dateTime = triggerTime.dateTime;
            taskData.performAt = triggerTime.dateTime;

            console.warn('*** performAt *** :', taskData.options.config.dateTime);
            console.log('taskData:', taskData);

            return Promise.resolve(taskData);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async deleteCallflow(callflowId: string) {
        if (typeof callflowId === 'undefined') {
            throw Error('Callflow ID is UNDEFINED');
        } else if (callflowId === null) {
            throw Error('Callflow ID is NULL');
        } else if (callflowId.toString().trim() === '') {
            throw Error('Callflow ID is EMPTY');
        }

        try {
            callflowId = callflowId.trim();

            await this.afs
                .collection(COLLECTION.AUTOMATION_CALLFLOW)
                .doc(callflowId)
                .delete();

            await firebase.firestore()
                .collection(COLLECTION.AUTOMATION_CALLFLOW_STEP)
                .where('callflowId', '==', callflowId)
                .get()
                .then(async snapshots => {
                    if (snapshots.empty) {
                        return Promise.resolve();
                    }
                    // tslint:disable-next-line: prefer-for-of
                    for (let index = 0; index < snapshots.docs.length; index++) {
                        const snapshot = snapshots.docs[index].data();
                        if (snapshot) {
                            await firebase.firestore()
                                .collection(COLLECTION.AUTOMATION_CALLFLOW_STEP)
                                .doc(snapshot.id)
                                .delete();
                        }
                    }
                    return Promise.resolve();
                });

            return Promise.resolve(true);

        } catch (error) {
            throw Error('Unable to delete step from callflow');
        }
    }

    async deleteCallflowStep(stepId: string) {
        if (typeof stepId === 'undefined') {
            throw Error('Step ID is UNDEFINED');
        } else if (stepId === null) {
            throw Error('Step ID is NULL');
        } else if (stepId.trim() === '') {
            throw Error('Step ID is EMPTY');
        }

        try {
            stepId = stepId.trim();

            await this.afs
                .collection(COLLECTION.AUTOMATION_CALLFLOW_STEP)
                .doc(stepId)
                .delete();

            // Renumber the indexes after deleteing a step

            return Promise.resolve(true);
        } catch (error) {
            throw Error('Unable to delete step from sequence');
        }
    }

    async deletePipelineTask(stepId: string) {
        try {
            await this.afs.collection(COLLECTION.TASK)
                .doc(stepId)
                .delete();

            return Promise.resolve();

        } catch (error) {
            throw error;
        }
    }

    async deleteSequence(sequenceId: string) {
        if (typeof sequenceId === 'undefined') {
            throw Error('Sequence ID is UNDEFINED');
        } else if (sequenceId === null) {
            throw Error('Sequence ID is NULL');
        } else if (sequenceId.toString().trim() === '') {
            throw Error('Sequence ID is EMPTY');
        }

        try {
            sequenceId = sequenceId.trim();

            await this.afs
                .collection(COLLECTION.AUTOMATION_SEQUENCE)
                .doc(sequenceId)
                .delete();

            await firebase.firestore()
                .collection(COLLECTION.AUTOMATION_STEP)
                .where('sequenceId', '==', sequenceId)
                .get()
                .then(async snapshots => {
                    if (snapshots.empty) {
                        return Promise.resolve();
                    }
                    // tslint:disable-next-line: prefer-for-of
                    for (let index = 0; index < snapshots.docs.length; index++) {
                        const snapshot = snapshots.docs[index].data();
                        if (snapshot) {
                            await firebase.firestore()
                                .collection(COLLECTION.AUTOMATION_STEP)
                                .doc(snapshot.id)
                                .delete();
                        }
                    }
                    return Promise.resolve();
                });

            return Promise.resolve(true);

        } catch (error) {
            throw Error('Unable to delete step from sequence');
        }
    }

    async deleteStep(stepId: string) {
        if (typeof stepId === 'undefined') {
            throw Error('Step ID is UNDEFINED');
        } else if (stepId === null) {
            throw Error('Step ID is NULL');
        } else if (stepId.trim() === '') {
            throw Error('Step ID is EMPTY');
        }

        try {
            stepId = stepId.trim();

            await this.afs
                .collection(COLLECTION.AUTOMATION_STEP)
                .doc(stepId)
                .delete();

            // Renumber the indexes after deleteing a step

            return Promise.resolve(true);
        } catch (error) {
            throw Error('Unable to delete step from sequence');
        }
    }

    getCallflowStep(stepId: string) {
        if (typeof stepId === 'undefined') {
            throw Error('Step ID is UNDEFINED');
        } else if (stepId === null) {
            throw Error('Step ID is NULL');
        } else if (stepId.trim() === '') {
            throw Error('Step ID is EMPTY');
        }

        try {
            this.callflowCollection = this.afs.collection(
                COLLECTION.AUTOMATION_CALLFLOW_STEP, ref => ref
                    .where('isDeleted', '==', false)
                    .where('id', '==', stepId.toString().trim())
                // .limit(1)
            );

            return this.callflowCollection
                .snapshotChanges()
                .pipe(
                    map(arr => {
                        return arr.map(snap => {
                            const data = snap.payload.doc.data();
                            const doc = snap.payload.doc;
                            const id = { id: doc.id };

                            return { ...data, ...id };
                        });
                    })
                );
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    getCallflowSteps(callflowId: string) {
        if (typeof callflowId === 'undefined') {
            throw Error('Stage ID is UNDEFINED');
        } else if (callflowId === null) {
            throw Error('Stage ID is NULL');
        } else if (callflowId.trim() === '') {
            throw Error('Stage ID is EMPTY');
        }

        try {
            this.callflowCollection = this.afs.collection(
                COLLECTION.AUTOMATION_STEP, ref => ref
                    .where('isDeleted', '==', false)
                    .where('callflowId', '==', callflowId.toString().trim())
                    .orderBy('index')
            );

            return this.callflowCollection.snapshotChanges()
                .pipe(
                    map(arr => {
                        return arr.map(snap => {
                            const data = snap.payload.doc.data();
                            const doc = snap.payload.doc;
                            const id = { id: doc.id };

                            return { ...data, ...id };
                        });
                    })
                );
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    getDefaultSchedule() {
        const schedule = {
            fromTime: 0,
            toTime: 24,
            unitTime: 'minutes', // immediately, minutes, hours, days
            unitValue: 30,
            minValue: 30,
            maxValue: 30,
            minUnits: 'minutes', // immediately, minutes, hours, days
            maxUnits: 'minutes', // immediately, minutes, hours, days
            timeSettingType: 'any_time',  // any_time, time_range
            weekDaySelect: {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
            },
        };

        return schedule;
    }

    getDefaultStats() {
        const stats = {
            sent: 0,
            clicks: 0,
            openRate: 0,
            ctr: 0,
            delivered: 0,
            opened: 0,
        };

        return stats;
    }

    getIsAnyDay(weekdays: any): boolean {
        if (weekdays.sunday === true
            && weekdays.monday === true
            && weekdays.tuesday === true
            && weekdays.wednesday === true
            && weekdays.thursday === true
            && weekdays.friday === true
            && weekdays.saturday === true
        ) {
            return true;
        } else {
            return false;
        }
    }

    getSequence(params: any = null): Observable<IStep[]> {
        console.log('AutomationService.getSequence() | params =', params);

        let callflowId = null;
        let sequenceId = null;
        let pipelineId = null;
        let stageId = null;

        if (typeof params === 'undefined') {
            throw Error('missing sequenceId OR stageId');
        }

        if (!isUndefinedOrNullOrEmpty(params.stageId)) {
            stageId = params.stageId.toString().trim();
            sequenceId = stageId;
        } else if (!isUndefinedOrNullOrEmpty(params.sequenceId)) {
            sequenceId = params.sequenceId.toString().trim();
            stageId = sequenceId;
        } else if (!isUndefinedOrNullOrEmpty(params.pipelineId)) {
            pipelineId = params.pipelineId.toString().trim();
        } else if (!isUndefinedOrNullOrEmpty(params.callflowId)) {
            callflowId = params.callflowId.toString().trim();
        }

        if (sequenceId === null && pipelineId === null && stageId === null && callflowId === null) {
            throw Error('missing sequenceId and pipelineId and stageId and callflowId');
        }

        try {

            if (stageId !== null) {

                console.log('*** get the automations by stage ***');

                this.sequenceCollection = this.afs.collection(COLLECTION.AUTOMATION_STEP, ref => ref
                    .where('isDeleted', '==', false)
                    .where('stageId', '==', stageId)
                    // .limit(1));
                );
                return this.sequenceCollection.snapshotChanges()
                    .pipe(
                        map(arr => {
                            // console.log('data came', arr);

                            return arr.map(snap => {
                                const data = snap.payload.doc.data();
                                const doc = snap.payload.doc;
                                const id = { id: doc.id };

                                return { ...data, ...id };
                            });
                        })
                    );

            } else if (callflowId !== null) {

                console.log('*** get the automations by callflow ***');

                this.sequenceCollection = this.afs.collection(COLLECTION.AUTOMATION_CALLFLOW_STEP, ref => ref
                    .where('isDeleted', '==', false)
                    .where('callflowId', '==', callflowId)
                    // .limit(1));
                );
                return this.sequenceCollection.snapshotChanges()
                    .pipe(
                        map(arr => {
                            // console.log('data came', arr);

                            return arr.map(snap => {
                                const data = snap.payload.doc.data();
                                const doc = snap.payload.doc;
                                const id = { id: doc.id };

                                return { ...data, ...id };
                            });
                        })
                    );

            } else if (pipelineId !== null) {

                console.log('*** get the automations by pipeline ***');

                this.sequenceCollection = this.afs.collection(COLLECTION.AUTOMATION_STEP, ref => ref
                    .where('isDeleted', '==', false)
                    .where('pipelineId', '==', pipelineId)
                    // .limit(1));
                );
                return this.sequenceCollection.snapshotChanges()
                    .pipe(
                        map(arr => {
                            // console.log('data came', arr);

                            return arr.map(snap => {
                                const data = snap.payload.doc.data();
                                const doc = snap.payload.doc;
                                const id = { id: doc.id };

                                return { ...data, ...id };
                            });
                        })
                    );

            } else if (sequenceId !== null) {

                console.log('*** get the automations by sequence ***');

                this.sequenceCollection = this.afs.collection(COLLECTION.AUTOMATION_SEQUENCE, ref => ref
                    .where('isDeleted', '==', false)
                    .where('id', '==', sequenceId)
                    // .limit(1));
                );
                return this.sequenceCollection.snapshotChanges()
                    .pipe(
                        map(arr => {
                            return arr.map(snap => {
                                const data = snap.payload.doc.data();
                                const doc = snap.payload.doc;
                                const id = { id: doc.id };

                                return { ...data, ...id };
                            });
                        })
                    );
            }
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    getSequences(params: any = null) {
        try {
            let companyId = this.authService.company.value.id;
            let pipelineId = null;
            let teamId = null;

            if (typeof params === 'undefined') {
                throw Error('missing companyId OR teamId OR pipelineId');
            } else if (typeof params.companyId !== 'undefined' && params.companyId !== '') {
                companyId = params.companyId;
            } else if (typeof params.pipelineId !== 'undefined') {
                pipelineId = params.pipelineId;
            } else if (typeof params.teamId !== 'undefined') {
                teamId = params.teamId;
            }

            if (pipelineId) {
                this.sequenceCollection = this.afs.collection(
                    COLLECTION.AUTOMATION_SEQUENCE, ref => ref
                        .where('isDeleted', '==', false)
                        .where('pipelineId', '==', pipelineId)
                        .orderBy('name')
                );
            } else if (teamId) {
                this.sequenceCollection = this.afs.collection(
                    COLLECTION.AUTOMATION_SEQUENCE, ref => ref
                        .where('isDeleted', '==', false)
                        .where('teamId', '==', teamId)
                        .orderBy('name')
                );
            } else {
                this.sequenceCollection = this.afs.collection(
                    COLLECTION.AUTOMATION_SEQUENCE, ref => ref
                        .where('isDeleted', '==', false)
                        .where('companyId', '==', companyId)
                        .orderBy('name')
                );
            }

            return this.sequenceCollection
                .snapshotChanges()
                .pipe(
                    map(arr => {
                        return arr.map(snap => {
                            const data = snap.payload.doc.data();
                            const doc = snap.payload.doc;
                            const id = { id: doc.id };

                            return { ...data, ...id };
                        });
                    })
                );
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    getStep(stepId: string) {
        if (typeof stepId === 'undefined') {
            throw Error('Step ID is UNDEFINED');
        } else if (stepId === null) {
            throw Error('Step ID is NULL');
        } else if (stepId.trim() === '') {
            throw Error('Step ID is EMPTY');
        }

        try {
            this.stepCollection = this.afs.collection(
                COLLECTION.AUTOMATION_STEP, ref => ref
                    .where('isDeleted', '==', false)
                    .where('id', '==', stepId.toString().trim())
                // .limit(1)
            );

            return this.stepCollection
                .snapshotChanges()
                .pipe(
                    map(arr => {
                        return arr.map(snap => {
                            const data = snap.payload.doc.data();
                            const doc = snap.payload.doc;
                            const id = { id: doc.id };

                            return { ...data, ...id };
                        });
                    })
                );
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    getSteps(stageId: string) {
        if (typeof stageId === 'undefined') {
            throw Error('Stage ID is UNDEFINED');
        } else if (stageId === null) {
            throw Error('Stage ID is NULL');
        } else if (stageId.trim() === '') {
            throw Error('Stage ID is EMPTY');
        }

        try {
            this.stepCollection = this.afs.collection(
                COLLECTION.AUTOMATION_STEP, ref => ref
                    .where('isDeleted', '==', false)
                    .where('stageId', '==', stageId.toString().trim())
                    .orderBy('index')
            );

            return this.stepCollection.snapshotChanges()
                .pipe(
                    map(arr => {
                        return arr.map(snap => {
                            const data = snap.payload.doc.data();
                            const doc = snap.payload.doc;
                            const id = { id: doc.id };

                            return { ...data, ...id };
                        });
                    })
                );
        } catch (error) {
            throw Error('Unable to read from Database');
        }
    }

    /**
     * Get a list of all the automation steps for this sequence in chronological order
     * @param userId The ID of the current user.
     * @param sequenceId The ID of the automation sequence.
     */
    async loadAutomationSteps(userId: string, stageId: string): Promise<IStep[]> {
        const steps: IStep[] = automationData.steps;
        steps.forEach(step => {
            this.addStep(stageId, step);
        });

        return Promise.resolve(steps);
    }

    /**
     * Get a list of all the automation steps for this sequence in chronological order
     * @param userId The ID of the current user.
     * @param sequenceId The ID of the automation sequence.
     */
    async loadCallflowSteps(userId: string, callflowId: string): Promise<IStep[]> {
        const steps: IStep[] = automationData.steps;
        steps.forEach(step => {
            this.addStep(callflowId, step);
        });

        return Promise.resolve(steps);
    }

    async updateCallflowStep(stepId: string, step: any) {
        try {
            if (typeof step === 'undefined') {
                throw Error('Step data is UNDEFINED');
            } else if (step === null) {
                throw Error('Step data is NULL');
            }

            if (typeof stepId === 'undefined') {
                throw Error('Step ID is UNDEFINED');
            } else if (stepId === null) {
                throw Error('Step ID is NULL');
            } else if (stepId.trim() === '') {
                throw Error('Step ID is EMPTY');
            }

            stepId = stepId.trim();

            await this.afs
                .collection(COLLECTION.AUTOMATION_CALLFLOW_STEP)
                .doc(stepId)
                .update(step);

            return Promise.resolve();

        } catch (error) {
            console.error(error);
            throw Error('Unable to update the automation step.');
        }
    }

    async updateStep(stepId: string, step: any) {
        try {
            if (typeof step === 'undefined') {
                throw Error('Step data is UNDEFINED');
            } else if (step === null) {
                throw Error('Step data is NULL');
            }

            if (typeof stepId === 'undefined') {
                throw Error('Step ID is UNDEFINED');
            } else if (stepId === null) {
                throw Error('Step ID is NULL');
            } else if (stepId.trim() === '') {
                throw Error('Step ID is EMPTY');
            }

            stepId = stepId.trim();

            await this.afs
                .collection(COLLECTION.AUTOMATION_STEP)
                .doc(stepId)
                .update(step);

            return Promise.resolve();

        } catch (error) {
            console.error(error);
            throw Error('Unable to update the automation step.');
        }
    }

    async updatePipelineTaskStatus(stepId: string, isEnabled: boolean) {
        try {
            if (typeof stepId === 'undefined') {
                throw Error('Step ID is UNDEFINED');
            } else if (stepId === null) {
                throw Error('Step ID is NULL');
            } else if (stepId.trim() === '') {
                throw Error('Step ID is EMPTY');
            }

            stepId = stepId.trim();

            if (isEnabled) {
                await this.afs
                    .collection(COLLECTION.TASK)
                    .doc(stepId)
                    .update({ status: 'scheduled' });
            } else {
                await this.afs
                    .collection(COLLECTION.TASK)
                    .doc(stepId)
                    .update({ status: 'paused' });
            }

            return Promise.resolve();

        } catch (error) {
            console.error(error);
            throw Error('Unable to update the automation task status');
        }
    }

}
