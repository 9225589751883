<mat-nav-list>

    <div mat-list-item style="padding-bottom: 20px;">
        <h4 *ngIf="data"><mat-icon style="color: #03A9F4; margin-top: -5px; padding-right: 10px;" svgIcon="alert"></mat-icon>{{ data.message }}</h4>
        <h4 *ngIf="!data">Are you sure you want to update?</h4>
    </div>
    <div>
        <button style="float: right; margin-left: 10px;" align="end" mat-raised-button (click)="onClick(true)" mat-flat-button color="primary">
            <mat-icon>checkmark</mat-icon>
            Yes
        </button>
        <button style="float: right; margin-left: 10px;" align="end" mat-raised-button (click)="onClick(false)" mat-flat-button color="default">
            <mat-icon>close</mat-icon>
            No
        </button>
    </div>
</mat-nav-list>