import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit, OnDestroy {
  allSubscription = new Subscription();
  @Input() msgs: any;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DeleteConfirmationComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  ngOnInit() {
    //  console.log(this.data.message);
  }

  ngOnDestroy() {
    this.allSubscription.unsubscribe();
  }

  onClick(option: boolean): void {
    this.bottomSheetRef.dismiss(option);
    // tslint:disable-next-line: deprecation
    event.preventDefault();
  }

}
