import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
// import { bufferCount, concatMap, take, map } from 'rxjs/operators';
import { isUndefinedOrNullOrEmpty } from '../../shared/utils';

import { tap, scan, take } from 'rxjs/internal/operators';

export interface IQueryConfig {
    path: string;       // path to collection
    field: string;      // field to orderBy
    limit: number;      // limit per query
    reverse: boolean;   // reverse order?
    prepend: boolean;   // prepend to source?
    isDeleted?: boolean;
    companyId?: string;
}

@Injectable({
    providedIn: 'root'
})
export class PaginationService {

    // Source data
    private _done = new BehaviorSubject(false);
    private _loading = new BehaviorSubject(false);
    private _data = new BehaviorSubject([]);

    private query: IQueryConfig;

    // Observable data
    data: Observable<any> = this._data.asObservable();
    done: Observable<boolean> = this._done.asObservable();
    loading: Observable<boolean> = this._loading.asObservable();

    constructor(
        // private http: HttpClient,
        private afs: AngularFirestore,
    ) {
        this._loading.next(false);
        this._data.next([]);
        this._done.next(false);
    }

    // Initial query sets options and defines the Observable
    // passing opts will override the defaults
    init(path: string, field: string, opts?: any) {
        this.query = {
            path,
            field,
            limit: 2,
            reverse: false,
            prepend: false,
            ...opts
        };

        console.log('*** pagination query:', this.query);

        this._loading.next(false);

        const first = this.afs.collection(this.query.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.query.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                } else {
                    return ref
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                } else {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit);
                }
            }
        });

        this.mapAndUpdate(first);

        // Create the observable array for consumption in components
        this.data = this._data.asObservable()
            .pipe(
                scan((acc, val) => {
                    return this.query.prepend ? val.concat(acc) : acc.concat(val);
                })
            );
    }

    // Retrieves additional data from firestore
    more() {
        const cursor = this.getCursor();

        const more = this.afs.collection(this.query.path, ref => {
            if (isUndefinedOrNullOrEmpty(this.query.isDeleted)) {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                }
            } else {
                if (isUndefinedOrNullOrEmpty(this.query.companyId)) {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                } else {
                    return ref
                        .where('isDeleted', '==', this.query.isDeleted)
                        .where('companyId', '==', this.query.companyId)
                        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                        .limit(this.query.limit)
                        .startAfter(cursor);
                }
            }
        });
        this.mapAndUpdate(more);
    }


    // Determines the doc snapshot to paginate query
    private getCursor() {
        const current = this._data.value;
        if (current.length) {
            return this.query.prepend ? current[0].doc : current[current.length - 1].doc;
        }
        return null;
    }

    // Maps the snapshot to usable format the updates source
    private mapAndUpdate(col: AngularFirestoreCollection<any>) {

        console.warn('**************************');
        console.log('   mapAndUpdate()');
        console.warn('**************************');
        console.log('*** this._done:', this._done.value);
        console.log('*** this._loading:', this._loading.value);
        console.warn('**************************');

        if (this._done.value || this._loading.value) {
            return;
        }

        // loading
        this._loading.next(true);

        // Map snapshot with doc ref (needed for cursor)
        return col.snapshotChanges()
            .pipe(
                tap(arr => {
                    let values = arr.map(snap => {
                        const data = snap.payload.doc.data();
                        const doc = snap.payload.doc;
                        return { ...data, doc };
                    });

                    // If prepending, reverse the batch order
                    values = this.query.prepend ? values.reverse() : values;

                    // update source with new values, done loading
                    console.log('CHAT GROUPS:', values);

                    this._loading.next(false);

                    // no more values, mark done
                    if (!values.length) {
                        this._done.next(true);
                    }

                    this._data.next(values);
                })
            )
            .pipe(
                take(1)
            )
            .subscribe();
    }
}
