import { Component, OnInit, OnDestroy } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { IUser } from '../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  isLoggedIn: boolean;
  user: IUser;

  constructor(
    private authService: AuthService,
    // private route: ActivatedRoute,
    // private router: Router,
  ) {
    this.isLoggedIn = false;
    this.subs.add(
      this.authService.user
        .subscribe(user => {
          this.user = user;
        })
    );
  }

  ngOnInit(): void {
    this.subs.add(
      this.authService.afAuth.authState
        .subscribe(user => {
          if (user) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = false;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  logout() {
    this.authService.signOut();
  }
}
