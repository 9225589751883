<h2 mat-dialog-title class="dialogTitle">{{ title }}
  <div class="dlg-button-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
</h2>
<mat-dialog-content *ngIf="recordCount > 0" [formGroup]="form" [perfectScrollbar]="config">

  <div *ngIf="!showSpinner">
    <mat-label>Tags</mat-label>
  <mat-list class="tags">
    <mat-list-item>
      <mat-checkbox
      [(ngModel)]="master_checked"
      [ngModelOptions]="{standalone: true}"
      [(indeterminate)]="master_indeterminate"
      (change)="master_change()"
      >
        <b>Check/ Uncheck All</b>
      </mat-checkbox>
    </mat-list-item>

    <mat-list-item *ngFor="let item of tag_list">
      <mat-checkbox
      [(ngModel)]="item.checked"
      [ngModelOptions]="{standalone: true}"
      [disabled]="item.disabled"
      [labelPosition]="item.labelPosition"
      (change)="list_change()"
      >
        {{ item.name }}
      </mat-checkbox>
    </mat-list-item>

  </mat-list>

  <div>
    <span style="font-weight: bold;">{{ recordCount }}</span> {{ recordCountMessage }}
  </div>
</div>
<div *ngIf="showSpinner" class="loadingSpinner">
    <div class="centerFlex">
      <mat-progress-spinner class="centerFlex" mode="indeterminate" [diameter]="60"></mat-progress-spinner>
    </div>
    <div class="centerFlex loadingMessage">Loading Tags from CRM</div>
</div>
</mat-dialog-content>

<mat-dialog-content *ngIf="recordCount < 1">

  <div class="centerFlex" style="font-size: 18pt;">No leads to push to pipeline</div>

</mat-dialog-content>


<mat-dialog-actions *ngIf="recordCount > 0" align="end">
  <button mat-raised-button color="primary" (click)="push()">Push</button>
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="recordCount < 1" align="end">
  <button mat-raised-button color="primary" (click)="close()">Close</button>
</mat-dialog-actions>