import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smsOrderbyDeal',
  pure: false
})
export class SmsOrderByDealPipe implements PipeTransform {
  transform(group: any[], path: string[], order: number): any[] {
    // Check if is not null
    if (!group || !path || !order) {
      return group;
    }

    return group.sort((a: any, b: any) => {
      // We go for each property followed by path
      path.forEach(property => {
        a = a[property];
        b = b[property];
      });

      // Order * (-1): We change our order
      return a > b ? order : order * (- 1);
    });
  }
}
