<div *ngIf="!limitExceed">
    <div mat-dialog-title>
        <h2 style="font-size: 20px;">Add Team Member
            <div class="dlg-button-close" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </div>
        </h2>
        <div *ngIf="mode === '1' && data.isTeamAdmin && !data.is" class="toggleButton" (click)="addFromExisting()">
            Add from existing users
        </div>
        <div *ngIf="mode === '2' && data.isTeamAdmin && !data.is" class="toggleButton" (click)="backButton()">
            <mat-icon>arrow_back</mat-icon>
        </div>
    </div>

    <mat-dialog-content *ngIf="mode === '1'" [perfectScrollbar]="config">
        <div>
            <form class="example-form" [formGroup]="userForm">
                <!-- <mat-label>{{perm.response.permissionGroupName}}</mat-label> -->
                <mat-form-field class="example-full-width">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name" formControlName="name">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>
                <!-- <button (click)="onCheching()">Get selected</button> -->
                <mat-form-field *ngIf="data.isAdmin">
                    <mat-label>Team</mat-label>
                    <mat-select placeholder="Team" formControlName="teamId" (selectionChange)="onTeamChange()">
                        <mat-option *ngFor="let team of teams" value="{{ team.id }}">
                            {{ team.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Permission Group</mat-label>
                    <mat-select placeholder="Permission Level" formControlName="permissionGroupId">
                        <mat-option *ngFor="let group of permissionGroups" value="{{ group.id }}">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Pipelines</mat-label>
                    <mat-select placeholder="Pipelines" multiple formControlName="pipelineIds">
                        <mat-option *ngFor="let pipeline of filteredPipelines" value="{{ pipeline.id }}">
                            {{ pipeline.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Select SMS Account -->
                <div *ngIf="isCompanyAdmin || isTeamAdmin">
                    <mat-form-field>
                        <mat-label>SMS Accounts</mat-label>
                        <mat-select placeholder="SMS Account" formControlName="smsIds">
                            <!--
                            <mat-option *ngFor="let account of smsAccounts" value="{{ account.id }}">
                                {{ account.name }}
                            </mat-option>
                            -->

                            <mat-option *ngFor="let account of smsAccounts" value="{{ account.id }}"
                                (click)="changeSmsLogoURL(account.logoMiniUrl)">
                                <img *ngIf="account.logoMiniUrl" height="32px" style="padding-right: 15px;"
                                    src="{{ account.logoMiniUrl }}" />
                                {{ account.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Select Email Account -->
                    <mat-form-field>
                        <mat-label>Email Accounts</mat-label>
                        <mat-select placeholder="Email Account" formControlName="emailIds">
                            <!--
                            <mat-option *ngFor="let account of emailAccounts" value="{{ account.id }}">
                                {{ account.name }}
                            </mat-option>
                            -->

                            <mat-option *ngFor="let account of emailAccounts" value="{{ account.id }}"
                                (click)="changeEmailLogoURL(account.logoMiniUrl)">
                                <img *ngIf="account.logoMiniUrl" height="32px" style="padding-right: 15px;"
                                    src="{{ account.logoMiniUrl }}" />
                                {{ account.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="mode === '2' && (isCompanyAdmin || isTeamAdmin) && !data.is" [perfectScrollbar]="config">
        <div>
            <mat-list>
                <div class="userDiv" *ngFor="let user of usersAvailable">
                    <mat-list-item (click)="addUserToForm(user)">
                        <p mat-line>{{ user.name }}
                            <mat-icon class="float-right">arrow_forward_ios</mat-icon>
                        </p>
                        <p mat-line>{{ user.email }}</p>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </div>
            </mat-list>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="addUser()" [disabled]="!userForm.valid">
            {{ (data.is)? 'Save' : 'Create' }}</button>
    </mat-dialog-actions>
</div>
<div *ngIf="limitExceed">
    <h2 mat-dialog-title>Limit Exceed
        <div class="dlg-button-close" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </div>
    </h2>
    <mat-dialog-content [perfectScrollbar]="config">
        <div>
            <h3>You've reached your USER limit. Please upgrade to increase your user limit.</h3>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary">Buy Now <mat-icon>attach_money</mat-icon></button>
    </mat-dialog-actions>
</div>