<mat-card class="dropContainer" style="margin: 0px;">
    <mat-card-content class="dropContent">

        <div class="dropzone" dropZone (hovered)="toggleHover($event)" (dropped)="uploadFile($event)" [class.hovering]="isHovering">
            <label class="file-label">
                <input mat-fab color="primary" #file class="file-input" type="file" (change)="uploadFile($event.target.files)">
            </label>
        </div>

        <div *ngIf="percentage | async as pct" style="text-align: center;">
            <progress class="progress is-info" [value]="pct" max="100"></progress> {{ pct | number }}%
        </div>

        <div *ngIf=" snapshot | async as snap">
            {{ snap?.bytesTransferred | fileSize }} of {{ snap?.totalBytes | fileSize }}
        </div>

    </mat-card-content>
</mat-card>