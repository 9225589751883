import { Injectable } from '@angular/core';
// import { MaterialModule } from '../../shared/material.module';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { UpdateConfirmationComponent } from '../../update-confirmation/update-confirmation.component';

export const enum PROMPT_TYPE {
  YES_NO = 'yes_no',
  DELETE = 'delete',
}

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {

  isDelete = true;
  isYesNo = false;

  constructor(
    private bottomSheet: MatBottomSheet,
  ) { }

  openBottomSheet(message: string, promptType: PROMPT_TYPE = PROMPT_TYPE.YES_NO) {
    if (promptType === PROMPT_TYPE.YES_NO) {
      this.bottomSheet.open(UpdateConfirmationComponent, {
        data: {
          message
        }
      });
      return this.bottomSheet;
    } else if (promptType === PROMPT_TYPE.DELETE) {
      this.bottomSheet.open(DeleteConfirmationComponent, {
        data: {
          message
        }
      });
      return this.bottomSheet;
    } else {
      this.bottomSheet.open(DeleteConfirmationComponent, {
        data: {
          message
        }
      });
      return this.bottomSheet;
    }
  }
}
