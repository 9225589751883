
// TODO: Move the following functions into a separate module and require them in this module
//       These are specific to GeoPoints and Geo Searching which is not fully implemented yet in Firestore
//       which is why we have a work-around with geo_lat and geo_long to bound the search area

/**
 * Calculates the distance, in kilometers, between two locations, via the
 * Haversine formula. Note that this is approximate due to the fact that
 * the Earth's radius varies between 6356.752 km and 6378.137 km.
 *
 * @param location1 The first location given as .latitude and .longitude
 * @param location2 The second location given as .latitude and .longitude
 * @return The distance, in kilometers, between the inputted locations.
 */
export function distance(location1, location2): number {
    const radius = 6371; // Earth's radius in kilometers
    const latDelta = degreesToRadians(location2.latitude - location1.latitude);
    const lonDelta = degreesToRadians(location2.longitude - location1.longitude);

    const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
        (
            Math.cos(degreesToRadians(location1.latitude)) *
            Math.cos(degreesToRadians(location2.latitude)) *
            Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2)
        );

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return radius * c;
}

/**
 * Calculates the SW and NE corners of a bounding box around a center point for a given radius;
 *
 * @param center The center given as .latitude and .longitude
 * @param  radius The radius of the box (in kilometers)
 * @return The SW and NE corners given as .swCorner and .neCorner
 */
export function boundingBoxCoordinates(center, radius: number) {
    const KM_PER_DEGREE_LATITUDE = 110.574;
    const latDegrees = radius / KM_PER_DEGREE_LATITUDE;
    const latitudeNorth = Math.min(90, center.latitude + latDegrees);
    const latitudeSouth = Math.max(-90, center.latitude - latDegrees);

    // calculate longitude based on current latitude
    const longDegsNorth = metersToLongitudeDegrees(radius, latitudeNorth);
    const longDegsSouth = metersToLongitudeDegrees(radius, latitudeSouth);
    const longDegs = Math.max(longDegsNorth, longDegsSouth);
    return {
        swCorner: { // bottom-left (SW corner)
            latitude: latitudeSouth,
            longitude: wrapLongitude(center.longitude - longDegs),
        },
        neCorner: { // top-right (NE corner)
            latitude: latitudeNorth,
            longitude: wrapLongitude(center.longitude + longDegs),
        },
    };
}

/**
 * Calculates the number of degrees a given distance is at a given latitude.
 *
 * @param distance The distance to convert.
 * @param latitude The latitude at which to calculate.
 * @return The number of degrees the distance corresponds to.
 */
export function metersToLongitudeDegrees(theDistance: number, latitude: number): number {
    const EARTH_EQ_RADIUS = 6378137.0;
    // this is a super, fancy magic number that the GeoFire lib can explain (maybe)
    const E2 = 0.00669447819799;
    const EPSILON = 1e-12;
    const radians = degreesToRadians(latitude);
    const num = Math.cos(radians) * EARTH_EQ_RADIUS * Math.PI / 180;
    const denom = 1 / Math.sqrt(1 - E2 * Math.sin(radians) * Math.sin(radians));
    const deltaDeg = num * denom;
    if (deltaDeg < EPSILON) {
        return theDistance > 0 ? 360 : 0;
    }
    // else
    return Math.min(360, theDistance / deltaDeg);
}

/**
 * Wraps the longitude to [-180,180].
 *
 * @param longitude The longitude to wrap.
 * @return longitude The resulting longitude.
 */
export function wrapLongitude(longitude: number): number {
    if (longitude <= 180 && longitude >= -180) {
        return longitude;
    }
    const adjusted = longitude + 180;
    if (adjusted > 0) {
        return (adjusted % 360) - 180;
    }
    // else
    return 180 - (-adjusted % 360);
}

export function degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
}
