import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Overlay, CloseScrollStrategy } from '@angular/cdk/overlay';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IStage } from '../models';
import { isUndefinedOrNull, isUndefinedOrNullOrEmpty } from '../shared/utils';
const stripeCurrencies = require('../shared/stripe-currencies.json');
const { v4: uuidv4 } = require('uuid');

import { SessionService } from '../services/session/session.service';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

const DEFAULT_TITLE = 'Push To CRM';

@Component({
  selector: 'app-push-leads-dialog',
  templateUrl: './push-leads-dialog.component.html',
  styleUrls: ['./push-leads-dialog.component.scss'],
})
export class PushLeadsDialogComponent implements OnInit, OnDestroy {
  countryCode: string;
  supportedCurrencies: string[] = [];

  allSubscriptions = new Subscription();
  title: string;
  recordCount: number;
  recordCountMessage: string;

  form: FormGroup;
  toPipeline = new FormControl();
  toStage = new FormControl();
  allCurrencyTypes = new FormControl();

  pipelineId: string;
  pipelines: any[] = [];
  stageId: string;
  stages: IStage[] = [];

  ownerId: string;
  ownerName: string;
  dealCurrencyType: string;
  dealValue: number;
  dealExpectedCloseDate: Date;
  dealTitle: string;

  currencyTypes: any[] = [];

  apikey: string = null;

  public config: PerfectScrollbarConfigInterface = {};
  cancelText: string;

  // For High Level tags
  tags: any[] = [];
  tag_list: any[] = [];
  master_checked: boolean = false;
  master_indeterminate: boolean = false;
  showSpinner: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PushLeadsDialogComponent>,
    private fb: FormBuilder,
    private http: HttpClient,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    try {
      this.cancelText = 'Cancel';

      // Load supported currencies for STRIPE
      this.countryCode = 'US';
      let index = -1;
      index = stripeCurrencies.findIndex(
        (countryData) => countryData.id === this.countryCode
      );
      if (index > -1) {
        this.supportedCurrencies =
          stripeCurrencies[index].supported_payment_currencies;
      } else {
        index = stripeCurrencies.findIndex(
          (countryData) => countryData.id === 'US'
        );
        if (index > -1) {
          this.supportedCurrencies =
            stripeCurrencies[index].supported_payment_currencies;
        } else {
          this.supportedCurrencies = ['usd', 'cad', 'eur'];
        }
      }

      if (!isUndefinedOrNullOrEmpty(this.data)) {
        this.currencyTypes.push({
          id: 'USD',
          name: 'US Dollar',
          code: 'USD',
          symbol: '$',
        });
        this.currencyTypes.push({
          id: 'AUD',
          name: 'Australian Dollar',
          code: 'AUD',
          symbol: '$',
        });
        this.currencyTypes.push({
          id: 'CAD',
          name: 'Canadian Dollar',
          code: 'CAD',
          symbol: '$',
        });
        this.currencyTypes.push({
          id: 'EUR',
          name: 'Euro',
          code: 'EUR',
          symbol: '€',
        });
        this.currencyTypes.push({
          id: 'GBP',
          name: 'Pound Sterling',
          code: 'GBP',
          symbol: '£',
        });
        this.currencyTypes.push({
          id: 'BTC',
          name: 'Bitcoin',
          code: 'BTC',
          symbol: '₿',
        });
        this.currencyTypes.push({
          id: 'JPY',
          name: 'Japanese Yen',
          code: 'JPY',
          symbol: '¥',
        });

        this.ownerId = this.data.ownerId;
        this.ownerName = this.data.ownerName;
        this.dealCurrencyType = this.data.dealCurrencyType;
        this.dealValue = this.data.dealValue;
        this.dealExpectedCloseDate = this.data.dealExpectedCloseDate;
        this.dealTitle = this.data.dealTitle;

        this.title = isUndefinedOrNullOrEmpty(this.data.title)
          ? DEFAULT_TITLE
          : this.data.title.trim();
        this.recordCount =
          !isUndefinedOrNull(this.data.recordCount) && this.data.recordCount < 1
            ? 0
            : this.data.recordCount;
        if (this.recordCount < 0) {
          this.recordCountMessage = '';
        } else if (this.recordCount === 1) {
          this.recordCountMessage = 'lead to push into CRM';
        } else {
          this.recordCountMessage = 'leads to push into CRM';
        }

        if (isUndefinedOrNull(this.data.pipelines)) {
          throw Error('No pipelines to select');
        }

        this.pipelineId = isUndefinedOrNullOrEmpty(this.data.pipelineId)
          ? ''
          : this.data.pipelineId;
        this.stageId = isUndefinedOrNullOrEmpty(this.data.stageId)
          ? ''
          : this.data.stageId;
        this.pipelines = this.data.pipelines;

        this.apikey = this.data.apikey;
      }

      this.form = this.fb.group({
        toPipeline: [this.pipelineId, Validators.required],
        toStage: [this.stageId, Validators.required],
        dealTitle: [''],
        dealValue: [this.dealValue.toFixed(2), Validators.required],
        allCurrencyTypes: [this.dealCurrencyType, Validators.required],
        ownerId: [this.ownerId, Validators.required],
        ownerName: [this.ownerName, Validators.required],
      });
    } catch (error) {
      console.error(error);
      this.recordCount = 0;
      this.pipelines = null;
      this.stages = null;
    }
  }

  ngOnInit() {
    this.allSubscriptions.add(
      this.sessionService.company.subscribe((company) => {
        if (!isUndefinedOrNullOrEmpty(company)) {
          // Set the pipeline and stage on the form.
          this.toPipeline.setValue(this.pipelineId);
          this.toStage.setValue(this.stageId);
          this.allCurrencyTypes.setValue(this.dealCurrencyType);
        }
      })
    );

    this.getTags();
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  changeTag(tagId: number) {
    return;
  }

  changePipeline(pipelineIndex: number) {
    const pipeline = this.pipelines[pipelineIndex];
    this.pipelineId = pipeline.pipelineId;
    this.stages = pipeline.stage;
    this.stageId = this.stages[0].id;
    this.form.get('toStage').setValue(this.stageId);
    this.toStage.setValue(this.stageId);
  }

  changeStage(stageIndex: number) {
    const stage = this.stages[stageIndex];
    this.stageId = stage.id;
    this.form.get('toStage').setValue(this.stageId);
    this.toStage.setValue(this.stageId);
  }

  push() {
    let pipelineId = this.toPipeline.value;
    if (!isUndefinedOrNullOrEmpty(pipelineId)) {
      pipelineId = this.pipelineId.trim();
    }
    let stageId = this.toStage.value;
    if (!isUndefinedOrNullOrEmpty(stageId)) {
      stageId = this.stageId.trim();
    }

    const formStageId = this.form.get('toStage').value;
    const ctrlStageId = this.toStage.value;

    let dealTitle = this.form.get('dealTitle').value;
    if (!isUndefinedOrNullOrEmpty(dealTitle)) {
      dealTitle = this.dealTitle.trim();
    }
    let dealValue = this.form.get('dealValue').value;
    if (!isUndefinedOrNullOrEmpty(dealValue)) {
      dealValue = parseFloat(this.dealValue.toFixed(2));
    } else {
      if (typeof dealValue === 'string') {
        dealValue = parseFloat(
          parseFloat(dealValue.replace(/[^0-9\,\.]/g, '')).toFixed(2)
        );
      } else {
        dealValue = parseFloat(this.dealValue.toFixed(2));
      }
    }
    let dealCurrencyType = this.form.get('allCurrencyTypes').value;
    if (!isUndefinedOrNullOrEmpty(dealCurrencyType)) {
      dealCurrencyType = this.dealCurrencyType;
    }
    // !TODO: Add some validation and a drop-down date selector to the form
    const dealExpectedCloseDate = this.dealExpectedCloseDate;

    let ownerId = this.form.get('ownerId').value;
    if (!isUndefinedOrNullOrEmpty(ownerId)) {
      ownerId = this.ownerId.trim();
    }
    let ownerName = this.form.get('ownerName').value;
    if (!isUndefinedOrNullOrEmpty(ownerName)) {
      ownerName = this.ownerName.trim();
    }

    let tagList: any[] = this.tag_list.filter(
      (item: any) => item.checked === true
    );

    tagList = tagList.map((item: any) => {
      return { id: item.id, name: item.name };
    });

    const data = {
      pipelineId,
      stageId,
      dealTitle,
      dealValue,
      dealCurrencyType,
      dealExpectedCloseDate,
      ownerId,
      ownerName,
      tags: tagList,
    };

    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }

  async getTags() {
    this.showSpinner = true;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    const url = `https://us-central1-aesthetic-root-414319.cloudfunctions.net/apiGetTagsV2?locationId=${this.data.wlOptions.locationId}&integrationId=${this.data.wlOptions.integrationId}`;

    const taskIdResult = await this.http.get<any>(url, { headers }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error))
    );

    taskIdResult.pipe(first()).subscribe(async (resTask) => {
      const pushMessage = resTask.message;
      if (resTask.success) {
        // Update the tags.
        this.tags.length = 0;
        for (const item of resTask.data.tags) {
          this.tags.push(item);
          this.tag_list.push({
            name: item.name,
            id: item.id,
            disabled: false,
            checked: false,
            labelPosition: 'after',
          });
        }
      }
      this.showSpinner = false;
      return pushMessage;
    });
  }

  list_change() {
    let checked_count = 0;
    //Get total checked items
    for (let value of Object.values(this.tag_list)) {
      if (value.checked) {
        checked_count++;
      }
    }

    if (checked_count > 0 && checked_count < this.tag_list.length) {
      // If some checkboxes are checked but not all; then set Indeterminate state of the master to true.
      this.master_indeterminate = true;
    } else if (checked_count == this.tag_list.length) {
      //If checked count is equal to total items; then check the master checkbox and also set Indeterminate state to false.
      this.master_indeterminate = false;
      this.master_checked = true;
    } else {
      //If none of the checkboxes in the list is checked then uncheck master also set Indeterminate to false.
      this.master_indeterminate = false;
      this.master_checked = false;
    }
  }

  master_change() {
    for (let value of Object.values(this.tag_list)) {
      value.checked = this.master_checked;
    }
  }
}
