<h2 mat-dialog-title>{{ title }}</h2>

<mat-dialog-content [formGroup]="form">

  <mat-form-field>
    <input matInput placeholder="Data Export Filename" formControlName="filename">
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

  <button class="mat-raised-button" (click)="close()">
    Close
  </button>

  <button class="mat-raised-button mat-primary" (click)="export()">
    Export
  </button>

</mat-dialog-actions>