export interface People {
  id: string;
  response: {
    companyId: string;
    companyName: string;
    personLabelId: number;
    organizationId: string;
    firstName: string;
    lastName: string;
    ownerId: string;
    ownerName: string;
    organizationName: string;
    displayName?: string;
    customField: any;
    numberIndexed: any;
    lookupNumber?: any;
    lookupEmail?: any;
    emailLookup?: any;
    openDealsCount?: number;
    closedDealsCount?: number;
    Number: [
      {
        cellNo: string;
        type: string;
      }
    ];
    Email: [
      {
        email: string;
        type: string;
      }
    ];
    tags?: any;
  };
}

// export interface People {
//   id:string;
//   response:{
//     name: string;
//     organization: string;
//     email: string;
//     phone: string;
//     owner: string;
//     label: number;
//     closedDeals: number;
//     openDeals: number;
//   }
// }
