import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// FOR Disabling material scroll
import { Overlay, CloseScrollStrategy } from '@angular/cdk/overlay';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

const DEFAULT_TITLE = 'Add Merge Field';

@Component({
  selector: 'app-merge-fields',
  templateUrl: './merge-fields.component.html',
  styleUrls: ['./merge-fields.component.scss']
})
export class MergeFieldsComponent implements OnInit, OnDestroy {

  title: string;
  cancelText: string;
  contactFields: any[] = [];
  dealFields: any[] = [];
  organizationFields: any[] = [];
  config: PerfectScrollbarConfigInterface = {};

  constructor(
    public dialogRef: MatDialogRef<MergeFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    try {
      this.cancelText = 'Cancel';
      this.title = DEFAULT_TITLE;

      if (typeof this.data !== 'undefined' && this.data !== null) {

        console.log('data:', data);

        this.title = (typeof this.data.title === 'undefined' || this.data.title === null || this.data.title.trim() === '')
          ? this.title = DEFAULT_TITLE : this.data.title;
      }

      /*
      const varsContactPerson = ['displayName', 'firstName', 'lastName', 'phoneNumber', 'email'];
      const varsOrganization = ['organizationName', 'website', 'address'];
      const varsDeal = ['dealTitle', 'dealValue', 'dealValueCurrencyType', 'expectedCloseDate', 'dealStatus'];
      */
      this.contactFields.push({ id: '', name: 'Display Name', code: '%%displayName%%' });
      this.contactFields.push({ id: '', name: 'First Name', code: '%%firstName%%' });
      this.contactFields.push({ id: '', name: 'Last Name', code: '%%lastName%%' });
      this.contactFields.push({ id: '', name: 'Phone Number', code: '%%phoneNumber%%' });
      this.contactFields.push({ id: '', name: 'Email', code: '%%email%%' });

      this.dealFields.push({ id: '', name: 'Deal Title', code: '%%dealTitle%%' });
      this.dealFields.push({ id: '', name: 'Deal Value', code: '%%dealValue%%' });
      this.dealFields.push({ id: '', name: 'Deal Currency Type', code: '%%dealValueCurrencyType%%' });
      this.dealFields.push({ id: '', name: 'Deal Expected Close Date', code: '%%expectedCloseDate%%' });
      this.dealFields.push({ id: '', name: 'Deal Status', code: '%%dealStatus%%' });

      this.organizationFields.push({ id: '', name: 'Organization Name', code: '%%organizationName%%' });
      this.organizationFields.push({ id: '', name: 'Organization Address', code: '%%address%%' });
      this.organizationFields.push({ id: '', name: 'Organization Website', code: '%%website%%' });

      /*
      this.contactFields['firstName'] = { name: 'First Name', code: '%%firstName%%' };
      this.contactFields['lastName'] = { name: 'Last Name', code: '%%lastName%%' };
      this.contactFields['displayName'] = { name: 'Display Name', code: '%%displayName%%' };
      this.contactFields['phoneNumber'] = { name: 'Phone Number', code: '%%phoneNumber%%' };
      this.contactFields['email'] = { name: 'Email', code: '%%email%%' };

      this.dealFields['dealTitle'] = { name: 'Deal Title', code: '%%dealTitle%%' };
      this.dealFields['dealValue'] = { name: 'Deal Value', code: '%%dealValue%%' };
      this.dealFields['dealValueCurrencyType'] = { name: 'Deal Currency Type', code: '%%dealValueCurrencyType%%' };
      this.dealFields['expectedCloseDate'] = { name: 'Deal Expected Close Date', code: '%%expectedCloseDate%%' };
      this.dealFields['dealStatus'] = { name: 'Deal Status', code: '%%dealStatus%%' };

      this.organizationFields['organizationName'] = { name: 'Organization Name', code: '%%organizationName%%' };
      this.organizationFields['website'] = { name: 'Organization Name', code: '%%website%%' };
      this.organizationFields['address'] = { name: 'Organization Name', code: '%%address%%' };
      */
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  addField(name: string, code: string) {
    const data = {
      name,
      code,
    };
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }
}
