import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

// import { redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';

import { AppComponent } from './app.component';
import { FinderComponent } from './finder/finder.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';

// const redirectUnauthorizedToLanding = redirectUnauthorizedTo(['login']);
const accountModule = () =>
  import('./account/account.module').then((x) => x.AccountModule);
const adminModule = () =>
  import('./admin/admin.module').then((x) => x.AdminModule);
const integrationsModule = () =>
  import('./integrations/integrations.module').then(
    (x) => x.IntegrationsModule
  );
const settingsModule = () =>
  import('./settings/settings.module').then((x) => x.SettingsModule);

const routes: Routes = [
  {
    path: '',
    component: FinderComponent,
  },
  {
    path: ':token',
    component: FinderComponent,
  },
  {
    path: ':token/:locationId',
    component: AppComponent,
  },
  { path: 'account', loadChildren: accountModule },
  {
    path: 'admin',
    loadChildren: adminModule,
    canActivate: [AngularFireAuthGuard],
  },
  { path: 'integrations', loadChildren: integrationsModule },
  { path: 'settings', loadChildren: settingsModule },
  {
    path: 'finder/:token/:locationId/:apikey',
    component: FinderComponent,
  },
  {
    path: 'finder/:token/:locationId',
    component: FinderComponent,
  },
  {
    path: 'finder/:token',
    component: FinderComponent,
  },
  {
    path: 'finder',
    component: FinderComponent,
  },
  {
    path: 'home/:token/:locationId/:apikey',
    component: HomeComponent,
  },
  {
    path: 'home/:token/:locationId',
    component: HomeComponent,
  },
  {
    path: 'home/:token',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AngularFireAuthGuard],
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
