import { Component, Inject, OnInit } from '@angular/core';
import { ExportResults } from '../models';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// const moment = require('moment');
import * as moment from 'moment';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {
  form: FormGroup;
  category: string;
  exportType: string;
  filename: string;
  filenameNew: string;
  location: string;
  recordCount: number;
  recordCountMessage: string;
  title: string;
  source: string;
  selectedType = 'BASIC';
  exportTypes = [
    {
      name: 'CSV - Basic Data',
      value: 'BASIC',
    },
    /*
    {
      name: 'CSV - Full Data',
      value: 'FULL',
    },
    {
      name: 'PUSH to Pipeline',
      value: 'CRM'
    },
    {
      name: 'CSV - Territory Call Sheet',
      value: 'TERRITORY',
    },
    */
  ];

  constructor(
    // @Inject('moment') private moment,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      title,
      recordCount,
      filename,
      exportType,
      category,
      location,
      source,
    }: ExportResults
  ) {
    this.category = typeof category === 'undefined' ? '' : category;
    this.location = typeof location === 'undefined' ? '' : location;
    this.exportType = typeof exportType === 'undefined' ? 'BASIC' : exportType;
    this.filename = filename;
    this.title = typeof title === 'undefined' ? '' : title;
    this.recordCount =
      typeof recordCount === 'undefined' || recordCount === null
        ? 0
        : recordCount;
    this.source = typeof source === 'undefined' ? '' : source;
    if (this.source === '') {
      this.exportTypes.push(
        {
          name: 'CSV - Full Data',
          value: 'FULL',
        },
        {
          name: 'PUSH to Pipeline',
          value: 'CRM',
        }
      );
    } else {
      this.exportTypes.push({
        name: 'Google Ads Audience',
        value: 'ADWORDS',
      });
    }

    if (this.recordCount < 1) {
      this.recordCountMessage = '';
    } else if (this.recordCount === 1) {
      this.recordCountMessage = 'lead will be exported';
    } else {
      this.recordCountMessage = 'leads will be exported';
    }

    this.form = this.fb.group({
      filename: [filename, Validators.required],
      exportType: [exportType, Validators.required],
    });
  }

  ngOnInit() {
    this.form.get('exportType').setValue(this.selectedType);
    this.exportType = this.selectedType;
  }

  export() {
    this.filename = this.form.get('filename').value;
    console.log('this.filename:', this.filename);
    this.exportType = this.form.get('exportType').value;
    console.log('*** DIALOG CLOSE | EXPORT TYPE:', this.exportType);
    this.dialogRef.close({
      exportType: this.exportType,
      filename: this.filename,
    });
  }

  close() {
    this.dialogRef.close();
  }

  updateFilename(exportType: string) {
    this.filename = this.form.get('filename').value;

    if (this.form.pristine) {
      let data = '';
      if (this.source === '') {
        data =
          this.category.toLocaleLowerCase() +
          '_in_' +
          this.location
            .toLocaleLowerCase()
            .replace(/[\,\.]/g, '')
            .replace(/\s/g, '_');
      } else {
        data = this.location.replace(/[\,\.]/g, '').replace(/\s/g, '_');
      }

      switch (exportType) {
        case 'ADWORDS':
          this.filename =
            'adwords_' + data + '_' + moment().format('YYYY-MM-DD');
          break;

        case 'CRM':
          this.filename =
            'pipeline_' + data + '_' + moment().format('YYYY-MM-DD');
          break;

        case 'TERRITORY':
          // Set the default export filename
          this.filename =
            'territory_' + data + '_' + moment().format('YYYY-MM-DD');
          break;

        case 'BASIC':
          this.filename = data + '_' + moment().format('YYYY-MM-DD');
          break;

        case 'FULL':
          this.filename = 'full_' + data + '_' + moment().format('YYYY-MM-DD');
          break;

        default:
          this.filename = data + '_' + moment().format('YYYY-MM-DD');
      }
    }

    this.form.patchValue({
      filename: this.filename,
      exportType,
    });

    return this.filename;
  }
}
