import { take, tap, scan, catchError, map, concatMap, bufferCount } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddCampaign, IChatMessage, Inbox, SmsSendRequest, } from '../../models';
import { SessionService } from '../session/session.service';
import { BehaviorSubject, throwError, Observable, Observer, from, } from 'rxjs';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, DocumentData, QueryDocumentSnapshot, } from '@angular/fire/firestore';
import localforage from 'localforage';

import { environment } from '../../../environments/environment';
import { ACTION, COLLECTION, DEFAULT_COUNTRY_CODE, JOB_STATUS, TASK_STATUS, Utils, isUndefinedOrNullOrEmpty } from '../../shared/utils';

import { Message, MenuItem } from '../message';
import { MESSAGES, MENU_ITEMS } from '../mock-messages';

const RECORD_LIMIT = 10;
const TASK_ORIGIN = 'leadcarrot';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

interface QueryConfig {
    path: string; //  path to collection
    field: string; // field to orderBy
    limit: number; // limit per query
    reverse: boolean; // reverse order?
    prepend: boolean; // prepend to source?
}

@Injectable({
    providedIn: 'root'
})
export class SmsService {
    private utils = new Utils();
    // Source data
    _done = new BehaviorSubject(false);
    _loading = new BehaviorSubject(false);
    _data = new BehaviorSubject([]);
    private query: QueryConfig;
    data: Observable<any>;
    done: Observable<boolean> = this._done.asObservable();
    loading: Observable<boolean> = this._loading.asObservable();
    messages: IChatMessage[] = [];
    messagesBehaviour = new BehaviorSubject<IChatMessage[]>([]);
    chatObserver: BehaviorSubject<IChatMessage[]>[] = [];

    // Observing section for multiple conversations
    private activeChats: any[] = [];

    constructor(
        private http: HttpClient,
        private sessionService: SessionService,
        private afs: AngularFirestore,
    ) { }

    async deleteGroup(groupId: string) {
        try {
            const chatRef = this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(groupId)
                .collection(COLLECTION.CHAT_GROUP_MESSAGES);
            await this.deleteFirestoreCollection(chatRef);

            return this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(groupId).delete();
        } catch (error) {
            console.error(error);
            return Promise.resolve(error);
        }
    }

    async hideGroup(groupId: string) {
        try {
            return await this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(groupId)
                .update({ isHidden: true });
        } catch (error) {
            console.error(error);
            return Promise.resolve(error);
        }
    }

    async showGroup(groupId: string) {
        try {
            return await this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(groupId)
                .update({ isHidden: false });
        } catch (error) {
            console.error(error);
            return Promise.resolve(error);
        }
    }

    async markReadGroup(groupId: string) {
        try {
            return await this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(groupId)
                .update({ isRead: true });
        } catch (error) {
            console.error(error);
            return Promise.resolve(error);
        }
    }

    async markUnreadGroup(groupId: string) {
        try {
            return await this.afs
                .collection(COLLECTION.CHAT_GROUPS)
                .doc(groupId)
                .update({ isRead: false });
        } catch (error) {
            console.error(error);
            return Promise.resolve(error);
        }
    }

    // TODO: Remove watchChat and unwatchChat
    // Make this a part of the notification
    watchChat(chatId: string) {
        /*
        // Add chat ID to list of chats we are monitoring the messages
        if (typeof this.activeChats[chatId] === 'undefined') {
          this.activeChats[chatId] = null;
        }

        // Watch for updates to this chat thread
        this.getChatMessages(null, chatId, null).subscribe(messages => {
          this.chatObserver[chatId] = new BehaviorSubject<IChatMessage[]>(messages);
        });
        */
    }

    unwatchChat(chatId: string) {
        if (typeof this.activeChats[chatId] !== 'undefined') {

            // unsubscribe from this chat observer.
            if (this.chatObserver[chatId] !== 'undefined') {
                delete this.chatObserver[chatId];
            }

            delete this.activeChats[chatId];
        }
    }

    // Add a real-time list of messages to the chats collection

    private async deleteFirestoreCollection(
        collection: AngularFirestoreCollection<any>,
    ): Promise<number> {

        let totalDeleteCount = 0;
        const batchSize = 500;

        return new Promise<number>((resolve, reject) =>
            from(collection.ref.get())
                .pipe(
                    concatMap((q) => from(q.docs)),
                    bufferCount(batchSize),
                    concatMap((docs: Array<QueryDocumentSnapshot<any>>) => new Observable(
                        (o: Observer<number>) => {
                            const batch = this.afs.firestore.batch();
                            docs.forEach((doc) => {
                                batch.delete(doc.ref);
                            });
                            batch.commit()
                                .then(() => {
                                    o.next(docs.length);
                                    o.complete();
                                })
                                .catch((e) => o.error(e));
                        })),
                )
                .subscribe(
                    (batchDeleteCount: number) => totalDeleteCount += batchDeleteCount,
                    (e) => reject(e),
                    () => resolve(totalDeleteCount),
                ),
        );
    }

    fixChatgroups(companyId: string) {
        try {
            const apiLeadCarrot = environment.cloudUrl + '/chatGroupCleanup';
            const headers = this.utils.createCloudHeaders(this.sessionService.userToken);

            const body = {
                adminId: this.sessionService.user.value.id,
                companyId
            };

            // console.warn(body);
            // throw Error('NOT sending sms');

            return this.http.post(
                apiLeadCarrot,
                body,
                {
                    headers,
                    // withCredentials: true
                })
                .subscribe();
        } catch (error) {
            // console.error(error);
            // this.ngxLoader.stop();
            // this.openSnackBar('Unable to change password', 'close');
            // this.utils.resetForm(this.passwordForm);
            console.error(error);
        }
    }

    smsSend(sms: any, taskId: string = '') {

        // return this.sendSms(sms);
        console.log('---------------------------------------------------');
        console.log('typeof sms.dateTime', typeof sms.dateTime);
        console.warn('Sms.Service => sendSms() | sms:', sms);
        console.log('---------------------------------------------------');

        try {
            const topicName = ACTION.SMS_SEND;

            const data = JSON.stringify(sms);
            const customAttributes = {
                origin: TASK_ORIGIN,
                action: ACTION.SMS_SEND,
                taskId
            };

            const apiLeadCarrot = environment.cloudUrl + '/pubsubPublish';
            const headers = this.utils.createCloudHeaders(this.sessionService.userToken);

            const body = {
                data,
                customAttributes,
                topicName,
            };

            // console.warn(body);
            // throw Error('NOT sending sms');

            return this.http.post(
                apiLeadCarrot,
                body,
                {
                    headers,
                    // withCredentials: true
                })
                .pipe(
                    map((response: Response) => {
                        console.log('pubsubPublish():', response);
                        return { response, sms };
                    }),
                    catchError((error: Response) => throwError(error))
                );
        } catch (error) {
            // console.error(error);
            // this.ngxLoader.stop();
            // this.openSnackBar('Unable to change password', 'close');
            // this.utils.resetForm(this.passwordForm);
            console.error(error);
        }


        /*
        const body = JSON.stringify(sms);
        console.log('SMS Body:', body);

        return this.http
          .post(
            `${this.sessionService.END_POINT}/sms/${this.sessionService.companyId}`,
            body,
            { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
          )
          .pipe(
            map((res: Response) => res),
            catchError((error: Response) => throwError(error))
          );

        // throwError(Error('TODO: Re-Enable the SMS Sending'));
        */
    }


    async createSmsData(
        dealId: string,
        stageId: string,
        step: any,
        userId: string,
        previousStepDateTime: any,
    ): Promise<any> {

        try {

            // console.warn('+++ typeof previousStepDateTime:', typeof previousStepDateTime);
            // console.log('+++ previousStepDateTime:', previousStepDateTime);

            let lastDateTime: any = previousStepDateTime;
            if (typeof previousStepDateTime === 'string') {
                lastDateTime = new Date(previousStepDateTime);
            } else if (typeof previousStepDateTime === 'object') {
                if (typeof previousStepDateTime._seconds !== 'undefined' && previousStepDateTime !== null) {
                    lastDateTime = new Date(previousStepDateTime * 1000);
                }
            }

            console.log('----------------------------------------------------------');
            console.warn('typeof lastDateTime:', typeof lastDateTime);
            console.warn('lastDateTime:', lastDateTime);
            console.warn('lastDateTime JSON.stringify():', JSON.stringify(lastDateTime));
            console.log('----------------------------------------------------------');

            let results: any;
            results = await Promise.all([
                this.getDocument(step.custom.settingId, COLLECTION.INTEGRATION),
                this.getActivityData(step.custom.activityId),
                this.getDocument(dealId, COLLECTION.DEAL),
            ]);

            const integration = results[0];
            const activityData = results[1];
            const dealData = results[2];
            results = [];
            const integrationType = integration.isCompany ? 'company' : 'personal';

            const fromNumber = integration.custom.fromNumber;
            /*
            const fromName = integration.custom.fromName;
            const fromEmail = integration.custom.fromEmail;
            let from = fromEmail;
            if (fromName !== '') {
                from = fromName + '<' + fromEmail + '>';
            }
            */
            const person = {
                id: '',
                name: '',
                phone: '',
                email: '',
            };

            const taskId = '';
            const pipelineId = dealData.pipelineId;
            const customVariables = {};

            if (step.custom.to.length > 0) {
                const contactPerson = step.custom.to[0];
                person.id = contactPerson.id;
                person.name = contactPerson.displayName;
                person.phone = this.utils.toE164(contactPerson.number, DEFAULT_COUNTRY_CODE);
                // person.email = typeof contactPerson.email !== 'undefined' ? contactPerson.email : '';

            } else {
                const contactData = await this.getDocument(dealData.contactPersonId, COLLECTION.CONTACT);
                person.id = dealData.contactPersonId;
                person.name = contactData.displayName;
                person.phone = this.utils.toE164(this.getContactNumber(contactData.Number, step.custom.phoneType), DEFAULT_COUNTRY_CODE);
                // person.email = getContactEmail(contactData.Email, 'work');
            }

            const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
            // const dateTime = new Date();

            const taskData = {
                options: {
                    message: {
                        body: step.custom.body,
                        to: person.phone,
                        from: fromNumber,
                    },
                    config: {
                        id: taskId,
                        to: person.phone,
                        activityId: activityData.id,
                        activityName: '',
                        activityLabel: activityData.label,

                        userId,
                        userName: '',

                        companyId: step.companyId,
                        companyName: step.companyName,

                        organizationId: typeof dealData.organizationId === 'undefined' || dealData.organizationId === null
                            ? '' : dealData.organizationId,
                        organizationName: typeof dealData.organizationName === 'undefined' || dealData.organizationName === null
                            ? '' : dealData.organizationName,

                        contactId: person.id,
                        contactName: person.name,

                        dealId,
                        dealName: dealData.dealTitle,

                        campaignId: typeof step.campaignId === 'undefined' || step.campaignId === null ? '' : step.campaignId,
                        customVariables,
                        dateTime: lastDateTime,

                        deliveryStatus: false,
                        groupId: '',
                        immediate: false,
                        isCampaign: false,
                        isDeleted: false,
                        isProcessed: false,
                        jobStatus: JOB_STATUS.NEW,

                        phoneType: step.custom.phoneType,
                        sentBody: '',

                        sequenceId: step.sequenceId,
                        name: step.name,
                        pipelineId,
                        stageId,

                        integrationId: step.custom.settingId,
                        integrationType,
                    },
                },
                isAutomation: true,
                id: taskId,
                performAt: lastDateTime,
                schedule: step.schedule,
                status: TASK_STATUS.SCHEDULED,
                createdAt: serverTimestamp,
                createdBy: userId,
                updatedAt: serverTimestamp,
                updatedBy: userId,
                worker: 'smsSend',
            };

            console.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
            console.log('!!!!!!!!         Calculate the time of the next AUTOMATION STEP              !!!!!!!!');
            console.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');

            const triggerTime = this.calculateTaskSchedule(step, lastDateTime);
            taskData.options.config.immediate = triggerTime.immediate;
            taskData.options.config.dateTime = triggerTime.dateTime;
            taskData.performAt = triggerTime.dateTime;

            console.warn('*** performAt *** :', taskData.options.config.dateTime);
            console.log('createSmsData:', taskData);

            return Promise.resolve(taskData);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    sendSms(sms: SmsSendRequest) {
        try {
            console.log('---------------------------------------------------');
            console.log('typeof sms.dateTime', typeof sms.dateTime);
            console.warn('Sms.Service => sendSms() | sms:', sms);
            console.log('---------------------------------------------------');


            const body = JSON.stringify(sms);
            console.log('SMS Body:', body);

            return this.http
                .post(
                    `${ this.sessionService.END_POINT }/sms/${ this.sessionService.companyId }`,
                    body,
                    { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
                )
                .pipe(
                    map((res: Response) => res),
                    catchError((error: Response) => throwError(error))
                );
        } catch (error) {
            console.error(error);
            throw error;
        }
        // throwError(Error('TODO: Re-Enable the SMS Sending'));
    }

    updateSms(sms: SmsSendRequest, smsId: string) {
        const body = JSON.stringify(sms);
        console.log(body);
        console.log(smsId);

        return this.http
            .patch(
                `${ this.sessionService.END_POINT }/sms/${ this.sessionService.companyId }/${ smsId }`,
                body,
                { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
            )
            .pipe(
                map((res: Response) => res),
                catchError((error: Response) => throwError(error))
            );
    }

    deleteSms(smsId: string) {
        console.log(smsId);
        return this.http
            .delete(
                `${ this.sessionService.END_POINT }/sms/${ this.sessionService.companyId }/${ smsId }`,
                { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
            )
            .pipe(
                map((res: Response) => res),
                catchError((error: Response) => throwError(error))
            );
    }


    calculateTaskSchedule(step: any, lastDateTime: any) {

        const triggerTime = {
            immediate: true,
            dateTime: new Date()
        };

        if (step.schedule.unitTime === 'immediately') {
            console.warn('--- IMMEDIATELY ---');
            triggerTime.immediate = true;
            triggerTime.dateTime = new Date(lastDateTime.getTime() + (ONE_SECOND * 4));
        } else {
            triggerTime.immediate = false;
            if (step.schedule.timeSettingType === 'any_time') {

                console.warn('--- ANY_TIME ---');

                const isAnyDay = this.getIsAnyDay(step.schedule.weekDaySelect);
                if (isAnyDay) {
                    // Add the to current time
                    if (step.schedule.unitTime === 'minutes') {
                        // Add minutes to the time.
                        const offset = (step.schedule.unitValue * ONE_MINUTE);
                        triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);

                    } else if (step.schedule.unitTime === 'hours') {
                        // Add hours to the time.
                        const offset = (step.schedule.unitValue * ONE_HOUR);
                        triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);

                    } else {
                        // Add days to the time.
                        const offset = (step.schedule.unitValue * ONE_DAY);
                        triggerTime.dateTime = new Date(lastDateTime.getTime() + offset);
                    }
                } else {
                    // ONLY specific WEEKDAYS
                }
            }

            // sms.dateTime = new Date().getTime() + (hour_milisecond / 2);  // 30 mins from now.
            // if (this.newSms.get('schedule').value === 'same_day') {
            //    sms.dateTime = new Date(new Date()).getTime() + hour_milisecond + min_milisecond;
            // } else if (this.newSms.get('schedule').value === 'later') {
            //    sms.dateTime = new Date(this.newSms.get('sendingDate').value).getTime() + hour_milisecond + min_milisecond;
            // }
        }

        return triggerTime;
    }


    async getActivityData(activityId: string): Promise<any> {
        // Get all from autoStep where stageId === stageId
        if (typeof activityId === 'undefined' || activityId === null || activityId.trim() === '') {
            return Promise.resolve({ id: null, label: null, name: null });
        }
        const queryActivity = this.afs.firestore
            .collection(COLLECTION.ACTIVITY).doc(activityId);

        return await queryActivity
            .get()
            .then(async (snapshot) => {
                if (!snapshot.exists) {
                    // Do nothing as we didn't find it
                    throw Error('Activity does not exist for id [' + activityId + ']');
                } else {
                    return snapshot.data();
                }
            });
    }

    getContactNumber(numbers: any[], defaultType: string): string {
        try {
            let toNumber = '';
            let type = defaultType;
            if (typeof defaultType === 'undefined' || defaultType === null || defaultType.trim() === '') {
                type = 'cell';
            }

            for (const phoneNumber of numbers) {
                if (type === phoneNumber.type) {
                    toNumber = phoneNumber.cellNo;
                    break;
                }
            }
            if (typeof toNumber !== 'undefined' && toNumber !== null && toNumber.trim() !== '') {
                return toNumber;
            }

            let newType = 'cell';
            if (type !== newType) {
                // Try to get a cell number.
                for (const phoneNumber of numbers) {
                    if (newType === phoneNumber.type) {
                        toNumber = phoneNumber.cellNo;
                        break;
                    }
                }
                if (typeof toNumber !== 'undefined' && toNumber !== null && toNumber.trim() !== '') {
                    return toNumber;
                }
            }

            newType = 'work';
            if (type !== newType) {
                // Try to get a cell number.
                for (const phoneNumber of numbers) {
                    if (newType === phoneNumber.type) {
                        toNumber = phoneNumber.cellNo;
                        break;
                    }
                }
                if (typeof toNumber !== 'undefined' && toNumber !== null && toNumber.trim() !== '') {
                    return toNumber;
                }
            }

            newType = 'home';
            if (type !== newType) {
                // Try to get a cell number.
                for (const phoneNumber of numbers) {
                    if (newType === phoneNumber.type) {
                        toNumber = phoneNumber.cellNo;
                        break;
                    }
                }
                if (typeof toNumber !== 'undefined' && toNumber !== null && toNumber.trim() !== '') {
                    return toNumber;
                }
            }

            return '';
        } catch (error) {
            console.warn(error);
            return '';
        }
    }

    async getDocument(uid: string, collectionName: string): Promise<any> {
        try {
            if (typeof uid === 'undefined' || uid === null) {
                throw Error('Document ID is UNDEFINED or NULL');
            }

            if (typeof collectionName === 'undefined' || collectionName === null) {
                throw Error('Collection ID is UNDEFINED or NULL');
            }

            const queryRef = this.afs.firestore.doc(`${ collectionName }/${ uid }`);
            const data = await queryRef.get()
                .then(querySnapshot => {
                    if (!querySnapshot.exists) {
                        // Do nothing as we didn't find it
                        throw Error('No document exists in collection [' + collectionName + '] with id [' + uid + ']');
                    } else {
                        const docData = querySnapshot.data();
                        if (typeof docData === 'undefined') {
                            throw Error('UNDEFINED document data in collection [' + collectionName + '] with id [' + uid + ']');
                        }
                        docData.id = querySnapshot.id;
                        // console.log(`collection(${collectionName}).doc(${uid}):`, docData);
                        return docData;
                    }
                });
            return Promise.resolve(data);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    getIsAnyDay(weekdays: any): boolean {
        if (weekdays.sunday === true
            && weekdays.monday === true
            && weekdays.tuesday === true
            && weekdays.wednesday === true
            && weekdays.thursday === true
            && weekdays.friday === true
            && weekdays.saturday === true
        ) {
            return true;
        } else {
            return false;
        }
    }

    getAllInboxes(setting: any, lastInboxDate: any | null = null, lessThan: boolean | null) {
        console.log(setting);

        // return of(
        //   [
        //     {
        //       id: 'qqq',
        //       updatedAt: '',
        //       createdAt: '',
        //       senderId: '',
        //       senderType: '',
        //       isDeleted: true,
        //       contactPersonEmail: null,
        //       contactPersonId: null,
        //       contactPersonName: null,
        //       from: setting.number,
        //       to: '+9123456'
        //     },
        //     {
        //       id: 'fdsfsda',
        //       updatedAt: '',
        //       createdAt: '',
        //       senderId: '',
        //       senderType: '',
        //       isDeleted: true,
        //       contactPersonEmail: null,
        //       contactPersonId: '1234ertyujh',
        //       contactPersonName: 'second person',
        //       from: setting.number,
        //       to: '+1125678'
        //     }
        //   ]
        // );

        const settingType = setting.isCompany ? 'company' : 'personal';
        // const settingNumber = setting.phoneNumber
        if (lastInboxDate !== null && setting) {
            return this.http
                .get(
                    `${ this.sessionService.END_POINT }/sms/` +
                    `${ this.sessionService.companyId }/${ settingType }/${ setting.id }/${ setting.number }` +
                    `?dateTime=${ lastInboxDate }&lessThan=${ lessThan }`,
                    { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
                );
        } else {
            return this.http
                .get(
                    `${ this.sessionService.END_POINT }/sms/` +
                    `${ this.sessionService.companyId }/${ settingType }/${ setting.id }/${ setting.number }`,
                    { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
                );
        }
    }

    getAllInboxGroups(setting: any) {

        console.log('@@@@ SMS service -> getAllInboxGroups() @@@@@ ', setting);
        console.log('companyId:', this.sessionService.companyId);
        // console.log('from:', setting.number);

        return this.afs.collection<Inbox>('Groups', ref => ref
            .where('companyId', '==', this.sessionService.companyId)
            // .where('from', '==', setting.number)
            // .orderBy('isRead', 'asc')
            .orderBy('updatedAt', 'desc'))
            .valueChanges();
    }

    // Get the SMS Chat conversation messages
    // USES the message Behaviour Subject


    async markGroup(groupId: string, isRead: boolean = true) {
        try {
            return this.afs.collection(COLLECTION.CHAT_GROUPS).doc(groupId)
                .set({ isRead }, { merge: true });
        } catch (error) {
            return Promise.resolve(false);
        }
    }

    getChatMessages(groupId: string) {

        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        console.warn('SMS Service - getChatMessages() | groupId:', groupId);

        /**
         * TODO: Get messages from Firestore
         */
        if (typeof groupId === 'undefined' || groupId === null || groupId === '') {
            return this.afs.collection(COLLECTION.CHAT_GROUPS).doc('UNKNOWN')
                .collection<IChatMessage>('Chat') // , ref => ref
                .valueChanges();
        }
        return this.afs.collection(COLLECTION.CHAT_GROUPS).doc(groupId)
            .collection<IChatMessage>(COLLECTION.CHAT_GROUP_MESSAGES) // , ref => ref
            // .where('companyId', '==', companyId)
            // .orderBy('updatedAt', 'desc')
            // )
            .valueChanges();
        // .where('companyId', '==', this.sessionService.companyId)
        // .where('from', '==', setting.number)
        // .orderBy('updatedAt', 'desc'))
        /*
        const settingType = setting.isCompany ? 'company' : 'personal';
        if (lastMessageDate !== null) {
          return this.http
            .get(
              `${this.sessionService.END_POINT}/sms/${this.sessionService.companyId}/
              ${ settingType}/${setting.id}/${setting.number}/${groupId}?dateTime=${lastMessageDate.toDateString()}`,
              { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
            ).pipe(
              map((messages: Response) => {
                return messages['response'];
              }),
              catchError((error: Response) => throwError(error))
            );
        } else {
          return this.http
            .get(
              `${this.sessionService.END_POINT}/sms/${this.sessionService.companyId}/`
              + `${settingType}/${setting.id}/${setting.number}/${groupId}`,
              { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
            ).pipe(
              map((messages: Response) => {
                return messages['response'].reverse();
              }),
              catchError((error: Response) => throwError(error))
            );
        }
        */
    }

    markAsRead(groupId: string, messageId: string) {
        if (typeof groupId === 'undefined' || typeof messageId === 'undefined') {
            return;
        }

        return this.afs.collection(COLLECTION.CHAT_GROUPS).doc(groupId)
            .collection(COLLECTION.CHAT_GROUPS).doc(messageId).set({ isRead: true }, { merge: true });
    }

    // Get the SMS Chat conversation messages
    // USES the message Behaviour Subject
    getChatHistory(
        setting: any,
        groupId: string,
        lastMessageDate: Date | null = null,
    ) {

        console.warn('SMS Service - getChatHistory() | groupId:', groupId);

        const settingType = setting.isCompany ? 'company' : 'personal';
        if (lastMessageDate !== null) {
            return this.http
                .get(
                    `${ this.sessionService.END_POINT }/sms/${ this.sessionService.companyId }/
          ${ settingType }/${ setting.id }/${ setting.number }/${ groupId }?dateTime=${ lastMessageDate.toDateString() }`,
                    { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
                ).pipe(
                    map((messages: any) => {
                        this.messages = messages.response;
                        this.messagesBehaviour.next(this.messages);
                    }),
                    catchError((error: Response) => throwError(error))
                );
        } else {
            return this.http
                .get(
                    `${ this.sessionService.END_POINT }/sms/${ this.sessionService.companyId }/`
                    + `${ settingType }/${ setting.id }/${ setting.number }/${ groupId }`,
                    { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
                ).pipe(
                    map((messages: any) => {
                        this.messages = messages.response;
                        this.messagesBehaviour.next(this.messages);
                    }),
                    catchError((error: Response) => throwError(error))
                );
        }
    }

    getAllTags() {
        return this.http
            .get(
                `${ this.sessionService.END_POINT }/company/tags/${ this.sessionService.companyId }`,
                { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
            ).pipe(
                catchError((error: Response) => throwError(error))
            );
    }

    sendCampaign(campaign: AddCampaign) {
        const body = JSON.stringify(campaign);
        console.log(body);
        console.log(this.sessionService.companyId);

        return this.http
            .post(
                `${ this.sessionService.END_POINT }/campaign/add/${ this.sessionService.companyId }`,
                body,
                { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }

            )
            .pipe(
                map((res: Response) => res),
                catchError((error: Response) => throwError(error))
            );
    }

    async getLastChatId(): Promise<string> {
        const companyId = this.sessionService.companyId;
        const userId = this.sessionService.user.value.id;
        const key = 'last-chat-id|' + userId + '|' + companyId;
        return await localforage.getItem<string>(key)
            .then((chatId) => {
              if (isUndefinedOrNullOrEmpty(chatId)) {
                  return '';
              } else {
                  return chatId;
              }
            });
    }

    setLastChatId(chatId: string) {
        const companyId = this.sessionService.companyId;
        const userId = this.sessionService.user.value.id;
        const key = 'last-chat-id|' + userId + '|' + companyId;
        localforage.setItem(key, chatId);
    }

    getSmsByDealId(id: string) {
        return this.http.get(`${ this.sessionService.END_POINT }/sms/${ this.sessionService.companyId }/${ id }`,
            { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
        ).pipe(
            map((res: Response) => res),
            catchError((error: Response) => throwError(error))
        );
    }

    getChats(groupId: string, limit: number) {
        return this.afs
            .collection(COLLECTION.CHAT_GROUPS)
            .doc(groupId)
            .collection(COLLECTION.CHAT_GROUP_MESSAGES, ref => ref
                .limit(limit)
                .orderBy('updatedAt', 'desc'))
            .snapshotChanges();
    }

    more(groupId: string, cursor: any) {
        return this.afs
            .collection(COLLECTION.CHAT_GROUPS)
            .doc(groupId)
            .collection(COLLECTION.CHAT_GROUP_MESSAGES, ref => ref
                .limit(RECORD_LIMIT)
                .orderBy('createdAt', 'desc')
                .startAfter(cursor)
            )
            .snapshotChanges();
    }

    init(path: string, field: string, groupId: string, opts?: any) {
        this.query = {
            path,
            field,
            limit: RECORD_LIMIT,
            reverse: false,
            prepend: false,
            ...opts
        };

        this._data.next([]);
        this.data = this._data.asObservable();
        const first = this.afs.collection(COLLECTION.CHAT_GROUPS).doc(groupId).collection(this.query.path, ref => {
            return ref
                .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                .limit(this.query.limit);
        });
        this.mapAndUpdate(first);
        this.data = this._data.asObservable().pipe(
            scan((acc, val) => {
                console.log('ACC', acc);
                console.log('val', val);
                return this.query.prepend ? val.concat(acc) : acc.concat(val);
            }));
    }

    mapAndUpdate(col: AngularFirestoreCollection<DocumentData>): any {
        if (this._done.value || this._loading.value) { return; }

        // loading
        this._loading.next(true);

        // Map snapshot with doc ref (needed for cursor)
        return col.snapshotChanges()
            .pipe(
                tap(arr => {
                    console.log('data came', arr);

                    let values = arr.map(snap => {
                        const data = snap.payload.doc.data();
                        const doc = snap.payload.doc;
                        return { ...data, doc };
                    });

                    // If prepending, reverse the batch order
                    values = this.query.prepend ? values.reverse() : values;

                    // update source with new values, done loading
                    this._data.next(values);
                    this._loading.next(false);
                    // no more values, mark done
                    if (!values.length) {
                        this._done.next(true);
                    }
                }),
                take(1),
            )
            .subscribe();
    }

    mores(groupId: string) {
        const cursor = this.getCursor();
        console.log(cursor);

        const more = this.afs
            .collection(COLLECTION.CHAT_GROUPS)
            .doc(groupId)
            .collection(this.query.path, ref => {
                return ref
                    .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                    .limit(this.query.limit)
                    .startAfter(cursor);
            });
        this.mapAndUpdate(more);
    }

    // Determines the doc snapshot to paginate query
    private getCursor() {
        const current = this._data.value;
        if (current.length) {
            return this.query.prepend ? current[0].doc : current[current.length - 1].doc;
        }
        return null;
    }

    getMessages(): Promise<Message[]> {
        return Promise.resolve(MESSAGES);
    }

    getMenuItems(): Promise<MenuItem[]> {
        return Promise.resolve(MENU_ITEMS);
    }

}
