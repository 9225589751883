import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
// import { MatTooltip } from '@angular/material/tooltip';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-fab-sticky',
  templateUrl: './fab-sticky.component.html',
  styleUrls: ['./fab-sticky.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FabStickyComponent {

  ttText: string;
  ttDelay: string;

  // tslint:disable-next-line: no-output-native
  @Output() click = new EventEmitter();

  constructor() {
    this.ttText = 'Add new';
    this.ttDelay = '250';
  }
}
