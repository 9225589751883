export class Signup {
    displayName: string;
    email: string;
    password: string;
    companyName: string;
    companyDomain?: string;
    industry: string;
    websiteUrl?: string;
    pipelines?: number;
    teams?: number;
    users?: number;
    renewal?: any;
    isClient?: boolean;

    constructor() {
        this.displayName = '';
        this.email = '';
        this.password = '';
        this.companyName = '';
        this.companyDomain = '';
        this.industry = '';
        this.websiteUrl = '';
        this.pipelines = 3;
        this.teams = 3;
        this.users = 3;
        this.isClient = false;
        this.renewal = new Date();
    }
}
