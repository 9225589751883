import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { ICompany, IContact, IContactItem, IUser, } from '../../models';
import { environment } from '../../../environments/environment';
import { COLLECTION, createAuthHeaders, isUndefinedOrNull, isUndefinedOrNullOrEmpty } from '../../shared/utils';

import { AuthService } from '../auth/auth.service';

const IS_DEBUG = !environment.production;

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private _user: IUser;
  private _company: ICompany;

  private _contact: IContact = null;
  contact = new BehaviorSubject<IContact>(null);

  private _contacts: IContact[] = [];
  contacts = new BehaviorSubject<IContact[]>([]);

  private _contactItems: IContactItem[] = [];
  contactItems = new BehaviorSubject<IContactItem[]>(null);

  endPoint: string;
  apiCloud: string;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    if (!IS_DEBUG) {
      this.endPoint = environment.backendApiUrl;
    } else {
      this.endPoint = environment.backendApiDebugUrl;
    }

    this.apiCloud = environment.cloudUrl; // + '/userGetTeamMembers';

    this.authService.user
      .subscribe(user => {
        if (user) {
          this._user = user;
        } else {
          this._user = null;
        }
      });

    this.authService.company
      .subscribe(async company => {

        if (isUndefinedOrNullOrEmpty(company)) {

          console.log('CONTACT SERVICE | NO COMPANY');

          this._contactItems = [];
          this.contactItems.next(this._contactItems);
          this._contacts = [];
          this.contacts.next(this._contacts);
          this._contact = null;
          this.contact.next(this._contact);
        } else {

          this._company = company;

          console.log('CONTACT SERVICE | we have a company:', company.id);

          // await this.getByCompany(company.id);
          /*
          this.getByCompany(company.id)
            .subscribe(items => {

            });
          */
        }
      });
  }

  private _mapContact(item: any) {
    try {
      const id = item.id;

      let name = item.name;
      if (isUndefinedOrNullOrEmpty(name)) {
        name = item.displayName;
      }
      const nameLcase = name.toLowerCase();
      let photoURL = item.photoURL;
      if (isUndefinedOrNullOrEmpty(photoURL)) {
        photoURL = '';
      }
      let custom = item.custom;
      if (isUndefinedOrNullOrEmpty(custom)) {
        custom = {};
      }
      let customFields = item.customFields;
      if (isUndefinedOrNullOrEmpty(customFields)) {
        customFields = item.customField;
        if (isUndefinedOrNullOrEmpty(customFields)) {
          customFields = {};
        }
      }
      let labelId = item.labelId;
      if (isUndefinedOrNullOrEmpty(labelId)) {
        labelId = item.personLabelId;
      }

      let emails = [];
      let emailAddress: any = {};
      let phones = [];
      let phoneNumber: any = {};

      if (isUndefinedOrNullOrEmpty(item.emails)) {
        emails = [];
        item.emails = emails;
      }
      if (isUndefinedOrNullOrEmpty(item.phones)) {
        phones = [];
        item.phones = phones;
      }

      if ((!isUndefinedOrNull(item.emails) && item.emails.length > 0) && (!isUndefinedOrNull(item.phones) && item.phones.length > 0)) {
        emailAddress = item.emails[0];
        phoneNumber = item.phones[0];
      } else if ((!isUndefinedOrNull(item.Email) && item.Email.length > 0) && (!isUndefinedOrNull(item.Number) && item.Number.length > 0)) {
        emailAddress = item.Email[0];
        phoneNumber = item.Number[0];
      } else if (!isUndefinedOrNull(item.emails) && item.emails.length > 0) {
        emailAddress = item.emails[0];
      } else if (!isUndefinedOrNull(item.phones) && item.phones.length > 0) {
        phoneNumber = item.phones[0];
      } else if (!isUndefinedOrNull(item.Email) && item.Email.length > 0) {
        emailAddress = item.Email[0];
      } else if (!isUndefinedOrNull(item.Number) && item.Number.length > 0) {
        phoneNumber = item.Number[0];
      }

      const contact = Object.assign(item, {
        id, name, nameLcase, photoURL, custom, customFields, labelId,
        email: emailAddress, emails, phone: phoneNumber, phones,
      });

      return contact;

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  add(data: any) {
    try {
      const body = JSON.stringify(data);

      return this.http
        .post(
          `${ this.endPoint }/contact/${ this._company.id }`,
          body,
          { headers: createAuthHeaders(this._user.token) }
        )
        .pipe(
          map((res: Response) => res),
          // catchError((error: Response) => throwError(error))
        );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  delete(contactId: string) {
    try {
      return this.http
        .delete(
          `${ this.endPoint }/contact/${ this._company.id }/${ contactId }`,
          { headers: createAuthHeaders(this._user.token) }
        );
    } catch (error) {
      throw error;
    }
  }

  async get(contactId: string, companyId: string = null) {
    try {
      if (isUndefinedOrNullOrEmpty(companyId)) {
        companyId = this._company.id;
      }

      return await firebase.firestore()
        .collection(COLLECTION.CONTACT)
        .doc(contactId)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            const docData = snapshot.data();
            docData.id = snapshot.id;

            if (docData.companyId !== companyId) {
              this._contact = null;
              this.contact.next(this._contact);
              throw Error('company ID does not match.');
            }

            const contact = this._mapContact(docData);
            this._contact = null;
            this.contact.next(this._contact);
            return contact;
          }
        })
        .catch((error: any) => {
          console.error(error);
          return null;
        });
    } catch (error) {
      console.error(error);
      this._contact = null;
      this.contact.next(this._contact);
      throw error;
    }
  }

  getOne(contactId: string) {
    try {
      return this.http
        .get(
          `${ this.endPoint }/contact/get/${ this._company.id }/${ contactId }`,
          { headers: createAuthHeaders(this._user.token) }
        )
        .pipe(
          map((response: any) => {
            const item = response.data;

            if (typeof item === 'undefined') {
              this._contact = null;
              this.contact.next(this._contact);
              return null;
            } else {
              const contact = this._mapContact(item);
              this._contact = contact;
              this.contact.next(this._contact);
              return contact;
            }
          })
        );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getContact(contactId: string) {
    try {
      const contact = this._contacts.find(item => item.id === contactId);
      if (isUndefinedOrNullOrEmpty(contact)) {
        return null;
      }
      return contact;
    } catch (error) {
      throw error;
    }
  }

  async getByCompany(companyId: string) {
    try {
      return await firebase.firestore()
        .collection(COLLECTION.CONTACT)
        .where('isDeleted', '==', false)
        .where('companyId', '==', companyId)
        .orderBy('displayName', 'asc')
        .get()
        .then(snapshots => {
          if (snapshots.empty) {
            this._contactItems = [];
            this._contacts = [];
            this.contacts.next(this._contacts);
            return [];
          }

          for (const snapshot of snapshots.docs) {
            if (snapshot.exists) {
              const docData = snapshot.data();
              docData.id = snapshot.id;

              const contact = this._mapContact(docData);
              this._contacts.push(contact);

              const contactItem = {
                id: contact.id,
                name: contact.name,
                nameLcase: contact.name.toLowerCase(),
                photoURL: contact.photoURL,
                labelId: contact.lableId,
                email: contact.email,
                emails: contact.emails,
                phone: contact.phone,
                phones: contact.phones,
              };
              this._contactItems.push(contactItem);
            }
          }

          this._contactItems = this._contactItems.sort((a: any, b: any) => {
            return a.nameLcase < b.name ? -1 : (a.nameLcase === b.nameLcase ? 0 : 1);
          });
          this._contacts = this._contacts.sort((a: any, b: any) => {
            return a.nameLcase < b.nameLcase ? -1 : (a.nameLcase === b.nameLcase ? 0 : 1);
          });

          // Notify components of the updated Contact List
          this.contacts.next(this._contacts);
          this.contactItems.next(this._contactItems);
          return this._contacts;
        })
        .catch((error: any) => {
          console.error(error);
          return [];
        });
    } catch (error) {
      console.error(error);
      this._contact = null;
      this.contact.next(this._contact);
      throw error;
    }
  }

  getByOrganization(orgId: string) {
    try {
      return this.http
        .get(
          `${ this.endPoint }/contact/getbyOrganization/${ this._company.id }/${ orgId }`,
          { headers: createAuthHeaders(this._user.token) }
        )
        .pipe(
          map((response: any) => {

            let items = response.data;
            if (typeof items === 'undefined') {
              this._contacts = [];
            } else {

              items = items.data.map(item => {

                const contact = this._mapContact(item);
                this._contacts.push(contact);

                const contactItem = {
                  id: contact.id, name: contact.name, photoURL: contact.photoURL, labelId: contact.lableId,
                  email: contact.email, emails: contact.emails, phone: contact.phone, phones: contact.phones,
                };
                this._contactItems.push(contactItem);

              });
            }

            this._contactItems = this._contactItems.sort((a: any, b: any) => {
              return a.name < b.name ? -1 : (a.name === b.name ? 0 : 1);
            });
            this._contacts = this._contacts.sort((a: any, b: any) => {
              return a.name < b.name ? -1 : (a.name === b.name ? 0 : 1);
            });


            // Notify components of the updated Contact List
            this.contactItems.next(this._contactItems);
            this.contacts.next(this._contacts);
            return this._contacts;
          })
        );

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  update(data: any) {
    try {
      const body = JSON.stringify(Object.assign(data));

      return this.http
        .patch(
          `${ this.endPoint }/contact/${ this._company.id }/${ data.id }`,
          body,
          { headers: createAuthHeaders(this._user.token) }
        );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  addTag(tag: any) {
    try {
      const body = JSON.stringify(tag);

      return this.http
        .post(
          `${ this.endPoint }/company/addTag/${ this._company.id }`,
          body,
          { headers: createAuthHeaders(this._user.token) }
        );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
