import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';

// import { SubUserRequest, SubUserResponse, SubUserRequestNew, SubUserReponseNew, AddUserByInviteRequest } from '../../models';
// import { User, IUser, Company, Pipeline, PipelineInfo, PermissionGroup, Stage, Permissions, ActivityTemplate } from '../../models';
import { IUser, IUserLite, ICompany, IPipeline, IPermissionGroup, IStage, IPermissions, IActivityTemplate } from '../../models';
import { COLLECTION, arrayUnique, isAllowedTo, isUndefinedOrNull, isUndefinedOrNullOrEmpty, createAuthHeaders } from '../../shared/utils';

import { AuthService } from '../auth/auth.service';

// import uuidv4 from 'uuid/v4';
const { 'v4': uuidv4 } = require('uuid');

const IS_DEBUG = !environment.production;
const END_POINT_CLOUD = environment.cloudUrl;

const AUTH_TOKEN_NOT_FOUND = 'Auth token not found.';
const COMPANY_ID_NOT_FOUND = 'Company ID not found.';
const COMPANY_NOT_FOUND = 'Company not found.';
const PERMISSION_GROUP_ID_NOT_FOUND = 'Permission Group ID not found.';
const PERMISSION_GROUP_NOT_FOUND = 'Permission Group not found.';
const PERMISSION_GROUP_NAME_NOT_FOUND = 'Permission Group Name not found.';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  endPoint: string;

  CompanyArray: ICompany[] = [];
  CompanyBehavior = new BehaviorSubject<ICompany[]>([]);

  private _permissionGroups: IPermissionGroup[] = [];
  permissionGroups = new BehaviorSubject<IPermissionGroup[]>([]);

  private _pipelines: IPipeline[] = [];
  pipelines = new BehaviorSubject<IPipeline[]>([]);

  private _pipelineTemplates: IPipeline[] = [];
  pipelineTemplates = new BehaviorSubject<IPipeline[]>([]);

  private _pipelineMarketplace: IPipeline[] = [];
  pipelineMarketplace = new BehaviorSubject<IPipeline[]>([]);

  private _users: IUser[] = [];
  users = new BehaviorSubject<IUser[]>([]);

  activityTemplate: IActivityTemplate[] = [
    {
      id: 'one',
      activityDuration: '',
      activityNote: '',
      activityTime: '',
      activityTitle: 'Test Meetup',
      activityType: 'Meeting',
      notifyAfter: 0,
      pipelines: [{
        id: 'Qx6ytAQ6',
        name: 'Mypipeline',
        createdAt: new Date(),
        updatedAt: new Date(),
        stages: [
          {
            id: 'wCrIe3bR',
            name: 'Contact Made',
            url: '',
            isContacted: true,
            isPending: false,
            isMeeting: false,
            isWon: false,
          },
          {
            id: 'UlDKRuHq',
            name: 'Demo Scheduled',
            url: '',
            isContacted: false,
            isPending: false,
            isMeeting: true,
            isWon: false,
          },
          {
            id: '8vkGnSKC',
            name: 'Proposal Made',
            url: '',
            isContacted: false,
            isPending: true,
            isMeeting: false,
            isWon: false,
          },
          {
            id: '60j8HH54',
            name: 'Negotiation Started',
            url: '',
            isContacted: false,
            isPending: false,
            isMeeting: false,
            isWon: true,
          }
        ],
        companyId: '',
        companyName: '',
        custom: {
          isCreateNewClientOnWin: false,
          pricePerAppt: 1500,
        },
        teamId: 'sf45fdg',
        teamName: 'test'
      }]
    },
    {
      id: 'two',
      activityDuration: '',
      activityNote: '',
      activityTime: '',
      activityTitle: 'Test Gathering',
      activityType: 'Call',
      notifyAfter: 0,
      pipelines: [{
        id: 'uQ7grTGq',
        name: 'Your Pipeline',
        createdAt: new Date(),
        updatedAt: new Date(),
        stages: [{
          id: '7YZvYIlH',
          name: 'Start',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: false,
          isWon: false,
        }],
        companyId: '',
        companyName: '',
        custom: {
          isCreateNewClientOnWin: false,
          pricePerAppt: 1500,
        },
        teamId: 'sf45fdg',
        teamName: 'test',
      },
      {
        id: 'Qx6ytAQ6',
        name: 'Mypipeline',
        nameLcase: 'xxxxxx',
        createdAt: new Date(),
        updatedAt: new Date(),
        stages: [{
          id: 'A4ApkXuY',
          name: 'Leads in ',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: false,
          isWon: false,
        },
        {
          id: 'wCrIe3bR',
          name: 'Contact Made',
          url: '',
          isContacted: true,
          isPending: false,
          isMeeting: false,
          isWon: false,
        },
        {
          id: 'UlDKRuHq',
          name: 'Demo Scheduled',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: true,
          isWon: false,
        },
        {
          id: '8vkGnSKC',
          name: 'Proposal Made',
          url: '',
          isContacted: false,
          isPending: true,
          isMeeting: false,
          isWon: false,
        },
        {
          id: '60j8HH54',
          name: 'Negotiation Started',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: false,
          isWon: true,
        }],
        companyId: '',
        companyName: '',
        custom: {
          isCreateNewClientOnWin: false,
          pricePerAppt: 1500,
        },
        teamId: 'sf45fdg',
        teamName: 'test'
      }]
    }
  ];

  activityTemplateBehavior = new BehaviorSubject<IActivityTemplate[]>([
    {
      id: 'one',
      activityDuration: '',
      activityNote: '',
      activityTime: '',
      activityTitle: 'Test Meetup',
      activityType: 'Meeting',
      notifyAfter: 0,
      pipelines: [{
        id: 'Qx6ytAQ6',
        name: 'Mypipeline',
        createdAt: new Date(),
        updatedAt: new Date(),
        stages: [
          {
            id: 'wCrIe3bR',
            name: 'Contact Made',
            url: '',
            isContacted: true,
            isPending: false,
            isMeeting: false,
            isWon: false,
          },
          {
            id: 'UlDKRuHq',
            name: 'Demo Scheduled',
            url: '',
            isContacted: false,
            isPending: false,
            isMeeting: true,
            isWon: false,
          },
          {
            id: '8vkGnSKC',
            name: 'Proposal Made',
            url: '',
            isContacted: false,
            isPending: true,
            isMeeting: false,
            isWon: false,
          },
          {
            id: '60j8HH54',
            name: 'Negotiation Started',
            url: '',
            isContacted: false,
            isPending: false,
            isMeeting: false,
            isWon: true,
          }
        ],
        companyId: '',
        companyName: '',
        custom: {
          isCreateNewClientOnWin: false,
          pricePerAppt: 1500,
        },
        teamId: 'sf45fdg',
        teamName: 'test'
      }]
    },
    {
      id: 'two',
      activityDuration: '',
      activityNote: '',
      activityTime: '',
      activityTitle: 'Test Gathering',
      activityType: 'Call',
      notifyAfter: 0,
      pipelines: [{
        id: 'uQ7grTGq',
        name: 'Your Pipeline',
        createdAt: new Date(),
        updatedAt: new Date(),
        stages: [{
          id: '7YZvYIlH',
          name: 'Start',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: false,
          isWon: false,
        }],
        companyId: '',
        companyName: '',
        custom: {
          isCreateNewClientOnWin: false,
          pricePerAppt: 1500,
        },
        teamId: 'sf45fdg',
        teamName: 'test'
      },
      {
        id: 'Qx6ytAQ6',
        name: 'Mypipeline',
        createdAt: new Date(),
        updatedAt: new Date(),
        stages: [{
          id: 'A4ApkXuY',
          name: 'Leads in ',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: false,
          isWon: false,
        },
        {
          id: 'wCrIe3bR',
          name: 'Contact Made',
          url: '',
          isContacted: true,
          isPending: false,
          isMeeting: false,
          isWon: false,
        },
        {
          id: 'UlDKRuHq',
          name: 'Demo Scheduled',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: true,
          isWon: false,
        },
        {
          id: '8vkGnSKC',
          name: 'Proposal Made',
          url: '',
          isContacted: false,
          isPending: true,
          isMeeting: false,
          isWon: false,
        },
        {
          id: '60j8HH54',
          name: 'Negotiation Started',
          url: '',
          isContacted: false,
          isPending: false,
          isMeeting: false,
          isWon: true,
        }],
        companyId: '',
        companyName: '',
        custom: {
          isCreateNewClientOnWin: false,
          pricePerAppt: 1500,
        },
        teamId: 'sf45fdg',
        teamName: 'test'
      }]
    }
  ]);

  private _usersLite: IUserLite[] = [];
  usersLite = new BehaviorSubject<IUserLite[]>([]);

  user: IUser;
  company: ICompany;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    if (!IS_DEBUG) {
      this.endPoint = environment.backendApiUrl;
    } else {
      this.endPoint = environment.backendApiDebugUrl;
    }

    this._pipelines = [];
    this.pipelines.next(this._pipelines);

    this.usersLite
      .subscribe(value => {
        this._usersLite = value;
      });

    this.authService.company
      .subscribe(company => {
        this.company = company;
        if (company) {
          this._pipelines = this.company.pipelines;
        } else {
          this._pipelines = [];
        }
        this.pipelines.next(this._pipelines);
      });

    this.authService.user
      .subscribe(user => {
        this.user = user;
      });
  }

  private _createPipelineCustomData(item: any = null) {
    const oldCustomItems = {
      photoURL: (isUndefinedOrNullOrEmpty(item.custom) || isUndefinedOrNullOrEmpty(item.custom.photoURL))
        ? '' : item.custom.photoURL,
      pricePerAppt: (isUndefinedOrNullOrEmpty(item.custom) || isUndefinedOrNullOrEmpty(item.custom.pricePerAppt))
        ? item.pricePerAppt : item.custom.pricePerAppt,
      isCreateNewClientOnWin: (isUndefinedOrNullOrEmpty(item.custom) || isUndefinedOrNullOrEmpty(item.custom.isCreateNewClientOnWin))
        ? item.isCreateNewClientOnWin : item.custom.isCreateNewClientOnWin,
      isTemplate: (isUndefinedOrNullOrEmpty(item.custom) || isUndefinedOrNullOrEmpty(item.custom.isTemplate))
        ? false : item.custom.isTemplate,
      templateId: (isUndefinedOrNullOrEmpty(item.custom) || isUndefinedOrNullOrEmpty(item.custom.templateId))
        ? '' : item.custom.templateId,
      isPublished: (isUndefinedOrNullOrEmpty(item.custom) || isUndefinedOrNullOrEmpty(item.custom.isPublished))
        ? false : item.custom.isPublished,
      publishedId: (isUndefinedOrNullOrEmpty(item.custom) || isUndefinedOrNullOrEmpty(item.custom.publishedId))
        ? '' : item.custom.publishedId,
    };
    const customItems = isUndefinedOrNullOrEmpty(item.custom) ? {} : item.custom;
    return Object.assign(oldCustomItems, customItems);
  }

  private _findPermisionGroupId(groups: any[], name: string): string {
    const group = groups.find(item => item.permissionGroupName === name);
    if (typeof group === 'undefined') {
      return null;
    }
    return group.id;
  }

  private _mapCompany(item: any) {
    try {
      // tslint:disable-next-line: no-shadowed-variable
      let id = item.id;
      if (isUndefinedOrNull(id)) {
        id = item.pipelineId;
      }
      let name = item.name;
      if (isUndefinedOrNullOrEmpty(name)) {
        name = item.companyName;
      }
      const nameLcase = name.toLowerCase();
      let photoURL = item.photoURL;
      if (isUndefinedOrNullOrEmpty(photoURL)) {
        if (!isUndefinedOrNullOrEmpty(item.custom) && !isUndefinedOrNullOrEmpty(item.custom.photoURL)) {
          photoURL = item.custom.photoURL;
        } else if (!isUndefinedOrNullOrEmpty(this.user.photoURL)) {
          photoURL = this.user.photoURL;
        } else {
          photoURL = '';
        }
      } else {
        photoURL = item.photoURL;
      }
      let custom = item.custom;
      if (isUndefinedOrNullOrEmpty(custom)) {
        custom = this._createPipelineCustomData(item);
      }

      // tslint:disable-next-line: no-shadowed-variable
      let pipelines = [];
      if (!isUndefinedOrNullOrEmpty(item.pipelines)) {
        pipelines = item.pipelines.map((pipeline: any) => this._mapPipeline(pipeline, id, name));
      } else {
        pipelines = item.pipeline.map((pipeline: any) => this._mapPipeline(pipeline, id, name));
      }

      const company: ICompany = Object.assign(item, {
        id,
        name,
        nameLcase,
        photoURL,
        pipelines,
        adminUsers: item.adminAssigned,
        allUsers: item.userAssigned,
        isClient: typeof item.isClient === 'undefined' ? false : item.isClient,
        ownerId: item.ownerId,
        settings: item.globalSetting,
        website: item.websiteUrl,
        industry: item.industry,
        domain: item.companyDomain,
        teams: [],
        permissionGroups: [],
        // userRoles: typeof data.userRoles === 'undefined' ? [] : data.userRoles,
        userPermissions: typeof item.userPermissions === 'undefined' ? [] : item.userPermissions,
        isDeleted: isUndefinedOrNullOrEmpty(item.isDeleted) ? false : item.isDeleted,
        createdAt: item.createdAt, createdBy: item.createdBy, updatedAt: item.updatedAt, updatedBy: item.updatedBy,
      });

      return company;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private _mapPipeline(item: any, companyId: string, companyName: string) {
    try {
      // tslint:disable-next-line: no-shadowed-variable
      let id = item.id;
      if (isUndefinedOrNull(id)) {
        id = item.pipelineId;
      }
      let name = item.name;
      if (isUndefinedOrNullOrEmpty(name)) {
        name = item.pipelineName;
      }
      if (isUndefinedOrNullOrEmpty(name)) {
        name = '';
      }
      const nameLcase = name.toLowerCase();
      let photoURL = item.photoURL;
      if (isUndefinedOrNullOrEmpty(photoURL)) {
        photoURL = '';
      }
      let custom = item.custom;
      if (isUndefinedOrNullOrEmpty(custom)) {
        custom = this._createPipelineCustomData(item);
      }
      let pricePerAppt = item.pricePerAppt;
      if (isUndefinedOrNullOrEmpty(pricePerAppt)) {
        pricePerAppt = 1500;
      }

      let stages = item.stage;
      if (isUndefinedOrNullOrEmpty(stages)) {
        if (isUndefinedOrNullOrEmpty(item.stage)) {
          stages = item.stage;
        }
      }
      stages = this._mapStages(stages, id, companyId);

      const pipeline: IPipeline = Object.assign(item, {
        id, name, nameLcase, photoURL, custom, pricePerAppt, stages,

        companyId,
        companyName,

        teamId: item.teamId,
        teamName: item.teamName,

        index: isUndefinedOrNullOrEmpty(item.index) ? 0 : item.index,
        isDeleted: isUndefinedOrNullOrEmpty(item.isDeleted) ? false : item.isDeleted,

        createdAt: item.createdAt, createdBy: item.createdBy, updatedAt: item.updatedAt, updatedBy: item.updatedBy,
      });

      return pipeline;

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private _mapStages(stages: any = null, pipelineId: string, companyId: string) {
    try {

      if (isUndefinedOrNullOrEmpty(stages)) {
        return [];
      }

      return stages.map(stage => {

        // tslint:disable-next-line: no-shadowed-variable
        let id = stage.id;
        if (isUndefinedOrNull(id)) {
          id = stage.stageId;
        }
        let name = stage.name;
        if (isUndefinedOrNullOrEmpty(name)) {
          name = stage.stageName;
        }

        let isContacted = stage.isContacted;
        if (isUndefinedOrNullOrEmpty(isContacted)) {
          isContacted = false;
        }
        let isPending = stage.isPending;
        if (isUndefinedOrNullOrEmpty(isPending)) {
          isPending = false;
        }
        let isMeeting = stage.isMeeting;
        if (isUndefinedOrNullOrEmpty(isMeeting)) {
          isMeeting = false;
        }
        let isWon = stage.isWon;
        if (isUndefinedOrNullOrEmpty(isWon)) {
          isWon = false;
        }

        let stageStatus = stage.status;
        if (isUndefinedOrNullOrEmpty(status)) {
          stageStatus = '';
          if (isContacted) {
            stageStatus = 'contacted';
          } else if (isPending) {
            stageStatus = 'pending';
          } else if (isMeeting) {
            stageStatus = 'meeting';
          } else if (isWon) {
            stageStatus = 'won';
          }
        }
        const url = isUndefinedOrNullOrEmpty(stage.url) ? '' : stage.url;

        const data: IStage = {
          pipelineId,
          companyId,
          id,
          name,
          nameLcase: name.toLowerCase(),
          url,

          // Internal Identifiers for automations and logic
          isContacted,
          isPending,
          isMeeting,            // Marked as optional
          isWon,                // Marked as optional

          // NEW identifier for automation and logic
          status: stageStatus,  // Marked as optional
          class: isUndefinedOrNullOrEmpty(stage.class) ? '' : stage.class,
          custom: isUndefinedOrNullOrEmpty(stage.custom) ? null : stage.custom,
        };
        return data;
      });
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  private _validateCompanPipelines(pipelines: any[] = []) {
    pipelines = pipelines.filter(pipeline => pipeline.isDeleted !== true);
    pipelines = pipelines.sort((a, b) => {
      if (typeof a.index === 'undefined') {
        if (typeof b.index === 'undefined') {
          return 0;
        } else {
          return -1;
        }
      } else {
        if (typeof b.index === 'undefined') {
          return 1;
        } else {
          return a.index - b.index;
        }
      }
    });

    // Ensure sequential order of pipelines.
    const newPipelines: any[] = [];
    for (let index = 0; index < pipelines.length; index++) {
      pipelines[index].index = index;
      newPipelines.push(pipelines[index]);
    }
    return newPipelines;
  }

  // #############################################################################################################
  // Manage Activity Template (CRUD)
  // #############################################################################################################

  addActivityTemplate(title: string, type: string, notifyAfter: number, time: string, pipelines: IPipeline[]) {
    this.activityTemplateBehavior.next(this.activityTemplate);
    console.log('Behavior ', this.activityTemplateBehavior);
    console.log('Behavior ', this.activityTemplate);
  }

  getAllActivityTempaplates() {
    return this.activityTemplateBehavior.next(this.activityTemplate);
  }

  getActivityTemplateForEdit(id: string) {
    const index = this.activityTemplate.findIndex(item => item.id === id);
    return this.activityTemplate[index];
  }


  // #############################################################################################################
  // Manage Users CRUD
  // #############################################################################################################

  updateLoggedInUser(displayName: string, countryCode: string, phoneNumber: string, uid: string, photoURL: string) {
    const user = {
      displayName,
      countryCode,
      phoneNumber,
      uid,
      photoURL,
    };
    const body = JSON.stringify(user);
    return this.http
      .post(
        this.endPoint + `/user/updateProfile`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        map((res: any) => res),
        catchError((error: any) => throwError(error)),
      );
  }

  async deleteUser(userId: string) {
    return this.http
      .delete(
        `${ this.endPoint }/user/${ userId }/${ this.company.id }`,
        { headers: createAuthHeaders(this.user.token) },
      )
      .pipe(
        catchError((error: HttpErrorResponse) => throwError(error)),
      );
    /*
    // getUsers(userId: string) {
    console.log('CALL -> getUsers()');
    const api_url = END_POINT_CLOUD + '/users';
    const headers = new HttpHeaders()
      .set('x-firebase-id-token', await this.authService.authUser.getIdToken());
    return this.http.delete(`${api_url}/${userId}`, { headers: headers });
    */
  }

  getUsers(currentCompanyId: string) {

    console.log('Data.Service -> getUsers() | currentCompanyId = ', currentCompanyId);

    this._users = [];
    this.users.next(this._users);
    return this.http
      .get(
        this.endPoint + `/user/getUsersByCompany/${ currentCompanyId }`,
        { headers: createAuthHeaders(this.user.token) },
      )
      .pipe(
        catchError((error: Response) => throwError(error)),
      )
      .subscribe(data => {
        const allUsers = JSON.parse(JSON.stringify(data));
        this._users = [];
        const users = allUsers;
        console.log('Data.Service -> getUsers() | allUsers = ', allUsers);
        users.response.map(user => {
          console.log('Data.Service -> getUsers() | users = ', user);
          this._users.push(user);
        }
        );
        this.users.next(this._users);
      });
  }

  getAllUsers() {
    return this.users;
  }

  getUserForEdit(id: string) {
    console.log('Data.Service -> getUserForEdit() | id = ', id);
    console.log('Data.Service -> getUserForEdit() | _users = ', this._users);
    const index = this._users.findIndex(item => item.id === id);
    return this._users[index];
  }

  addUser(name: string, email: string, companyId: string, permisionGroupId: string) {
    const user = {
      userEmail: email,
      displayName: name,
      companyId: this.company.id,
      permissionGroupId: permisionGroupId,
    };
    console.log('Data.Service -> addUser() | user = ', user);

    const body = JSON.stringify(user);
    return this.http
      .post(
        this.endPoint + `/user/add`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        catchError((error: Response) => throwError(error)),
      ).subscribe(data => {
        console.log(data);
        this._users.push(JSON.parse(JSON.stringify(data)));
        this.users.next(this._users);
      });
  }

  editUser(uid: string, permissionGroupId: string) {
    const user = {
      permissionGroupId
    };
    console.log('Data.Service -> editUser() | user = ', user);
    console.log('Data.Service -> editUser() | permissionGroupId = ', permissionGroupId);

    const body = JSON.stringify(user);
    return this.http
      .post(
        this.endPoint + `/user/updatePermission/${ this.company.id }/${ uid }`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        catchError((error: Response) => throwError(error)),
      ).subscribe(data => {
        console.log(data);
        console.log(this._users);
        const index = this._users.findIndex(item => item.id === uid);
        this._users[index] = JSON.parse(JSON.stringify(data));
        console.log(this._users);
        this.users.next(this._users);
      });
  }

  editUserIsAdmin(uid: string, isAdmin: boolean) {
    console.log('Data.Service -> editUserIsAdmin() | uid = ', uid);
    console.log('Data.Service -> editUserIsAdmin() | isAdmin = ', isAdmin);

    const body = '{ "isAdmin":' + isAdmin + '}';
    return this.http
      .post(
        this.endPoint + `/company/makeAdmin/${ this.company.id }/${ uid }`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        catchError((error: Response) => throwError(error)),
      );
  }

  // #############################################################################################################
  // Manage Pipelines CRUD
  // #############################################################################################################

  public getPipelines(currentCompanyId: string) {
    return this.http
      .get(
        this.endPoint + `/pipeline/${ currentCompanyId }`,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        map((res: any) => {
          if (res) {
            this._pipelines = res.response.sort((a: any, b: any) => {
              if (typeof a.index === 'undefined') {
                if (typeof b.index === 'undefined') {
                  return 0;
                } else {
                  return -1;
                }
              } else {
                if (typeof b.index === 'undefined') {
                  return 1;
                } else {
                  return a.index - b.index;
                }
              }
            });
            this.pipelines.next(this._pipelines);
          } else {
            this._pipelines = [];
            this.pipelines.next(this._pipelines);
          }
          return res;
        }),
        catchError((error: Response) => throwError(error)),
      );
  }

  async getPipelinesNew(companyId: string = null, source: string = 'company', showDeleted: boolean = false) {
    if (isUndefinedOrNullOrEmpty(companyId)) {
      companyId = this.company.id;
    }

    if (source === 'company') {
      return await firebase.firestore()
        .collection(COLLECTION.COMPANY)
        .doc(companyId)
        .get()
        .then(snapshot => {
          this._pipelines = [];

          if (!snapshot.exists) {
            throw Error('no company');
          }

          const docData = snapshot.data();
          if (typeof docData !== 'undefined') {
            // tslint:disable-next-line: no-shadowed-variable
            docData.id = snapshot.id;
            const company = this._mapCompany(docData);

            if (showDeleted) {
              this._pipelines = company.pipelines;
            } else {
              this._pipelines = company.pipelines.filter(item => item.isDeleted === false);
            }
          }

          if (this._pipelines.length > 1) {
            this._pipelines = this._pipelines.sort((a, b) => {
              if (typeof a.index === 'undefined') {
                if (typeof b.index === 'undefined') {
                  return 0;
                } else {
                  return -1;
                }
              } else {
                if (typeof b.index === 'undefined') {
                  return 1;
                } else {
                  return a.index - b.index;
                }
              }
            });
          }

          this.pipelines.next(this._pipelines);
          return this._pipelines;
        })
        .catch((error: any) => {
          console.error(error);
          this._pipelines = [];
          this.pipelines.next(this._pipelines);
          return this._pipelines;
        });

    } else if (source === 'template') {
      return await firebase.firestore()
        .collection('templates')
        .doc(companyId)
        .collection('pipelines')
        .get()
        .then(snapshots => {
          const pipelineTemplates: any[] = [];

          if (snapshots.empty) {
            throw Error('no templates');
          }

          for (const snapshot of snapshots.docs) {
            const docData = snapshot.data();
            docData.id = snapshot.id;

            let companyName = docData.name;
            if (isUndefinedOrNullOrEmpty(companyName)) {
              companyName = docData.companyName;
            }

            pipelineTemplates.push(this._mapPipeline(docData, companyId, companyName));
          }

          if (pipelineTemplates.length > 1) {
            this._pipelineTemplates = pipelineTemplates.sort((a, b) => {
              if (typeof a.index === 'undefined') {
                if (typeof b.index === 'undefined') {
                  return 0;
                } else {
                  return -1;
                }
              } else {
                if (typeof b.index === 'undefined') {
                  return 1;
                } else {
                  return a.index - b.index;
                }
              }
            });
          } else {
            this._pipelineTemplates = pipelineTemplates;
          }

          this.pipelineTemplates.next(this._pipelineTemplates);
          return this._pipelineTemplates;
        })
        .catch((error: any) => {
          console.error(error);
          this._pipelineTemplates = [];
          this.pipelineTemplates.next(this._pipelineTemplates);
          return this._pipelineTemplates;
        });

    } else if (source === 'marketplace') {
      return await firebase.firestore()
        .collection('marketplace')
        .doc('leadcarrot')
        .collection('pipelines')
        .get()
        .then(snapshots => {
          const pipelineMarketplace: any[] = [];

          if (snapshots.empty) {
            throw Error('no templates');
          }

          for (const snapshot of snapshots.docs) {
            const docData = snapshot.data();
            docData.id = snapshot.id;

            let companyName = docData.name;
            if (isUndefinedOrNullOrEmpty(companyName)) {
              companyName = docData.companyName;
            }

            pipelineMarketplace.push(this._mapPipeline(docData, companyId, companyName));
          }

          if (pipelineMarketplace.length > 1) {
            this._pipelineMarketplace = pipelineMarketplace.sort((a: any, b: any) => {
              if (typeof a.index === 'undefined') {
                if (typeof b.index === 'undefined') {
                  return 0;
                } else {
                  return -1;
                }
              } else {
                if (typeof b.index === 'undefined') {
                  return 1;
                } else {
                  return a.index - b.index;
                }
              }
            });
          } else {
            this._pipelineMarketplace = pipelineMarketplace;
          }

          this.pipelineMarketplace.next(this._pipelineMarketplace);
          return this._pipelineMarketplace;
        })
        .catch((error: any) => {
          console.error(error);
          this._pipelineMarketplace = [];
          this.pipelineMarketplace.next(this._pipelineMarketplace);
          return this._pipelineMarketplace;
        });
    }
  }

  getAllPipelines() {
    return this.pipelines;
  }

  async getCompany(companyId: string): Promise<ICompany> {
    return await firebase.firestore()
      .collection(COLLECTION.COMPANY)
      .doc(companyId)
      .get()
      .then(snapshot => {
        this._pipelines = [];

        if (!snapshot.exists) {
          throw Error('no company');
        }

        const docData = snapshot.data();
        if (typeof docData !== 'undefined') {
          // tslint:disable-next-line: no-shadowed-variable
          docData.id = snapshot.id;
          const company = this._mapCompany(docData);

          company.pipelines = company.pipelines.filter(item => item.isDeleted === false);
          if (company.pipelines.length > 1) {
            company.pipelines = company.pipelines.sort((a, b) => {
              if (typeof a.index === 'undefined') {
                if (typeof b.index === 'undefined') {
                  return 0;
                } else {
                  return -1;
                }
              } else {
                if (typeof b.index === 'undefined') {
                  return 1;
                } else {
                  return a.index - b.index;
                }
              }
            });
          }

          return company;
        }
      })
      .catch((error: any) => {
        console.error(error);
        return Promise.resolve(null);
      });
  }

  addPipeline(
    name: string,
    teamId: string,
    stages: IStage[],
    isCreateNewClientOnWin: boolean,
    pricePerAppt: number = 1500,
  ) {
    const pipeline = {
      name,
      stage: stages,
      teamId,
      isCreateNewClientOnWin,
      pricePerAppt,
    };
    console.log('Data.Service -> addPipeline() | pipeline = ', pipeline);

    const body = JSON.stringify(pipeline);
    console.log('addPipeline() -> body', body);

    return this.http
      .post(
        this.endPoint + `/pipeline/${ this.company.id }`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        catchError((error: Response) => throwError(error)),
      );
  }

  editPipeline(
    name: string,
    teamId: string,
    stages: IStage[],
    isCreateNewClientOnWin: boolean,
    pipelineId: string,
    pricePerAppt: number = 1500,
  ) {
    const pipeline = {
      id: pipelineId,
      name,
      stage: stages,
      teamId,
      isCreateNewClientOnWin,
      pricePerAppt,
    };

    console.log('Data.Service -> editPipeline() | pipeline = ', pipeline);

    const body = JSON.stringify(pipeline);
    return this.http
      .post(
        this.endPoint + `/pipeline/update/${ this.company.id }/` + pipelineId,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe<any>(
        catchError((error: Response) => throwError(error)),
      )
      .subscribe(data => {
        console.log('Data.Service -> editPipeline() | Edit Successful | pipeline = ', pipeline);

        const index = this._pipelines.findIndex(item => item.id === pipelineId);
        // this._pipelines[index] = pipe;
        this._pipelines[index] = data.response;
        this.pipelines.next(this._pipelines);
        console.log(this._pipelines);

        const pipelineIndex = this.company.pipelines.find(item => item.id === pipelineId);
        if (pipelineIndex > -1) {
          const companyData = this.company;
          companyData.pipelines[pipelineIndex] = pipeline;
          this.authService.company.next(companyData);

          console.warn('Pipeline updated successfully');
        }
      });
  }

  async clonePipeline(
    id: string,
    source: string = 'company',
    companyId: string = null,
    teamId: string = null,
    teamName: string = null,
  ) {
    try {
      if (isUndefinedOrNullOrEmpty(id)) {
        throw Error('Pipeline ID is invalid.');
      }

      if (isUndefinedOrNullOrEmpty(companyId)) {
        companyId = this.company.id;
      }

      console.log('company ID:', companyId);
      console.log('fromPipeline ID:', id);

      const company = await this.getCompany(companyId);
      if (isUndefinedOrNull(company)) {
        throw Error('Company not found in Lead Carrot');
      }

      let newPipeline: any = null;
      if (source === 'company') {
        const index = company.pipelines.findIndex(item => item.id === id);
        if (index === -1) {
          throw Error('Unable to find pipeline to clone.');
        }
        newPipeline = cloneDeep(company.pipelines[index]);

      } else if (source === 'template') {
        newPipeline = await firebase.firestore()
          .collection('templates')
          .doc(companyId)
          .collection('pipelines')
          .doc(id)
          .get()
          .then(snapshot => {
            if (!snapshot.exists) {
              return null;
            }
            const docData = snapshot.data();
            if (typeof docData !== 'undefined') {
              docData.id = snapshot.id;
              return docData;
            }
            return null;
          });

      } else if (source === 'marketplace') {
        newPipeline = await firebase.firestore()
          .collection('marketplace')
          .doc('leadcarrot')
          .collection('pipelines')
          .doc(id)
          .get()
          .then(snapshot => {
            if (!snapshot.exists) {
              return null;
            }
            const docData = snapshot.data();
            if (typeof docData !== 'undefined') {
              docData.id = snapshot.id;
              return docData;
            }
            return null;
          });
      }

      if (isUndefinedOrNullOrEmpty(newPipeline)) {
        throw Error('Missing pipeline to clone');
      }

      newPipeline.id = uuidv4();
      newPipeline.name = 'Copy of ' + newPipeline.name;
      newPipeline.pipelineId = newPipeline.id;
      newPipeline.pipelineName = newPipeline.name;
      newPipeline.nameLcase = newPipeline.name.toLowerCase();

      if (!isUndefinedOrNullOrEmpty(teamId)) {
        newPipeline.teamId = teamId;
      }
      if (!isUndefinedOrNullOrEmpty(teamName)) {
        newPipeline.teamName = teamName;
      }

      console.log('newPipeline:', newPipeline);

      const stageList: any[] = [];
      newPipeline.stages.forEach(item => {
        const newStage = { oldId: item.id, newId: uuidv4() };
        console.log('new stage:', newStage);
        stageList.push(newStage);
      });

      newPipeline.stages = newPipeline.stages.map(item => {

        const stageId = stageList.find(myStage => myStage.oldId === item.id).newId;
        console.log('mapped stage id: from [' + item.id + '] to [' + stageId + ']');

        item.id = stageId;
        item.stageId = stageId;

        return item;
      });


      // Add new pipeline to company.
      console.log('newPipeline:', newPipeline);
      company.pipelines.push(newPipeline);

      if (company.pipelines) {
        const _pipelines = JSON.parse(JSON.stringify(company.pipelines));

        console.log('_pipelines:', _pipelines);

        // SAVE new pipeline to company
        await firebase.firestore()
          .collection(COLLECTION.COMPANY)
          .doc(companyId)
          .update({ pipelines: _pipelines, pipeline: _pipelines })
          .catch((error: any) => console.error(error));
      }

      // CLONE [Move On Reply] for each stage.
      if (source === 'company') {

        console.log('CLONE [Move On Reply] | source === company');

        for (const stageData of stageList) {
          const stageDoc = await firebase.firestore()
            .collection('stage')
            .doc(stageData.oldId)
            .get()
            .then(snapshot => {
              if (!snapshot.exists) {
                return null;
              }
              return snapshot.data();
            });

          if (!isUndefinedOrNull(stageDoc)) {
            stageDoc.id = newPipeline.id;
            stageDoc.name = newPipeline.name;
            stageDoc.id = stageData.newId;
            stageDoc.stageId = stageDoc.id;
            stageDoc.stats = {
              clicks: 0,
              ctr: 0,
              openRate: 0,
              sent: 0,
              delivered: 0,
              opened: 0,
            };
            const custom = stageDoc.moveOnReply.custom;
            if (custom.id === id) {
              custom.id = newPipeline.id;
              custom.name = newPipeline.name;
              custom.stageId = stageList.find(myStage => myStage.oldId === custom.stageId).newId;
            }
            stageDoc.moveOnReply.custom = custom;

            console.log('add new STAGE Move On Reply');
            console.log('stageDoc:', stageDoc);

            await firebase.firestore()
              .collection('stage')
              .doc(stageDoc.id)
              .set(stageDoc)
              .catch((error: any) => console.error(error));
          }
        }

      } else if (source === 'template') {

        console.log('CLONE [Move On Reply] | source === template');

        for (const stageData of stageList) {
          const stageDoc = await firebase.firestore()
            .collection('templates')
            .doc(companyId)
            .collection('stage')
            .doc(stageData.oldId)
            .get()
            .then(snapshot => {
              if (!snapshot.exists) {
                return null;
              }
              return snapshot.data();
            });

          if (!isUndefinedOrNull(stageDoc)) {
            stageDoc.id = newPipeline.id;
            stageDoc.name = newPipeline.name;
            stageDoc.id = stageData.newId;
            stageDoc.stageId = stageDoc.id;
            stageDoc.stats = {
              clicks: 0,
              ctr: 0,
              openRate: 0,
              sent: 0,
              delivered: 0,
              opened: 0,
            };
            const custom = stageDoc.moveOnReply.custom;
            if (custom.id === id) {
              custom.id = newPipeline.id;
              custom.name = newPipeline.name;
              custom.stageId = stageList.find(myStage => myStage.oldId === custom.stageId).newId;
            }
            stageDoc.moveOnReply.custom = custom;

            console.log('add new STAGE Move On Reply');
            console.log('stageDoc:', stageDoc);

            await firebase.firestore()
              .collection('stage')
              .doc(stageDoc.id)
              .set(stageDoc)
              .catch((error: any) => console.error(error));
          }
        }

      } else if (source === 'marketplace') {

        console.log('CLONE [Move On Reply] | source === marketplace');

        for (const stageData of stageList) {
          const stageDoc = await firebase.firestore()
            .collection('marketplace')
            .doc('leadcarrot')
            .collection('stage')
            .doc(stageData.oldId)
            .get()
            .then(snapshot => {
              if (!snapshot.exists) {
                return null;
              }
              return snapshot.data();
            });

          if (!isUndefinedOrNull(stageDoc)) {
            stageDoc.id = newPipeline.id;
            stageDoc.name = newPipeline.name;
            stageDoc.id = stageData.newId;
            stageDoc.stageId = stageDoc.id;
            stageDoc.stats = {
              clicks: 0,
              ctr: 0,
              openRate: 0,
              sent: 0,
              delivered: 0,
              opened: 0,
            };
            const custom = stageDoc.moveOnReply.custom;
            if (custom.id === id) {
              custom.id = newPipeline.id;
              custom.name = newPipeline.name;
              custom.stageId = stageList.find(myStage => myStage.oldId === custom.stageId).newId;
            }
            stageDoc.moveOnReply.custom = custom;

            console.log('add new STAGE Move On Reply');
            console.log('stageDoc:', stageDoc);

            await firebase.firestore()
              .collection('stage')
              .doc(stageDoc.id)
              .set(stageDoc)
              .catch((error: any) => console.error(error));
          }
        }
      }

      // CLONE [Automation Steps] for each stage.
      if (source === 'company') {

        console.log('CLONE [Automation Steps] | source === company');

        for (const stageData of stageList) {
          await firebase.firestore()
            .collection('autoStep')
            .where('stageId', '==', stageData.oldId)
            .get()
            .then(async snapshots => {
              if (snapshots.empty) {
                return null;
              }
              for (const snapshot of snapshots.docs) {
                if (!snapshot.exists) {
                  return null;
                }
                const autoStep = snapshot.data();
                if (typeof autoStep !== 'undefined') {
                  autoStep.createdAt = new Date();
                  autoStep.createdBy = this.user.id;
                  autoStep.updatedAt = autoStep.createdAt;
                  autoStep.updatedBy = this.user.id;
                  autoStep.sequenceId = '';
                  autoStep.stageId = stageList.find(myStage => myStage.oldId === autoStep.stageId).newId;
                  autoStep.stats = {
                    clicks: 0,
                    ctr: 0,
                    openRate: 0,
                    sent: 0,
                    delivered: 0,
                    opened: 0,
                  };

                  if (autoStep.action === 'move') {
                    const custom = autoStep.custom;
                    if (custom.id === id) {
                      custom.id = newPipeline.id;
                      custom.name = newPipeline.name;
                      custom.stageId = stageList.find(myStage => myStage.oldId === custom.stageId).newId;
                    }
                    autoStep.custom = custom;
                  }
                }

                console.log('add new STAGE AUTOMATION');
                console.log('autoStep:', autoStep);

                await firebase.firestore()
                  .collection('autoStep')
                  .doc()
                  .set(autoStep)
                  .catch((error: any) => console.error(error));
              }
            });
        }

      } else if (source === 'template') {

        console.log('CLONE [Automation Steps] | source === template');

        for (const stageData of stageList) {
          await firebase.firestore()
            .collection('templates')
            .doc(companyId)
            .collection('autoStep')
            .where('stageId', '==', stageData.oldId)
            .get()
            .then(async snapshots => {
              if (snapshots.empty) {
                return null;
              }
              for (const snapshot of snapshots.docs) {
                if (!snapshot.exists) {
                  return null;
                }
                const autoStep = snapshot.data();
                if (typeof autoStep !== 'undefined') {
                  autoStep.createdAt = new Date();
                  autoStep.createdBy = this.user.id;
                  autoStep.updatedAt = autoStep.createdAt;
                  autoStep.updatedBy = this.user.id;
                  autoStep.sequenceId = '';
                  autoStep.stageId = stageList.find(myStage => myStage.oldId === autoStep.stageId).newId;
                  autoStep.stats = {
                    clicks: 0,
                    ctr: 0,
                    openRate: 0,
                    sent: 0,
                    delivered: 0,
                    opened: 0,
                  };

                  if (autoStep.action === 'move') {
                    const custom = autoStep.custom;
                    if (custom.id === id) {
                      custom.id = newPipeline.id;
                      custom.name = newPipeline.name;
                      custom.stageId = stageList.find(myStage => myStage.oldId === custom.stageId).newId;
                    }
                    autoStep.custom = custom;
                  }
                }

                console.log('add new STAGE AUTOMATION');
                console.log('autoStep:', autoStep);

                await firebase.firestore()
                  .collection('autoStep')
                  .doc()
                  .set(autoStep)
                  .catch((error: any) => console.error(error));
              }
            });
        }

      } else if (source === 'marketplace') {

        console.log('CLONE [Automation Steps] | source === marketplace');

        for (const stageData of stageList) {
          await firebase.firestore()
            .collection('marketplace')
            .doc('leadcarrot')
            .collection('autoStep')
            .where('stageId', '==', stageData.oldId)
            .get()
            .then(async snapshots => {
              if (snapshots.empty) {
                return null;
              }
              for (const snapshot of snapshots.docs) {
                if (!snapshot.exists) {
                  return null;
                }
                const autoStep = snapshot.data();
                if (typeof autoStep !== 'undefined') {
                  autoStep.createdAt = new Date();
                  autoStep.createdBy = this.user.id;
                  autoStep.updatedAt = autoStep.createdAt;
                  autoStep.updatedBy = this.user.id;
                  autoStep.sequenceId = '';
                  autoStep.stageId = stageList.find(myStage => myStage.oldId === autoStep.stageId).newId;
                  autoStep.stats = {
                    clicks: 0,
                    ctr: 0,
                    openRate: 0,
                    sent: 0,
                    delivered: 0,
                    opened: 0,
                  };

                  if (autoStep.action === 'move') {
                    const custom = autoStep.custom;
                    if (custom.id === id) {
                      custom.id = newPipeline.id;
                      custom.name = newPipeline.name;
                      custom.stageId = stageList.find(myStage => myStage.oldId === custom.stageId).newId;
                    }
                    autoStep.custom = custom;
                  }
                }

                console.log('add new STAGE AUTOMATION');
                console.log('autoStep:', autoStep);

                await firebase.firestore()
                  .collection('autoStep')
                  .doc()
                  .set(autoStep)
                  .catch((error: any) => console.error(error));
              }
            });
        }
      }

      // CLONE [Deal Drip] for the pipeline.
      /*
      const sequenceDoc = await firebase.firestore()
        .collection('sequence')
        .doc(fromid)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) {
            return null;
          }
          return snapshot.data();
        });
      if (!isUndefinedOrNull(sequenceDoc)) {
        stageDoc.id = stageData.newId;
        stageDoc.stageId = stageDoc.id;
        stageDoc.stats = {
          clicks: 0,
          ctr: 0,
          openRate: 0,
          sent: 0,
          delivered: 0,
          opened: 0,
        };
        const custom = stageDoc.moveOnReply.custom;
        if (custom.id === fromid) {
          custom.id = newPipeline.id;
          custom.name = newPipeline.name;
          custom.stageId = stageList.find(myStage => myStage.oldId === custom.stageId).newId;
        }
        stageDoc.moveOnReply.custom = custom;
        console.log('add new STAGE Move On Reply');
        console.log('stageDoc:', stageDoc);
        // await firebase.firestore()
        //   .collection('stage')
        //   .doc(stageDoc.id)
        //   .set(stageDoc)
        //   .catch((error: any) => console.error(error));
      }
      */


      /*
      const index = this._pipelines.findIndex(item => item.id === key);
      // this._pipelines[index] = pipe;
      this._pipelines[index] = data['response'];
      this.pipelines.next(this._pipelines);
      console.log(this._pipelines);
      const pipelineIndex = this.company.pipelines.findIndex(item => item.id === key);
      this.company.pipelines[pipelineIndex] = pipeline;
      this.sessionService.companySubject.next(this.company);
      */

      this.authService.company.next(company);

      return Promise.resolve(newPipeline);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async addAdminsToPipeline(pipelineId: string = null, companyId: string = null) {
    try {
      if (isUndefinedOrNullOrEmpty(pipelineId)) {
        throw Error('Pipeline ID is invalid.');
      }
      if (isUndefinedOrNullOrEmpty(companyId)) {
        throw Error('Company ID is invalid.');
      }

      const company = await this.getCompany(companyId);
      const adminUsers = company.adminUsers;

      /*
      team.users: await this._createUsers(company.adminUsers),
      team.admins: company.adminUsers,

      console.warn('groupTeamLead:', groupTeamLead);

      const pipeline = await company.pipelines.find(item => item.id === pipelineId);
      if (typeof pipeline !== 'undefined') {

        // console.log('pipeline:', pipeline);

        const teamId = pipeline.teamId;
        const team = await firebase.firestore()
          .collection(COLLECTION.TEAM)
          .doc(teamId)
          .get()
          .then(snapshot => {
            if (!snapshot.exists) {
              return null;
            }
            const docData = snapshot.data();
            if (typeof docData === 'undefined') {
              return null;
            }
            docData.id = snapshot.id;
            return docData;
          });

        const teamDetail = {
          permissionGroupId: groupTeamLead.id,
          permissionGroupName: groupTeamLead.permissionGroupName,
          teamId: team.id,
          teamName: team.teamName,
        };
        }
      }
      */
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getPipelineForEdit(id: string) {
    console.log('Data.Service -> getPipelineForEdit() | id = ', id);
    console.log('Data.Service -> getPipelineForEdit() | _pipelines = ', this._pipelines);

    const index = this._pipelines.findIndex(item => item.id === id);
    return this._pipelines[index];
  }

  deletePipeline(pipelineId: string) {
    return this.http
      .delete(
        `${ this.endPoint }/pipeline/${ this.company.id }/${ pipelineId }`,
        { headers: createAuthHeaders(this.user.token) },
      ); /*.pipe(
        catchError((error: Response) => throwError(error)),
      );*/
  }

  async updatePipelineIndex(companyId: string, newList: any[]) {
    try {
      if (isUndefinedOrNullOrEmpty(companyId)) {
        throw Error('Company ID is invalid.');
      }
      companyId = companyId.trim();

      if (isUndefinedOrNullOrEmpty(newList)) {
        throw Error('newList is invalid.');
      }

      console.log('company ID:', companyId);
      console.log('newList:', newList);

      const company = await firebase.firestore()
        .collection(COLLECTION.COMPANY)
        .doc(companyId)
        .get()
        .then(snapshot => {
          if (!snapshot.exists) {
            return null;
          }
          return snapshot.data();
        });

      if (isUndefinedOrNull(company)) {
        throw Error('Company not found in Lead Carrot');
      }

      console.log('company:', company);


      for (const item of newList) {

        if (item.id !== '') {
          const pIndex = company.pipeline.findIndex(pipeline => pipeline.id === item.id);
          if (pIndex !== -1) {
            company.pipeline[pIndex].index = item.index;
          }
        }
      }

      return await this.afs
        .collection(COLLECTION.COMPANY)
        .doc(companyId)
        .update({ pipeline: company.pipeline });

    } catch (error) {
      console.error(error);
      throw Error('Unable to update the Database');
    }
  }

  // #############################################################################################################
  // Manage Permissions CRUD
  // #############################################################################################################

  addPermissionGroup(title: string, permissions: Permissions) {
    const permissionGroup = {
      companyId: this.company.id,
      permissionGroupName: title,
      // teamId: teamId,
      permission: permissions
    };
    const body = JSON.stringify(permissionGroup);
    console.log(body);

    return this.http
      .post(
        this.endPoint + `/group/${ this.company.id }`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        map((response: any) => {
          const data = JSON.parse(JSON.stringify(response.data));
          this._permissionGroups.push(data);
          this.permissionGroups.next(this._permissionGroups);
          return response;
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  editPermissionGroup(title: string, permissions: Permissions, groupId: string) {
    const permissionGroup = {
      companyId: this.company.id,
      permissionGroupName: title,
      // teamId: teamId,
      permission: permissions
    };
    console.log('Data.Service -> editPermissionGroup() | permissionGroup = ', permissionGroup);

    const body = JSON.stringify(permissionGroup);

    console.log('body:', body);

    return this.http
      .patch(
        this.endPoint + `/group/${ this.company.id }/${ groupId }`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      )
      .pipe(
        map((response: any) => {
          const data = JSON.parse(JSON.stringify(response.data));
          const index = this._permissionGroups.findIndex(item => item.id === groupId);
          this._permissionGroups[index] = data;
          this.permissionGroups.next(this._permissionGroups);
          return data;
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  deletePermission(groupId: string) {
    return this.http
      .delete(
        `${ this.endPoint }/group/${ this.company.id }/${ groupId }`,
        { headers: createAuthHeaders(this.user.token) },
      )
      .pipe(
        map((response: any) => {
          const index = this._permissionGroups.findIndex(item => item.id === groupId);
          this._permissionGroups.splice(index, 1);
          this.permissionGroups.next(this._permissionGroups);
          return response;
        }),
        catchError((error: any) => throwError(error)),
      );
  }

  /*
  get permissionGroups() {
    return this._permissionGroups;
  }
  */

  getPermissionGroupsByCompany(companyId: string) {
    this._permissionGroups = [];
    this.permissionGroups.next(this._permissionGroups);

    /*
    return this.http
      .get(
        this.endPoint + `/group/${companyId}`,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        map((response: Response) => {
          console.log(response);
          this._permissionGroups = response['data'];
          this.permissionGroups.next(this._permissionGroups);
          return response;
        }),
        catchError((error: Response) => throwError(error)),
      );
    */
    try {
      if (!isUndefinedOrNullOrEmpty(companyId)) {
        return this.afs
          .collection(COLLECTION.PERMISSION_GROUP, ref => ref
            .where('isDeleted', '==', false)
            .where('companyId', '==', companyId))
          .snapshotChanges()
          .pipe(
            map(docs => {
              if (docs) {
                const allGroups = docs.map(action => {
                  const group: any = action.payload.doc.data();
                  group.id = action.payload.doc.id;

                  if (isUndefinedOrNullOrEmpty(group.name)
                    && !isUndefinedOrNullOrEmpty(group.permissionGroupName)
                  ) {
                    group.name = group.permissionGroupName;
                  }
                  return group;
                });
                this._permissionGroups = allGroups;
                this.permissionGroups.next(this._permissionGroups);
                return this._permissionGroups;

              } else {
                this._permissionGroups = [];
                this.permissionGroups.next(this._permissionGroups);

                return this._permissionGroups;
              }
              // ,
              // catchError((error: Response) => throwError(error))
            })
          );
      }
    } catch (error) {
      console.error(error);
      throw error;
    }

  }

  getPermissionGroup(groupId: string) {
    const index = this._permissionGroups.findIndex(item => item.id === groupId);
    if (index === - 1) {
      throw Error('No permission group with that ID.');
    }
    return this._permissionGroups[index];
  }

  // #############################################################################################################
  // Manage Users CRUD
  // #############################################################################################################

  addUserByAdmin(newUser: any) {
    console.log('Data.Service -> addUserByAdmin() | newUser = ', newUser);

    const body = JSON.stringify(newUser);
    return this.http
      .post(
        `${ this.endPoint }/user/addByAdminNew`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        catchError((error: Response) => throwError(error)),
      );
  }

  updateUserByAdmin(userId: string, newUser: any) {

    console.log('Data.Service -> updateUserByAdmin() | newUser = ', newUser);

    const body = JSON.stringify(newUser);
    return this.http
      .post(
        `${ this.endPoint }/user/update/${ this.company.id }/${ userId }`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        catchError((error: Response) => throwError(error)),
      );
  }

  addUserByAdminNew(newUser: any) {
    console.log('Data.Service -> addUserByAdminNew() | newUser = ', newUser);

    const body = JSON.stringify(newUser);
    return this.http.post(
      `${ this.endPoint }/user/add/${ this.company.id }`,
      // `${this.endPoint}/user/add/${this.company.id}`,
      body,
      { headers: createAuthHeaders(this.user.token) },
    ).pipe(
      catchError((error: Response) => throwError(error)),
    );
  }

  getUserByAdminByCompanyId() {
    console.log('Data.Service -> getUserByAdminByCompanyId()');

    return this.http
      .get(
        `${ this.endPoint }/user/getByCompany/${ this.company.id }`,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe<any>(
        catchError((error: Response) => throwError(error)),
      )
      .subscribe(response => {
        console.log(response.response);
        this._usersLite = response.response;
        this.usersLite.next(this._usersLite);
        console.log('in subscribe');
      });
  }

  getUserByAdminByCompanyIdNew(companyId: string) {
    return this.http
      .get(
        `${ this.endPoint }/user/byCompany/${ companyId }`,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        map((response: any) => {
          this._usersLite = response.response;
          this.usersLite.next(this._usersLite);
        }),
        catchError(error => {
          console.error('*** getUserByAdminByCompanyIdNew() ***');
          console.warn(error.error);
          return throwError(error.error);
        }),
      );
  }

  getUsersOtherCompanies(userId: string) {
    console.log('Data.Service -> getUsersOtherCompanies() | userId = ', userId);

    return this.http
      .get(`${ this.endPoint }/user/getUsersOtherCompanies/${ userId }`,
        { headers: createAuthHeaders(this.user.token) },
        /*
              ).pipe(
                map((data: Response) => {
                  console.log(data.response);
                }),
        */
      ).pipe(
        catchError((error: Response) => throwError(error)),
      );
  }

  addUserByInvite(userId: string, userData: any) {
    console.log('Data.Service -> addUserByInvite() | userId = ', userId);
    console.log('Data.Service -> addUserByInvite() | userData = ', userData);

    const body = JSON.stringify(userData);
    return this.http
      .post(
        `${ this.endPoint }/user/setUserByInvite/${ userId }`,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        catchError((error: any) => throwError(error)),
      );
  }

  importPipeline(pipelineId: string, stageId: string, url: string) {
    const pipeline = {
      pipelineId,
      stageId,
      url,
    };
    console.log('Data.Service -> importPipeline() | pipeline = ', pipeline);

    const body = JSON.stringify(pipeline);
    return this.http
      .post(
        this.endPoint + '/deal/import/' + this.company.id,
        body,
        { headers: createAuthHeaders(this.user.token) },
      ).pipe(
        map((res: any) => res),
        catchError((error: any) => throwError(error)),
      );
  }

  hasPermission(objectName: string, permissionName: string) {

    return true;

    /*
    if (typeof objectName === 'undefined' || objectName === null || objectName.trim() === '') {
      return false;
    }
    if (typeof permissionName === 'undefined' || permissionName === null || permissionName.trim() === '') {
      return false;
    }
    if (this.sessionService.permissionGroup === null) {
      return false;
    }

    return isAllowedTo(objectName, permissionName, this.sessionService.permissionGroup.permissions);
    */
  }


  // #############################################################################################################
  // Manage Team CRUD
  // #############################################################################################################

  async teamUserDelete(teamId: string, userId: string) {
    const data = {
      teamId,
      userId,
    };

    const body = JSON.stringify(data);
    console.log('url: ', END_POINT_CLOUD + '/teamUserDelete');
    console.log('POST body :', body);

    return this.http
      .post(
        END_POINT_CLOUD + '/teamUserDelete',
        body,
        { headers: this.createAuthHeaders(this.user.token) }
      ).pipe(
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  createAuthHeaders(authToken = ''): any {
    let headers: HttpHeaders;
    if (typeof authToken !== 'undefined' && authToken !== null && authToken.trim() !== '') {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', authToken)
        .set('x-firebase-id-token', authToken);
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json');
    }
    return headers;
  }
}
