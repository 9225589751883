<mat-nav-list>

    <div mat-list-item style="padding-bottom: 20px;">
        <h4 *ngIf="data"><mat-icon style="color: red; padding-right: 10px;" svgIcon="alert"></mat-icon>{{data.message}}</h4>
        <h4 *ngIf="!data">Are you sure you want to delete?</h4>
    </div>
    <div>
        <button style="float: right; margin-left: 10px;" align="end" mat-raised-button (click)="onClick(true)" mat-flat-button color="warn">
            <mat-icon>delete</mat-icon>
            Delete
        </button>
        <button style="float: right; margin-left: 10px;" align="end" mat-raised-button (click)="onClick(false)" mat-flat-button color="default">
            <mat-icon>cancel</mat-icon>
            Cancel
        </button>
    </div>
</mat-nav-list>