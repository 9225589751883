import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExportResults } from '../models';
import * as moment from 'moment';

@Component({
  selector: 'app-export-custom-dialog',
  templateUrl: './export-custom-dialog.component.html',
  styleUrls: ['./export-custom-dialog.component.scss'],
})
export class ExportCustomDialogComponent implements OnInit {
  form: FormGroup;
  category: string;
  exportType: string;
  filename: string;
  location: string;
  recordCount: number;
  recordCountMessage: string;
  title: string;
  selectedType = 'GOOGLE_CUSTOM_AUDIENCE';
  exportTypes = [
    {
      name: 'Google Custom Audience',
      value: 'GOOGLE_CUSTOM_AUDIENCE',
    },
  ];

  constructor(
    // @Inject('moment') private moment,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExportCustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      category,
      exportType,
      filename,
      location,
      recordCount,
      title,
    }: ExportResults
  ) {
    this.category = category;
    this.exportType = exportType;
    this.filename = filename;
    this.location = location;
    this.title = title;
    this.recordCount =
      typeof recordCount === 'undefined' || recordCount === null
        ? 0
        : recordCount;

    if (this.recordCount < 1) {
      this.recordCountMessage = '';
    } else if (this.recordCount === 1) {
      this.recordCountMessage = 'lead will be exported';
    } else {
      this.recordCountMessage = 'leads will be exported';
    }

    this.form = this.fb.group({
      filename: [filename, Validators.required],
      exportType: [exportType, Validators.required],
    });
  }

  ngOnInit() {
    this.form.get('exportType').setValue(this.selectedType);
    this.exportType = this.selectedType;
  }

  export() {
    this.exportType = this.form.get('exportType').value;
    console.log('*** DIALOG CLOSE | EXPORT TYPE:', this.exportType);
    this.dialogRef.close({
      exportType: this.exportType,
      filename: this.filename,
    });
  }

  close() {
    this.dialogRef.close();
  }

  updateFilename(exportType: string) {
    const data = this.location
      .toLocaleLowerCase()
      .replace(/[\,\.]/g, '')
      .replace(/\s/g, '_');

    switch (exportType) {
      case 'CRM':
        this.filename =
          'pipeline_' +
          this.category.toLocaleLowerCase() +
          '_in_' +
          data +
          '_' +
          moment().format('YYYY-MM-DD');
        break;

      case 'TERRITORY':
        // Set the default export filename
        this.filename =
          'territory_' +
          this.category.toLocaleLowerCase() +
          '_in_' +
          data +
          '_' +
          moment().format('YYYY-MM-DD');
        break;

      case 'BASIC':
        this.filename =
          this.category.toLocaleLowerCase() +
          '_in_' +
          data +
          '_' +
          moment().format('YYYY-MM-DD');
        break;

      case 'FULL':
        this.filename =
          'pipeline_' +
          this.category.toLocaleLowerCase() +
          '_in_' +
          data +
          '_' +
          moment().format('YYYY-MM-DD');
        break;

      default:
        this.filename =
          this.category.toLocaleLowerCase() +
          '_in_' +
          data +
          '_' +
          moment().format('YYYY-MM-DD');
    }

    this.form.patchValue({
      filename: this.filename,
      exportType,
    });

    return this.filename;
  }
}
