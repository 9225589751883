<!-- ============================================================== -->
<!-- Topbar - style you can find in header.scss -->
<!-- ============================================================== -->
<mat-toolbar color="primary" class="topbar telative app-header" style="padding: 0; height: 52px;">
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->
    <!--
    <button mat-icon-button (click)=" sidenavToggle()" fxShow="false" fxShow.gt-lg="false" fxShow.sm="true"
        fxShow.xs="true">
        <mat-icon>menu</mat-icon>
    </button>
    -->

    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
        <div>
            <a class="navbar-brand" [routerLink]="['/dashboard']">
                <!-- Logo icon -->
                <b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" style="height: 36px;">
                    <!-- Light Logo icon -->
                    <!--
                    <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" style="height: 36px;">
                    -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                    <!-- dark Logo text -->
                    <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" style="height: 36px;">
                    <!-- Light Logo text -->

                    <!--
                    <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"
                         style="height: 36px;">
                    -->
                </span>
            </a>
        </div>
        <div>
            <span>{{ company?.name }}</span>
        </div>
    </div>

    <!-- ============================================================== -->
    <!-- Search - style you can find in header.scss -->
    <!-- ============================================================== -->
    <!--
        <button mat-icon-button class="srh-btn">
            <mat-icon>search</mat-icon>
        </button>
        <form class="app-search">
            <input type="text" class="form-control" placeholder="Search &amp; enter" style="height: 100%;">
            <a class="cl-srh-btn"><i class="ti-close"></i></a>
        </form>
        -->



    <!-- <div id="menu" style="width:100%;height:100%">
                <app-menu></app-menu>
        </div> -->
    <span fxFlex></span>


    <!-- ============================================================== -->
    <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <!--
        <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
          <mat-icon>settings</mat-icon>
        </button>
        -->
</mat-toolbar>
<!-- ============================================================== -->
<!-- End Topbar - style you can find in pages.scss -->
<!-- ============================================================== -->