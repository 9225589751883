<!-- <button (click)="checkForm()">check</button> -->
<h2 mat-dialog-title>
    {{ title }}
    <div class="dlg-button-close" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
</h2>

<mat-dialog-content style="font-size: 14pt;">
    <mat-tab-group [dynamicHeight]="true">
        <!-- SHOW rows -->
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabTitle">Yes / No</span>
            </ng-template>

            <div class="box-options">
                <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.email?.enabled"
                    (change)="toggleFilterEnabled($event, 'email')">
                    Email
                </mat-slide-toggle>
                <!-- <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;" /> -->
                <div class="hide-me" [ngClass]="{'show-me': filters?.email?.enabled === true}" style="padding-left: 40px;">
                    <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.email?.exists"
                        (change)="toggleFilterExists($event, 'email', true)" [disabled]="!filters?.email?.enabled">
                        Has Email
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="box-options">
                <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.phone?.enabled"
                    (change)="toggleFilterEnabled($event, 'phone')">
                    Phone
                </mat-slide-toggle>
                <div class="hide-me" [ngClass]="{'show-me': filters?.phone?.enabled === true}" style="padding-left: 40px;">
                    <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.phone?.exists"
                        (change)="toggleFilterExists($event, 'phone', true)" [disabled]="!filters?.phone?.enabled">
                        Has Phone
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="box-options">
                <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.url?.enabled"
                    (change)="toggleFilterEnabled($event, 'url')">
                    URL
                </mat-slide-toggle>
                <div class="hide-me" [ngClass]="{'show-me': filters?.url?.enabled === true}" style="padding-left: 40px;">
                    <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.url?.exists"
                        (change)="toggleFilterExists($event, 'website', true)" [disabled]="!filters?.url?.enabled">
                        Has Website URL
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="box-options">
                <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.ssl?.enabled"
                    (change)="toggleFilterEnabled($event, 'ssl')">
                    SSL
                </mat-slide-toggle>
                <div class="hide-me" [ngClass]="{'show-me': filters?.ssl?.enabled === true}" style="padding-left: 40px;">
                    <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.ssl?.exists"
                        (change)="toggleFilterExists($event, 'ssl', true)" [disabled]="!filters?.ssl?.enabled">
                        Has SSL Certificate
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="box-options">
                <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.fbPixel?.enabled"
                    (change)="toggleFilterEnabled($event, 'fbPixel')">
                    FB Pixel
                </mat-slide-toggle>
                <div class="hide-me" [ngClass]="{'show-me': filters?.fbPixel?.enabled === true}" style="padding-left: 40px;">
                    <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.fbPixel?.exists"
                        (change)="toggleFilterExists($event, 'fbPixel', true)" [disabled]="!filters?.fbPixel?.enabled">
                        Has FB Pixel
                    </mat-slide-toggle>
                </div>
            </div>

            <div class="box-options">
                <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.postal?.enabled"
                    (change)="toggleFilterEnabled($event, 'postal')">
                    Zip / Postal
                </mat-slide-toggle>
                <div class="hide-me" [ngClass]="{'show-me': filters?.postal?.enabled === true}" style="padding-left: 40px;">
                    <mat-slide-toggle class="toggle-filter-option" color="primary" [checked]="filters?.postal?.exists"
                        (change)="toggleFilterExists($event, 'postal', true)" [disabled]="!filters?.postal?.enabled">
                        Has Zip / Postal
                    </mat-slide-toggle>
                </div>
            </div>
        </mat-tab>
    
    </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="save()">Save</button>
    <button mat-raised-button (click)="close()" cdkFocusInitial>Close</button>
</mat-dialog-actions>