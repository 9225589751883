import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError, Observable, Subject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import localforage from 'localforage';

// TODO! Rename the SinglePermissionGroup MODEL to PermissionGroup

// import { ArrayOfStages } from '../../models/ArrayOfStages';
import { ICompany } from '../../models/ICompany';
import { IEmailResponse } from '../../models/IEmailResponse';
import { IPermissionGroup } from '../../models/IPermissionGroup';
// import { IStage } from '../../models/IStage';
import { ITeam } from '../../models/ITeam';
import { IUserLite } from '../../models/IUserLite';
import { IUser } from '../../models/IUser';
import { User } from '../../models/User';

import {
  COLLECTION,
  createAuthHeaders,
  getDocument,
  isUndefinedOrNullOrEmpty,
  isUndefinedOrNull,
} from '../../shared/utils';

import { environment } from '../../../environments/environment';
// import { PipelineService } from './pipeline.service';

import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';

const IS_DEBUG = !environment.production;
const DEFAULT_DEAL_STATUS = 'active';
const DEFAULT_IS_PERSONAL = true;

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  END_POINT = '';

  // ----------------------------------------------------
  // AF - Need to be initialized here or breaks other services
  _userToken: string;
  userTokenSubject = new BehaviorSubject<string>('');
  idToken: string;

  private _user: IUser;
  user$: BehaviorSubject<IUser>;

  authUser: any; // IUser;
  userData: User;
  userDataSubject: BehaviorSubject<User>;
  user = new BehaviorSubject<IUser>(null);

  private _companyId: string;
  companyId: BehaviorSubject<string>;
  private _company: ICompany;
  company = new BehaviorSubject<ICompany>(null);

  private _isPersonal = DEFAULT_IS_PERSONAL;
  isPersonalSubject = new BehaviorSubject<boolean>(DEFAULT_IS_PERSONAL);

  private _teams: ITeam[] = [];
  teams = new BehaviorSubject<ITeam[]>([]);
  private _users: IUserLite[] = [];
  usersSubject = new BehaviorSubject<IUserLite[]>([]);
  private _automations: any[] = [];
  automationsSubject: BehaviorSubject<any[]>;

  private _dealStatus = DEFAULT_DEAL_STATUS;
  dealStatusSubject = new BehaviorSubject<string>(DEFAULT_DEAL_STATUS);

  private _emailThreadId = '';
  emailThreadIdSubject = new BehaviorSubject<string>('');
  private _messageThreadId = '';
  messageThreadIdSubject = new BehaviorSubject<string>('');
  private _teamThreadId = '';
  teamThreadIdSubject = new BehaviorSubject<string>('');
  mailMessage: IEmailResponse[] = [];
  allMessage = new BehaviorSubject<IEmailResponse[]>([]);

  private _pipelineId = null;
  pipelineIdSubject: BehaviorSubject<string>;
  private _stageId = '';
  stageIdSubject = new BehaviorSubject<string>('');
  private _stageName = '';
  stageNameSubject = new BehaviorSubject<string>('');

  sidebarSubject = new Subject<boolean>();

  fixSms$: Observable<any>;
  querySmsGroups: Observable<any>;

  permissionGroupSubject: BehaviorSubject<IPermissionGroup>;

  // ----------------------------------------------------

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient
  ) {
    try {
      if (!IS_DEBUG) {
        this.END_POINT = environment.backendApiUrl;
      } else {
        this.END_POINT = environment.backendApiDebugUrl;
      }

      this.authService.user.subscribe((user) => {
        try {
          // console.log('SessionService => constructor()');

          if (user) {
            // console.log('SessionService => constructor() -- USER is GOOD');

            // this.userData = user;
            this.idToken = user.token;
            this.userToken = user.token;
            this._userToken = user.token;
            // this.emailThreadId = '';
            // this.messageThreadId = '';
            // this.teamThreadId = '';

            this._user = user;
            this.user.next(this._user);
          } else {
            console.log('SessionService => constructor() -- USER is BAD');

            // this.userData = null;
            this.idToken = '';
            this.userToken = '';
            this._userToken = '';
            this.idToken = '';
            this._user = null;
            this.user.next(this._user);
          }
        } catch (error) {
          console.error(error);

          console.log('SessionService => constructor() -- ERROR');

          // this.userData = null;
          this.idToken = '';
          this.userToken = '';
          this._userToken = '';
          this.idToken = '';
          this._user = null;
          this.user.next(this._user);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  // ------------------------------------------------------------------

  get isPersonal(): boolean {
    return this._isPersonal;
  }

  set isPersonal(status: boolean) {
    if (isUndefinedOrNull(status)) {
      status = false;
    }
    if (status === this.isPersonal) {
      return;
    }
    this._isPersonal = status;
    localforage.setItem('isPersonal', status.toString());
    this.isPersonalSubject.next(status);
  }

  // ------------------------------------------------------------------

  get userToken() {
    return this._userToken;
  }

  set userToken(token: string) {
    if (isUndefinedOrNullOrEmpty(token)) {
      token = '';
    }
    if (token === this._userToken) {
      return;
    }
    this._userToken = token;
    localforage.setItem('userToken', token);
    this.userTokenSubject.next(token);
  }

  // ------------------------------------------------------------------

  setAttribute(key: string, value: any): void {
    sessionStorage.setItem(key, value);
    try {
      this[key].next(value);
    } catch (error) {
      console.warn(error);
    }
  }

  getAttribute(key: string): Observable<string> {
    return this[key].asObservable();
  }

  logout(): Promise<any> {
    // console.log('SessionService.logout()');
    this.clear();
    try {
      localforage.removeItem('userToken');
      this.authService.signOut();
      return Promise.resolve();
    } catch (error) {
      // console.log(error);
      return Promise.resolve();
    }
  }

  clear() {
    // console.log( '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@' );
    // console.log( '@@@@@@@@                CLEAR THE LOCAL CACHE              @@@@@@@@@@@' );
    // console.log( '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@' );

    this._user = null;
    this.user.next(this._user);

    localforage.clear(); // In-case of bad local storage data
  }
}
