declare global {
  interface Window {
    onboardFlowSettings: any;
    OnboardFlowLoader: any;
  }
}

// const onboardFlowSettings = window.onboardFlowSettings;
// const OnboardFlowLoader = window.OnboardFlowLoader;

import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { ICompany, IUser } from './models';
import { FaLayersTextComponent } from '@fortawesome/angular-fontawesome';
import localforage from 'localforage';

// import { NgxSpinnerService } from 'ngx-spinner';

interface iWlOptions {
  crmType: string;
  locationId: string;
  locationApikey: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  returnUrl: string;
  user: IUser;
  company: ICompany;
  isLoggedIn: boolean;

  wlOptions: iWlOptions = {
    crmType: null,
    locationId: null,
    locationApikey: null,
  };

  @HostListener('window:message', ['$event'])
  handleMessage(event: any) {
    /*
    if (event.origin.trim() === 'http://localhost:4200') {
      return;
    }
    */

    // console.warn('*** GOT THE MESSAGE ***');
    // console.warn('*** postMessage:', event);

    if (typeof event.data.action !== 'undefined') {
      // console.warn('APP COMPONENT - ' + event.data.key);

      if (event.data.action === 'save') {
        // Save data
        if (event.data.key !== 'undefined') {
          if (event.data.key === 'finderData') {
            localforage.setItem(event.data.key, event.data.value);
            localforage.setItem('finderApikey', event.data.value.apikey);
            localforage.setItem('finderCompanyId', event.data.value.companyId);
            localforage.setItem('finderUserId', event.data.value.userId);
          } else {
            localforage.setItem(event.data.key, event.data.value);
          }
        }
      }
    }

    /*
    console.warn('*** MESSAGE ORIGIN *** :', event.origin);
    console.warn(event);
    console.warn(event.data);
    localforage.setItem('finderData', JSON.stringify(event.data));
    alert(event.origin);
    */
  }

  constructor(
    // private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router //private spinner: NgxSpinnerService
  ) {
    this.isLoggedIn = false;
    this.company = null;
    this.user = null;
  }

  ngOnInit() {
    //this.spinner.show();
    /*

    this.authService.afAuth.authState.subscribe((user) => {
      // console.warn('user:', user);

      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;

        console.log('this.router.url:', this.router.url);
        console.log('this.route.outlet:', this.route.outlet);

        let isReset = false;
        let isRegister = false;
        if (this.router.url.indexOf('/account/reset') === 0) {
          isReset = true;
        }
        if (this.router.url.indexOf('/account/register') === 0) {
          isRegister = true;
        }
        if (!isReset && !isRegister) {
          this.router.navigate(
            ['/account/login'],
            {}
            // { queryParams: { returnUrl: state.url } }
          );
        }


        //  if (!user) {
        //    this.returnUrl = '/';
        //    this.router.navigate([this.returnUrl]);
        //  }

      }
    });


    this.authService.user.subscribe((user) => {
      this.user = user;
    });

    this.authService.company.subscribe((company) => {
      this.company = company;
    });
    */
  }

  logout() {
    // this.authService.signOut();
  }
}
