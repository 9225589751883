// import { auth } from 'firebase/app';
import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from '../session/session.service';
import { environment } from '../../../environments/environment';
import { Utils } from '../../shared/utils';

const IS_DEBUG = !environment.production;
const COLLECTION_IMPORT_TRANSACTIONS = 'ImportTransactions';

interface ITransactionData {
  companyId: string;
  createdAt: any;
  createdBy: string;
  error: string;
  id: string;
  isDeleted: boolean;
  processedRows: number;
  skippedFileUrl: string;
  status: string;
  totalRows: number;
  updatedAt: any;
  updatedBy: string;
  importedDealIds: any;
  importedOrganizationIds: any;
  importedPersonIds: any;
}

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  private utils = new Utils();
  END_POINT: string;
  importCollection: AngularFirestoreCollection<ITransactionData>;
  importDoc: AngularFirestoreDocument<ITransactionData>;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private sessionService: SessionService,
  ) {
    if (!IS_DEBUG) {
      this.END_POINT = environment.backendApiUrl;
    } else {
      this.END_POINT = environment.backendApiDebugUrl;
    }
  }

  async cancelImport(importId: string) {
    try {
      return await firebase.firestore()
        .collection(COLLECTION_IMPORT_TRANSACTIONS)
        .doc(importId)
        .update({ status: 'canceled', updatedAt: new Date() });
    } catch (error) {
      console.error(error);
      return Promise.resolve(false);
    }
  }

  dataFromFile(url: string) {
    const body = {
      url: url
    };

    return this.http.post(
      this.END_POINT + '/import/dataFromFile/',
      body,
      { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
    );
  }

  getImports(companyId: string): Observable<ITransactionData[]> {
    return this.afs.collection<ITransactionData>(COLLECTION_IMPORT_TRANSACTIONS, ref => ref
      .orderBy('createdAt', 'desc')
      .where('companyId', '==', companyId))
      .valueChanges()
      .pipe(map(items => {
        if (items) {
          const transactions: ITransactionData[] = [];
          for (const item of items) {
            const transaction: ITransactionData = item;
            if (item.status === 'completed') {
              transaction.status = 'done';
            } else if (item.status === 'inprogress') {
              transaction.status = 'in-progress';
            }
            transactions.push(transaction);
          }
          return transactions;
        }
      }));
  }

  proceedUpload(importId: string, isHeader: boolean, isImportDeals: boolean, pipelineId: string, stageId: string, mapping: any) {
    const body = {
      id: importId,
      hasHeader: isHeader,
      isImportDeals: isImportDeals,
      userId: this.sessionService.user.value.id,
      companyId: this.sessionService.company.value.id,
      pipelineId: pipelineId,
      stageId: stageId,
      mapping: mapping,
    };

    return this.http
      .post(
        this.END_POINT + '/import/mappedData/' + this.sessionService.company.value.id,
        body,
        { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
      )
      .pipe(
        catchError((error: Response) => throwError(error))
      );
  }

  getImportHistory() {
    return this.http
      .get(
        `${ this.sessionService.END_POINT }/import/get/${ this.sessionService.company.value.id }`,
        { headers: this.utils.createAuthHeaders(this.sessionService.userToken) }
      )
      .pipe(
        catchError((error: Response) => throwError(error))
      );
  }

  async deleteImportLog(importId: string) {
    try {
      await this.afs.collection(COLLECTION_IMPORT_TRANSACTIONS).doc(importId).delete();
      return Promise.resolve(true);
    } catch (error) {
      return Promise.resolve(false);
    }
  }

}
