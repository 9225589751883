// import { Deal } from '../../models/Deal';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortingDealFeatures',
  pure: false
})
export class SortingDealFeaturesPipe implements PipeTransform {

  // transform(deals: Deal[], path: string[], order: number): Deal[] {
  transform(deals: any[], path: string[], order: number): any[] {

    // Check if is not null
    if (!deals || !path || !order) {
      return deals;
    }

    return deals.sort((a: any, b: any) => {

      // We go for each property followed by path
      path.forEach(property => {
        a = a[property];
        b = b[property];
      });

      // Order * (-1): We change our order
      return a > b ? order : order * (- 1);
    });
  }
}
