import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, search: string): any {

    if (!search) { return value; }  // Return value if no search provided

    // Create a subset of values that match the search criteria
    const results = value.filter(item => {
      if (!item) { return; } // Return nothing if this item is empty

      // Return the index of this item if it matches our search criteria
      return item.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    return results;
  }
}
