import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Overlay, CloseScrollStrategy } from '@angular/cdk/overlay';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

import { isUndefinedOrNull, isUndefinedOrNullOrEmpty } from '../shared/utils';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}
// END

@Component({
  selector: 'app-export-deal',
  templateUrl: './export-deal.component.html',
  styleUrls: ['./export-deal.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
  ],
})
export class ExportDealComponent implements OnInit, OnDestroy {
  dlgTitle: string;
  isAutomation: boolean;
  isExportDeals: boolean;
  isMoveDeals: boolean;
  isMoveOnReply: boolean;
  isChat: boolean;

  allSubscriptions = new Subscription();
  formMoveDeal: FormGroup;
  autoStepName = new FormControl('');
  fromPipelines = new FormControl();
  fromDealStages = new FormControl();
  allPipelines = new FormControl();
  dealStages = new FormControl();

  dealId: string;
  pipelineId: string;
  pipelineName: string;
  pipelines: any[] = [];
  stageId: string;
  stageName: string;
  stages: any[] = [];
  stepName: string;

  fromPipelineId: string;
  fromPipelineName: string;
  fromStageId: string;
  fromStageName: string;
  fromStages: any[] = [];

  public config: PerfectScrollbarConfigInterface = {};
  cancelText: string;

  constructor(
    public dialogRef: MatDialogRef<ExportDealComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dlgTitle = 'Download Deals';
    this.cancelText = 'Cancel';
    this.stepName = '';
    this.isAutomation = false;
    this.isExportDeals = true;
    this.isMoveDeals = false;
    this.isMoveOnReply = false;
    this.isChat = false;

    this.dealId = '';
    this.pipelineId = '';
    this.pipelineName = '';
    this.stageId = '';
    this.stageName = '';
    this.fromPipelineId = '';
    this.fromPipelineName = '';
    this.fromStageId = '';
    this.fromStageName = '';

    if (!isUndefinedOrNullOrEmpty(this.data)) {
      if (!isUndefinedOrNull(this.data.isAutomation)) {
        this.isAutomation = this.data.isAutomation;
        if (!isUndefinedOrNullOrEmpty(this.data.stepName)) {
          this.stepName = this.data.stepName;
        }
      }
      if (!isUndefinedOrNull(this.data.isChat)) {
        this.isChat = this.data.isChat;
      }
      if (!isUndefinedOrNull(this.data.isExportDeals)) {
        this.isExportDeals = this.data.isExportDeals;
      }
      if (!isUndefinedOrNull(this.data.isMoveDeals)) {
        this.isMoveDeals = this.data.isMoveDeals;
      }
      if (!isUndefinedOrNull(this.data.isMoveOnReply)) {
        this.isMoveOnReply = this.data.isMoveOnReply;
        this.dlgTitle = 'Move on 1st Reply';
      }

      // Data maybe in this.data or this.data.custom for automations
      if (!isUndefinedOrNullOrEmpty(this.data.pipelines)) {
        this.pipelines = this.data.pipelines;
        if (!isUndefinedOrNullOrEmpty(this.data.dealId)) {
          this.dealId = this.data.dealId;
        }
      } else if (
        !isUndefinedOrNullOrEmpty(this.data.custom) &&
        !isUndefinedOrNullOrEmpty(this.data.custom.pipelines)
      ) {
        this.pipelines = this.data.custom.pipelines;
        if (!isUndefinedOrNullOrEmpty(this.data.custom.dealId)) {
          this.dealId = this.data.custom.dealId;
        }
      }

      if (!isUndefinedOrNullOrEmpty(this.data.fromPipelineId)) {
        this.fromPipelineId = this.data.fromPipelineId;
      } else {
        this.fromPipelineId = '';
      }
      if (!isUndefinedOrNullOrEmpty(this.data.fromStageId)) {
        this.fromStageId = this.data.fromStageId;
      } else {
        this.fromStageId = '';
      }
      if (!isUndefinedOrNullOrEmpty(this.data.pipelineId)) {
        this.pipelineId = this.data.pipelineId;
      } else {
        this.pipelineId = '';
      }
      if (!isUndefinedOrNullOrEmpty(this.data.stageId)) {
        this.stageId = this.data.stageId;
      } else {
        this.stageId = '';
      }

      // Ensure we have both from and to pipeline and stage IDs
      if (this.pipelineId === '' && this.fromPipelineId !== '') {
        this.pipelineId = this.fromPipelineId;
      }
      if (this.stageId === '' && this.fromStageId !== '') {
        this.stageId = this.fromStageId;
      }

      // Ensure we have both from and to pipeline and stage NAMES
      if (this.fromPipelineId !== '') {
        let pipelineIndex = this.pipelines.findIndex(
          (pipeline) => pipeline.id === this.fromPipelineId
        );
        if (pipelineIndex === -1) {
          pipelineIndex = 0;
        }
        this.fromPipelineName = this.pipelines[pipelineIndex].pipelineName;
        this.fromStages = [];
        this.fromStages.push({
          id: '',
          name: '--- All Stages ---',
          stageId: '',
          stageName: '--- All Stages ---',
        });
        for (const stage of this.pipelines[pipelineIndex].stages) {
          this.fromStages.push(stage);
        }
      }
      if (this.pipelineId !== '') {
        let pipelineIndex = this.pipelines.findIndex(
          (pipeline) => pipeline.id === this.pipelineId
        );
        if (pipelineIndex === -1) {
          pipelineIndex = 0;
        }
        this.pipelineName = this.pipelines[pipelineIndex].pipelineName;
        this.stages = [];
        this.stages.push({
          id: '',
          name: '--- All Stages ---',
          stageId: '',
          stageName: '--- All Stages ---',
        });
        for (const stage of this.pipelines[pipelineIndex].stages) {
          this.stages.push(stage);
        }
      }
    }
  }

  ngOnInit() {
    // Set the pipeline and stage on the form.
    this.autoStepName.setValue(this.stepName);
    this.fromPipelines.setValue(this.pipelineId);
    this.fromDealStages.setValue(this.fromStageId);
    this.allPipelines.setValue(this.pipelineId);
    this.dealStages.setValue(this.stageId);

    this.formMoveDeal = new FormGroup({
      autoStepName: this.autoStepName,
      fromPipelines: this.allPipelines,
      fromDealStage: this.fromDealStages,
      allPipelines: this.allPipelines,
      dealStages: this.dealStages,
    });
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  changePipeline(pipelineIndex: number) {
    this.stages = [];
    this.stages.push({
      id: '',
      name: '--- All Stages ---',
      stageId: '',
      stageName: '--- All Stages ---',
    });
    for (const stage of this.pipelines[pipelineIndex].stages) {
      this.stages.push(stage);
    }

    this.stageId = this.stages[0].stageId;
    this.dealStages.setValue(this.stageId);
  }

  export() {
    const pipelineId = this.allPipelines.value;
    const stageId = this.dealStages.value;
    const stepName = this.autoStepName.value;
    let stageName = '';

    let fromPipelineName = '';
    const fromStageId = this.fromDealStages.value;
    let fromStageName = '';
    let pipelineName = '';

    const pipelineIndex = this.pipelines.findIndex(
      (pipeline) => pipeline.id === this.pipelineId
    );
    if (pipelineIndex > -1) {
      pipelineName = this.pipelines[pipelineIndex].name;
    }

    const fromIndex = this.pipelines.findIndex(
      (pipeline) => pipeline.id === this.fromPipelineId
    );
    if (fromIndex > -1) {
      fromPipelineName = this.pipelines[fromIndex].name;
    }
    let stageIndex = this.stages.findIndex((stage) => stage.id === stageId);
    if (stageIndex > -1) {
      stageName = this.stages[stageIndex].name;
    }
    stageIndex = this.fromStages.findIndex((stage) => stage.id === fromStageId);
    if (stageIndex > -1) {
      fromStageName = this.fromStages[stageIndex].name;
    }

    const data = {
      fromPipelineId: this.fromPipelineId,
      fromPipelineName,
      fromStageId: this.fromDealStages.value,
      fromStageName,
    };
    console.log('data:', data);

    this.dialogRef.close(data);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
