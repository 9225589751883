
<h2 mat-dialog-title class="dialogTitle">{{ title }}
  <div class="dlg-button-close" (click)="close()">
      <mat-icon>close</mat-icon>
  </div>
</h2>
<mat-dialog-content *ngIf="recordCount > 0" [formGroup]="form">

  <mat-form-field>
    <input matInput placeholder="Enter filename for data export" formControlName="filename">
  </mat-form-field>

  <mat-form-field>
    <mat-select placeholder="Choose export file type" [(value)]="selectedType">
      <mat-option *ngFor="let exportType of exportTypes" [value]="exportType.value" (click)="updateFilename(selectedType)">
        {{ exportType.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div><span style="font-weight: bold;">{{ recordCount }}</span> {{ recordCountMessage }}</div>

</mat-dialog-content>

<mat-dialog-content *ngIf="recordCount < 1">

  <div>No leads to export</div>

</mat-dialog-content>


<mat-dialog-actions *ngIf="recordCount > 0" align="end">
  <button mat-raised-button color="primary" (click)="export()">Export</button>
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="recordCount < 1" align="end">
  <button mat-raised-button color="primary" (click)="close()">Close</button>
</mat-dialog-actions>
