import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'letterBold'
})
export class LetterBoldPipe implements PipeTransform {

  transform(value: string, search: string): any {
    if (!search) { return value; }

    let indexAdder = 0;
    let indexs = this.locations(search.toLowerCase(), value.toLowerCase());
    indexs = indexs.map((x) => {
      const solution = x + indexAdder;
      indexAdder += 2;
      return solution;
    });

    const searchLength = search.length;
    const holder = value.split('');
    indexs.forEach((i) => {
      holder.splice(i, 0, '<span class="bold">');
      holder.splice(i + searchLength + 1, 0, '</span>');
    });

    return holder.join('');
  }

  locations(substring: string, searchValue: string) {
    const indexs = [];
    let i = -1;
    while ((i = searchValue.indexOf(substring, i + 1)) >= 0) {
      indexs.push(i);
    }

    return indexs;
  }
}
