import { Component, Output, ViewChild, ElementRef, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';

const APP_NAME = 'LeadCarrot CRM';
const APP_URL_IMPORT = '/import';
const CURRENT_URL = 'Current app url';
const FILE_TYPE_CSV = 'csv';
const FILE_UPLOAD_COMPLETE = 'File upload complete';
const ONLY_CSV_FILES_SUPPORTED = 'Only .CSV files are supported';
const UNSUPPORTED_FILE_TYPE = 'Unsupported file type';
const SNACKBAR_ACTION_CLOSE = 'close';
const SNACKBAR_DURATION = 2500;
const TASK_RUNNING = 'running';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit, OnDestroy {
  @ViewChild('file', { static: true }) file: ElementRef;
  @Output() fileUrlEmmiter = new EventEmitter();
  @Input() fromCampaign = false;
  @Input() userId = '';

  allSubscriptions = new Subscription();

  hasBaseDropZoneOver = false;
  isHovering: boolean;              // State for dropzone CSS toggling

  task: AngularFireUploadTask;      // Main task
  percentage: Observable<number>;   // Progress monitoring
  snapshot: Observable<any>;
  downloadURL: Observable<string>;  // Download URL

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage,
  ) { }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  ngOnInit() {
    this.reset();
  }

  /**
   * Storage path on the cloud server for the file.
   * @param pathData JSON data to choose from in creating the storage path url.
   */
  createStoragePath(pathData: any): string {
    const path = pathData.currentUrl + '/' + new Date().getTime() + '_' + pathData.currentUser + '_' + pathData.filename;
    return path;
  }

  /**
   * Is the file upload task active
   * @param snapshot Upload task snapshot.
   */
  isActive(snapshot) {
    return snapshot.state === TASK_RUNNING && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  /**
   * Client-side validation of filetype using only the file extension.
   * @param currentUrl App URL route we currently are on.
   * @param file Filename to validate the extension.
   */
  isValidFiletype(currentUrl: string, file: File) {
    const response = {
      success: true,
      message: '',
    };
    if (currentUrl === APP_URL_IMPORT) {
      const fileExtension = file.name.split('.')[1].toLowerCase();
      if (fileExtension !== FILE_TYPE_CSV) {
        console.error(UNSUPPORTED_FILE_TYPE + '[' + fileExtension + ']');
        response.success = false;
        response.message = ONLY_CSV_FILES_SUPPORTED;
      }
    }
    return response;
  }

  /**
   * Display a message to the user with the Material Snackbar.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   */
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: SNACKBAR_DURATION,
    });
  }

  /**
   * Reset the UI display
   */
  reset() {
    this.file.nativeElement.value = '';
    this.percentage = of(0);
    this.downloadURL = of('');
  }

  /**
   * Start a new task to upload a file to Cloud Storage.
   * @param path The path in Cloud Storage for the file.
   * @param file The file to upload from the UI <input> element.
   * @param metadata Extra metadata for the file to help find it later.
   */
  startUploadTask(path: string, file: File, metadata: firebase.storage.UploadMetadata) {
    // The main task
    this.task = this.storage.upload(path, file, metadata);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();

    // ON task completion
    this.task.then(res => {
      console.log(FILE_UPLOAD_COMPLETE + ':', res);
      const fileRef = this.storage.ref(path);

      // The file's download URL
      this.downloadURL = fileRef.getDownloadURL();
      this.allSubscriptions.add(
        this.downloadURL.subscribe(
          url => {
            // const temp = res.metadata.fullPath.split('/');
            const data = {
              fileName: file.name,
              url,
            };
            // const body = JSON.parse(data);
            this.fileUrlEmmiter.emit(data);
          },
          err => {
            console.error(err);
          })
      );
    });
  }

  /**
   * UI function to toggle a CSS class.
   * @param isHovering TRUE if the mouse is hovering over the drop-zone
   */
  toggleHover(isHovering: boolean) {
    this.isHovering = isHovering;
  }

  /**
   * Begin process of uploading a file to Cloud Storage.
   * @param event List of files from the UI <input> element.
   */
  uploadFile(event: FileList) {
    // Get the first item from the list
    const file = event.item(0);

    // Current Route
    const currentUrl = this.router.url;
    // this.currentUrl = this.router.url.split('/')[2].split('?')[0];
    console.log(CURRENT_URL + ':', this.router.url);

    // Validate file type based on route
    const validStatus = this.isValidFiletype(currentUrl, file);
    if (!validStatus.success) {
      this.openSnackBar(validStatus.message, SNACKBAR_ACTION_CLOSE);
      return;
    }

    // Current User ID
    const currentUser = this.userId; // this.sessionService.user.value.id;
    console.log('current user id:', currentUser);

    // Create storage path for file on the server
    const pathData = { filename: file.name, currentUser, currentUrl };
    const path = this.createStoragePath(pathData);

    // Totally optional metadata
    const metadata = {
      customMetadata: {
        app: APP_NAME,
        appUrl: currentUrl,
        userId: currentUser,
      }
    };

    this.startUploadTask(path, file, metadata);
  }
}
