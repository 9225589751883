<h2 mat-dialog-title class="dialogTitle">{{ title }}
  <div class="dlg-button-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
</h2>

<mat-dialog-content>
  <mat-list>
    <h3 matSubheader>
      <span style="padding-left: 10px;">Contact Merge Fields</span>
    </h3>
    <mat-list-item *ngFor="let field of contactFields" (click)="addField(field.name, field.code)">
      <mat-icon matListIcon>person</mat-icon>
      <h4 matLine>{{ field.name }}</h4>
      <p matLine class="demo-2"> {{ field.code }} </p>
    </mat-list-item>

    <mat-divider></mat-divider>

    <h3 matSubheader>
      <span style="padding-left: 10px;">Organization Merge Fields</span>
    </h3>
    <mat-list-item *ngFor="let field of organizationFields" (click)="addField(field.name, field.code)">
      <mat-icon matListIcon>business</mat-icon>
      <h4 matLine>{{ field.name }}</h4>
      <p matLine class="demo-2"> {{ field.code }} </p>
    </mat-list-item>

    <mat-divider></mat-divider>

    <h3 matSubheader>
      <span style="padding-left: 10px;">Deal Merge Fields</span>
    </h3>
    <mat-list-item *ngFor="let field of dealFields" (click)="addField(field.name, field.code)">
      <mat-icon matListIcon>monetization_on</mat-icon>
      <h4 matLine>{{ field.name }}</h4>
      <p matLine class="demo-2"> {{ field.code }} </p>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- <button mat-raised-button color="primary" (click)="addField()">Add</button> -->
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>
