import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {

  @Input() userId: string;

  showDropZone: boolean;
  isHovering: boolean;
  files: File[] = [];

  constructor() {
    this.showDropZone = true;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    console.log('UploaderComponent.onDrop() | FileList:', files);
    this.showDropZone = false;
    for (let index = 0; index < files.length; index++) {
      this.files.push(files.item(index));
    }
  }
}
