<div id="timeline">
    <div *ngFor="let item of list; let i = index" class="timeline-item">
        <div [class]="item.styleClass? item.styleClass: ''">
            <div class="timeline-icon">
                <span class="item-icon {{item?.icon}}"></span>
            </div>
            <div class="timeline-content" [class.right]="i % 2 !== 0">
                <h2>{{item?.title}}</h2>

                <div class="post-header" style="margin-left: 0; padding-left: 0;">
                    <div style="margin: 0 3px; height: 40px; width: 40px;">
                        <img style="height: 40px; width: 40px; border-radius: 50%;" alt="{{ item?.userName }}"
                            [src]="item?.userPhotoURL">
                    </div>

                    <div fxFlex>
                        <div class="post-from">
                            <strong><span>{{ item?.userName }}</span></strong>
                        </div>
                        <div class="post-last-updated">
                            <span class="post-time" [matTooltip]="formatDate(item?.createdAt?.seconds * 1000)" timeago
                                [date]="item?.createdAt?.seconds * 1000" [live]="true"></span>
                        </div>
                    </div>
                </div>

                <p>
                    {{item?.content}}
                </p>

                <button *ngIf="item?.command" class="btn bg-green" (click)="action(item)">
                    <i class="fa fa-search-plus"></i> {{item?.label}}
                </button>
            </div>
        </div>
    </div>
</div>