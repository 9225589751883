import { ISequence, IStep, IAutomationHeaderNames } from '../models';

export const headers: IAutomationHeaderNames = {
    schedule: 'Schedule',
    active: 'Active',
    sent: 'Sent',
    clicks: 'Clicks',
    openRate: 'Open Rate',
    ctr: 'CTR',
};

export const steps: IStep[] = [
    /*
    {
        id: '1',
        type: 'action',
        action: 'send',
        name: 'Send Email Message 1',
        isActive: true,
        stats: { sent: 246, clicks: 149, openRate: 98.4, ctr: 61.1, },
        schedule: {
            unitValue: 30,
            maxUnits: 'minutes', // immediately, minutes, hours, days
            maxValue: 30,
            unitTime: 'minutes',
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        },
        index: 1,
        isDeleted: false,
        companyId: 'OWkllMIov655LHq3JpQ7',
        stageId: 'f65f95cb-6bf6-432b-a14d-3ff7ed55db48',
        subject: 'email',
        sequenceId: '',
    },
    */
    {
        id: '2',
        type: 'action',
        action: 'send',
        name: 'Send SMS Message 1',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, delivered: 0, opened: 0, },
        schedule: {
            unitValue: 30,
            maxUnits: 'day', // immediately, minutes, hours, days
            maxValue: 5,
            unitTime: 'day',
            minUnits: 'day', // immediately, minutes, hours, days
            minValue: 1,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        },
        index: 2,
        isDeleted: false,
        companyId: 'OWkllMIov655LHq3JpQ7',
        stageId: 'f65f95cb-6bf6-432b-a14d-3ff7ed55db48',
        subject: 'sms',
        sequenceId: '',
    }, {
        id: '3',
        type: 'action',
        action: 'send',
        name: 'Phone Call 1',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, delivered: 0, opened: 0, },
        schedule: {
            unitValue: 30,
            maxUnits: 'day', // immediately, minutes, hours, days
            maxValue: 5,
            unitTime: 'day',
            minUnits: 'day', // immediately, minutes, hours, days
            minValue: 1,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        },
        index: 3,
        isDeleted: false,
        companyId: 'OWkllMIov655LHq3JpQ7',
        stageId: 'f65f95cb-6bf6-432b-a14d-3ff7ed55db48',
        subject: 'phone',
        sequenceId: '',
    }, {
        id: '4',
        type: 'action',
        action: 'send',
        name: 'Send VoiceMail 1',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, delivered: 0, opened: 0, },
        schedule: {
            unitValue: 30,
            maxUnits: 'day', // immediately, minutes, hours, days
            maxValue: 5,
            unitTime: 'day',
            minUnits: 'days', // immediately, minutes, hours, days
            minValue: 2,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        },
        index: 4,
        isDeleted: false,
        companyId: 'OWkllMIov655LHq3JpQ7',
        stageId: 'f65f95cb-6bf6-432b-a14d-3ff7ed55db48',
        subject: 'vm',
        sequenceId: '',
    }, {
        id: '5',
        type: 'action',
        action: 'send',
        name: 'Send ChatBot Message 1',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, delivered: 0, opened: 0, },
        schedule: {
            unitValue: 30,
            maxUnits: 'hours', // immediately, minutes, hours, days
            maxValue: 22,
            unitTime: 'hours',
            minUnits: 'hours', // immediately, minutes, hours, days
            minValue: 3,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        },
        index: 5,
        isDeleted: false,
        companyId: 'OWkllMIov655LHq3JpQ7',
        stageId: 'f65f95cb-6bf6-432b-a14d-3ff7ed55db48',
        subject: 'chatbot',
        sequenceId: '',
    }, {
        id: '6',
        type: 'action',
        action: 'send',
        name: 'Trigger Zap 1',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, delivered: 0, opened: 0, },
        schedule: {
            unitValue: 30,
            maxUnits: 'minutes', // immediately, minutes, hours, days
            maxValue: 45,
            unitTime: 'minutes',
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        },
        index: 6,
        isDeleted: false,
        companyId: 'OWkllMIov655LHq3JpQ7',
        stageId: 'f65f95cb-6bf6-432b-a14d-3ff7ed55db48',
        subject: 'zapier',
        sequenceId: '',
    }, {
        id: '7',
        type: 'action',
        action: 'send',
        name: 'Send SMS Message 2',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, delivered: 0, opened: 0, },
        schedule: {
            unitValue: 30,
            maxUnits: 'minutes', // immediately, minutes, hours, days
            maxValue: 45,
            unitTime: 'minutes',
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        },
        index: 7,
        isDeleted: false,
        companyId: 'OWkllMIov655LHq3JpQ7',
        stageId: 'f65f95cb-6bf6-432b-a14d-3ff7ed55db48',
        subject: 'sms',
        sequenceId: '',
    }
];

/*
export const newSteps: IStep[] = [
    {
        id: '1',
        type: 'email',
        action: 'send',
        name: 'Send Email Message',
        isActive: true,
        stats: { sent: 246, clicks: 149, openRate: 98.4, ctr: 61.1, },
        schedule: {
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '2',
        type: 'sms',
        action: 'send',
        name: 'Send SMS Message',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'day', // immediately, minutes, hours, days
            minValue: 1,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '8',
        type: 'activity',
        action: 'activity',
        name: 'Add Activity',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '9',
        type: 'deal',
        action: 'deal',
        name: 'Add Deal',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '7',
        type: 'goto',
        action: 'goto_step',
        name: 'Goto another Stage',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '6',
        type: 'zap',
        action: 'send',
        name: 'Trigger Zap',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'minutes', // immediately, minutes, hours, days
            minValue: 30,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '5',
        type: 'chatbot',
        action: 'send',
        name: 'Send ChatBot Message',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'hours', // immediately, minutes, hours, days
            minValue: 3,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '4',
        type: 'vm',
        action: 'send',
        name: 'Send VoiceMail',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'days', // immediately, minutes, hours, days
            minValue: 2,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }, {
        id: '3',
        type: 'phone',
        action: 'send',
        name: 'Phone Call',
        isActive: true,
        stats: { sent: 0, clicks: 0, openRate: 0, ctr: 0, },
        schedule: {
            minUnits: 'day', // immediately, minutes, hours, days
            minValue: 1,
            timeSettingType: 'any_time',  // any_time, time_range
            fromTime: 0,
            toTime: 24,
            weekDaySelect: { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, },
        }
    }
];
*/
