import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../models/User';
import { Message } from '../../models/Message';

import * as firebase from 'firebase/app';

/* globally accessible app code (in every feature module) */
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messagesCollection: AngularFirestoreCollection<Message>;
  msgDoc: AngularFirestoreDocument<Message>;
  msgs: Observable<Message[]>;
  msg: Observable<Message>;

  debug = false;

  private COL_SYS_MSGS = 'system_messages';
  private enableSoftDelete = false;
  userEmail: string;
  user: User;
  uuid: string;

  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    public userService: UserService
  ) {
    // // console.log('INIT MessageService.constructor()');
    this.resetService();

    this.authService.user.subscribe((user) => {
      // console.log( 'MessageService.constructor() -> this.authService.getAuth().subscribe()' );

      if (user) {
        // console.log('MessageService.constructor() - AUTHORIZED'); // | (uid) == ' + auth.uid + '  | (auth) == ', auth);
        this.userEmail = user.email;
      } else {
        this.resetService();
        // console.log('MessageService.constructor() - NOT AUTHORIZED');
      }
    });
  }

  private resetService() {
    // console.log('*** MessageService.resetService() ***');

    this.user = null; // This comes from UserService
    this.uuid = '';
    this.userEmail = '';

    // Get latest system message
    this.messagesCollection = this.afs.collection(this.COL_SYS_MSGS, (ref) => {
      return ref.orderBy('date_created', 'desc');
    });
    this.messagesCollection
      .snapshotChanges()
      .pipe(
        map((changes) => {
          if (!changes.length) {
            // console.log('is-empty');
            // this.setWaitStatus(false);
          } else {
            // this.setWaitStatus(true);
          }
        })
      )
      .subscribe();
  }

  get(id: string): Observable<Message> {
    // ToDo: Add restriction for Contacts you own or have Visibility to.

    this.msgDoc = this.afs.doc<Message>(this.COL_SYS_MSGS + `/${id}`);
    this.msg = this.msgDoc.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as Message;
          data.id = action.payload.id;
          return data;
        }
      })
    );

    return this.msg;
  }

  // getAll(uid: string, category: string, city: string, state: string) {
  getLast(uuid: string = '') {
    // Get latest system message
    this.messagesCollection = this.afs.collection(this.COL_SYS_MSGS, (ref) => {
      return ref.orderBy('date_created', 'desc').limit(1);
    });

    return this.messagesCollection.snapshotChanges().pipe(
      map((changes) => {
        if (changes === []) {
          // this.setWaitStatus(false);
        } else {
          // this.setWaitStatus(true);
        }
        return changes.map((action) => {
          const data = action.payload.doc.data();
          data.id = action.payload.doc.id;
          // console.log({ msg: { data } });
          return data;
        });
      })
    );
  }
}
