import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { IUser } from '../models';
import { Subscription } from 'rxjs';
// import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  subs: Subscription;
  user: IUser;
  isLoggedIn: boolean;

  constructor(
    private authService: AuthService,
    // private route: ActivatedRoute,
    // private router: Router,
  ) {

    this.isLoggedIn = false;
    this.subs = new Subscription();
    this.user = null;
  }

  ngOnInit(): void {
    console.log('ngOnInit()');

    this.subs.add(
      this.authService.user
        .subscribe(user => {
          this.user = user;
          if (this.user) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = false;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
