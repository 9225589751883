<div class="perfect-scrollbar-container">
    
    <perfect-scrollbar [config]="config" #userPS  style="height: calc(100vh);">

    <!--
        (psXReachEnd)="onScrollMessagesEvent($event)"
        (psYReachEnd)="onScrollMessagesEvent($event)"
        (psXReachStart)="onScrollMessagesEvent($event)"
        (psYReachStart)="onScrollMessagesEvent($event)">
    -->

        <mat-card *ngIf="isUploadPanelVisible" class="only-bottom-margin">
            <mat-card-title>Upload Profile Photo</mat-card-title>
            <mat-card-content class="no-margin">
                <div class="example-container" style="width: 100%;">
                    <div fxLayout="row column" fxLayoutWrap="wrap">
                        <div fxFlex="100">
                            <div class="row">
                                <uploader [userId]="userId"></uploader>
                            </div>
                            <div class="row" id="uploadUserPhoto" style="padding: 10px 0;">
                                <button mat-raised-button color="primary" (click)="isUploadPanelVisible = false;">
                                    Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!isUploadPanelVisible" class="no-margin">
            <mat-card-title>Profile Info</mat-card-title>
            <mat-card-content class="no-margin" style="padding-top: 0 !important;">
                <div class="example-container" style="width: 100%;">
                    <form class="profileForm" style="margin: 0; padding: 0;" [formGroup]="userGroup">

                        <div fxLayout="row column" fxLayoutWrap="wrap">

                            <div fxFlex="100">
                                <div id="showUserPhoto" style="margin: 0; padding: 0;">
                                    <ngx-avatar *ngIf="!user?.photoURL" gravatarId="{{ user?.email }}" size="132"
                                        round="true" src="/assets/images/favicon.png" class="profile-pic"></ngx-avatar>
                                    <ngx-avatar *ngIf="user?.photoURL" size="132" round="true" src="{{ user?.photoURL }}"
                                        class="profile-pic"></ngx-avatar>
                                </div>
                            </div>

                            <div fxFlex="100">
                                <button mat-raised-button color="primary" (click)="isUploadPanelVisible = true;">
                                    Change Photo</button>
                            </div>

                            <div fxFlex="100" style="margin-top: 20px; padding-bottom: 10px;">
                                <mat-form-field>
                                    <mat-icon matPrefix svgIcon="account"></mat-icon>
                                    <input matInput placeholder="Your Name" formControlName="displayName">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-icon matPrefix svgIcon="email"></mat-icon>
                                    <input matInput placeholder="Your Email" formControlName="userEmail"
                                        readonly="readonly">
                                </mat-form-field>

                                <!--
                                <mat-form-field>
                                    <input matInput placeholder="Country" formControlName="country">
                                    </mat-form-field>
                                -->

                                <!--
                                <mat-form-field>
                                    <mat-select placeholder="Country" formControlName="countryCode">
                                        <mat-option *ngFor="let country of countries" value="{{ country.value }}">
                                            {{country.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                -->

                                <!--
                                <mat-select-country label="Country" class="className" formControlName="country"
                                    (onCountrySelected)="onCountrySelected($event)">
                                </mat-select-country>
                                -->

                                <!--
                                <mat-form-field>
                                    <input matInput placeholder="Your Phone Number" formControlName="phoneNumber"
                                        (blur)="verifyNumber()">
                                </mat-form-field>
                                -->

                                <mat-icon style="color: green;" svgIcon="phone"></mat-icon>
                                <ngx-intl-tel-input [inputId]="'phoneNumber0'" [cssClass]="'custom'"
                                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true" [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode" name="phoneNumber" formControlName="phoneNumber">
                                </ngx-intl-tel-input>

                                <!--
                                <div><strong>Form value:</strong>
                                    <pre>{{ userGroup.value | json }}</pre>
                                </div>
                                -->
                            </div>

                            <!--
                            <div fxFlex="100">
                                <mat-list class="apiList">
                                    <mat-list-item *ngFor="let account of googleAccounts$ | async; let i = index"
                                        class="apiItem">
                                        <span class="apiValue">
                                            {{ account.email }}
                                        </span>
                                        <mat-slide-toggle class="apiToggle" color="primary" [checked]="account.isEnabled"
                                            (change)="toggleEnabled(account.id)">
                                        </mat-slide-toggle>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                            <div fxFlex="100">
                                <button mat-raised-button color="default" id="btnGoogleLogin"
                                    class="btn-block btn-lg m-t-20 m-b-20" style="margin-bottom: 10px; margin-right: 20px;"
                                    (click)="signInAuth('google')" matTooltip="Login with Google">
                                    <div style="position: absolute; left: 12px; top: -12px;">
                                        <mat-icon style="transform: scale(2.0);" svgIcon="google-login">
                                        </mat-icon>
                                    </div>
                                    Login with Google
                                </button>
                            </div>
                            -->
                        </div>
                    </form>
                </div>
                <div class="example-container" style="width: 100%;">
                    <button mat-raised-button color="primary" class="btn-bottom-rightx"
                        (click)="updateProfile()">Save</button>
                </div>

                <!--
                <div fxLayout="row column" fxLayoutWrap="wrap">
                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100"></div>
                </div>
                -->

                <hr />

                <div class="example-container" style="width: 100%; height: 350px;">
                    <h2>Change Password</h2>
                    <form class="passwordForm" style="margin: 0; padding: 0;" [formGroup]="passwordForm"
                        #passwordFormDirective="ngForm">

                        <div fxLayout="row column" fxLayoutWrap="wrap">

                            <!-- col full-->

                            <div fxFlex="100">
                                <mat-form-field>
                                    <input matInput placeholder="New Password" formControlName="newPassword" required>
                                </mat-form-field>
                            </div>

                            <div fxFlex="100">
                                <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="newPassword.errors.required">
                                        Password is required.
                                    </div>
                                    <div *ngIf="newPassword.errors.minlength">
                                        Password must be at least 6 characters long.
                                    </div>
                                    <!--
                            <div *ngIf="newPassword.errors.forbiddenName">
                                Password cannot be Bob.
                            </div>
                            -->
                                </div>
                                <mat-form-field>
                                    <input matInput placeholder="Repeat New Password" formControlName="newPasswordRepeat"
                                        required>
                                </mat-form-field>
                                <div *ngIf="passwordForm.errors?.noMatch && (newPasswordRepeat.touched || newPasswordRepeat.dirty)"
                                    class="cross-validation-error-message alert alert-danger">
                                    Passwords must match
                                </div>
                            </div>

                            <div fxFlex="100">
                                <button *ngIf="isDisabled()" mat-raised-button class="btn-bottom-right"
                                    style="float: right;" color="primary" disabled (click)="changePassword()">Save</button>
                                <button *ngIf="!isDisabled()" mat-raised-button class="btn-bottom-right"
                                    style="float: right;" color="primary" (click)="changePassword()">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-card-content>
        </mat-card>

    </perfect-scrollbar>
</div>