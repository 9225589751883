import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
// import * as firebase from 'firebase/app';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import localforage from 'localforage';

// import { User } from '../models/User';
import {
  COLLECTION,
  isUndefinedOrNull,
  isUndefinedOrNullOrEmpty,
  getDocuments,
  createAuthHeaders,
} from '../../shared/utils';

import { IUser } from '../../models/IUser';
import { ICompany } from '../../models/ICompany';
import { ITeam } from '../../models/ITeam';
import { IPipeline } from '../../models/IPipeline';
import { IStage } from '../../models/IStage';
import { Signup } from '../../models';

// import { SessionService } from './session.service';
// import { Store } from '@ngrx/store';
// import * as fromRoot from '../../app.reducer';
// import * as UI from '../../shared/ui.actions';
import { environment } from '../../../environments/environment';

const IS_DEBUG = !environment.production;

const FIREBASE_PROJECT_ID_PROD = 'leadcarrot-prod';
const CRM_URL_PROD = 'https://leadcarrot.io';
const CRM_URL_DEV = 'https://crm-dev.leadcarrot.io';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  END_POINT: string;
  CLOUD_URL: string;

  authUser: firebase.User;

  // tslint:disable-next-line: variable-name
  private _companyId: string;
  private companyId: BehaviorSubject<string>;

  // tslint:disable-next-line: variable-name
  private _user: IUser;
  user: BehaviorSubject<IUser>;
  user$: Observable<IUser>;

  // tslint:disable-next-line: variable-name
  private _company: ICompany;
  company: BehaviorSubject<ICompany>;
  company$: Observable<ICompany>;

  subs = new Subscription();

  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private http: HttpClient // private store: Store<fromRoot.State>,
  ) {
    if (!IS_DEBUG) {
      this.END_POINT = environment.backendApiUrl;
    } else {
      this.END_POINT = environment.backendApiDebugUrl;
    }
    this.CLOUD_URL = environment.cloudUrl;

    this._companyId = null;
    this.companyId = new BehaviorSubject<string>(this._companyId);

    this._company = null;
    this.company = new BehaviorSubject<ICompany>(this._company);
    this.company$ = of(null);

    this._user = null;
    this.user = new BehaviorSubject<IUser>(this._user);

    this.afAuth.authState.subscribe(
      (user) => {
        if (user) {
          // console.log('user email:', user.email);
          // console.log('user uid:', user.uid);

          this.authUser = user;
          this._loadUserData(user.uid, user);

          return;
        } else {
          // console.log('--- #1 - AuthState Change USER === NULL');
          this.authUser = null;
          this._user = null;
          this.user.next(this._user);
        }
      },
      (error) => {
        console.warn(error.message);
        this.authUser = null;
        this._user = null;
        this.user.next(this._user);
        this.afAuth.signOut();
      }
    );

    /*
    this.user$ = this.afAuth.authState
        .pipe(
            switchMap(user => {
                console.log('--- #2 - AuthState user$:', user);
                console.log('--- #2 - AuthState Change:', user === null ? '-------' : user.email);
                if (user) {
                    this.authUser = user;
                    return this.afs
                        .collection(COLLECTION.USER)
                        .doc<any>(user.uid)
                        .snapshotChanges()
                        .pipe(
                            map(async action => {
                                if (action) {
                                    // Get document data
                                    const docData: any = action.payload.data();
                                    const docId = user.uid;

                                    // Use spread operator to add the id to the document data
                                    const data = { id: docId, ...docData };

                                    const companyId = typeof data.companyId === 'undefined' ? '' : data.companyId;
                                    let companyIds = [];
                                    if (!isUndefinedOrNullOrEmpty(data.companyIds)) {
                                        companyIds = Object.keys(data.companyIds);
                                    }

                                    const authEmail = isUndefinedOrNullOrEmpty(user.email) ? '' : user.email.trim();
                                    const authPhone = isUndefinedOrNullOrEmpty(user.phoneNumber) ? '' : user.phoneNumber.trim();
                                    const authName = isUndefinedOrNullOrEmpty(user.displayName) ?
                                        (authEmail === '' ? '' : authEmail) : user.displayName.trim();
                                    const authPhoto = isUndefinedOrNullOrEmpty(user.photoURL) ? '' : user.photoURL.trim();

                                    const email = isUndefinedOrNullOrEmpty(data.userEmail) ? authEmail : data.userEmail.trim();
                                    const phone = isUndefinedOrNullOrEmpty(data.phoneNumber) ? authPhone : data.phoneNumber.trim();
                                    const name = isUndefinedOrNullOrEmpty(data.displayName) ?
                                        (authName === '' ? email : authName) : data.displayName.trim();
                                    let photoURL = isUndefinedOrNullOrEmpty(data.photoURL) ? authPhoto : data.photoURL.trim();
                                    if (photoURL === '' && email !== '') {
                                        photoURL = 'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?d=identicon';
                                    }

                                    let lastPage = typeof data.lastPage === 'undefined' ? '' : data.lastPage;
                                    if (isUndefinedOrNullOrEmpty(lastPage)) {
                                        lastPage = '/dashboards/dashboard1';
                                    }
                                    localforage.setItem('*** user lastPage:', lastPage);

                                    const userData: IUser = {
                                        id: user.uid,
                                        name: name,
                                        photoURL: photoURL,
                                        email: email,
                                        phone: phone,
                                        countryCode: typeof data.country === 'undefined' ? 'us' : data.countryCode,
                                        companyId: companyId,
                                        companyIds: companyIds,
                                        companyDetails: data.companyDetail === 'undefined' ? [] : data.companyDetail,
                                        lastPage: lastPage,
                                        lastCompanyId: typeof data.lastCompanyId === 'undefined' ? companyId : data.lastCompanyId,
                                        lastPipelineId: typeof data.lastPipelineId === 'undefined' ? '' : data.lastPipelineId,
                                        isTrial: typeof data.isTrial === 'undefined' ? false : data.isTrial,
                                        emailId: typeof data.emailId === 'undefined' ? '' : data.emailId,
                                        smsId: typeof data.smsId === 'undefined' ? '' : data.smsId,
                                        messageToken: typeof data.messageToken === 'undefined' ? '' : data.messageToken,
                                        token: await user.getIdToken(),
                                    };

                                    this._user = userData;
                                    this.user.next(userData);

                                    console.log('!!! userData:', userData);
                                    return userData;
                                }
                            })
                        );
                } else {
                    return of(null);
                }
            })
        );
    */

    // this._companyIdListener();
  }

  private async _loadUserData(userId: string, user: any) {
    try {
      // console.log('load user data:', userId);

      return this.afs
        .collection(COLLECTION.USER)
        .doc<any>(user.uid)
        .snapshotChanges()
        .pipe(
          map(async (action: any) => {
            // console.log('*** ACTION:', action);

            if (action) {
              // Get document data
              const docData: any = action.payload.data();
              const docId = user.uid;

              // Use spread operator to add the id to the document data
              const data = { id: docId, ...docData };

              /*
              const companyId =
                typeof data.companyId === 'undefined' ? '' : data.companyId;
              let companyIds = [];
              if (!isUndefinedOrNullOrEmpty(data.companyIds)) {
                companyIds = Object.keys(data.companyIds);
              }
              */

              const authEmail = isUndefinedOrNullOrEmpty(user.email)
                ? ''
                : user.email.trim();
              const authPhone = isUndefinedOrNullOrEmpty(user.phoneNumber)
                ? ''
                : user.phoneNumber.trim();
              const authName = isUndefinedOrNullOrEmpty(user.displayName)
                ? authEmail === ''
                  ? ''
                  : authEmail
                : user.displayName.trim();
              const authPhoto = isUndefinedOrNullOrEmpty(user.photoURL)
                ? ''
                : user.photoURL.trim();

              const email = isUndefinedOrNullOrEmpty(data.userEmail)
                ? authEmail
                : data.userEmail.trim();
              const phone = isUndefinedOrNullOrEmpty(data.phoneNumber)
                ? authPhone
                : data.phoneNumber.trim();
              const name = isUndefinedOrNullOrEmpty(data.displayName)
                ? authName === ''
                  ? email
                  : authName
                : data.displayName.trim();
              const nameLcase = name.toLowerCase();
              let photoURL = isUndefinedOrNullOrEmpty(data.photoURL)
                ? authPhoto
                : data.photoURL.trim();
              if (photoURL === '' && email !== '') {
                photoURL =
                  'https://www.gravatar.com/avatar/' +
                  Md5.hashStr(email) +
                  '?d=identicon';
              }

              let lastPage =
                typeof data.lastPage === 'undefined' ? '' : data.lastPage;
              if (isUndefinedOrNullOrEmpty(lastPage)) {
                lastPage = '/dashboards/dashboard1';
              }
              localforage.setItem('*** user lastPage:', lastPage);

              /*
              const companyDetails =
                typeof data.companyDetails === 'undefined'
                  ? []
                  : data.companyDetails;

              const emailIds: string[] = [];
              const emailId =
                typeof data.emailId === 'undefined' ? '' : data.emailId;
              if (emailId !== '') {
                emailIds.push(emailId);
              }
              const smsIds: string[] = [];
              const smsId = typeof data.smsId === 'undefined' ? '' : data.smsId;
              if (smsId !== '') {
                smsIds.push(smsId);
              }

              const lastCompanyId = isUndefinedOrNullOrEmpty(data.lastCompanyId)
                ? companyId
                : data.lastCompanyId;
              */

              const userData: IUser = {
                id: user.uid,
                name,
                nameLcase,
                photoURL,
                email,
                phone,
                countryCode:
                  typeof data.country === 'undefined' ? 'us' : data.countryCode,
                /*
                companyId,
                companyIds,
                custom: {
                  companyDetails,
                  emailIds,
                  smsIds,
                },
                */
                lastPage,
                /*
                lastCompanyId,
                lastPipelineId:
                  typeof data.lastPipelineId === 'undefined'
                    ? ''
                    : data.lastPipelineId,
                */
                isTrial:
                  typeof data.isTrial === 'undefined' ? false : data.isTrial,
                /*
                messageToken:
                  typeof data.messageToken === 'undefined'
                    ? ''
                    : data.messageToken,
                */
                token: '',
              };
              this._user = userData;

              /*
              // Update last company ID
              if (isUndefinedOrNullOrEmpty(data.lastCompanyId)) {
                await this._updateLastCompanyId(companyId, userData.id);
              }
              */

              this._broadcastUserData().then((res) => res);

              // console.log('user:', this._user);
              return Promise.resolve(this._user);
            }

            return Promise.resolve(null);
          })
        )
        .subscribe();
    } catch (error) {
      console.warn(error.message);
      throw error;
    }
  }

  /*
  private async _updateLastCompanyId(companyId: string, userId: string) {
    try {
      if (
        isUndefinedOrNullOrEmpty(companyId) ||
        isUndefinedOrNullOrEmpty(userId)
      ) {
        return Promise.resolve(false);
      }

      this.afs
        .collection(COLLECTION.USER)
        .doc<any>(userId)
        .update({ lastCompanyId: companyId });

      // console.log('updated last company used:', companyId);

      return Promise.resolve(true);
    } catch (error) {
      return Promise.resolve(false);
    }
  }
  */

  private async _broadcastUserData() {
    (this._user.token = await this.authUser.getIdToken()),
      // this.user.next(userData);
      this.user.next(this._user);
    return Promise.resolve();
  }

  get userId(): string {
    if (isUndefinedOrNullOrEmpty(this._user)) {
      return '';
    }
    return this._user.id;
  }

  get userName(): string {
    if (isUndefinedOrNullOrEmpty(this._user)) {
      return '';
    }
    return this._user.name;
  }

  get userEmail(): string {
    if (isUndefinedOrNullOrEmpty(this._user)) {
      return '';
    }
    return this._user.email;
  }

  get userPhotoURL(): string {
    if (isUndefinedOrNullOrEmpty(this._user)) {
      return '';
    }
    return this._user.photoURL;
  }

  emailSignIn(email: string, password: string) {
    // this.store.dispatch(new UI.StartLoading);

    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((userData) => {
        // log('emailSignIn():', userData);

        // this.store.dispatch(new UI.StopLoading);
        return userData;
      });
  }

  /*
  emailSignUp(email: string, password: string): Promise<any> {
      // console.log('AuthService => emailSignUp()');
      return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
          .then((user) => this._updateUserData(user))
          .then(() => {
              // console.log('Welcome, your account has been created!');
          })
          .then(() => {
              this.afAuth.auth.currentUser.sendEmailVerification()
                  .then(() => console.log('We sent you an email verification'))
                  .catch((error) => console.error(error.message));
          })
          .catch((error) => console.error(error.message));
  }
  */

  // TODO: Create Facebook App and use API Key
  facebookLogin() {
    // TODO: Create Facebook App and use API Key
    const provider = new auth.FacebookAuthProvider();
    return this.socialLogin(provider);
  }

  googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    return this.socialLogin(provider);
  }

  githubLogin() {
    const provider = new auth.GithubAuthProvider();
    return this.socialLogin(provider);
  }

  twitterLogin() {
    const provider = new auth.TwitterAuthProvider();
    return this.socialLogin(provider);
  }

  /*
  linkSocialAccount(provider: auth.AuthProvider) {
    return this.afAuth.auth.currentUser.linkWithPopup(provider)
      .then(result => {
        // Accounts successfully linked.
        // const credential = result.credential;
        const user = result.user;
        return this._updateUserData(user);
      })
      .catch((error: any) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          this.mergeCredentials(error);
        } else if (error.code === 'auth/popup-blocked') {
          this.authLoginWithRedirect(provider);
        } else {
          // console.log(error.message);
          return null;
        }
      });
  }
  */

  /*
  unlinkSocialAccount(providerId): Promise<firebase.User> {
    return this.afAuth.auth.currentUser.unlink(providerId)
      .then(result => {
        // Auth provider unlinked from account
        return result;
      }).catch((error: any) => {
        // An error happened
        // console.log(error);
        return null;
      });
  }
  */

  private socialLogin(provider: auth.AuthProvider) {
    // console.log('AuthService => socialLogin()');

    return this.afAuth
      .signInWithPopup(provider)
      .then((credential) => {
        // console.log('socialLogin():', credential);
        const providerData = credential.user.providerData;
        // console.log('providers:', providerData);

        /*
        let hasAccount = false;
        if (providerData) {
            for (const item of providerData) {
                console.log('item:', item);
                if (item.providerId === 'password') {
                    hasAccount = true;
                    break;
                }
            }
            if (!hasAccount) {
                this.afAuth.auth.currentUser.delete();
                throw Error('No Lead Carrot account exists for this email.');
            }
        }
        return this._updateUserData(credential.user);
        */
        return credential;
      })
      .catch((error: any) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          this.mergeCredentials(error);
        } else if (error.code === 'auth/popup-blocked') {
          this.authLoginWithRedirect(provider);
        } else {
          // console.log(error.message);
          this.signOut();
          return error;
        }
      });
  }

  // Needed for merging accounts - merge Social Login Credentials with user data
  mergeCredentials(error: any): void {
    // console.log('AuthService => mergeCredentials() | error = error', error);
  }

  // Needed for merging accounts
  authLoginWithRedirect(provider: auth.AuthProvider): Promise<void> {
    // console.log('authLoginWithRedirect()');
    return this.afAuth.signInWithRedirect(provider).catch((error: any) => {
      console.error(error);
    });
  }

  signUp(data: Signup) {
    // console.log('AuthService => signUp()');
    // throw { message: 'User signup is disabled' };
    // this.store.dispatch(new UI.StartLoading);
    // this.emailSignUp(data.userEmail, data.password);
    const body = JSON.stringify(data);

    // console.log('signUp() | body:', body);

    return this.http
      .post(`${this.END_POINT}/user/signup`, body, {
        headers: createAuthHeaders(),
      })
      .pipe(
        map((res: Response) => res),
        catchError((error: Response) => throwError(error))
      );
    // this.store.dispatch(new UI.StopLoading);
  }

  private async _updateUserData(user) {
    // console.log('AuthService => _updateUserData()');
    const userRef: AngularFirestoreDocument<any> = this.afs
      .collection(COLLECTION.USER)
      .doc(user.uid);

    if (!isUndefinedOrNullOrEmpty(user)) {
      const email = isUndefinedOrNullOrEmpty(user.email)
        ? ''
        : user.email.trim();
      const phone = isUndefinedOrNullOrEmpty(user.phoneNumber)
        ? ''
        : user.phoneNumber.trim();
      const name = isUndefinedOrNullOrEmpty(user.displayName)
        ? email === ''
          ? ''
          : email
        : user.displayName.trim();
      const photo = isUndefinedOrNullOrEmpty(user.photoURL)
        ? ''
        : user.photoURL.trim();
      let photoURL = photo;
      if (photo === '' && email !== '') {
        photoURL =
          'https://www.gravatar.com/avatar/' +
          Md5.hashStr(email) +
          '?d=identicon';
      }

      const data = {
        uid: user.uid,
        displayName: name,
        userEmail: email,
        phoneNumber: phone,
        id: user.uid,
        name,
        email,
        phone,
        photoURL,
      };

      await userRef.update(data);
    }
    return Promise.resolve(user);
  }

  resetPassword(email: string) {
    // console.log('AuthService => resetPassword()');
    const crmUrl =
      environment.firebase.projectId === FIREBASE_PROJECT_ID_PROD
        ? CRM_URL_PROD
        : CRM_URL_DEV;
    const actionCodeSettings = {
      url: crmUrl,
      handleCodeInApp: true,
    };
    return this.afAuth
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => {}) // console.log("We've sent you a password reset link")
      .catch((error) => console.error(error.message));
  }

  signOut(): Promise<void> {
    // console.log('AuthService => signOut()');
    return this.afAuth.signOut();
  }
}
