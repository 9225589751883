// https://developers.facebook.com/docs/graph-api/reference/post/#example

import { ICoordinates } from './ICoordinates';
import { IEntities } from './IEntities';
import { IEvent } from './IEvent';
import { ITargeting } from './ITargeting';

export const enum TIMELINE_VISIBILITY {
    HIDDEN = 'HIDDEN',
    NORMAL = 'NORMAL',
    FORCED_ALLOW = 'FORCED_ALLOW',
}

/*
    id,
    createdTime,
    message,
    from,
    attachments,
*/
export interface IPost {

    // The Post ID
    id: string; // token with structure: Post ID

    // Action links
    actions: any[];   // list

    // The admin creator of a Page Post. Only available if there exists more than one admin for the page.
    adminCreator?: string; // BusinessUser|User|Application

    // The only objectives under which this post can be advertised.
    allowedAdvertisingObjectives?: any[];  // list<string>

    // Information about the app this post was published by.
    application?: any; // Application
    applicationId: string;

    // The backdated time for backdate post.
    // For regular post, this field will be set to null.
    backdatedTime: any; // datetime

    // The call to action type used in any Page posts for mobile app engagement ads.
    callToAction?: any;  // struct with keys: type, value

    // Whether the page viewer can send a private reply to this post.
    canReplyPrivately?: boolean;

    // Sub-shares of a multi-link share post.
    childAttachments: any[];  // list

    // Used in the UI to attach the list of reply comments to build a tree-view.
    comments?: any;

    // If comments are being mirrored to an external site, this function returns the domain of that external site.
    commentsMirroringDomain?: string;  // domain

    // ID of a company in the CRM linked to this "Note".
    companyId?: string;

    // ID of a contact in the CRM linked to this "Note".
    contactId?: string;

    // An array of information about the attachment to the post.
    coordinates?: ICoordinates;

    // The time the post was published, expressed as UNIX timestamp
    createdAt: any;   // datetime
    createdBy: string;

    // When the post is used for promotion, what kind of optimization could the resulting ad be used for.
    deliveryGrowthOptimizations?: any; // list<enum>

    // The list of entities such as links and hashtags in the post message.
    entities: IEntities;

    // If this Post has a place, the event associated with the place.
    event?: IEvent;

    expandedHeight: number;
    expandedWidth: number;

    // Object that controls news feed targeting for this post.
    // Anyone in these groups will be more likely to see this post, others will be less likely, but may still see it anyway.
    // Any of the targeting fields shown here can be used, none are required (applies to Pages only).
    feedTargeting?: any;

    // Information about the formatting for the message.
    formatting?: any;    // enum

    // The ID of the user, page, group, or event that published the post.
    from: any;

    // Name of the user, page, group, or application that created the post.
    fromName: string;

    // Full size picture from attachment.
    // -- OLD -- If the photo's largest dimension exceeds 720 pixels, it is resized, with the largest dimension set to 720.
    fullPicture?: string;

    height?: number;

    // A link to an icon representing the type of this post.
    icon: string;

    // The place where the story was made but not specifically added to the post by the user.
    implicitPlace?: any; // Place

    // Whether the post can be promoted on Instagram.
    // It returns the enum "eligible" if it can be promoted.
    // Otherwise it returns an enum for why it cannot be promoted.
    instagramEligibilty?: any;   // enum

    // Whether the post can be promoted on Facebook in-stream placement.
    // It returns enum "eligible" when video is eligible.
    // Otherwise it returns an enum for why it cannot be promoted.
    instreamEligibility?: any;  // enum

    // Whether or not the post references an app.
    isAppShare?: boolean;

    // Whether the post is eligible for promotion.
    isEligibleForPromotion?: boolean;

    // Whether the post has expiration time that has passed.
    isExpired: boolean;

    // Whether a post has been set to hidden.
    isHidden: boolean;

    // Returns True if the post was created inline when creating ads.
    isInlineCreated?: boolean;

    // Whether this post can be promoted in Instagram.
    isInstagramEligible?: boolean;

    // Whether the post is currently popular.
    // Based on whether the total actions as a percentage of reach exceeds a certain threshold.
    isPopular: boolean;

    // Indicates whether a scheduled post was published.
    // (applies to scheduled Page Post only, for users post and instanlty published posts this value is always true).
    isPublished: boolean;

    // Whether the post is a spherical video post.
    isSpherical: boolean;

    // Whether the post containing a live video can be promoted.
    // It returns an empty list when video is eligibile.
    // Otherwise it returns a list of reasons why it cannot be promoted.
    liveVideoEligibility?: any[];    // list<enum>

    // The message written in the post.
    message: string;

    // Profiles mentioned or tagged in a message. This is an object with a unique key for each mention or tag in the message.
    messageTags: any[];

    // Whether automatically select the order of the links in multi-link share post when used in an ad.
    multiShareEndCard?: boolean;

    // Whether automatically select the order of the links in multi-link share post when used in an ad.
    multiShareOptimized?: boolean;

    // ID of an organization in the CRM linked to this "Note".
    organizationId?: string;

    // The ID of a parent post for this post, if it exists.
    // For example, if this story is a 'Your Page was mentioned in a post' story,
    //    the parent_id will be the original post where the mention happened.
    parentId: any;  // token with structure: Post ID

    // The permanent static URL to the post on www.facebook.com.
    // Example: https://www.facebook.com/FacebookforDevelopers/posts/10153449196353553
    permalinkUrl?: any; // uri

    // ID of the place associated with the post.
    place?: any; // Place

    // Attached poll object if available.
    poll?: any;  // Question
    pollId?: string;

    // The privacy settings for a post.
    privacy?: any;   // Privacy

    // ID of post to use for promotion for stories that cannot be promoted directly.
    promotableId?: any;  // token with structure: Post ID

    // Status of the promotion, if the post was promoted.
    promotionStatus?: string;

    // A list of properties for any attached video, for example, the length of the video.
    properties?: any[];  // list

    // Total number of comments plus number of reactions of a post.
    publishingStats: any;   // number

    // User's reaction to post.
    reactionType?: any;

    // UNIX timestamp of the scheduled publish time for the post
    scheduledPublishTime: any; // float

    // Number of times the post has been shared.
    shares: any; // struct with keys: count

    // Description of the type of a status update.
    statusType?: string;

    // Text of stories not intentionally generated by users, such as those generated when two users become friends.
    // You must have the "Include recent activity stories" migration enabled in your app to retrieve this field.
    story?: string;

    // The list of tags in the post description.
    storyTags?: any[];   // list

    // Whether user is subscribed to the post
    subscribed?: boolean;

    // The profile this was posted on if different from the author.
    target?: any;    // Profile

    // Object that limited the audience for this content.
    // Anyone not in these demographics will not be able to view this content.
    // This will not override any Page - level demographic restrictions that may be in place.
    targeting?: ITargeting;

    // Timeline visibility information of the post.
    timelineVisibility: TIMELINE_VISIBILITY;

    // A list of translations provided by the author of the post, represented in key-value pairs.
    // The keys are locales and the values are translations in corresponding locales.
    translations?: any[];   // list<KeyValue:string,string>

    // The time the post was last updated, which occurs when a user comments on the post.
    updatedAt: any;   // datetime
    updatedBy: string;

    // ID of the user or Page the post was shared from.
    via: any;   // User|Page

    // Whether the post can be promoted with different video buying options.
    // It returns an empty list when video is eligible.
    // Otherwise it returns a list of reasons why thepost cannot be promoted.
    videoBuyingEligibility?: any[];    // list<enum>

    // An array of information about the attachment to the post.
    width?: number;

    // Whether this post will be autocropped if deliver to Instagram.
    willBeAutocroppedWhenDeliverToInstagram?: boolean;

    // -----------------------------------
    index?: number;
    isDeleted?: boolean;
    deletedAt?: any;
    deletedBy?: any;
}

/*
Edge                Description
------------------  ---------------------------------------------------------
attachments         Any attachments that are associated with the story
comments            Comments made on this
dynamic_posts       All dynamic ad creatives
insights            Insights for this post (only for Pages).
likes               People who like this
picture             The picture scraped from any link included with the post.
reactions           People who reacted on this
seenapp @workplace  People who have seen this
sharedposts         Shared posts
sponsor_tags        An array sponsor pages tagged in the post
to                  Profiles mentioned or targeted in this post.
*/
